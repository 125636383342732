export const bcnGeneralAdmin = "bcn:admin";
// Can create manual transactions
export const bcnManualTransactionCreator = "bcn:manual-transaction-creator";
// Can edit monthly reports
export const bcnMonthlyReportEditor = "bcn:monthly-report-editor";
// Can see and manage users
export const bcnUserAccountManager = "bcn:user-account-manager";
// Can see and manage orders
export const bcnCatalogOrderManager = "bcn:catalog-order-manager";
export const bcnEventAdmin = "bcn:event-admin";

export const bcnSettingsManager = "bcn:settings-manager";

export const sponsorAdmin: CompanyRoleKey = 201;
export const sponsorAccounting: CompanyRoleKey = 202;
export const sponsorSales: CompanyRoleKey = 203;
export const memberAdmin: CompanyRoleKey = 101;
export const memberUser: CompanyRoleKey = 102;

export type AdminRoleKey =
  | "bcn:admin"
  | "bcn:manual-transaction-creator"
  | "bcn:monthly-report-editor"
  | "bcn:user-account-manager"
  | "bcn:catalog-order-manager"
  | "bcn:settings-manager"
  | "bcn:impersonator"
  | "bcn:event-admin"
  | "bcn:sysadmin"
  | "no-one-should-have-this-role";

export type CompanyRoleKey = 201 | 202 | 203 | 101 | 102;

export type CompanyRoleOption = {
  label: string;
  value: CompanyRoleKey;
};

export enum CompanyRoles {
  MemberAdmin = 101,
  MemberUser = 102,
  SponsorAdmin = 201,
  SponsorAccounting = 202,
  SponsorSales = 203,
}

export const companyRoleOptions: CompanyRoleOption[] = [
  { label: "Member Admin", value: 101 },
  { label: "Member User", value: 102 },
  { label: "Sponsor Admin", value: 201 },
  { label: "Sponsor Accounting", value: 202 },
  { label: "Sponsor Sales", value: 203 },
];

type RoleMap = {
  [key in CompanyRoleKey]: string;
};

export const companyRoleMap: RoleMap = {
  101: "Member Admin",
  102: "Member User",
  201: "Sponsor Admin",
  202: "Sponsor Accounting",
  203: "Sponsor Sales",
};
