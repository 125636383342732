import { apiClient } from "services/api/client";
import { catalog } from "../../prefixes";

import {
  IPagedResult,
  ICatalogProduct,
  IProductPageQuery,
  ICatalogCategory,
  ICatalogInfo,
} from "services/api/types";

export const index = async (query: IProductPageQuery) => {
  const url = `${catalog}/products`;
  const response = await apiClient.post<IPagedResult<ICatalogProduct>>(
    url,
    query
  );
  return response;
};

export const categories = async () => {
  const url = `${catalog}/categories`;
  const response = await apiClient.get<ICatalogCategory[]>(url);
  return response;
};

export const getCatalogInfo = async () => {
  const url = `${catalog}/popularCategories`;
  const response = await apiClient.get<ICatalogInfo>(url);
  return response.data;
};

export const getNewestProducts = async () => {
  const url = `${catalog}/newestProducts`;
  const response = await apiClient.get<ICatalogProduct[]>(url);
  return response.data;
};

export const getRandomProducts = async () => {
  const url = `${catalog}/randomProducts`;
  const response = await apiClient.get<ICatalogProduct[]>(url);
  return response.data;
};
