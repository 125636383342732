import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { MaskedNumberInput } from "areas/Shared/components/maskedInputs/MaskedNumberInput";
import { formatNumber, round } from "utils/number";

type Props = {
  pointValue: number | undefined;
  dollarValue: number | undefined;
  dollarsPerPoint: number;
  onChange: (evt: { dollars?: number; points?: number }) => void;
  pointsError?: string;
  dollarsError?: string;
  showPointsError?: boolean;
  showDollarsError?: boolean;
  allowNegative: boolean;
  dollarLabel?: string;
  pointLabel?: string;
  disabled?: boolean;
};

const DollarPointsInput = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: theme.spacing(1),
      },
      error: {
        gridColumn: "1 / 3",
        marginLeft: theme.spacing(1),
        color: theme.palette.error.main,
      },
    })
  );

  const classes = useStyles();

  const handlePointsChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = evt.target;

    if (value.trim().length === 0) {
      props.onChange({ dollars: undefined, points: undefined });
    } else {
      const pointsInt = parseInt(value, 0);
      const dollars = round(pointsInt * props.dollarsPerPoint, 2);
      const args = { dollars: dollars, points: pointsInt };

      props.onChange(args);
    }
  };

  const handleDollarsChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const adjustedValue = evt.target.value.replace("$", "");
    const dollarsFloat = parseFloat(adjustedValue);

    const intermediatePoints = dollarsFloat / props.dollarsPerPoint;
    const fixedPoints = round(intermediatePoints, 2);

    const args = {
      dollars: isNaN(dollarsFloat) ? undefined : dollarsFloat,
      points: isNaN(fixedPoints) ? undefined : fixedPoints,
    };

    // Added 10/19/21 --- Attempting to short circuit the updates here.
    // Don't raise onChange if we already have the same dollar value.
    // BUT make sure to round the values first so we're not running into
    // JavaScript floating point issues (3923 vs. 3922.99999999995)
    const roundedDollarValue = round(props.dollarValue, 2);
    const roundedDollarsFloat = round(dollarsFloat, 2);

    if (roundedDollarValue !== roundedDollarsFloat) {
      props.onChange(args);
    }
  };

  return (
    <div className={classes.root}>
      <FormControl>
        <TextField
          disabled={props.disabled}
          fullWidth={true}
          error={props.showDollarsError}
          variant="outlined"
          value={formatNumber(props.dollarValue, 2) ?? ""}
          label={props.dollarLabel || "Amount (Dollars)"}
          onChange={handleDollarsChange}
          margin="dense"
          InputProps={{
            inputComponent: MaskedNumberInput as any,
            inputProps: {
              allowNegative: props.allowNegative,
              prefix: "$",
              decimalScale: 2,
            },
          }}
        />
        {props.showDollarsError ? (
          <FormHelperText className={classes.error}>
            {props.dollarsError}
          </FormHelperText>
        ) : undefined}
      </FormControl>
      <FormControl>
        <TextField
          // type="number"  // This causes a parsing issue
          fullWidth={true}
          disabled={props.disabled}
          value={props.pointValue ?? ""}
          error={props.showPointsError}
          variant="outlined"
          onChange={handlePointsChange}
          label={props.pointLabel || "Amount (Points)"}
          InputProps={{
            inputComponent: MaskedNumberInput as any,
            inputProps: {
              allowNegative: props.allowNegative,
              prefix: "",
              decimalScale: 0,
            },
          }}
          margin="dense"
        />
        {props.showPointsError ? (
          <FormHelperText className={classes.error}>
            {props.pointsError}
          </FormHelperText>
        ) : undefined}
      </FormControl>
    </div>
  );
};

export default DollarPointsInput;
