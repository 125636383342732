import React from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { MaskedNumberInput } from "areas/Shared/components/maskedInputs/MaskedNumberInput";

type Props = {
  className: string;
  value: number;
  min?: string | number;
  onChanged: (points: number) => void;
  errorClassName: string;
  errorMessage: string;
};

const QuantityTextBox = (props: Props) => {
  return (
    <FormControl className={props.className}>
      <TextField
        // type="number"
        variant="outlined"
        margin="dense"
        InputProps={{
          inputComponent: MaskedNumberInput as any,
          inputProps: {
            allowNegative: false,
            prefix: "",
            decimalScale: 0,
            min: props.min,
          },
        }}
        value={props.value ?? ""}
        id="quantity"
        onFocus={(arg1: React.FocusEvent<HTMLInputElement>) => {
          if (arg1.target) {
            arg1.target.select();
          }
        }}
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = evt.target;
          if (value.trim().length > 0) {
            const parsed = parseInt(value, 0);
            props.onChanged(parsed);
          }
        }}
        label="Quantity"
      />
      <FormHelperText variant="standard" className={props.errorClassName}>
        {props.errorMessage}
      </FormHelperText>
    </FormControl>
  );
};

export default QuantityTextBox;
