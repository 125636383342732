import React from "react";
import TextField from "@material-ui/core/TextField";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { LookupListType } from "services/api/types";
import ListTypeNotes from "./ListTypeNotes";

type Props = {
  typeName: LookupListType;
  data: { id?: any };
  onSave: (data: { id?: any }) => Promise<void>;
  handleClose: () => void;
};

const GenericFormModal = (props: Props) => {
  const [editData, setEditData] = React.useState<{ id?: any }>(
    Object.assign({}, props.data)
  );

  const booleanRenderer = (
    propName: string,
    value: boolean,
    readOnly: boolean
  ) => {
    return (
      <div>
        <FormControlLabel
          disabled={readOnly}
          control={
            <Checkbox
              checked={value}
              onChange={(evt) => handleChange(propName, evt.target.checked)}
              size="small"
            />
          }
          label={propName}
        />
      </div>
    );
  };

  const handleChange = (propName: string, value: any) => {
    const newData = Object.assign({}, editData, { [propName]: value });
    setEditData(newData);
  };

  const stringRenderer = (
    value: string,
    propName: string,
    readOnly: boolean
  ) => {
    return (
      <div>
        <TextField
          fullWidth
          placeholder={propName}
          disabled={readOnly}
          onChange={(evt) => handleChange(propName, evt.target.value)}
          label={propName}
          variant="outlined"
          margin="dense"
          value={value}
        />
      </div>
    );
  };

  const numberRenderer = (
    value: number,
    propName: string,
    readOnly: boolean
  ) => {
    return (
      <div>
        <TextField
          fullWidth
          placeholder={propName}
          disabled={readOnly}
          label={propName}
          onChange={(evt) => handleChange(propName, evt.target.value)}
          variant="outlined"
          margin="dense"
          type="number"
          value={value}
        />
      </div>
    );
  };

  const objectRenderer = (value: object, readOnly: boolean) => {
    return <span></span>;
  };

  const readOnlyPropNames = ["id", "legacyId", "createdOn", "updatedOn"];

  const knownNumberPropNames = ["supplierProfileSortOrder"];

  const renderEachProperty = () => {
    const result: JSX.Element[] = [];
    for (const propName in editData) {
      const isKnownNumber = knownNumberPropNames.indexOf(propName) >= 0;
      const type = isKnownNumber ? "number" : typeof editData[propName];
      const shouldBeHidden = readOnlyPropNames.indexOf(propName) >= 0;

      console.log({ propName, type });

      if (!shouldBeHidden) {
        switch (type) {
          case "boolean":
            result.push(
              booleanRenderer(propName, editData[propName] as boolean, false)
            );
            break;
          case "string":
            result.push(
              stringRenderer(editData[propName] as string, propName, false)
            );
            break;
          case "number":
            result.push(
              numberRenderer(editData[propName] as number, propName, false)
            );
            break;
          default:
            result.push(objectRenderer(editData[propName], shouldBeHidden));
        }
      }
    }

    return result;
  };

  const handleSave = async () => {
    await props.onSave(editData);
    props.handleClose();
  };

  const getSingularName = (listName: LookupListType) => {
    switch (listName) {
      case "Brands":
        return "Brand";
      case "Categories":
        return "Category";
      case "EventTypes":
        return "Event Type";
      case "Markets":
        return "Market";
      case "Products":
        return "Product";
      case "RelationshipTypes":
        return "Relationship Type";
      case "Units":
        return "Unit";
      default:
        return listName;
    }
  };

  return (
    <ResponsiveModalShell
      handleSave={handleSave}
      handleClose={props.handleClose}
      title={`${props.data.id ? "Edit" : "Create"} ${getSingularName(
        props.typeName
      )}`}
    >
      {renderEachProperty()}
      <ListTypeNotes listType={props.typeName} />
    </ResponsiveModalShell>
  );
};

export default GenericFormModal;
