import { apiClient } from "services/api/client";
import { IDataMergeViewModel } from "services/api/types";

export const getMergeConflicts = async (dataMergeId: string) => {
  const url = `administrator/dataMerges/${dataMergeId}/mergeConflicts`;
  const response = await apiClient.get<IDataMergeViewModel>(url);
  return response.data;
};

export const useDiscardedValue = async (mergeActionId: string) => {
  const url = `administrator/resolvedConflicts/${mergeActionId}`;
  const response = await apiClient.post(url);
  return response;
};
