import React from "react";
import TextField from "@material-ui/core/TextField";

type Props = {
  value: string;
  touched: boolean | undefined;
  error: string | undefined;
  handleChange: (e: React.ChangeEvent<any>) => void;
};

const CityField = (props: Props) => {
  return (
    <TextField
      variant="outlined"
      id="city"
      margin="dense"
      label="City"
      fullWidth
      value={props.value}
      onChange={props.handleChange}
      error={props.touched && Boolean(props.error)}
      name="city"
      placeholder="City"
      helperText={props.touched && props.error}
    />
  );
};

export default CityField;
