import React from "react";
import MemberInfoWrapper from "./MemberInfoWrapper";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";

type Props = {
  companyId: string;
  handleClose: () => void;
};

const MemberInfoDialogWrapper = (props: Props) => {
  return (
    <ResponsiveModalShell
      title="Member Details"
      handleClose={props.handleClose}
    >
      <MemberInfoWrapper companyId={props.companyId} />
    </ResponsiveModalShell>
  );
};

export default MemberInfoDialogWrapper;
