import { apiClient } from "services/api/client";
import { getFileName } from "services/api/modules/helpers";

import {
  CompanyDataQualityType,
  ICompanyInfo,
  ICompanyStats,
  IDataQualityStats,
} from "../../types";

const statisticsUrl = "administrator/statistics";

export const getCompanyStats = async (activeOnly: boolean) => {
  const url = `${statisticsUrl}/companies`;
  const response = await apiClient.get<ICompanyStats>(url, {
    params: {
      activeOnly,
    },
  });
  return response;
};

export const getDataQualityStats = async () => {
  const url = `${statisticsUrl}/dataQuality`;
  const response = await apiClient.get<IDataQualityStats>(url);
  return response;
};

export const getDataQualityList = async (type: CompanyDataQualityType) => {
  const url = `${statisticsUrl}/dataQuality/${type}`;
  const response = await apiClient.get<ICompanyInfo[]>(url);
  return response;
};

export const getDataQualityListExport = async (
  type: CompanyDataQualityType
) => {
  const url = `${statisticsUrl}/dataQuality/${type}/export`;
  const response = await apiClient.post(url, {}, { responseType: "blob" });
  const fileName = getFileName(response, `${type}.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};
