import { History } from "history";
import { Order } from "components/DataTables/MyGenericTableTypes";

export type PagingParams<T> = {
  page: string;
  pageSize: string;
  order: Order;
  orderBy: keyof T | undefined;
  search: string;
};

export type PublicSetArguments<TData, TPage extends PagingParams<TData>> = {
  prop: keyof Omit<TPage, "page" | "pageSize" | "order" | "orderBy" | "search">;
  value: string | undefined;
  forcePage0?: boolean;
};

export type PrivateSetArguments<TData, TPage extends PagingParams<TData>> = {
  prop: keyof TPage;
  value: string | undefined;
  forcePage0?: boolean;
};

export type SetResult = "set" | "ignored";

export class PagingQueryManager<TData, TPage extends PagingParams<TData>> {
  constructor(
    protected history: History,
    protected query: URLSearchParams,
    protected defaultParams: TPage
  ) {}

  getQueryValue = (
    prop: keyof Omit<
      TPage,
      "page" | "pageSize" | "order" | "orderBy" | "search"
    >
  ): string => {
    return this.getRawValue(prop);
  };

  get page() {
    const rawValue = this.getRawValue("page");
    return parseInt(rawValue, 0);
  }

  get search() {
    return this.getRawValue("search");
  }

  setSearch = (value: string | undefined) => {
    const defaultOrderBy = this.defaultParams["orderBy"];
    this.setMultipleValuesWithUpdate(
      { prop: "search", value },
      { prop: "page", value: undefined },
      { prop: "orderBy", value: defaultOrderBy as string }
    );
  };

  setPage = (value: number) => {
    this.setValueWithUpdate({ prop: "page", value: value.toString() });
  };

  get pageSize() {
    const rawValue = this.getRawValue("pageSize");
    return parseInt(rawValue, 0);
  }

  setPageSize = (value: number) => {
    this.setValueWithUpdate({ prop: "pageSize", value: value.toString() });
  };

  get order() {
    const rawValue = this.getRawValue("order");
    return rawValue as Order;
  }

  setOrder = (value: Order) => {
    this.setValueWithUpdate({ prop: "order", value });
  };

  get orderBy() {
    const rawValue = this.getRawValue("orderBy");

    if (rawValue) {
      return rawValue as keyof TData;
    }

    return this.defaultParams["orderBy"];
  }

  setOrderBy = (value: keyof TData | undefined) => {
    this.setValueWithUpdate({
      prop: "orderBy",
      value: value ? (value as string) : undefined,
    });
  };

  // Public method prevents usage with some props. This allows all.
  protected getRawValue = (prop: keyof TPage): string => {
    const queryValue = this.mySimpleQueryStringGetter(prop as string);
    return queryValue ?? this.defaultParams[prop as string];
  };

  protected setMultipleValuesWithUpdate = (
    ...args: PrivateSetArguments<TData, TPage>[]
  ) => {
    const results: Array<SetResult> = [];

    args.forEach((arg) => {
      const result = this.setValue(arg);
      results.push(result);
    });

    const someSet = results.some((r) => r === "set");
    if (someSet) {
      this.history.push({
        search: this.query.toString(),
      });
    } else {
      console.warn("NONE SET!");
    }
  };

  protected setSingleValueWithUpdate = (
    args: PublicSetArguments<TData, TPage>
  ) => {
    const setResult = this.setValue(args);
    if (setResult === "set") {
      this.history.push({
        search: this.query.toString(),
      });
    }
  };

  protected setValueWithUpdate = (args: PrivateSetArguments<TData, TPage>) => {
    const setResult = this.setValue(args);
    if (setResult === "set") {
      this.history.push({
        search: this.query.toString(),
      });
    }
  };

  protected setValue = (args: PrivateSetArguments<TData, TPage>): SetResult => {
    const { prop, value, forcePage0 } = args;

    if (forcePage0) {
      this.query.delete("page");
    }

    const oldValue = this.getRawValue(prop);

    if (oldValue !== value) {
      if (!value) {
        this.query.delete(prop as string);
      } else {
        this.query.set(prop as string, value);
      }

      return "set";
    }

    return "ignored";
  };

  protected mySimpleQueryStringGetter = (key: string) => {
    const search = window.location.search ?? "";

    const keyValuePairs = search.replace("?", "").split("&");
    const matchedPair = keyValuePairs.find((kv) => kv.startsWith(`${key}=`));

    if (!matchedPair) return null;

    return matchedPair.split("=")[1];
  };
}
