import React from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import { IMarket } from "services/api/types";
import MenuItem from "@material-ui/core/MenuItem";

const marketToOption = (market: IMarket) => {
  return { label: market.description, value: market.id };
};

type Props = {
  value: string | undefined;
  touched: boolean | undefined;
  error: string | undefined;
  handleChange: (e: React.ChangeEvent<any>) => void;
  markets: IMarket[];
  setTouched?: () => void;
};

// If you're searching for <Select you may be interested in this
// example that uses the TextField component with the "select"
// property to render a select.

const MarketDropdown = (props: Props) => {
  return (
    <FormControl fullWidth>
      <TextField
        select
        variant="outlined"
        margin="dense"
        label="Market"
        onFocus={props.setTouched}
        error={props.touched && Boolean(props.error)}
        id="marketId"
        name="marketId"
        onChange={props.handleChange}
        value={props.value ?? ""}
        helperText={props.touched && props.error}
      >
        {props.markets.map(marketToOption).map((option) => {
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </TextField>
    </FormControl>
  );
};

export default MarketDropdown;
