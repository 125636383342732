import React, { CSSProperties } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

type Props = {
  startValue: Date | null;
  endValue: Date | null;
  onStartDateChanged: (value: Date | null) => void;
  onEndDateChanged: (value: Date | null) => void;
  title: string;
  style?: CSSProperties;
  startError?: string;
  endError?: string;
};

const DateRange = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      dates: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: theme.spacing(2),
      },
      error: {
        color: "red",
        display: "inline-block",
        marginLeft: theme.spacing(2),
      },
    })
  );

  const classes = useStyles();
  const endDateBeforeStart =
    props.startValue && props.endValue && props.startValue > props.endValue;

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={props.style} className={classes.root}>
        <Typography variant="caption" color="primary">
          {props.title}
          {endDateBeforeStart ? (
            <span className={classes.error}>End date is before start date</span>
          ) : undefined}
        </Typography>
        <div className={classes.dates}>
          <FormControl>
            <KeyboardDatePicker
              style={{ margin: 0 }}
              format="MM/dd/yyyy"
              label="Start"
              required={true}
              error={Boolean(props.startError)}
              helperText={props.startError}
              inputVariant="outlined"
              onErrorCapture={(evt) => alert("Hey")}
              margin="dense"
              value={props.startValue}
              onChange={(e) => props.onStartDateChanged(e)}
            />
          </FormControl>
          <FormControl>
            <KeyboardDatePicker
              style={{ margin: 0 }}
              format="MM/dd/yyyy"
              label="End"
              required={true}
              error={Boolean(props.endError)}
              helperText={props.endError}
              inputVariant="outlined"
              margin="dense"
              value={props.endValue}
              onChange={(e) => props.onEndDateChanged(e)}
            />
          </FormControl>
        </div>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default DateRange;
