import React from "react";
import {
  AdminRoleKey,
  CompanyRoleKey,
  CompanyRoles,
  memberAdmin,
  sponsorAdmin,
} from "types/roles";
import { CompanyType, ICompany, IAccountInfo } from "services/api/types";
import { RouteComponentProps } from "react-router-dom";

import TransactionsContainer, {
  TransctionScreenMode,
} from "areas/Shared/Transactions/TransactionsContainer";

const CompanyUsersContainer = React.lazy(
  () => import("areas/Shared/Users/CompanyUsersContainer")
);
const Overview = React.lazy(() => import("../CompanyOverviewContainer"));
const Contacts = React.lazy(
  () => import("../../Contacts/ContactDetailsContainer")
);

const SponsorMemberUploads = React.lazy(
  () => import("areas/Admin/Sponsors/SponsorMemberUploads")
);

const Locations = React.lazy(
  () => import("../../Locations/LocationsContainer")
);
const PurchaserSuppliersContainer = React.lazy(
  () => import("../../PurchaserSuppliers/PurchaserSuppliersContainer")
);
const SupplierPurchasersContainer = React.lazy(
  () => import("../../SupplierPurchasers/SupplierPurchasersContainer")
);
const StatementsContainer = React.lazy(
  () => import("../../Statements/StatementsContainer")
);
const MonthlyReports = React.lazy(
  () => import("../../MonthlyReports/MonthlyReportsContainer")
);
const OrdersListContainer = React.lazy(
  () => import("../../Orders/OrdersListContainer")
);
const EventHistoryContainer = React.lazy(
  () => import("../../Events/EventHistoryContainer")
);
const Notes = React.lazy(() => import("../../../Admin/Notes/NotesContainer"));
const Categories = React.lazy(
  () => import("../../Categories/CategoriesContainer")
);
const BrandsProducts = React.lazy(
  () => import("../../BrandsProducts/BrandsProductsContainer")
);
const PointsHistoryContainer = React.lazy(
  () => import("areas/Shared/PointsHistory/PointsHistoryContainer")
);
const MembershipDetailsPage = React.lazy(
  () => import("areas/Members/MembershipDetailsPage")
);

const EmailHistoryContainer = React.lazy(
  () => import("areas/Admin/Email/CompanyEmailHistoryContainer")
);

export type RenderContext = {
  routeProps: RouteComponentProps<any>;
  isAdminNotImpersonating: boolean;
  usersAdminRoles: AdminRoleKey[];
  companyType: CompanyType;
  usersCompanyRole: CompanyRoleKey | undefined;
  currentAccount: IAccountInfo;
};

export type ValidTabCountProperties = keyof Pick<ICompany, "eventCount">;

export type CompanyScreenDef = {
  label: string;
  routeSuffix: string;
  nonAdminCompanyTypes: CompanyType[] | "All";
  adminCompanyTypes: CompanyType[];
  companyPermissions: CompanyRoleKey[];
  adminPermission: AdminRoleKey[];
  renderComponent: (context: RenderContext) => JSX.Element;
  badgeCountProperty?: ValidTabCountProperties;
  // Make it a route we can get to, but not by clicking on a tab
  hideTab?: boolean;
};

const getModeForTransactionsContainer = (
  context: RenderContext
): TransctionScreenMode => {
  if (context.isAdminNotImpersonating) {
    if (
      context.usersAdminRoles.some(
        (r) => r === "bcn:manual-transaction-creator"
      )
    ) {
      return "transaction-admin";
    }

    return "general-admin";
  }
  if (context.companyType === "Member") return "member";
  if (context.companyType === "Sponsor") return "sponsor";
  throw Error("Unabled to get mode for transactions screen");
};

// Screens/tabs appear in the same order in the UI
export const companyScreenDefs: CompanyScreenDef[] = [
  {
    adminCompanyTypes: ["Competitor", "Member", "Sponsor", "Prospect"],
    adminPermission: ["bcn:admin"],
    routeSuffix: "overview",
    companyPermissions: [
      CompanyRoles.MemberAdmin,
      CompanyRoles.SponsorAdmin,
      CompanyRoles.SponsorAccounting,
      CompanyRoles.SponsorSales,
    ],
    label: "Overview",
    nonAdminCompanyTypes: ["Sponsor", "Member"],
    renderComponent: (context) => {
      const { usersAdminRoles, usersCompanyRole } = context;
      const canViewPoints =
        (context.isAdminNotImpersonating &&
          usersAdminRoles.some((r) => r === "bcn:admin")) ||
        Boolean(usersCompanyRole);

      const shouldBeInEventMode = window.location.href
        .toLocaleLowerCase()
        .includes("/events/");

      return (
        <Overview
          isAdminNotImpersonating={context.isAdminNotImpersonating}
          eventMode={shouldBeInEventMode}
          canEditCompanyInfo={usersAdminRoles.some((r) => r === "bcn:admin")}
          canViewPoints={canViewPoints}
          companyType={context.companyType}
          canAddNotes={usersAdminRoles.some((r) => r === "bcn:admin")}
          canViewEvents={usersAdminRoles.some((r) => r === "bcn:admin")}
          canViewNotes={usersAdminRoles.some((r) => r === "bcn:admin")}
          canAddTransactions={usersAdminRoles.some(
            (r) => r === "bcn:manual-transaction-creator"
          )}
        />
      );
    },
  },
  {
    renderComponent: (context) => {
      return <MembershipDetailsPage hasToolbar={false} />;
    },
    label: "Membership Details",
    routeSuffix: "membership-details",
    adminPermission: [],
    adminCompanyTypes: [],
    companyPermissions: [CompanyRoles.MemberAdmin, CompanyRoles.MemberUser],
    nonAdminCompanyTypes: ["Member"],
  },
  {
    renderComponent: (context) => {
      const adminCanWrite =
        context.isAdminNotImpersonating &&
        context.usersAdminRoles.some((s) => s === "bcn:admin");

      const companyUserCanWrite =
        context.usersCompanyRole === sponsorAdmin ||
        context.usersCompanyRole === memberAdmin;

      return <Locations isReadOnly={!(adminCanWrite || companyUserCanWrite)} />;
    },
    companyPermissions: [
      CompanyRoles.MemberAdmin,
      CompanyRoles.SponsorAdmin,
      CompanyRoles.SponsorAccounting,
      CompanyRoles.SponsorSales,
    ],
    label: "Locations",
    nonAdminCompanyTypes: ["Sponsor", "Member"],
    routeSuffix: "locations",
    adminPermission: ["bcn:admin"],
    adminCompanyTypes: ["Competitor", "Sponsor", "Member", "Prospect"],
  },
  {
    companyPermissions: [
      CompanyRoles.MemberAdmin,
      CompanyRoles.SponsorAdmin,
      CompanyRoles.SponsorAccounting,
      CompanyRoles.SponsorSales,
    ],
    label: "Contacts",
    nonAdminCompanyTypes: ["Sponsor", "Member"],
    routeSuffix: "contacts",
    adminPermission: ["bcn:admin"],
    adminCompanyTypes: ["Competitor", "Sponsor", "Member", "Prospect"],
    renderComponent: (context) => {
      const adminCanWrite =
        context.isAdminNotImpersonating &&
        context.usersAdminRoles.some((s) => s === "bcn:admin");

      const companyUserCanWrite =
        context.usersCompanyRole === sponsorAdmin ||
        context.usersCompanyRole === memberAdmin;

      return (
        <Contacts
          {...context.routeProps}
          isReadOnly={!(adminCanWrite || companyUserCanWrite)}
        />
      );
    },
  },
  {
    renderComponent: (context) => {
      const canWrite =
        context.isAdminNotImpersonating &&
        context.usersAdminRoles.some((s) => s === "bcn:admin");

      return <Notes isReadOnly={!canWrite} />;
    },
    companyPermissions: [],
    label: "Notes",
    nonAdminCompanyTypes: [],
    routeSuffix: "notes",
    adminPermission: ["bcn:admin"],
    adminCompanyTypes: ["Competitor", "Sponsor", "Member", "Prospect"],
  },
  {
    renderComponent: (context) => {
      return (
        <PurchaserSuppliersContainer
          currentUserType={context.isAdminNotImpersonating ? "Admin" : "Member"}
        />
      );
    },
    companyPermissions: [CompanyRoles.MemberAdmin, CompanyRoles.SponsorAdmin],
    label: "Suppliers",
    nonAdminCompanyTypes: ["Member", "Sponsor"],
    routeSuffix: "suppliers",
    adminPermission: ["bcn:admin"],
    adminCompanyTypes: ["Member", "Sponsor"],
  },
  {
    renderComponent: (context) => {
      return (
        <SupplierPurchasersContainer
          currentUserType={
            context.isAdminNotImpersonating ? "Admin" : "Sponsor"
          }
        />
      );
    },
    companyPermissions: [
      CompanyRoles.SponsorSales,
      CompanyRoles.SponsorAdmin,
      CompanyRoles.SponsorAccounting,
    ],
    label: "Members",
    nonAdminCompanyTypes: ["Sponsor"],
    routeSuffix: "members",
    adminPermission: ["bcn:admin"],
    adminCompanyTypes: ["Sponsor", "Prospect", "Competitor"],
  },
  {
    renderComponent: (context) => {
      const canWrite =
        context.isAdminNotImpersonating &&
        context.usersAdminRoles.some((s) => s === "bcn:admin");

      return <Categories isReadOnly={!canWrite} />;
    },
    companyPermissions: [
      CompanyRoles.SponsorAdmin,
      CompanyRoles.SponsorAccounting,
      CompanyRoles.SponsorSales,
    ],
    label: "Categories",
    nonAdminCompanyTypes: ["Sponsor"],
    routeSuffix: "categories",
    adminPermission: ["bcn:admin"],
    adminCompanyTypes: ["Sponsor", "Prospect", "Competitor"],
  },
  {
    renderComponent: (context) => {
      return <BrandsProducts />;
    },
    companyPermissions: [],
    label: "Brands & Products",
    nonAdminCompanyTypes: [],
    routeSuffix: "brands-products",
    adminPermission: ["bcn:admin"],
    adminCompanyTypes: ["Sponsor", "Prospect", "Competitor"],
  },
  {
    renderComponent: (context) => {
      const mode = getModeForTransactionsContainer(context);
      const companyId = context.routeProps.match.params.companyId;
      return (
        <TransactionsContainer
          companyType={context.companyType}
          currentAccount={context.currentAccount}
          companyId={companyId}
          {...context.routeProps}
          mode={mode}
        />
      );
    },
    companyPermissions: [
      CompanyRoles.MemberAdmin,
      CompanyRoles.SponsorAdmin,
      CompanyRoles.SponsorAccounting,
    ],
    label: "Transactions",
    nonAdminCompanyTypes: ["Sponsor", "Member"],
    routeSuffix: "transactions",
    adminPermission: ["bcn:admin"],
    adminCompanyTypes: ["Sponsor", "Member", "Competitor"],
  },
  {
    renderComponent: (context) => {
      return <StatementsContainer currentAccount={context.currentAccount} />;
    },
    companyPermissions: [
      CompanyRoles.MemberAdmin,
      CompanyRoles.SponsorAdmin,
      CompanyRoles.SponsorAccounting,
    ],
    label: "Statements",
    nonAdminCompanyTypes: ["Sponsor", "Member"],
    routeSuffix: "statements",
    adminPermission: ["bcn:admin"],
    adminCompanyTypes: ["Sponsor", "Member", "Competitor"],
  },
  {
    renderComponent: (context) => {
      return (
        <PointsHistoryContainer
          mode="MemberPointsEarned"
          hasToolbar={false}
          {...context.routeProps}
        />
      );
    },
    companyPermissions: [],
    label: "Points Earned History",
    nonAdminCompanyTypes: [],
    routeSuffix: "pointsEarned",
    adminPermission: ["bcn:admin"],
    adminCompanyTypes: ["Member"],
  },
  {
    renderComponent: (context) => {
      return (
        <PointsHistoryContainer
          mode="SponsorPointsAwarded"
          hasToolbar={false}
          {...context.routeProps}
        />
      );
    },
    companyPermissions: [],
    label: "Points Awarded History",
    nonAdminCompanyTypes: [],
    routeSuffix: "pointsAwarded",
    adminPermission: ["bcn:admin"],
    adminCompanyTypes: ["Sponsor", "Competitor"],
  },
  {
    renderComponent: (context) => {
      return <OrdersListContainer />;
    },
    companyPermissions: [CompanyRoles.MemberAdmin, CompanyRoles.SponsorAdmin],
    label: "Orders",
    nonAdminCompanyTypes: ["Member"],
    routeSuffix: "orders",
    adminPermission: ["bcn:admin"],
    adminCompanyTypes: ["Member"],
  },
  {
    renderComponent: (context) => {
      return <EventHistoryContainer />;
    },
    companyPermissions: [],
    label: "Events",
    // badgeCountProperty: "eventCount",
    nonAdminCompanyTypes: [],
    routeSuffix: "events",
    adminPermission: ["bcn:admin"],
    adminCompanyTypes: ["Member", "Sponsor", "Competitor"],
  },
  {
    renderComponent: (context) => {
      return <CompanyUsersContainer />;
    },
    companyPermissions: [CompanyRoles.MemberAdmin, CompanyRoles.SponsorAdmin],
    label: "Users",
    nonAdminCompanyTypes: ["Sponsor", "Member"],
    routeSuffix: "users",
    adminPermission: ["bcn:user-account-manager"],
    adminCompanyTypes: ["Sponsor", "Member", "Competitor"],
  },
  {
    renderComponent: (context) => {
      return (
        <MonthlyReports
          companyType={context.companyType}
          {...context.routeProps}
        />
      );
    },
    companyPermissions: [],
    label: "Monthly Reports",
    nonAdminCompanyTypes: [],
    routeSuffix: "monthly-reports",
    adminPermission: ["bcn:admin"],
    adminCompanyTypes: ["Sponsor", "Competitor"],
  },
  {
    renderComponent: (context) => {
      return <SponsorMemberUploads {...context.routeProps} />;
    },
    label: "Member Uploads",
    companyPermissions: [],
    adminPermission: ["bcn:admin"],
    adminCompanyTypes: ["Sponsor", "Competitor"],
    routeSuffix: "member-uploads",
    nonAdminCompanyTypes: [],
    // Make it a route we can get to, but not by clicking on a tab
    hideTab: true,
  },
  {
    renderComponent: (context) => {
      return <EmailHistoryContainer {...context.routeProps} />;
    },
    label: "Email History",
    companyPermissions: [],
    adminPermission: ["bcn:admin"],
    adminCompanyTypes: ["Sponsor", "Member"],
    routeSuffix: "email-history",
    nonAdminCompanyTypes: [],
  },
];
