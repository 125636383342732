import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import * as appConstants from "applications/appConstants";

type ErrorBoundaryState = {
  hasError: boolean;
};

type ErrorBoundaryProps = {
  children?: React.ReactNode;
};

export default class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container style={{ backgroundColor: "#FDFDFE" }}>
          <Grid container>
            <Grid item xs={12}>
              {process.env.REACT_APP_BRANDING === appConstants.BuildPerks && (
                <img
                  style={{ width: "300px", margin: "2em 0" }}
                  src="/images/logo.png"
                  alt="logo"
                />
              )}
              {process.env.REACT_APP_BRANDING ===
                appConstants.BuildersClubNorth && (
                <img
                  style={{ width: "300px", margin: "2em 0" }}
                  src="/images/logo-horz.png"
                  alt="logo"
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <img src="/images/error guy.png" alt="Error" />
            </Grid>
            <Grid item xs={8}>
              <h1>Oops! Something went wrong.</h1>
              <p>
                We're sorry, but it looks like the page you're trying to access
                is currently unavailable. This might be due to a temporary
                technical issue, or the page might have been moved or removed.
              </p>
              <p>
                Click <a href="/">here</a> to go back to our homepage and
                explore other sections of our website.
              </p>
            </Grid>
          </Grid>
        </Container>
      );
    }

    return this.props.children;
  }
}
