import { apiClient } from "services/api/client";
import { IListedMonthlyReport } from "services/api/types";

const MONTHLY_REPORTS_URL = "sponsors/monthlyReports";

export const getReportList = async () => {
  const response = await apiClient.get<IListedMonthlyReport[]>(
    MONTHLY_REPORTS_URL
  );
  return response;
};

export const getRecentReports = async () => {
  const url = `${MONTHLY_REPORTS_URL}/recent`;
  const response = await apiClient.get<IListedMonthlyReport[]>(url);
  return response;
};
