import React from "react";
import ResponsiveDrawer from "components/Shell/ResponsiveDrawer";
import SponsorToolbarItems from "./SponsorToolbarItems";
import SponsorSidebar from "./SponsorSidebar";
import SignalRConnection from "../Shared/components/SignalRConnection";
import appTextStore from "stores/shared/appText";

type Props = {
  children: React.ReactNode;
};

const SponsorDrawer = (props: Props) => {
  React.useEffect(() => {
    appTextStore.ensureAllAppTextLoaded();
  }, []);

  return (
    <SignalRConnection groupName="CompanyUsers">
      <ResponsiveDrawer
        renderMobileToolbarItems={() => <SponsorToolbarItems />}
        renderDesktopToolbarItems={() => <SponsorToolbarItems />}
        renderSidebarContent={() => <SponsorSidebar />}
      >
        {props.children}
      </ResponsiveDrawer>
    </SignalRConnection>
  );
};

export default SponsorDrawer;
