import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { AdminPermissions as AdminPermissionsType } from "services/api/types";
import { permissionNames, permissionExplainations } from "../adminInfo";
import Tooltip from "@material-ui/core/Tooltip";

type Props = {
  mode: "Add" | "Update";
  getFieldValue: (field: string) => boolean;
  setFieldValue: (field: string, value: boolean) => void;
};

const AdminPermissions = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      header: {
        margin: theme.spacing(2, 0),
      },
      tooltip: {
        fontSize: 12,
        fontWeight: "normal",
        backgroundColor: theme.palette.primary.main,
      },
      note: {
        color: "red",
        fontWeight: "bold",
        margin: theme.spacing(1, 0),
      },
    })
  );

  const renderSinglePermission = (name: keyof AdminPermissionsType) => {
    const value =
      name === "hasGeneralAdminRole" ? true : props.getFieldValue(name);
    return (
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        title={permissionExplainations[name]}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={value}
              disabled={name === "hasGeneralAdminRole"}
              onChange={(e) => props.setFieldValue(name, e.target.checked)}
            />
          }
          label={permissionNames[name]}
        />
      </Tooltip>
    );
  };

  const renderPermissions = () => {
    return (
      <>
        {renderSinglePermission("hasGeneralAdminRole")}
        {renderSinglePermission("hasCatalogOrderManagerRole")}
        {renderSinglePermission("hasCompanyUserAccountManagerRole")}
        {renderSinglePermission("hasImpersonatorRole")}
        {renderSinglePermission("hasMonthlyReportEditorRole")}
        {renderSinglePermission("hasSettingsManagerRole")}
        {renderSinglePermission("hasTransactionCreatorRole")}
        {renderSinglePermission("hasEventAdminRole")}
        {renderSinglePermission("hasSysAdminRole")}
      </>
    );
  };

  const classes = useStyles();
  return (
    <>
      <Typography
        className={classes.header}
        component="p"
        color="primary"
        variant="caption"
      >
        Administrator Permissions
      </Typography>
      {props.mode === "Update" && (
        <div className={classes.note}>
          Note: Changes here will not take effect until the next time the user
          logs in.
        </div>
      )}
      <div>{renderPermissions()}</div>
    </>
  );
};

export default AdminPermissions;
