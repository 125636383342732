import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Observer } from "mobx-react";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import * as yup from "yup";
import { Formik, Field, Form, FormikActions } from "formik";
import { TextField } from "formik-material-ui";
import eventStore from "stores/admin/events";
import { IEventAdjustment } from "services/api/types";
import DollarPointsInput from "components/Inputs/DollarPointsInput";

type Props = {
  handleClose: () => void;
  eventAdjustment?: IEventAdjustment;
  eventCostPerPoint: number;
};

export type Option = {
  value: string;
  label: string;
};

const AddEditEventAdjustmentModal = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      formControl: {
        margin: theme.spacing(1),
      },
      form: {
        display: "grid",
        gridGap: theme.spacing(1),
        gridTemplateColumns: "1fr 1fr",
      },
      error: {
        color: theme.palette.error.main,
        marginLeft: theme.spacing(2),
      },
    })
  );

  const classes = useStyles();

  const schema = yup.object().shape({
    name: yup.string().required("Name is required"),
    amountDollars: yup
      .number()
      .required("Amount is required")
      .notOneOf([0], "Amount cannot be zero"),
    amountPoints: yup
      .number()
      .required("Amount is required")
      .notOneOf([0], "Amount cannot be zero"),
  });

  type FormData = {
    name: string;
    amountDollars: number | undefined;
    amountPoints: number | undefined;
  };

  const submit = async (values: FormData, actions: FormikActions<FormData>) => {
    props.handleClose();
    if (props.eventAdjustment) {
      await eventStore.updateEventAdjustment(
        props.eventAdjustment.id,
        eventStore.selectedEventDetails!.id,
        values.name,
        values.amountDollars!
      );
    } else {
      await eventStore.addEventAdjustment(
        eventStore.selectedEventDetails!.id,
        values.name,
        values.amountDollars!
      );
    }
  };

  return (
    <Observer
      render={() => {
        return (
          <div className={classes.root}>
            <Formik
              validationSchema={schema}
              onSubmit={submit}
              initialValues={{
                name: props.eventAdjustment?.name ?? "",
                amountDollars: props.eventAdjustment?.amountDollars,
                amountPoints: props.eventAdjustment
                  ? props.eventAdjustment.amountDollars /
                    props.eventCostPerPoint
                  : undefined,
              }}
              render={(formikProps) => {
                const pointCostError = formikProps.errors.amountPoints;
                const showPointCostError =
                  Boolean(pointCostError) &&
                  (formikProps.touched.amountPoints || false);

                const dollarCostError = formikProps.errors.amountDollars;
                const showDollarCostError =
                  Boolean(dollarCostError) &&
                  (formikProps.touched.amountDollars || false);

                return (
                  <ResponsiveModalShell
                    handleClose={props.handleClose}
                    handleSave={() => formikProps.handleSubmit()}
                    saveButtonCaption={props.eventAdjustment ? "Update" : "Add"}
                    title="Add Discount/Upgrade"
                  >
                    <Form className={classes.form}>
                      <Field
                        component={TextField}
                        variant="outlined"
                        margin="dense"
                        className={classes.formControl}
                        name="name"
                        label="Name"
                      />
                      <DollarPointsInput
                        allowNegative={true}
                        pointsError={pointCostError}
                        showPointsError={showPointCostError}
                        dollarsError={dollarCostError}
                        showDollarsError={showDollarCostError}
                        dollarsPerPoint={props.eventCostPerPoint}
                        onChange={(evt) => {
                          formikProps.setFieldValue("amountPoints", evt.points);

                          formikProps.setFieldValue(
                            "amountDollars",
                            evt.dollars
                          );
                        }}
                        pointValue={formikProps.values.amountPoints}
                        dollarValue={formikProps.values.amountDollars}
                      />
                    </Form>
                  </ResponsiveModalShell>
                );
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default AddEditEventAdjustmentModal;
