import { apiClient } from "services/api/client";
import { ICompanyLocation } from "services/api/types";

const MEMBERS_URL = "shared/members";

export const getShippingAddresses = async (memberId: string) => {
  const url = `${MEMBERS_URL}/${memberId}/shippingAddresses`;
  const response = await apiClient.get<ICompanyLocation[]>(url);
  return response.data;
};
