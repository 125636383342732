import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

type Props = {
  marketingInformation: string;
};

const MarketingTextInfoBlock = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      marketingInformation: {
        marginBottom: theme.spacing(3),
      },
    })
  );

  const classes = useStyles();
  return (
    <Typography className={classes.marketingInformation} variant="body2">
      {props.marketingInformation}
    </Typography>
  );
};

export default MarketingTextInfoBlock;
