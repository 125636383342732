import React from "react";
import TransactionsIcon from "@material-ui/icons/AttachMoney";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import HomeIcon from "@material-ui/icons/Home";
import UserIcon from "@material-ui/icons/VerifiedUserTwoTone";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import OrdersIcon from "@material-ui/icons/AddShoppingCart";
import ReportsIcon from "@material-ui/icons/ClearAll";
import DomainIcon from "@material-ui/icons/Domain";
import NewspaperIcon from "@material-ui/icons/Receipt";
import LeadsIcon from "@material-ui/icons/AccessAlarm";
import BuildIcon from "@material-ui/icons/Build";
import TextIcon from "@material-ui/icons/TextFields";
import MailIcon from "@material-ui/icons/MailOutline";
import CatalogIcon from "@material-ui/icons/BookOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { Observer } from "mobx-react";
import myStore from "stores/my";
import adminOrdersStore from "stores/adminOrders";
import * as roles from "types/roles";
import Badge from "@material-ui/core/Badge";
import { useInterval } from "utils/hooks";
import { useHistory } from "react-router";
import configStore from "stores/admin/config";
import clsx from "clsx";
import MobileOnlyHomeListItem from "components/Shell/MobileOnlyHomeListItem";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import needAttentionCountsStore from "stores/admin/needAttentionCounts";

import { paths } from "routes/Paths";

const NewAdminSidebar = () => {
  const history = useHistory();
  const useStyles = makeStyles((t) => {
    return {
      drawerIcon: {
        color: t.palette.common.white,
      },
      menuHost: {
        // transform: "translateY(-20px)",
      },
      nested: {
        paddingLeft: t.spacing(4),
      },
    };
  });

  const classes = useStyles();
  const [transactionsOpen, setTransactionsOpen] = React.useState(false);
  const [usersOpen, setUsersOpen] = React.useState(false);

  useInterval(() => {
    if (myStore.hasAdminRole("bcn:catalog-order-manager")) {
      console.log("updating on hold couunt", new Date());
      adminOrdersStore.updateNeedAttentionCount();
      needAttentionCountsStore.refreshCounts();
    }
  }, 1200000);

  React.useEffect(() => {
    adminOrdersStore.updateNeedAttentionCount();
    myStore.getRecentCompanies();
    configStore.loadConfig();
    needAttentionCountsStore.refreshCounts();
  }, []);

  // TODO: Figure out a better solution than having several things
  // need the bcn-closes-drawer class

  const renderListItem = (
    nested: boolean,
    subRoute: string,
    label: string,
    icon: JSX.Element | undefined,
    // No permissions here means it's available to everyone
    ...permissions: roles.AdminRoleKey[]
  ) => {
    if (permissions.length === 0 || myStore.hasAnyAdminRole(permissions)) {
      return (
        <ListItem
          className={clsx("bcn-closes-drawer", {
            [classes.nested]: nested,
          })}
          selected={history.location.pathname.endsWith(subRoute)}
          button
          onClick={() => history.push(`${paths.app.admin.root}/${subRoute}`)}
        >
          {icon ? (
            <ListItemIcon className="bcn-closes-drawer">{icon}</ListItemIcon>
          ) : (
            <div className="bcn-closes-drawer" style={{ width: "40px" }}></div>
          )}
          <div className="bcn-closes-drawer">{label}</div>
        </ListItem>
      );
    }

    return undefined;
  };

  const renderTransactionsCollapser = () => {
    if (!myStore.hasAdminRole("bcn:manual-transaction-creator")) {
      return undefined;
    }
    return (
      <ListItem button onClick={() => setTransactionsOpen(!transactionsOpen)}>
        <ListItemIcon>
          <TransactionsIcon className={classes.drawerIcon} />
        </ListItemIcon>
        <div>Transactions</div>
        {transactionsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
    );
  };

  const renderUsersCollapser = () => {
    return (
      <ListItem button onClick={() => setUsersOpen(!usersOpen)}>
        <ListItemIcon>
          <UserIcon className={classes.drawerIcon} />
        </ListItemIcon>
        <div>Users</div>
        {usersOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
    );
  };

  const renderUsersCollapse = () => {
    return (
      <Collapse in={usersOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {renderListItem(
            true,
            "company-users",
            "Company Users",
            undefined,
            roles.bcnUserAccountManager
          )}
          {renderListItem(
            true,
            "administrators",
            "Administrators",
            undefined,
            roles.bcnSettingsManager
          )}
        </List>
      </Collapse>
    );
  };

  const renderTransactionsCollapse = () => {
    return (
      <Collapse in={transactionsOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {renderListItem(
            true,
            "processReports",
            "Monthly Reports",
            undefined,
            roles.bcnMonthlyReportEditor
          )}
          {renderListItem(
            true,
            "createPosting",
            "Create Posting",
            undefined,
            roles.bcnMonthlyReportEditor
          )}
          {renderListItem(
            true,
            "postings",
            "Postings",
            undefined,
            roles.bcnMonthlyReportEditor
          )}
          {renderListItem(
            true,
            "marketing-fund",
            "Marketing Fund",
            undefined,
            roles.bcnMonthlyReportEditor
          )}
        </List>
      </Collapse>
    );
  };

  const hasAnyUserRole =
    myStore.hasAdminRole("bcn:settings-manager") ||
    myStore.hasAdminRole("bcn:user-account-manager");

  const renderList = (
    newSupplierProfileCount: number,
    newMemberReferralCount: number
  ) => {
    return (
      <List>
        {renderListItem(
          false,
          "home",
          "Home",
          <HomeIcon className={classes.drawerIcon} />
        )}
        {renderListItem(
          false,
          "orders",
          "Orders",
          <Badge
            color="error"
            badgeContent={adminOrdersStore.needAttentionCount}
          >
            <OrdersIcon className={classes.drawerIcon} />
          </Badge>,
          roles.bcnCatalogOrderManager
        )}
        {renderListItem(
          false,
          "reports",
          "Reports",
          <ReportsIcon className={classes.drawerIcon} />,
          roles.bcnGeneralAdmin
        )}
        {renderListItem(
          false,
          "events",
          "Events",
          <ReportsIcon className={classes.drawerIcon} />,
          roles.bcnGeneralAdmin
        )}
        {myStore.hasAdminRole(roles.bcnGeneralAdmin) ? (
          <>
            {renderTransactionsCollapser()}
            {renderTransactionsCollapse()}
          </>
        ) : undefined}
        {renderListItem(
          false,
          "maintenance",
          "Maintenance",
          <BuildIcon className={classes.drawerIcon} />,
          roles.bcnGeneralAdmin
        )}
        {renderListItem(
          false,
          "edit-app-text",
          "UI Text",
          <TextIcon className={classes.drawerIcon} />,
          roles.bcnGeneralAdmin
        )}
        {renderListItem(
          false,
          "maintenance/email/recentlySent?tab=0",
          "Email",
          <MailIcon className={classes.drawerIcon} />,
          roles.bcnGeneralAdmin
        )}
        {/* Mobile Catalog WIP */}
        <ListItem
          className="bcn-closes-drawer"
          selected={history.location.pathname.includes("catalog")}
          button
          onClick={() => history.push(paths.app.adminsAndMembers.mobileCatalog)}
        >
          <ListItemIcon className="bcn-closes-drawer">
            <CatalogIcon className={classes.drawerIcon} />
          </ListItemIcon>
          <div className="bcn-closes-drawer">Catalog</div>
        </ListItem>
        {renderListItem(
          false,
          "newspapers/issues?tab=0",
          "Newspapers",
          <NewspaperIcon className={classes.drawerIcon} />,
          roles.bcnGeneralAdmin
        )}
        {renderListItem(
          false,
          "leads",
          "Leads",
          <LeadsIcon className={classes.drawerIcon} />,
          roles.bcnGeneralAdmin
        )}
        {hasAnyUserRole ? (
          <>
            {renderUsersCollapser()}
            {renderUsersCollapse()}
          </>
        ) : undefined}
        {renderListItem(
          false,
          "member-referrals",
          "Member Referrals",
          <Badge color="error" badgeContent={newMemberReferralCount}>
            <DomainIcon className={classes.drawerIcon} />
          </Badge>,
          roles.bcnGeneralAdmin
        )}
        {renderListItem(
          false,
          "supplier-profiles",
          "Supplier Profiles",
          <Badge color="error" badgeContent={newSupplierProfileCount}>
            <ImportContactsIcon className={classes.drawerIcon} />
          </Badge>,
          roles.bcnGeneralAdmin
        )}
        <MobileOnlyHomeListItem />
      </List>
    );
  };

  return (
    <Observer
      render={() => {
        const { newMemberReferralCount, newSupplierProfileCount } =
          needAttentionCountsStore;
        return (
          <>{renderList(newSupplierProfileCount, newMemberReferralCount)}</>
        );
      }}
    />
  );
};

export default NewAdminSidebar;
