import { apiClient } from "services/api/client";
import { IHighlightedSponsorsResult } from "services/api/types";

export const getHighlightedSponsors = async (args: {
  memberId: string;
  lastCategoryId?: string;
}) => {
  const url = `members/sponsors/highlighted`;
  const response = await apiClient.get<IHighlightedSponsorsResult>(url, {
    params: args,
  });
  return response.data;
};
