import React from "react";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

type Props = {
  value: boolean;
  handleChange: (e: React.ChangeEvent<any>) => void;
};

const IsImportantCheckbox = (props: Props) => {
  return (
    <FormControlLabel
      label="Alert"
      checked={props.value}
      control={
        <Checkbox
          checked={props.value}
          onChange={props.handleChange}
          name="isImportant"
        />
      }
    />
  );
};

export default IsImportantCheckbox;
