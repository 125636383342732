import { CompanyRoleKey as CompanyRoleId } from "types/roles";

export enum Order {
  Desc = "DESC",
  Asc = "ASC",
}

export type Option = {
  value: string;
  label: string;
};

export type ContactOption = {
  value: string;
  label: string;
  companyId?: string;
};

export interface IMemberReferralData {
  companyName: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  emailAddress: string;
  notes: string;
}

export type NewMemberReferralStatus =
  | "New"
  | "Accepted"
  | "Dismissed"
  | "Claimed";

export interface IListedMemberReferral extends IMemberReferralData {
  id: string;
  createdByEmail: string;
  createdByFirstName: string;
  createdByLastName: string;
  referredByName: string;
  createdOn: Date;
  updatedOn: Date;
  memberId?: string;
  memberName?: string;
  memberIdentifier?: string;
  referredById?: string;
  status: NewMemberReferralStatus;
  statusUserName?: string;
  statusUserId?: string;
  statusDateTime?: Date;
}

type IndexList<ItemType, ListName extends string> = {
  [key in ListName]: ItemType[];
};

type IndexParams = {
  order: Order;
  orderKey: string;
  page: number;
  perPage: number;
  totalCount: number;
};

export type IndexResponse<ItemType, ListName extends string> = IndexList<
  ItemType,
  ListName
> &
  IndexParams;

export interface ISmallContact {
  id: string;
  companyId: string;
  companyName: string;
  firstName: string;
  lastName: string;
  altName?: string;
  altFullName: string;
  email: string;
  isActive: boolean;
  isEmployee: boolean;
  title?: string;
  imageUri?: string;
}

export interface ISimpleNewContact {
  companyId: string;
  firstName: string;
  lastName: string;
  altName?: string;
  email: Partial<IContactEmailAddress>;
  phone: Partial<IPhoneNumber>;
  title?: string;
  isEmployee: boolean;
}

export interface IContactDetailed extends ISmallContact {
  phoneNumbers: IPhoneNumber[];
  emailAddresses: IContactEmailAddress[];
  relatedContacts: IRelatedContact[];
  birthdayDate?: IContactDate;
  anniversaryDate?: IContactDate;
  notes: IContactNote[];
  statementPreference: StatementPreference;
  getNameTag: boolean;
  getNewsletter: boolean;
  extraNewsletters: number;
  relatedContactName?: string;
  relatedContactBirthday?: string;
  relationTypeId?: string;
  primaryLocationId?: string;
  newNote?: string;
  associatedUsers: ICompanyUser[];
  lastAwardedBirthdayPointsOn?: Date;
  newspaperPreference: NewspaperPreference;
}

export interface ITinyContact {
  id: string;
  firstName: string;
  lastName: string;
  altName?: string;
  email: string;
  altFullName: string;
}

export interface ITinyCompany {
  id: string;
  name: string;
  identifier: string;
}

export interface IPotentialDuplicateContact {
  id: string;
  contact1Id: string;
  contact1Name: string;
  contact1Title: string;
  contact2Id: string;
  contact2Name: string;
  contact2Title: string;
  companyType: CompanyType;
  companyId: string;
  companyName: string;
  companyIdentifier: string;
  createdOn: Date;
}

export interface IPotentialDuplicateCompany {
  id: string;
  company1Id: string;
  company1Name: string;
  company1Identifier: string;
  company1IsActive: boolean;
  company2Id: string;
  company2Name: string;
  company2Identifier: string;
  company2IsActive: boolean;
  companyType: CompanyType;
  createdOn: Date;
}

export interface IContactNote {
  id: string;
  content: string;
  createdOn: Date;
  createdBy: string;
}

export type DateType = "Birthday" | "Anniversary";

export interface IContactDate {
  id: string;
  dateType: DateType;
  day?: number;
  month: number;
  year?: number;
}

export interface IContactEmailAddress {
  id?: string;
  type: string;
  emailAddress: string;
  main: boolean;
}

export interface IRelatedContact {
  id: string;
  firstName: string;
  lastName: string;
  companyId: string;
  companyName: string;
  // Need to be able have this empty when adding
  // a new one in the UI.
  relationshipTypeId: string;
}

export interface IAddress {
  id?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  type: string;
  uspsValidatedOn?: Date;
}

export interface ILocation {
  id: string;
  address: IAddress;
}

export interface ILocationDetailed extends ILocation {
  primaryPhoneNumberId: string;
  primaryContact: ISmallContact;
  showOnInternet: boolean;
  isActive: boolean;
}

export interface ISendNewMemberConfirmationResult {
  userAccountCreatedFor: ISmallContact[];
  userAccountNotCreatedFor: ISmallContact[];
  emailsSent: number;
}

export interface IMarket {
  id: string;
  code: string;
  description: string;
}

export interface ICategory {
  id: string;
  name: string;
  showOnSupplierProfile: boolean;
}

export interface ICompanyCategory {
  id: string;
  companyId: string;
  category: ICategory;
  unit: IUnit;
  isPrimary: boolean;
  showOnReport: boolean;
  showOnInternet: boolean;
  rate: number;
  rateDesc: string;
}

export interface ICompanyCategoryCommand {
  id?: string;
  companyId: string;
  categoryId: string;
  rate: number;
  unitId: string;
  doNotAdvertise?: boolean;
  showOnInternet: boolean;
  showOnReport: boolean;
  isPrimary: boolean;
}

export interface IProduct {
  id: string;
  name: string;
}

export interface ICompanyProduct {
  id: string;
  product: IProduct;
}

export interface IBrand {
  id: string;
  name: string;
}

export interface ICompanyBrand {
  id: string;
  brand: IBrand;
}

export interface INote {
  id: string;
  content: string;
  createdOn: string;
  updatedOn: string;
  isImportant: boolean;
  legacyUserName: string;
  createdBy: {
    id: string;
    name: string;
  };
  updatedBy: {
    id: string;
    name: string;
  };
}

export interface ICompany {
  id: string;
  identifier: string;
  enrollDate: string;
  name: string;
  clubPoints: number;
  eventPoints: number;
  pointsPurchased: number;
  minimumMonthlyPurchase: number;
  isActive: boolean;
  inactiveReason: string;
  companyType: CompanyType;
  website: string;
  imageUrl?: string;
  taxNumber?: string;
  taxNumberType?: string;
  primaryLocationId?: string;
  primaryContactId?: string;
  primaryContact: IContactDetailed;
  supplierPrimaryCategoryId?: string;
  marketId?: string;
  marketingInformation: string;
  locations: ILocationDetailed[];
  categories: ICompanyCategory[];
  notes: INote[];
  brands: ICompanyBrand[];
  products: ICompanyProduct[];
  eventCount: number;
  redemptionsSummaryLines: IRedemptionSummaryLine[];
  pointsYearToDate?: number;
  pointsLastMonth?: number;
  pointsLast12Months?: number;
  pointsLast36Months?: number;
  pointsLast12to24Months?: number;
  marketCode?: string;
  referredById?: string;
  referredByIdentifier?: string;
  referredByName?: string;
  accounts: IAccountInfo[];
  eventAccount: IAccountInfo;
  usedCategories: string[];
}

export interface IRedemptionSummaryLine {
  typeId: number;
  typeName: string;
  quantity: number;
  points: number;
}

export interface ICreateCompanyCommand {
  companyType: CompanyType;
  name: string;
  website?: string;
  isActive: boolean;
  taxNumber?: string;
  taxNumberType?: string;
  minimumMonthlyPurchase?: number;
  marketingInformation?: string;
  enrollDate: Date;
  marketId?: string;
  supplierPrimaryCategoryId?: string;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  referralId?: string;
}

export interface IUpdateCompanyCommand {
  id: string;
  name: string;
  companyType: CompanyType;
  website?: string;
  enrollDate: string;
  isActive: boolean;
  taxNumber?: string;
  taxNumberType?: string;
  minimumMonthlyPurchase?: number;
  marketingInformation?: string;
  marketId?: string;
}

export type CompanyUserPermission =
  | "member:admin"
  | "member:user"
  | "sponsor:admin"
  | "sponsor:sales"
  | "sponsor:accounting"
  | "none";

type PermissionType = {
  label: string;
  companyType: "sponsor" | "member";
  value: CompanyUserPermission;
};

export const permissionTypes: PermissionType[] = [
  {
    label: "Member Admin",
    value: "member:admin",
    companyType: "member",
  },
  {
    label: "Member User",
    value: "member:user",
    companyType: "member",
  },
  {
    label: "None",
    value: "none",
    companyType: "member",
  },
  {
    label: "Sponsor Admin",
    value: "sponsor:admin",
    companyType: "sponsor",
  },
  {
    label: "Sponsor Sales",
    value: "sponsor:sales",
    companyType: "sponsor",
  },
  {
    label: "Sponsor Accounting",
    value: "sponsor:accounting",
    companyType: "sponsor",
  },
  {
    label: "None",
    value: "none",
    companyType: "sponsor",
  },
];

export type PhoneNumberType = "Work" | "Cell" | "Home" | "Direct" | "Other";

export interface IPhoneNumber {
  id?: string;
  type: PhoneNumberType;
  number: string;
  extension: string;
}

export interface ICompanyLocationPayload {
  companyLocation: {
    id?: string;
    companyId: string;
    primaryContactId?: string;
    address: IAddress;
    showOnInternet: boolean;
  };
}

export type CreateCompanyBrandCommand = {
  companyId: string;
  brandId?: string;
  brandName?: string;
};

export type CreateCompanyProductCommand = {
  companyId: string;
  productId?: string;
  productName?: string;
};

export interface ICompanyNotePayload {
  companyNote: {
    id?: string;
    companyId: string;
    content: string;
    isImportant: boolean;
  };
}

export interface IGlobalSearchResponseContact {
  id: string;
  name: string;
  isActive: boolean;
  email: string;
  company?: {
    id: string;
    name: string;
  };
}

export interface IPagedResult<T> {
  page: number;
  pageSize: number;
  totalCount: number;
  data: T[];
  totalPages: number;
  search: string;
}

export interface IFilter {
  fieldName: string;
  value: string;
}

export interface IPageQuery {
  page: number;
  pageSize: number;
  search?: string;
  orderByColumn?: string;
  orderByDirection?: string;
  filters?: IFilter[];
}

export interface ICompanyPageQuery extends IPageQuery {
  companyId?: string;
}

export interface ITransactionsQuery extends IPageQuery {
  accountId: string;
  pointsFilter: TransactionPointsFilter;
}

export interface ISponsorDirectoryQuery extends IPageQuery {
  memberId: string;
  categoryId?: string;
  notEarningPoints: boolean;
}

export interface IProductPageQuery {
  page: number;
  pageSize: number;
  search?: string;
  orderByColumn?: string;
  orderByDirection?: string;
  categoryId?: string;
  pointsMin?: number;
  pointsMax?: number;
  brandName?: string;
}

export interface ICatalogProduct {
  id: string;
  productName: string;
  salesCopy: string;
  model: string;
  cost: number;
  points: number;
  brand: string;
  imageUrl: string;
  categoryGreatGrandChild: string;
  categoryGrandChild: string;
  categoryChild: string;
  options: ICatalogOption[];
}

export interface ICatalogOption {
  id: string;
  size: string;
  color: string;
  a1Value: string;
  a2Value: string;
  a3Value: string;
  sort?: number;
  default?: boolean;
  image: string;
  name: string;
  deletedOn: Date | undefined;
}

export type IShoppingCartItem = {
  id?: string;
  product: ICatalogProduct;
  option?: ICatalogOption;
  quantity: number;
  allProductOptions: ICatalogOption[];
};

export type IShoppingCartItemIds = {
  productId: string;
  optionId?: string;
  quantity: number;
};

export interface IGlobalSearchResponse {
  contacts: IGlobalSearchResponseContact[];
  members: IGlobalSearchResponseCompany[];
  suppliers: IGlobalSearchResponseCompany[];
}

export interface IListedMember {
  id: string;
  identifier: string;
  imageUrl: string;
  name: string;
  primaryLocation: string;
  pointBalance: number;
  active: boolean;
  primaryContact: string;
  website: string;
}

export interface IListedSponsor {
  id: string;
  identifier: string;
  name: string;
  primaryLocation: string;
  minimumPoints: number;
  active: boolean;
  primaryContact: string;
}

export interface IRecentCompanies {
  suppliers: IGlobalSearchResponseCompany[];
  members: IGlobalSearchResponseCompany[];
}

export interface IDbUser {
  id: string;
  email: string;
}

export type Environment = "Production" | "Staging" | "Development";

export interface ILoggedInUserInfo {
  firstName: string;
  lastName: string;
  userId: string;
  environmentName: Environment;
  // PATODO: Will need to change to array at some point
  accountId?: string;
  phoneNumber: string | undefined;
  emailAddress: string;
  roles: string[]; // admin roles
  companies: ICompanyPermissionViewModel[];
}

// Might not be used...?
export interface ICompanyPermission {
  companyId: string;
  roleId: string;
}

export type CompanyTypeForExports =
  | "Sponsor"
  | "NonVipMember"
  | "VipMember"
  | "Prospect"
  | "Competitor";

export type CompanyType =
  | "Sponsor"
  | "Member"
  | "Prospect"
  | "Competitor"
  | "None"; // None used only with marketing fund (TODO: get rid of...no more marketing fund).
export type UserType = "Admin" | "Sponsor" | "Member";

export interface ICompanyPermissionViewModel {
  companyType: CompanyType;
  companyId: string;
  pointBalance: number;
  companyName: string;
  companyIdentifier: string;
  companyWebsite: string;
  roleId: CompanyRoleId;
  imageUrl: string;
}

export type PartyType = "Company" | "MarketingFund" | "None";

export interface IRelationType {
  id: string;
  description: string;
}

export interface IRelationshipType {
  id: string;
  description: string;
  oppositeTypeId: string;
}

export interface INewTransactionType {
  description: string;
  fromPartyType: PartyType;
  toPartyType: PartyType;
  defaultPoints?: number;
  defaultNote?: string;
  isManual: boolean;
  isEvent: boolean;
}

export interface ITransactionType extends INewTransactionType {
  id: number;
}

export interface ISummaryTransaction {
  id: string;
  isSummary: boolean;
  transactionTypeId: number;
  transactionTypeName: string;
  toCompanyId?: string;
  toAccountId?: string;
  toCompanyName?: string;
  toCurrentBalance?: number;
  toAccountType?: AccountType;
  fromCompanyId?: string;
  fromAccountId?: string;
  fromCompanyName?: string;
  fromCurrentBalance?: number;
  fromAccountType?: AccountType;
  points: number;
  quantity: number;
  refund: boolean;
  notes: string;
  transactionDate: Date;
  externalOrderId?: string;
  monthlyReportId?: string;
  isEditable: boolean;
  isRefundable: boolean;
}

export interface ICompanyInEvent {
  id: string;
  eventId: string;
  eventGroupId?: string;
  eventPayerId?: string;
  companyId: string;
  name: string;
  eventTypeName: string;
  startDate: Date;
  attendeeNames: string[];
  attendeeCount: number;
  totalPayments: number;
  amountDueDollars?: number;
  attendanceStatus?: EventGroupStatus;
  invoiceStatus?: InvoiceStatus;
  paymentStatus?: PaymentStatus;
  companyEventStatus: CompanyEventStatus;
}

export interface IStatementPreview {
  // IActivitySummaryMessage
  // We shouldn't do anything with it except forward it right
  // on to the PDF service
  message: any;
  start: Date;
  end: Date;
}

export type EventAttendeeInfo = {
  attendees: string[];
  eventName: string;
  companyName: string;
};

export interface ITransactionsResult extends IPagedResult<ISummaryTransaction> {
  companyType: CompanyType | undefined;
}

export interface ITransactionViewModel {
  id?: string;
  transactionTypeId: number;
  toAccountId: string | undefined;
  mainAccountId: string;
  quantity: number;
  points: number;
  reverse: boolean;
  notes: string;
  birthdayContactId?: string;
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isEnabled: boolean;
  userName: string;
  lastLoginDate?: Date;
}

export interface IJob {
  jobType: "deduct-annual-fees";
  status: "completed" | "in-progress";
  message: string;
  statusDateTime: Date;
}

export interface ICompanyUser extends IUser {
  lastReminderSent?: Date;
  permissions: ICompanyPermissionViewModel[];
  createdOn: Date;
  createdBy: string;
  hasSetPassword: boolean;
}

export interface IAdminUser extends IUser {
  hasGeneralAdminRole: boolean;
  phoneNumber: string;
  jobTitle: string;
  hasTransactionCreatorRole: boolean;
  hasImpersonatorRole: boolean;
  hasMonthlyReportEditorRole: boolean;
  hasCompanyUserAccountManagerRole: boolean;
  hasCatalogOrderManagerRole: boolean;
  hasSettingsManagerRole: boolean;
  hasEventAdminRole: boolean;
  hasSysAdminRole: boolean;
}

export type AdminPermissions = Omit<
  IAdminUser,
  | "id"
  | "firstName"
  | "lastName"
  | "email"
  | "userName"
  | "isEnabled"
  | "lastLoginDate"
  | "phoneNumber"
  | "jobTitle"
>;

export interface IUnit {
  id: string;
  description: string;
}

export interface IDailyStatistic {
  date: Date;
  logins: number;
  signUps: number;
}

export interface ISendRemindersResult {
  sent: number;
  remaining: number;
}

export interface ICompanyStats {
  members: number;
  sponsors: number;
  competitors: number;
  prospects: number;
}

export type CompanyDataQualityType =
  | "NoActiveUserAccounts"
  | "NoLoggedInUsers"
  | "WithoutPrimaryCategory"
  | "PrimaryContactWithoutEmail"
  | "WithoutPrimaryLocation"
  | "WithoutPrimaryContact"
  | "NoRedemptions";

export type DataQualityType =
  | CompanyDataQualityType
  | "PotentialDuplicateCompanies"
  | "PotentialDuplicateContacts";

export interface IDataQualityStats {
  potentialDuplicateCompanies: IGenericCompanyCountModel[];
  potentialDuplicateContacts: IGenericCompanyCountModel[];
  countsByType: {
    [key in CompanyDataQualityType]: IGenericCompanyCountModel[];
  };
}

export interface IGenericCompanyCountModel {
  companyType: CompanyType;
  count: number;
}

export interface ICatalogCategory {
  id: string;
  level: number;
  parentCategoryId?: string;
  name: string;
  productCount: number;
}

export interface IRandomProductInfo {
  productId: string;
  productName: string;
  imageUrl: string;
}

export interface ICatalogInfo {
  popularCategories: ICategoryInfo[];
  rootCategories: ICategoryInfo[];
  activeProductCount: number;
  minProductPointCost: number;
  maxProductPointCost: number;
}

export interface ICategoryInfo {
  categoryId: string;
  categoryName: string;
}

export interface IOrderItem {
  productId?: string;
  optionId?: string;
  giftCardDenomination?: number;
  quantity: number;
}

export type OrderType = "Catalog" | "GiftCard";

export interface ICreateOrderCommand {
  items: IOrderItem[];
  shipToLocationId?: string;
  shipToPhoneNumber: string;
  shipToEmail?: string;
  type: OrderType;
  shipToAddress?: {
    firstName: string;
    lastName: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  };
}

export interface IUpdateOrderCommand extends ICreateOrderCommand {
  orderId: string;
}

export interface IUpdateGiftCardOrderCommand extends ICreateOrderCommand {
  giftCardOrderId: string;
}

export interface IOrderResult {
  id: string | undefined;
  totalPoints: number | undefined;
  newPointBalance: number | undefined;
  poBoxAddressDetected: boolean;
}

export interface ICompanyLocation {
  id: string;
  addressId: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  primaryContactName: string;
}

export interface IGetOrdersResult {
  orders: IFullOrderModel[];
  spreadsheetSass: string;
}

export interface IFullOrderModel {
  id: string;
  companyId: string;
  companyName: string;
  clubId: string;
  currentPointBalance: number;
  marketCode?: string;
  shipToFirstName: string;
  shipToLastName: string;
  externalOrderId: string;
  status: OrderStatus;
  statusDetails: string;
  createdBy: string;
  createdByEmail: string;
  createdOn: Date;
  totalPoints: number;
  catalogItems: IFullCatalogOrderItem[];
  giftCardItems: IGiftCardOrderItem[];
  spreadsheetUri: string;
  address: IAddress;
  replinkOrderNumbers: string[];
  pointStatus: "Not Deducted" | "Deducted" | "Refunded";
}

export interface IGiftCardOrderItem {
  denomination: number;
  quantity: number;
  pointsEach: number;
  totalPoints: number;
}

export interface IFullCatalogOrderItem {
  productName: string;
  option: string;
  pointsEach: number;
  quantity: number;
  totalPoints: number;
  imageUrl: string;
  productNoLongerAvailable: boolean;
  optionNoLongerAvailable: boolean;
}

export type OrderTimeFrame = "ThirtyDays" | "SixMonths" | "OneYear" | "All";

export type OrderStatus =
  | "OnHold"
  | "Approved"
  | "Cancelled"
  | "Submitted"
  | "Error"
  | "Pending"
  | "All";

export type OrderFilterType =
  | "Pending"
  | "OnHold"
  | "Error"
  | "Recent"
  | "Company"
  | "PurchaseOrderId";

export interface IDestination {
  title: string;
  description: string;
  imageUrl: string;
  points: number;
}

export interface IDestinationData {
  title: string;
  destinations: IDestination[];
  notes: string[];
}

export type NewspaperPreference = "Always" | "Benchmark" | "Never";
export type AddressType = "new" | "existing";

export interface IGiftCardSettings {
  denominations: number[]; // obsolete
  pointsPerDollar: number;
}

export interface IGiftCardDenomination {
  quantity: number;
  denomination: number;
}

export interface IGiftCardModel {
  id: string;
  status: string;
  statusDetails: string;
  totalPoints: number;
  externalOrderId: string;
  email: string;
  phone: string;
  shipToFirstName: string;
  shipToLastName: string;
  unverifiedAddress: boolean;
  createdOn: Date;
  requestedDenominations: IGiftCardDenomination[];
  companyLocation: ICompanyLocation;
  company: ITinyCompany;
}

export interface INewMonthlyReportData {
  sponsorCostPerPoint: number;
  rates: IDerivedReportRate[];
  members: IMonthlyReportMember[];
  year: number;
  month: number;
  batch: number;
}

export interface IDerivedReportRate {
  unitType: string;
  ratio: number;
  rate: number;
  monetary: boolean;
}

export interface IMonthlyReportMember {
  id: string;
  name: string;
  identifier: string;
  rate0Dollars?: number;
  rate1Dollars?: number;
  rate2Dollars?: number;
  rate3Dollars?: number;
  rate4Dollars?: number;
  pointsToAward?: number;
  dollarAmountDue?: number;
}

export interface IMonthlyReportValue {
  memberId: string;
  rateIndex: number;
  points?: number;
  units: number;
}

interface IMonthlyReportCommand {
  month: number;
  year: number;
  isSubmitted: boolean;
  statementMessage: string;
  rates: IDerivedReportRate[];
  values: IMonthlyReportValue[];
  memberIds: string[];
}

export interface ICreateMonthyReportCommand extends IMonthlyReportCommand {
  sponsorId: string;
}

export interface IUpdateMonthlyReportCommand extends IMonthlyReportCommand {
  monthlyReportId: string;
}

export type AccountType = "Company" | "MarketingFund" | "Events" | "Points";

export type CreateOrderResult =
  | "Succeeded"
  | "FailedPoBoxAddressDetected"
  | "Failed";

export interface ICompanySearchResults {
  searchTerm: string;
  companies: ISearchResultCompany[];
}

export interface ISearchResultAccount {
  id: string;
  companyId?: string;
  name: string;
  identifier: string;
  computedBalance: number;
  type: AccountType;
  companyType?: CompanyType;
}

export interface ISearchAccountsResult {
  searchTerm: string;
  accounts: ISearchResultAccount[];
}

export interface ITinyMonthlyReport {
  id: string;
  companyId: string;
  awardedPointCost: number;
  sponsorName: string;
  sponsorIdentifier: string;
  status: MonthlyReportStatus;
  month: number;
  year: number;
  batch: number;
  awardedPoints: number;
  createdOn: Date;
  updatedOn: Date;
}

export interface IListedMonthlyReport {
  id: string;
  title: string;
  month: number;
  year: number;
  batch: number;
  sponsorName: string;
  sponsorIdentifier: string;
  sponsorId: string;
  awardedPoints: number;
  awardedPointCost: number;
  status: MonthlyReportStatus;
  submittedOn?: Date;
  submittedBy?: string;
  updatedOn?: Date;
  updatedBy?: string;
  postedOn?: Date;
}

export interface IFullMonthlyReportValue {
  monthlyReportRateId: string;
  unitsSold: number;
  points: number;
  memberId: string;
  rate: IDerivedReportRate;
}

export interface IFullMonthlyReport extends IListedMonthlyReport {
  statementMessage: string;
  values: IFullMonthlyReportValue[];
  rates: IDerivedReportRate[];
  sponsorCostPerPoint: number;
  minimumMonthlyPurchase?: number;
  sponsorPointCost: number;
  amountDue: number;
  totalPaid: number;
  balance: number;
  pointsEscrow: number;
  adminFee: number;
  pointsPurchased: number;
  sponsorPoints: number;
}

export interface IFullMonthlyReportResult {
  sponsorCostPerPoint: number;
  report: IFullMonthlyReport;
  members: IMonthlyReportMember[];
}

export interface IMonthlyReportSummary {
  id: string;
  sponsorInfo: ICompanyInfo;
  status: MonthlyReportStatus;
  manualAdjustment: number;
  payments: IMonthlyReportPayment[];
  sponsorCostPerPoint: number;
  minimumMonthlyPurchaseDollars?: number;
  minimumMonthlyPurchasePoints?: number;
  sponsorMinimumMonthlyPurchase?: number;
  awardedPoints: number;
  awardedPointCost: number;
  sponsorPointCost: number;
  sponsorPoints: number;
  amountDueDollars: number;
  amountDuePoints: number;
  totalPaidDollars: number;
  totalPaidPoints: number;
  balancePoints: number;
  balanceDollars: number;
  pointsEscrow: number;
  adminFee: number;
  pointsPurchased: number;
  adjustSponsorPointBalanceDollars: number;
  year: number;
  month: number;
  batch: number;
}

export type MonthlyReportStatus =
  | "Pending"
  | "Submitted"
  | "Processed"
  | "ReadyToPost"
  | "Posted";

export interface IGetMonthlyReportListResult {
  reports: ITinyMonthlyReport[];
  type: MonthlyReportStatus;
  searchTerm?: string;
}

export interface IAccountInfo {
  id: string;
  name: string;
  identifier: string;
  computedBalance: number;
  type: AccountType;
  companyId?: string;
}

export interface ICompanyInfo {
  id: string;
  name: string;
  accountId: string;
  alertCount: number;
  website: string;
  imageUrl: string | undefined;
  identifier: string;
  companyType: CompanyType;
  computedBalance: number;
  primaryLocation: {
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
  };
  primaryContact: {
    id: string;
    name: string;
    phone: string;
    email: string;
  };
}

export interface IMonthlyReportPayment extends INewMonthlyReportPayment {
  id: string;
}

export interface INewMonthlyReportPayment {
  monthlyReportId: string;
  paymentType: PaymentType;
  amount: number;
  date: Date;
  checkNumber: string;
  points?: number;
}

export interface INewGenericPayment {
  paymentType: PaymentType;
  amount: number;
  date: Date;
  checkNumber: string;
  points?: number;
}

export interface IExistingGenericPayment extends INewGenericPayment {
  id: string;
}

export type CompanyStatus = "Active" | "Inactive";
export type PaymentType = "Check" | "Points";
export type ReportFilterType = "Pending" | "Submitted" | "Processed" | "Search";
export type AttachmentAudience = "ActivitySummary" | "SponsorStatement" | "All";
export type StatementType = "ActivitySummary" | "SponsorStatement";

export interface IMonthlyStatementAttachment {
  id: string;
  markets: string[];
  file: File;
  audience: AttachmentAudience;
}

export interface IAddEventPayerPaymentCommand {
  payment: INewEventPayerPayment;
  invoiceAmounts: IInvoiceAmount[];
}

export interface IUpdateEventGroupPaymentCommand {
  payment: IUpdatedEventPayerPayment;
  invoiceAmounts: IInvoiceAmount[];
}

export interface INewEventPayerPayment {
  eventPayerId: string;
  paymentType: PaymentType;
  amount: number;
  date: Date;
  checkNumber: string;
  points?: number;
  refund: boolean;
  notes: string | undefined;
}

export interface IInvoiceAmount {
  invoiceId: string;
  amount: number;
}

export interface IUpdatedEventPayerPayment extends INewEventPayerPayment {
  id: string;
}

export interface IEventPayerPayment extends INewEventPayerPayment {
  id: string;
  editable: boolean;
  appliedInvoiceAmounts: IAppliedInvoiceAmount[];
}

export interface IUploadedAttachment {
  id: string;
  markets: string[];
  audience: AttachmentAudience;
  uri: string;
  originalFileName: string;
}

export interface INewPostingRequest {
  attachments: IUploadedAttachment[];
  sponsorMessage: string;
  memberMessage: string;
}

export interface IFileUploadResult {
  uri: string;
  originalFileName: string;
}

export type SortableMonthlyReportColumns = "CompanyName" | "ClubId";

export interface IMonthlyReportPostingSummary {
  status: string;
  postedCount: number;
  notPostedCount: number;
}

export type StatementGenerationStatus = "Waiting" | "Generating" | "Generated";

export interface IStatementSummary {
  statementGenerationStatus: StatementGenerationStatus;
  statusTime: Date;
  emailsToSend: number;
  emailsQueuedOn: Date | undefined;
  emailsSent: number;
  emailsFailed: number;
  statementsToPrint: number;
  totalCount: number;
  fileGenerationStatus: string;
}

export interface IPostingSummary {
  postingId: string;
  monthlyReportPostingSummary: IMonthlyReportPostingSummary;
  sponsorStatementSummary: IStatementSummary;
  activitySummarySummary: IStatementSummary;
  createdBy: string;
  createdOn: Date;
  approximateNumberOfMessagesInQueue: number;
  canEnqueueEmails: boolean;
  emailsEnqueuedAtLeastOnce: boolean;
}

export interface IGetPostingFilesResult {
  sass: string;
  files: IPostingFile[];
}

export interface IPostingFile {
  fileUri: string;
  totalBytes: number;
  description: string;
}

export interface IGetStatementEmailsForPostingResult {
  totalCount: number;
  statementEmails: IListedStatementEmail[];
  filter: string;
  totalSent: number;
  totalFailed: number;
  totalQueued: number;
}

export interface IListedStatementEmail {
  id: string;
  statementId: string;
  companyName: string;
  contactName: string;
  contactEmail: string;
  status: string;
}

export interface IStatementInfo {
  id: string;
  statementType: string;
  emailsSent: number;
  emailsToSend: number;
  createdOn: Date;
  statementDate: string;
}

export interface IGetStatementsResult {
  statements: IStatementInfo[];
}

export type StatementPreference = "None" | "Email" | "Print" | "Both";

export type AddressAction = "NoAction" | "Deactivate";

export interface IIdentityResult {
  succeeded: boolean;
  errors: { code: string; description: string }[];
}

export type EventVisibility = "Members" | "Sponsors" | "Both" | "None";
export type EventAttachmentVisibility = "Members" | "Sponsors" | "Both";
export type EventAttachmentType = "MoreInfo" | "Itinerary";

export interface INewEventData {
  name: string;
  description: string;
  eventTypeId: number;
  location: string;
  startDate: Date;
  endDate: Date;
  displayDate: string;
  costPerPersonDollars?: number;
  notes: string;
  visibility: EventVisibility;
  signUpStatus: EventSignUpStatus;
}

export interface IExistingEventData extends INewEventData {
  id: string;
}

export interface ICompanyEvent {
  companyId: string;
  eventId: string;
  eventName: string;
  attendeeCount?: number;
  eventGroupId?: string;
  guestCount?: number;
  eventPayerStatus: PaymentStatus;
  eventGroupStatus: EventGroupStatus;
  notes: ICompanyEventNote[];
}

export interface IStatementOfAccount {
  companyName: string;
}

export interface IEventGroupRefundInfo {
  totalPointsToRefund: number;
  nonVoidInvoiceNumbers: number[];
  totalCheckPayments: number;
  companyName: string;
  eventName: string;
  isFreshGroup: boolean;
  isHost: boolean; // Has guests
  isGuest: boolean;
}

export interface ICompanyEventAttendees {
  companyId: string;
  companyName: string;
  companyIdentifier: string;
  eventId: string;
  eventName: string;
  eventGroupId?: string;
  eventAccountInfo: IAccountInfo;
  attendees: IEventAttendeeViewModel[];
}

export type EventSignUpStatus = "ComingSoon" | "Open" | "SoldOut";

export interface IEventDetails {
  id: string;
  name: string;
  description: string;
  eventTypeId: number;
  eventType: string;
  location: string;
  visibility: EventVisibility;
  startDate: Date | undefined;
  endDate: Date | undefined;
  displayDate: string;
  costPerPersonDollars?: number;
  costPerPersonPoints?: number;
  notes: string;
  attachments: IEventAttachment[];
  images: IEventImage[];
  adjustments: IEventAdjustment[];
  activeEventGroupCount: number;
  isPastEvent: boolean;
  signUpStatus: EventSignUpStatus;
}

export interface ITinyUser {
  id: string;
  userName: string;
  email: string;
  fullName: string;
}

export type OperationType = "Created" | "Update" | "Delete";
export type TargetType = "Contact" | "MemberSuppliers" | "Location";

export interface IAuditRecord {
  id: string;
  date: Date;
  operationType: OperationType;
  targetType: TargetType;
  companyId: string | undefined;
  companyName: string | undefined;
  companyIdentifier: string | undefined;
  userId: string | undefined;
  userName: string | undefined;
  userFullName: string | undefined;
  notes: string;
  userIsBcnAdmin: boolean | undefined;
}

export interface IListedAdminEvent {
  id: string;
  attendeeCount: number;
  onWaitingListCount: number;
  inSignUpQueue: number;
  interestedCount: number;
  visibility: EventVisibility;
  // Same as visibility, except Both is replaced
  // by "Members & Sponsors" to make filtering easier
  verboseVisibility: string;
  name: string;
  description: string;
  displayDate: string;
  updatedOn: Date;
  startDate?: Date;
  lastUpdateUserName: string;
  isPastEvent: boolean;
  eventTypeId: number;
  eventType: string;
}

export interface IListedPublicEvent {
  id: string;
  name: string;
  description: string;
  displayDate: string;
  startDate?: Date;
  eventTypeId: number;
  eventType: string;
  attendeeCount: number;
}

export interface IEventType {
  id: number;
  name: string;
}

export interface IEventAttachment {
  id: string;
  eventId: string;
  originalFileName: string;
  url: string;
  visibility: EventAttachmentVisibility;
  type: EventAttachmentType;
}

export interface IEventImage {
  id: string;
  eventId: string;
  originalFileName: string;
  uri: string;
}

export interface IAddAttachmentCommand {
  eventId: string;
  type: EventAttachmentType;
  originalFileName: string;
  uri: string;
}

export interface IAddEventImageCommand {
  eventId: string;
  originalFileName: string;
  uri: string;
}

export interface IListedCompanyInEvent {
  id: string;
  companyId: string;
  eventId: string;
  eventGroupId?: string;
  companyIdentifier: string;
  companyName: string;
  companyType: string;
  groupLead: string;
  groupLeadEmail: string;
  attendeeCount: number;
  paymentCount: number;
  eventGroupStatus: EventGroupStatus;
  eventPayerStatus: PaymentStatus;
  invoiceStatus: InvoiceStatus;
  totalAmountInvoiced?: number;
  unpaidInvoiceAmount?: number;
  cancellationFee?: number;
  amountDueDollars?: number;
  outstandingInvoiceAmount?: number;
  needsInvoice: boolean;
  primaryContactName: string;
  primaryContactEmail: string;
  primaryContactPhone: string;
  notes: ICompanyEventNote[];
  hasNotes: boolean;
  pointsLast12Months: number;
  currentBalance: number;
  statusUser?: string;
  statusDate?: Date | undefined;
  nextInvoiceDue: Date | undefined;
  market: string | undefined;
}

export interface IEventCounts {
  eventGroups: number;
  cancelledGroups: number;
  waitingList: number;
  interested: number;
  signUpQueue: number;
}

export interface ICompanyEventNote {
  id: string;
  content: string;
  isImportant: boolean;
  createdOn: Date;
  createdBy: string;
}

export interface IEventGroupConfirmationEmail {
  id: string;
  eventGroupId: string;
  contact: ISmallContact;
  createdOn: Date;
  createdBy: string;
}

export interface IEventPayerDetails {
  id: string;
  accountId: string;
  accountName: string;
  accountPointBalance: number;
  eventPayerStatus: PaymentStatus;
  adjustments: IEventPayerAdjustment[];
  payments: IEventPayerPayment[];
  invoices: ITinyEventPayerInvoice[];
  eventAdjustmentsAvailable: boolean;
  outstandingInvoiceAmount?: number;
  amountDueDollars?: number;
  totalChargeDollars?: number;
  guests: IEventAttendeeViewModel[];
  attendeeRemovalRequiresConfirmation: boolean;
}

export interface IEventGroupDetails {
  id: string;
  roomsNeeded?: number;
  oldNotes: string;
  eventGroupStatus: EventGroupStatus;
  attendees: IEventAttendeeViewModel[];
  notes: ICompanyEventNote[];
  confirmationEmails: IEventGroupConfirmationEmail[];
}

export interface ICompanyEventDetails2 {
  id: string;
  eventId: string;
  accountInfo: IAccountInfo;
  companyInfo?: ICompanyInfo;
  groupDetails?: IEventGroupDetails;
  payerDetails?: IEventPayerDetails;
}

export interface IEventAttendeeViewModel {
  id: string;
  eventGroupId: string;
  attendeeId?: string;
  contactId?: string;
  contactFirstName?: string;
  contactLastName?: string;
  payerAccountId: string;
  payerAccountType: AccountType;
  payerCompanyId?: string;
  payerCompanyIdentifier: string;
  payerCompanyName: string;
  // Not all features go through the trouble of populating
  // this. That's why it's nullable
  payerCompanyNeedsInvoice?: boolean;
  attendeeCompanyId: string;
  attendeeCompanyName: string;
  isOnlineSignup: boolean;
  isGroupLead: boolean;
  isEmployee?: boolean;
  isNoCharge: boolean;
  signUpDate: Date;
}

export interface ISearchContact {
  id: string;
  name: string;
  email: string;
  companyName: string;
  companyId: string;
  companyIdentifier: string;
}

export interface IContactSearchResult {
  results: ISearchContact[];
  searchTerm: string;
}

export interface ISearchResultCompany {
  id: string;
  name: string;
  identifier: string;
  computedBalance: number; // << Might not need
  companyType: CompanyType;
  isActive: boolean;
}

export interface IGlobalSearchResponseCompany {
  id: string;
  identifier: string;
  computedBalance: number;
  name: string;
  isActive: boolean;
  companyType: CompanyType;
  primaryLocation?: {
    city: string;
    state: string;
  };
}

export interface IEventAdjustment {
  id: string;
  name: string;
  amountDollars: number;
  hasBeenUsed: boolean;
}

export interface IUpdatedEventAdjustmentResult {
  updatedAdjustment: IEventAdjustment;
  balancedChangedForCompanies: string[];
}

export interface IAddEventAdjustmentCommand {
  eventId: string;
  name: string;
  amountDollars: number;
}

export interface IUpdateEventAdjustmentCommand {
  eventAdjustmentId: string;
  name: string;
  amountDollars: number;
  eventId: string;
}

export interface IAddEventPayerAdjustment {
  eventPayerId: string;
  eventAdjustmentId?: string;
  manualDollarAdjustment?: number;
  quantity: number;
}

export interface IEventPayerAdjustment {
  id: string;
  eventAdjustmentId?: string;
  eventAdjustmentName?: string;
  totalAmountDollars: number;
  quantity: number;
  memo: string;
}

export interface IUpdateEventPayerAdjustmentCommand {
  eventPayerAdjustmentId: string;
  eventAdjustmentId: string | undefined;
  quantity: number;
  memo: string;
  manualDollarAdjustment: number | undefined;
}

export interface IUpdateEventAttendeesCommand {
  companyId: string;
  eventId: string;
  attendees: IUpdateAttendeeModel[];
}

export interface IUpdateAttendeeModel {
  attendeeId?: string;
  contactId?: string;
  payerAccountId: string;
  isNoCharge: boolean;
  isGroupLead: boolean;
}

export interface IGenericCost {
  description: string;
  dollars: number;
  points: number;
}

export interface IaccountEventSummary {
  totalAttendees: number;
  attendeesToCharge: number;
  eventCostPerPerson: IGenericCost;
  basePrice: IGenericCost;
  cancellationFee: IGenericCost;
  adjustments: IGenericCost;
  totalDue: IGenericCost;
  totalPayments: IGenericCost;
  balanceDue: IGenericCost;
  outstandingInvoiceAmount: IGenericCost;
}

export interface ITinyEventPayerInvoice {
  id: string;
  invoiceNumber: string;
  amount: number;
  amountPaid: number;
  void: boolean;
  createdOn: Date;
  dateDue: Date;
  isExplicitDueDate: boolean;
  createdBy: string;
  isPaidInFull: boolean;
}

export type EventPayerInvoiceType = "NotPaid" | "Paid" | "Void" | "All";

export interface ILargeEventPayerInvoice extends ITinyEventPayerInvoice {
  eventId: string;
  eventName: string;
  eventPayerId: string;
  payerName: string;
  payerCompanyId: string;
  amountDue: number;
}

export interface IAppliedInvoiceAmount {
  id: string;
  eventPayerInvoiceId: string;
  eventPayerPaymentId: string;
  amountApplied: number;
}

export type CostUnit = "Dollars" | "Points";

// broader than event type
export type EventCategory = "GrandDestination" | "LocalEvent";

export interface IListedCompanyEvent {
  id: string;
  name: string;
  location: string;
  displayDate: string;
  imageFileName: string;
  moreInfoAttachmentId?: string;
  itineraryAttachmentId?: string;
  description: string;
  eventTypeId: number;
  costUnit: CostUnit;
  costPerPerson?: number;
  category: EventCategory;
  signUpStatus: EventSignUpStatus;
}

export interface IEventSignupRequest {
  companyName: string;
  companyIdentifier: string;
  name: string;
  email: string;
  phone: string;
  numberOfAttendees: number;
  attendeeNames: string;
  agreedToTerms: boolean;
  eventId: string;
  eventName: string;
}

export interface ICreateUserCommand {
  firstName: string;
  lastName: string;
  emailAddress: string;
  userName: string;
  permissions: {
    companyId: string;
    roleId: number;
  }[];
}

export interface IUpdateUserCommand {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  permissions: {
    companyId: string;
    roleId: number;
  }[];
}

export type DeleteAttendeeOption = "MakeAnonymousAttendee" | "JustDelete";

export type EventFilterType = "Upcoming" | "Past";

export type UserListType =
  | "RecentLogins"
  | "NewUsers"
  | "SearchResults"
  | "NotSetPassword"
  | "CompanyUsers";

export type TransactionPointsFilter = "All" | "Earned" | "Deducted" | "Awarded";

export interface ISponsorDirectoryCompany {
  id: string;
  name: string;
  identifier: string;
  imageUrl?: string;
  primaryContactName?: string;
  primaryContactEmail?: string;
  primaryContactPhoneNumber?: string;
  marketingInformation: string;
  website: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  rates: { unit: string; category: string; rate: number; rateDesc: string }[];
  locations: IDetailedLocation[];
  brands: IBrand[];
  products: IProduct[];
  categories: ICompanyCategory[];
}

export interface IDetailedLocation {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  primaryContact?: string;
  phoneNumbers: ISimplePhoneNumber[];
}

export interface ISimplePhoneNumber {
  number: string;
  type: string;
}

export interface ISponsorDirectoryResults
  extends IPagedResult<ISponsorDirectoryCompany> {
  categoryName: string;
}

export interface ISponsorDirectoryCategory {
  id: string;
  name: string;
  companyCount: number;
  earningPointsIn: boolean;
}

export interface IPurchaserSupplier {
  id: string;
  purchaserSupplierId: string;
  purchaserSupplierCategoryId: string;
  categoryId: string;
  categoryName: string;
  isPrimaryCategory: boolean;
  supplierId: string;
  supplierName: string;
  supplierIdentifier: string;
  companyType: CompanyType;
  pointsLast12Months: number;
  points12to24Months: number;
  createdOn: Date;
  purchaserName: string;
  purchaserId: string;
}

export interface IPurchaserSuppliersResult {
  memberName: string;
  suppliers: IPurchaserSupplier[];
}

export interface ISupplierPurchaserResult {
  supplierName: string;
  members: ISupplierPurchaser[];
}

export interface ISupplierPurchaser {
  id: string;
  categoryName: string;
  purchaserName: string;
  purchaserId: string;
  purchaserIdentifier: string;
  pointsLast12Months: number;
  points12to24Months: number;
  createdOn: Date;
  supplierName: string;
  additionalCategories: ISupplierPurchaserAdditionalCategory[];
}

export interface ISupplierPurchaserAdditionalCategory {
  categoryId: string;
  categoryName: string;
  purchaserSupplierId: string;
  purchaserSupplierCategoryId: string;
}

export interface IApiProblemDetails {
  data: {
    errors: {
      [propName: string]: string;
    };
  };
}

export interface IMemberExportQuery {
  memberType: MemberDirectoryMemberType;
  categoryId?: string;
  competitorId?: string;
  searchTerm?: string;
}

export interface IMemberDirectoryQuery extends IPageQuery {
  memberType: MemberDirectoryMemberType;
  categoryId?: string;
  competitorId?: string;
}

export type CallSheetListType =
  | "MembersOnly"
  | "SponsorsOnly"
  | "MembersAndSponsors";

export interface ICallSheetQuery extends IPageQuery {
  orderByColumn: keyof IListedCompanyInEvent;
  eventId: string;
  listType: CallSheetListType;
}

export interface ICallSheetResult extends IPagedResult<IListedCompanyInEvent> {} // eslint-disable-line

export interface IMemberDirectoryResult
  extends IPagedResult<IMemberDirectoryMember> {
  memberType: MemberDirectoryMemberType;
  categoryId?: string;
  categoryName?: string;
  competitorId?: string;
  competitorName?: string;
}

export interface IMemberDirectoryMember {
  id: string;
  identifier: string;
  name: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  primaryContactName?: string;
  primaryPhone?: string;
  linkedOn?: Date;
  imageUrl?: string;
}

export interface ISponsorCategory {
  id: string;
  name: string;
  memberCount: number;
}

export interface IMemberDirectorySummary {
  activeMemberCount: number;
  notUsingMyCategories: INotUsingCategory[];
  usingMyCompetitors: IUsingCompetitor[];
}

export interface INotUsingCategory {
  categoryId: string;
  categoryName: string;
  memberNotUsingCount: number;
}

export interface IUsingCompetitor {
  competitorId: string;
  competitorName: string;
  categoryId: string;
  categoryName: string;
  memberUsingCount: number;
}

export type MemberDirectoryMemberType =
  | "All"
  | "NotUsingMyCategories"
  | "UsingMyCompetitors";

export interface IEventParticipationInfo {
  companyId: string;
  eventId: string;
  eventName: string;
  attendees: string[];
}

export interface IPurchaserInfo {
  id: string;
  name: string;
  identifier: string;
  website: string;
  marketingInformation: string;
  imageUrl: string;
  computedBalance: number;
  enrollDate: Date;
  last12Months: number;
  last36Months: number;
  lastMonth: number;
  yearToDate: number;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
  primaryContactName?: string;
  primaryContactPhoneNumber?: string;
  locations: IDetailedLocation[];
  redemptions: IRedemptionSummaryLine[];
  events: IEventParticipationInfo[];
  grandDestinations: IEventParticipationInfo[];
  usedCategories: string[];
}

export interface IDateTimeOffsetRange {
  start: Date;
  end: Date;
}

export interface ICommonDateRanges {
  yearToDate: IDateTimeOffsetRange;
  lastMonth: IDateTimeOffsetRange;
  last12to24Months: IDateTimeOffsetRange;
  last12Months: IDateTimeOffsetRange;
  last36Months: IDateTimeOffsetRange;
}

export interface IExportPredefinedCompanyListRequest {
  exportType: PredefinedCompanyExportType;
}

export interface IExportCompaniesRequest {
  companyTypes: CompanyTypeForExports[];
  includeActive: boolean;
  includeInactive: boolean;
  inactivesInSeparateWorksheet: boolean | null;
}

export interface IExportPointsAwardedRequest {
  sponsorId: string;
  memberId?: string;
  startDate: Date;
  endDate: Date;
}

export interface IExportPointsReceivedRequest {
  memberId: string;
  startDate: Date;
  endDate: Date;
}

export interface ICanCreatePostingResult {
  canCreateNew: boolean;
  blockers: string[];
}

export interface INewspaper {
  id: string;
  startYear: number;
  startMonth: number;
  endYear: number;
  endMonth: number;
  url: string;
}

export interface IListNewspapersResult {
  newspapers: INewspaper[];
  currentNewspaperId: string;
}

export interface INewspaperConfig {
  enrollmentCutoffMonths: number;
  pointsCutoff: number;
  pointsTimeframeMonths: number;
}

export interface INewspaperSetCounts {
  setName: string;
  companyCount: number;
  alwaysContactsCount: number;
  alwaysExtraSum: number;
  benchmarkContactsCount: number;
  benchmarkExtraSum: number;
  benchmarkMetContactsCount: number;
  benchmarkMetExtraSum: number;
  totalNewspapers: number;
  showMetBenchmarkColumn: boolean;
}

export interface IAppText {
  id: string;
  key: AppTextKey;
  friendlyName: string;
  text: string;
  isPlainText: boolean;
}

export type AppTextKey = "merchandise-info" | "membership-details" | "about-us";

export interface INewspaperResult {
  newspaper: INewspaper;
  currentNewspaperId: string;
}

export interface IOrdersThatNeedAttentionCounts {
  errorCount: number;
  pendingCount: number;
  onHoldCount: number;
}

export interface IOrderValidationResult {
  deletedOptionIds: string[];
  deletedProductIds: string[];
}

export interface IListedOrder {
  id: string;
  externalOrderId: string;
  type: OrderType;
  companyName: string;
  companyIdentifier: string;
  itemCount: number;
  shipToName: string;
  address: IAddress;
  totalPoints: number;
  status: string;
  createdByName: string;
  createdOn: Date;
}

export type PredefinedCompanyExportType =
  | "GetPrintedStatement"
  | "GetOnlineStatement";

export type ISpreadsheetError = {
  message: string;
  showDownloadTemplateMessage: boolean;
  row?: number;
};

export type IPointSummary = {
  yearMonthSort: string;
  points: number;
};

export type IChartData = {
  keys: string[];
  data: IPointSummary[];
};

export interface IUpdateEventGroupInvoicesCommand {
  eventGroupId: string;
  updates: IInvoiceUpdate[];
}

export interface IInvoiceUpdate {
  invoiceId: string;
  additionalAmountPaid: number;
}

export interface IEventAttendeeResult {
  eventId: string;
  eventName: string;
  attendees: IEventAttendeeModel[];
}

export interface IEventAttendeeModel {
  id: string;
  eventId: string;
  eventGroupId: string;
  companyName: string;
  companyIdentifier: string;
  totalCompanyAttendees: number;
  firstName: string;
  lastName: string;
  isGroupLead: boolean;
}

export interface IFeatureConfig {
  featureName: string;
  values: { [key: string]: string };
}

export type EmailType =
  | "Welcome"
  | "Reminder"
  | "ForgotPassword"
  | "OnHoldMember"
  | "OnHoldAdmin"
  | "GiftCard"
  | "OrderConfirmation"
  | "ActivitySummary"
  | "SponsorStatement"
  | "EventGroupConfirmation";

export type EmailAudience = "Member" | "Sponsor" | "Company" | "User" | "Admin";

export interface IListedEmailDefinition {
  id: string;
  audience: EmailAudience;
  friendlyName: string;
}

export interface IEmailDefinitionDetails {
  id: string;
  audience: EmailAudience;
  friendlyName: string;
  fromUserId: string;
  toUserIds: string[];
  ccUserIds: string[];
  bccUserIds: string[];
}

export interface IUpdateEmailDefinitionCommand {
  id: string;
  fromUserId: string;
  toUserIds: string[];
  ccUserIds: string[];
  bccUserIds: string[];
}

export type EventGroupStatus =
  | "Attending"
  | "Cancelled"
  | "InSignUpQueue"
  | "Interested"
  | "NotInterested"
  | "OnWaitingList"
  | "Blank";

export type InvoiceStatus = "Paid" | "NotPaid" | "NeedsInvoice" | "Blank";
export type PaymentStatus = "Blank" | "NoPayment" | "PartialPayment" | "Paid";

export type CompanyEventStatus =
  | "SignedUp"
  | "Paid"
  | "PartialPayment"
  | "Cancelled"
  | "InSignUpQueue"
  | "Interested"
  | "NotInterested"
  | "OnWaitingList"
  | "Blank";

export interface ISetPotentialEventGroupStatusCommand {
  companyId: string;
  eventId: string;
  newStatus: EventGroupStatus;
}

export interface IClearPotentialEventGroupStatusCommand {
  companyId: string;
  eventId: string;
}

export interface IAddCompanyEventNote {
  companyId: string;
  eventId: string;
  note: {
    content: string;
    isImportant: boolean;
  };
}

export interface ISponsorRoiInfo {
  totalRedemptions: number;
  totalPointsRedeemed: number;
}

export interface IPointsHistoryResult<T> {
  otherCompanyName: string;
  otherCompanyIdentifier: string;
  rows: T[];
  startDate: Date;
  endDate: Date;
  totalPoints: number;
  sponsorRoiInfo: ISponsorRoiInfo | undefined;
}

export type FlattenedGroupedPointHistoryRow = ISponsorGroupedRow &
  FlattenedPointHistoryRow;

export interface ISponsorDetailsRow extends ISponsorGroupedRow {
  postingDate: Date;
  otherCompanyId: string;
  postingDateNumber: number;
}

export interface IMemberDetailsRow extends IMemberGroupedRow {
  postingDate: Date;
}

export interface IMemberGroupedRow {
  id: string;
  sponsorId: string;
  sponsorIdentifier: string;
  sponsorName: string;
  rate: string;
  amount?: number;
  points: number;
  isMonetary: boolean;
  pointRate: number;
  unitName: string;
}

export interface IRateValue extends IDerivedReportRate {
  value?: number;
}

export interface ISponsorGroupedRow {
  id: string;
  otherCompanyName: string;
  otherCompanyIdentifier: string;
  points: number;
  rates: IRateValue[];
}

type FlattenedPointHistoryRow = {
  rate0Dollars?: number;
  rate1Dollars?: number;
  rate2Dollars?: number;
  rate3Dollars?: number;
  rate4Dollars?: number;
};

export type PointsHistoryMode = "SponsorPointsAwarded" | "MemberPointsEarned";

export interface IGrandDestinationVideoInfo {
  link: string;
  description: string;
}

export interface ISentEmail {
  id: string;
  sentToContactId?: string;
  sentToUserId?: string;
  sentToName: string;
  sentOn: Date;
  sentByName: string;
}

export type SentToPersonType = "Contact" | "User";

export interface ISentExternalEmailModel {
  id: string;
  personType: SentToPersonType;
  sentToName: string;
  sentToEmail: string;
  emailTypeCode: string;
  emailDefinitionName: string;
  subjectType: string;
  sentByName: string;
  sentOn: Date;
  status: SendGridEmailStatus;
  companies: ITinyCompany[];
}

export type SendGridEmailStatus =
  | "Opened"
  | "SpamReport"
  | "Dropped"
  | "Bounce"
  | "Click"
  | "Delivered"
  | "Deferred"
  | "Procssed"
  | "Sent"
  | "Unknown";

export interface ISendLinkNotificationResponse {
  companyType: CompanyType;
  allHistoryForLinkAndCompany: ISentEmail[];
}

export interface INewMemberConfirmationAttachment {
  id: string;
  originalFileName: string;
  fileUri: string;
}

export interface IUpdateMemberReferralClaimStatusCommand {
  referralId: string;
  signalRConnectionId: string;
  isClaimed: boolean;
}

export type EventGroupExportType =
  | "ActiveGroups"
  | "CancelledGroups"
  | "ActiveAndCancelledGroups";

export type ISupplierCategory = {
  id: string;
  name: string;
};

export interface ICreateSupplierProfileCommand {
  categories: IProfileCategory[];
}

export interface IApplySupplierProfileCommand
  extends ICreateSupplierProfileCommand {
  profileId: string;
  pointsToAward: number;
}

export interface IProfileCategory {
  categoryId: string;
  suppliers: IProfileSupplier[];
  selfChecked: boolean;
}

export interface IProfileSupplier {
  supplierId?: string;
  newSupplierName?: string;
  verified: boolean;
  isPrePopulatedValue: boolean;
  profileItemId: string | undefined;
}

export interface IProfileStats {
  lastCompletedOn: Date;
  newCompanyCount: number;
  newLinkCount: number;
  pointsEarnedLastYear: number;
  verifiedLinkCount: number;
}

// Not really used by the api
export type PointsAwardedQueryStringProps =
  | "cname"
  | "cid"
  | "start"
  | "end"
  | "pointRate"
  | "unitName";

export type FlattenedPointHistoryDetailsRow = ISponsorDetailsRow &
  FlattenedPointHistoryRow;

// Must match the property name in BcnContext on the server
// (this is used for a very limited spot in the system)
export type LookupListType =
  | "Units"
  | "Products"
  | "Brands"
  | "Categories"
  | "EventTypes"
  | "Markets"
  | "RelationshipTypes";

export interface IProhibitedVerificationViewModel {
  type: "Self" | "Supplier";
  categoryId: string;
  supplierId: string | undefined;
  cannotVerifyUntil: Date;
  lastVerifiedOn: Date;
  prohibitedById: string;
}

export interface INewProfileViewModel {
  categories: IProfileCategoryViewModel[];
  prohibitedVerifications: IProhibitedVerificationViewModel[];
}

export interface IListedSupplierProfile {
  id: string;
  createdByUserName: string;
  createdOn: Date;
  memberName: string;
  memberIdentifier: number;
  memberId: string;
  appliedOn: Date | undefined;
  pointsAwarded: number | undefined;
}

export interface IExistingProfileViewModel {
  categories: IProfileCategoryViewModel[];
  statistics?: IProfileStats;
  memberInfo: IProfileMemberInfo;
  appliedOn: Date | undefined;
  pointsAwarded: number | undefined;
  categorySelfCheckIds: string[];
  prohibitedVerifications: IProhibitedVerificationViewModel[];
}

export interface IProfileMemberInfo {
  companyId: string;
  companyName: string;
  identifier: string;
  completedOn: Date;
  userName: string;
}

export interface IProfileCategoryViewModel {
  categoryId: string;
  categoryName: string;
  suppliers: IProfileSupplierViewModel[];
}

export interface IProfileSupplierViewModel {
  supplierId?: string;
  supplierName?: string;
  newSupplierName: string;
  isPrePopulatedValue: boolean;
  verified: boolean;
  profileItemId?: string;
}

export interface IMarketingFundEvents {
  marketingFundAccountId: string;
  events: {
    eventId: string;
    eventName: string;
  }[];
}

export interface ITrackedLead {
  id: string;
  sponsorId: string;
  sponsorName: string;
  sponsorIdentifier: string;
  memberId: string;
  memberName: string;
  memberIdentifier: string;
  notes: string;
  createdOn: Date;
  closedOn?: Date;
  totalPointsAwarded?: number;
  createdBy: string;
}

export type TrackedLeadType = "Open" | "Closed" | "Both";

export interface ICreateTrackedLeadCommand {
  sponsorIds: string[];
  memberId: string;
  notes: string;
}

export type MemberUploadStatus =
  | "Waiting"
  | "Processing"
  | "Processed"
  | "Issue(s) Detected"
  | "Ready To Apply"
  | "Applying"
  | "Applied"
  | "Applied & Emailed";

export interface IListedMemberUpload {
  id: string;
  name: string;
  createdOn: Date;
  createdBy: string;
  status: MemberUploadStatus;
  linksCreated: number;
  companiesCreated: number;
  usersCreated: number;
  totalRows: number;
  newMemberCount: number;
  rowsApplied: number;
}

export interface IRecentMemberSponsorLink {
  id: string;
  purchaserSupplierId: string;
  sponsorId: string;
  sponsorName: string;
  sponsorIdentifier: string;
  memberId: string;
  memberName: string;
  memberIdentifier: string;
  linkedOn: Date;
  linkedById: string;
  linkedByName: string;
  linkedByCompanyId: string;
  linkedByCompanyName: string;
  memberEmailHistory: ISentEmail[];
  sponsorEmailHistory: ISentEmail[];
}

export interface IUploadMemberSpreadsheetResult {
  passedBasicValidation: boolean;
  errorMessage: string;

  // This should be part of Stage 1
  // newCompaniesExist: string;
  memberUploadId: string | undefined;
  hasMismatchedContacts: boolean;
}

export interface IReplinkInfo {
  companyName: string;
  programName: string;
  address1: string;
  zip: string;
  city: string;
  state: string;
  emailAddress: string;
  shipToEmail: string;
  phoneNumber: string;
}

export interface IHostCompanyInfo {
  companyName: string;
  address1: string;
  city: string;
  state: string;
  zip: string;
  phoneNumber: string;
  emailAddress: string;
}

export interface ICatalogProductSettings {
  tax: string;
  markup: string;
}

export interface IMergeCompaniesResult {
  dataMergeId: string;
  conflictCount: number;
  remainingCompanyId: string;
}

export interface IMergeContactsResult {
  dataMergeId: string;
  conflictCount: number;
}

export type DataMergeType = "Company" | "Contact";

export interface IDataMergeViewModel {
  id: string;
  description: string;
  keptEntityId: string;
  keptEntityName: string;
  conflicts: IMergeConflictViewModel[];
  childMerges: IDataMergeViewModel[];
  type: DataMergeType;
  companyId: string;
  companyName: string;
}

export interface IMergeConflictViewModel {
  id: string;
  contactId: string;
  mergeActionId: string;
  contactFirstName: string;
  contactLastName: string;
  companyId: string;
  companyName: string;
  companyIdentifier: string;
  fieldName: string;
  valueForDeleted: string;
  currentValue: string;
}

export interface IHighlightedSponsorsResult {
  // undefined in the rare case that we couldn't find any
  // category their not earning points in
  category: ICategory | undefined;
  sponsors: IHighlightedSponsor[] | undefined;
}

export interface IHighlightedSponsor {
  id: string;
  name: string;
  imageUrl: string;
  website: string;
}

export interface IEditOrderModel {
  orderId: string;
  companyLocations: ICompanyLocation[];
  shoppingCartItems: IShoppingCartItem[];
  phoneNumber: string;
  companyInfo: ICompanyInfo;
  createdBy: ITinyUser;
  createdOn: Date;
  shipToAddressId: string;
}

export interface IGenericContact {
  id: string;
  firstName: string;
  lastName: string;
  status: "Active" | "Inactive";
}

export interface IEventAccountSummary {
  companyId: string;
  companyName: string;
  companyIdentifier: string;
  eventTransactionCount: number;
}

export const isApiProblemDetails = (obj: any): obj is IApiProblemDetails => {
  return obj.data && obj.data.errors ? true : false;
};

export const getApiErrors = (problemDetails: IApiProblemDetails) => {
  const result: string[] = [];

  for (const errorPropName in problemDetails.data.errors) {
    result.push(problemDetails.data.errors[errorPropName]);
  }

  return result;
};
