import React from "react";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import myStore from "stores/my";
import uiStore from "stores/ui";
import { useHistory } from "react-router";
import { ApplicationPaths } from "components/api-authorization/ApiAuthorizationConstants";
import { paths } from "routes/Paths";
import CompanySelector from "components/Shell/CompanySelector";
import { ICompanyPermissionViewModel } from "services/api/types";
import BcnToolbarLogo from "components/Shell/BcnToolbarLogo";
import BuildPerksToolbarLogo from "components/Shell/BuildPerksToolbarLogo";
import withWidth from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import * as appConstants from "applications/appConstants";

type Props = {
  width: Breakpoint;
};

const SponsorToolbarItems = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      menuButton: {
        marginRight: theme.spacing(2),
      },
      grow: {
        flexGrow: 1,
      },
      welcome: {
        display: "block",
        [theme.breakpoints.down("md")]: {
          display: "none",
        },
      },
      title: {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "block",
        },
      },
      mobileTitlePortal: {
        pointerEvents: "none",
        width: "100%",
        textAlign: "center",
        fontSize: "large",
        position: "absolute",
        left: 0,
        // Many components stick stuff into our portal. This code
        // ensures that only the first one is shown.
        "& div": {
          display: "none",
        },
        "& div:first-child": {
          display: "block",
        },
      },
    })
  );

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const history = useHistory();

  function handleProfileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget as any);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleChangePassword() {
    handleMenuClose();
    uiStore.showModal({
      type: "ChangePasswordModal",
      persisted: true,
      props: {},
    });
  }

  const handleSignOut = () => {
    const logoutPath = {
      pathname: `${ApplicationPaths.LogOut}`,
      state: { local: true },
    };
    handleMenuClose();
    history.push(logoutPath);
  };

  const handleStopImpersoating = () => {
    myStore.stopImpersonating();
    history.push(myStore.preImpersonationPath ?? paths.app.admin.users);
    setAnchorEl(null);
  };

  const menuId = "primary-search-account-menu";
  const menu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleChangePassword}>Change My Password</MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      {myStore.impersonatingUserInfo === undefined && (
        <MenuItem onClick={handleSignOut}>Log Out</MenuItem>
      )}
      {myStore.impersonatingUserInfo && (
        <MenuItem onClick={handleStopImpersoating}>Stop Impersonating</MenuItem>
      )}
    </Menu>
  );

  const handleCompanyChange = (company: ICompanyPermissionViewModel) => {
    myStore.setCurrentCompany(company.companyId);
    if (company.companyType === "Sponsor") {
      history.push(`${paths.app.root}`);
    } else if (company.companyType === "Member") {
      history.push(`${paths.app.root}`);
    }
  };

  const renderCompanySelect = () => {
    if (
      myStore.currentUserInfo &&
      myStore.currentCompany &&
      myStore.currentUserInfo.companies.length > 1
    ) {
      return (
        <CompanySelector
          onChange={handleCompanyChange}
          currentCompanyId={myStore.currentCompany.companyId}
          companyPermissions={myStore.currentUserInfo.companies}
        />
      );
    }

    return undefined;
  };

  return (
    <>
      {process.env.REACT_APP_BRANDING === appConstants.BuildPerks && (
        <BuildPerksToolbarLogo />
      )}
      {process.env.REACT_APP_BRANDING === appConstants.BuildersClubNorth && (
        <BcnToolbarLogo />
      )}
      <div className={classes.mobileTitlePortal} id="mobile-title-portal" />
      {renderCompanySelect()}
      <div className={classes.grow} />
      <Typography className={classes.title} variant="subtitle2" noWrap>
        {myStore.emailAddress}
      </Typography>
      <IconButton edge="end" onClick={handleProfileMenuOpen} color="inherit">
        <AccountCircle />
      </IconButton>
      {menu}
    </>
  );
};

export default withWidth()(SponsorToolbarItems);
