import { apiClient } from "services/api/client";

export const updateBirthdayPointsThreshold = async (newThreshold: number) => {
  const url = "administrator/birthdayPoints/threshold";
  const response = await apiClient.post(url, { newThreshold });
  return response.data;
};

export const getBirthdayPointsThreshold = async () => {
  const url = "administrator/birthdayPoints/threshold";
  const response = await apiClient.get<{ threshold: number }>(url);
  return response;
};
