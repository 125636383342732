import { apiClient } from "services/api/client";
import {
  IPointsHistoryResult,
  ISponsorGroupedRow,
  ISponsorDetailsRow,
} from "services/api/types";
import { downloadFile } from "services/api/modules/helpers";

const SPONSOR_POINTS_URL = "sponsors/pointsHistory";

export const getPointHistoryDetails = async (params: {
  startDate: Date;
  endDate: Date;
  companyId: string;
  otherCompanyId: string;
}) => {
  const url = `${SPONSOR_POINTS_URL}/details`;
  const response = await apiClient.get<
    IPointsHistoryResult<ISponsorDetailsRow>
  >(url, {
    params,
  });
  return response;
};

export const getPointHistoryGrouped = async (args: {
  companyId: string;
  startDate: Date;
  endDate: Date;
}) => {
  const url = `${SPONSOR_POINTS_URL}/grouped`;
  const response = await apiClient.get<
    IPointsHistoryResult<ISponsorGroupedRow>
  >(url, {
    params: args,
  });
  return response;
};

export const getPointsHistoryDetailsExport = async (args: {
  startDate: Date;
  endDate: Date;
  companyId: string;
  otherCompanyId: string;
}) => {
  const url = `${SPONSOR_POINTS_URL}/details/export`;

  const response = await apiClient.post(url, args, {
    responseType: "blob",
  });

  downloadFile(response);
};

export const getPointsHistoryGroupedExport = async (params: {
  startDate: Date;
  endDate: Date;
}) => {
  const url = `${SPONSOR_POINTS_URL}/grouped/export`;

  const response = await apiClient.post(url, params, {
    responseType: "blob",
  });

  downloadFile(response);
};
