import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";

const BuildPerksToolbarLogo = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: 200,
        marginRight: theme.spacing(2),
        transform: "translateX(-8px)",
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    })
  );

  const classes = useStyles();
  const history = useHistory();

  return (
    <img
      onClick={() => history.push("/")}
      className={classes.root}
      src="/images/logo-white-bp.png"
      alt="BuildPerks"
    />
  );
};

export default BuildPerksToolbarLogo;
