import React from "react";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { CompanyType } from "services/api/types";
import MenuItem from "@material-ui/core/MenuItem";

type Props = {
  value: string | undefined;
  touched: boolean | undefined;
  error: string | undefined;
  handleChange: (e: React.ChangeEvent<any>) => void;
};

const SupplierTypeField = (props: Props) => {
  const validSupplierTypes: CompanyType[] = [
    "Sponsor",
    "Prospect",
    "Competitor",
  ];

  return (
    <FormControl fullWidth>
      <TextField
        select
        variant="outlined"
        margin="dense"
        label="Supplier Type"
        error={props.touched && Boolean(props.error)}
        name="companyType"
        onChange={props.handleChange}
        value={props.value}
        helperText={props.touched && props.error}
      >
        {validSupplierTypes.map((companyType) => {
          return (
            <MenuItem key={companyType} value={companyType}>
              {companyType}
            </MenuItem>
          );
        })}
      </TextField>
    </FormControl>
  );
};

export default SupplierTypeField;
