import { action, computed, observable } from "mobx";
import api from "services/api";
import {
  OrderFilterType,
  IFullOrderModel,
  AddressAction,
} from "services/api/types";
import { debounceDecorator } from "utils/helpers";

export class AdminOrdersStore {
  @observable
  public spreadsheetSass = "";

  @observable
  public ordersAreLoaded = false;

  @observable
  public ordersAreLoading = true;

  @observable
  public currentFilterType: OrderFilterType = "Pending";

  @observable
  public catalogOrders: IFullOrderModel[] = [];

  @observable
  public updatingOrderIds: string[] = [];

  @computed
  public get needAttentionCount() {
    return (
      this.pendingOrderCount + this.onHoldOrderCount + this.errorOrderCount
    );
  }

  @observable
  public pendingOrderCount = 0;

  @observable
  public onHoldOrderCount = 0;

  @observable
  public errorOrderCount = 0;

  @observable
  public memberSearch = "";

  @observable
  public purchaseOrderId = "";

  @action.bound
  public invalidateOrders() {
    this.ordersAreLoaded = false;
  }

  @action.bound
  public async retryOrder(catalogOrderId: string) {
    this.updatingOrderIds = [...this.updatingOrderIds, catalogOrderId];

    try {
      const retryResult = await api.admin.orders.retry(catalogOrderId);
      const newOrders = this.catalogOrders.map((o) => {
        if (o.id === retryResult.id) {
          return retryResult;
        }

        return o;
      });

      this.catalogOrders = newOrders;
    } catch (e) {
      console.error(e);
    }

    this.updatingOrderIds = this.updatingOrderIds.filter(
      (id) => id !== catalogOrderId
    );

    this.updateNeedAttentionCount();
  }

  @action.bound
  public async cancelOrder(
    catalogOrderId: string,
    addressAction: AddressAction
  ) {
    this.updatingOrderIds = [...this.updatingOrderIds, catalogOrderId];

    try {
      const cancelResult = await api.admin.orders.cancel(
        catalogOrderId,
        addressAction
      );

      const newOrders = this.catalogOrders.map((o) => {
        if (o.id === cancelResult.data.id) {
          return cancelResult.data;
        }

        return o;
      });

      this.catalogOrders = newOrders;
    } catch (e) {
      console.error(e);
    }

    this.updatingOrderIds = this.updatingOrderIds.filter(
      (id) => id !== catalogOrderId
    );
    this.updateNeedAttentionCount();
  }

  @action.bound
  public async scrubOrder(
    catalogOrderId: string
  ): Promise<"Updated" | "Cancelled" | "Error"> {
    this.updatingOrderIds = [...this.updatingOrderIds, catalogOrderId];

    try {
      const result = await api.admin.orders.scrubOrder(catalogOrderId);

      const newOrders = this.catalogOrders.map((o) => {
        if (o.id === result.data.id) {
          return result.data;
        }

        return o;
      });

      this.catalogOrders = newOrders;
      if (result.data.status === "Cancelled") return "Cancelled";
      return "Updated";
    } catch (e) {
      console.log(e);
      return "Error";
    } finally {
      this.updatingOrderIds = this.updatingOrderIds.filter(
        (id) => id !== catalogOrderId
      );
    }
  }

  @action.bound
  public async approveOrder(catalogOrderId: string) {
    this.updatingOrderIds = [...this.updatingOrderIds, catalogOrderId];

    try {
      const approveResult = await api.admin.orders.approve(catalogOrderId);

      const newOrders = this.catalogOrders.map((o) => {
        if (o.id === approveResult.id) {
          return approveResult;
        }

        return o;
      });

      this.catalogOrders = newOrders;
    } catch (e) {
      console.log(e);
    }

    this.updatingOrderIds = this.updatingOrderIds.filter(
      (id) => id !== catalogOrderId
    );
    this.updateNeedAttentionCount();
  }

  @action.bound
  public async updateNeedAttentionCount() {
    const result = await api.admin.orders.getNeedAttentionCount();
    const { data } = result;

    this.errorOrderCount = data.errorCount;
    this.pendingOrderCount = data.pendingCount;
    this.onHoldOrderCount = data.onHoldCount;
  }

  @action.bound
  public setFilterType(filterType: OrderFilterType) {
    this.currentFilterType = filterType;

    const shouldSearchNow =
      filterType === "Error" ||
      filterType === "OnHold" ||
      filterType === "Pending" ||
      filterType === "Recent" ||
      (filterType === "PurchaseOrderId" && this.purchaseOrderId.length > 0) ||
      (filterType === "Company" && this.memberSearch.length > 0);

    if (shouldSearchNow) {
      this.getOrders(true);
    }
  }

  @action.bound
  public setMemberSearchDebounced(memberSearch: string) {
    this.memberSearch = memberSearch;
    this.currentFilterType = "Company";
    this.getOrdersDebounced();
  }

  @action.bound
  public setPurchaseOrderIdDebounced(purchaseOrderId: string) {
    this.purchaseOrderId = purchaseOrderId;
    this.currentFilterType = "PurchaseOrderId";
    this.getOrdersDebounced();
  }

  @action.bound
  public setPurchaseOrderIdImmediate(purchaseOrderId: string) {
    this.purchaseOrderId = purchaseOrderId;
    this.currentFilterType = "PurchaseOrderId";
    this.getOrdersDebounced();
  }

  @action.bound
  @debounceDecorator(1000, false)
  public getOrdersDebounced() {
    this.getOrders(true);
  }

  @action.bound
  public async getOrders(force = false) {
    if (this.ordersAreLoaded && !force) return;

    // We could have a filter type without necessary corresponding
    // data. If this is the case, just clear the orders and don't
    // hit the api
    if (this.currentFilterType === "Company" && !this.memberSearch) {
      this.catalogOrders = [];
      return;
    }

    if (this.currentFilterType === "PurchaseOrderId" && !this.purchaseOrderId) {
      this.catalogOrders = [];
      return;
    }

    try {
      this.ordersAreLoading = false;

      const result = await api.admin.orders.get(
        this.currentFilterType,
        this.currentFilterType === "Company" ? this.memberSearch : undefined,
        this.currentFilterType === "PurchaseOrderId"
          ? this.purchaseOrderId
          : undefined
      );

      this.catalogOrders = result.data.orders;
      this.spreadsheetSass = result.data.spreadsheetSass;
      this.ordersAreLoaded = true;
    } catch (e) {
      console.error(e);
    }

    this.ordersAreLoading = false;
  }
}

export default new AdminOrdersStore();
