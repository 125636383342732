import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { INewspaper } from "services/api/types";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";

type Props = {
  newspaper?: INewspaper;
  handleClose: () => void;
  handleCreate: (data: Omit<INewspaper, "id">, callback: () => void) => void;
  handleUpdate: (data: INewspaper, callback: () => void) => void;
};

const AddEditNewspaperModal = (props: Props) => {
  const [startMonth, setStartMonth] = React.useState(
    props.newspaper?.startMonth ?? new Date().getMonth() + 1
  );
  const [startYear, setStartYear] = React.useState(
    props.newspaper?.startYear ?? new Date().getFullYear()
  );
  const [endMonth, setEndMonth] = React.useState(
    props.newspaper?.endMonth ?? new Date().getMonth() + 1
  );
  const [endYear, setEndYear] = React.useState(
    props.newspaper?.endYear ?? new Date().getFullYear()
  );
  const [url, setUrl] = React.useState(props.newspaper?.url ?? "");
  const [error, setError] = React.useState("");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      formControl: {
        margin: theme.spacing(1),
        minWidth: 140,
      },
      url: {
        minWidth: 700,
      },
      error: {
        color: theme.palette.error.main,
        marginBottom: theme.spacing(2),
      },
    })
  );

  const handleSave = () => {
    if (url.length === 0) {
      setError("Please enter a URL");
      return;
    }

    if (props.newspaper && props.handleUpdate) {
      props.handleUpdate(
        {
          id: props.newspaper!.id,
          startMonth,
          startYear,
          endMonth,
          endYear,
          url,
        },
        () => props.handleClose()
      );
    } else if (!props.newspaper && props.handleCreate) {
      props.handleCreate(
        { startMonth, startYear, endMonth, endYear, url },
        () => props.handleClose()
      );
    }
  };

  const getYears = () => {
    const result: number[] = [];
    const maxYear = new Date().getFullYear() + 1;

    for (let i = 2013; i <= maxYear; i++) {
      result.push(i);
    }

    return result;
  };

  const classes = useStyles();
  const years = getYears();

  const renderMonthSelect = (
    value: number,
    setValue: (value: number) => void,
    label: string
  ) => {
    return (
      <FormControl
        margin="dense"
        variant="outlined"
        className={classes.formControl}
      >
        <InputLabel id="month-label">{label}</InputLabel>
        <Select
          labelId="month-label"
          id="month-select"
          value={value}
          onChange={(evt) => setValue(evt.target.value as number)}
          label={label}
        >
          <MenuItem value="">
            <em>Select One</em>
          </MenuItem>
          <MenuItem value={1}>January</MenuItem>
          <MenuItem value={2}>February</MenuItem>
          <MenuItem value={3}>March</MenuItem>
          <MenuItem value={4}>April</MenuItem>
          <MenuItem value={5}>May</MenuItem>
          <MenuItem value={6}>June</MenuItem>
          <MenuItem value={7}>July</MenuItem>
          <MenuItem value={8}>August</MenuItem>
          <MenuItem value={9}>September</MenuItem>
          <MenuItem value={10}>October</MenuItem>
          <MenuItem value={11}>November</MenuItem>
          <MenuItem value={12}>December</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const renderYearSelect = (
    value: number,
    setValue: (value: number) => void,
    label: string
  ) => {
    return (
      <FormControl
        margin="dense"
        variant="outlined"
        className={classes.formControl}
      >
        <InputLabel id="year-label">{label}</InputLabel>
        <Select
          labelId="year-label"
          id="year-select"
          value={value}
          onChange={(evt) => setValue(evt.target.value as number)}
          label={label}
        >
          <MenuItem value="">
            <em>Select One</em>
          </MenuItem>
          {years.map((y) => {
            return (
              <MenuItem key={y} value={y}>
                {y}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  return (
    <ResponsiveModalShell
      handleClose={props.handleClose}
      handleSave={handleSave}
      title={props.newspaper ? "Edit Newspaper" : "Add Newspaper"}
    >
      <>
        {error ? <div className={classes.error}>{error}</div> : undefined}
        <div>
          {renderMonthSelect(startMonth, setStartMonth, "Start Month")}
          {renderYearSelect(startYear, setStartYear, "Start Year")}
        </div>
        <div>
          {renderMonthSelect(endMonth, setEndMonth, "End Month")}
          {renderYearSelect(endYear, setEndYear, "End Year")}
        </div>
        <div>
          <TextField
            className={clsx(classes.formControl, classes.url)}
            label="Url"
            margin="dense"
            variant="outlined"
            value={url}
            onChange={(evt) => {
              setUrl(evt.target.value);
              setError("");
            }}
          />
        </div>
      </>
    </ResponsiveModalShell>
  );
};

export default AddEditNewspaperModal;
