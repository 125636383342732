/* eslint-disable */
export type HashAccessor<T> = string | string[] | ((obj: T) => any);

export const access = <T>(
  obj: T,
  accessor: HashAccessor<T>,
  defaultValue?: any
) => {
  let value;
  if (typeof accessor === "function") {
    try {
      value = accessor(obj);
    } catch {
      /* ignore */
    }
  } else {
    const path = Array.isArray(accessor)
      ? accessor
          .map((key) => key.split("."))
          .reduce((acc, i) => acc.concat(i), [])
      : accessor.split(".");
    value = path.reduce((current, nextKey) => {
      const anyCurrent = current as any;
      if (
        current === undefined ||
        current === null ||
        (anyCurrent.hasOwnProperty && !anyCurrent.hasOwnProperty(nextKey))
      ) {
        return undefined;
      }
      return current[nextKey];
    }, obj);
  }
  return value === undefined ? defaultValue : value;
};

export function debounce<
  Context,
  Procedure extends (this: Context, ...args: any[]) => any
>(func: Procedure, waitMilliseconds = 50, immediate?: boolean): Procedure {
  let timeout: number | undefined;
  return function (this: Context, ...args: Parameters<Procedure>) {
    const context = this;
    const doLater = function () {
      timeout = undefined;
      if (!immediate) func.apply(context, args);
    };
    const shouldCallNow = immediate && !timeout;
    if (timeout) window.clearTimeout(timeout);
    timeout = window.setTimeout(doLater, waitMilliseconds);

    if (shouldCallNow) func.apply(context, args);
  } as Procedure;
}

export function debounceDecorator(wait: number = 50, immediate?: boolean) {
  return function (
    target: object,
    propertyName: string,
    propertyDesciptor: PropertyDescriptor
  ): PropertyDescriptor {
    const method = propertyDesciptor.value;
    propertyDesciptor.value = debounce(method, wait, immediate);
    return propertyDesciptor;
  };
}

export const stringToOption = (value: string) => ({ label: value, value });

export const chunkArrayBySize = <T>(arr: T[], chunkSize: number) => {
  const res: T[][] = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    const chunk = arr.slice(i, i + chunkSize);
    res.push(chunk);
  }
  return res;
};

export const chunkArray = <T>(arr: T[], numberOfChunks: number) => {
  let chunkSize = arr.length / numberOfChunks;
  const mod = arr.length % numberOfChunks;
  if (mod !== 0) {
    chunkSize++;
  }

  const result = chunkArrayBySize(arr, chunkSize);
  return result;
};
/* eslint-enable */
