import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import { EventSignUpStatus } from "services/api/types";

type Props = {
  value: EventSignUpStatus;
  onChange: (newValue: EventSignUpStatus) => void;
};

const EventVisibilityRadioGroup = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      mainLabel: {
        display: "inline-block",
      },
      group: {
        display: "inline-block",
        marginLeft: theme.spacing(2),
      },
      radioRoot: {
        padding: 3,
      },
    })
  );

  const classes = useStyles();

  const handleRadioChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(evt.currentTarget.value as EventSignUpStatus);
  };

  return (
    <div>
      <FormLabel className={classes.mainLabel}>Sign-Up Status:</FormLabel>
      <RadioGroup
        className={classes.group}
        row={true}
        name="status"
        onChange={handleRadioChange}
        value={props.value}
      >
        <FormControlLabel
          label="Open"
          value="Open"
          control={<Radio classes={{ root: classes.radioRoot }} />}
        />
        <FormControlLabel
          label="Sold Out"
          value="SoldOut"
          control={<Radio classes={{ root: classes.radioRoot }} />}
        />
        <FormControlLabel
          label="Coming Soon"
          value="ComingSoon"
          control={<Radio classes={{ root: classes.radioRoot }} />}
        />
      </RadioGroup>
    </div>
  );
};

export default EventVisibilityRadioGroup;
