import { Formik, FormikActions, FormikProps } from "formik";
import React from "react";
import { defaultErrorHandler } from "services/api/errors";
import {
  ICompanyProduct,
  CreateCompanyProductCommand,
  IProduct,
  Option,
} from "services/api/types";
import enumerableStore from "stores/enumerable";
import ProductsDropdown from "./ProductsDropdown";
import { Observer } from "mobx-react";
import * as yup from "yup";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";

type FormData = {
  productId?: string;
  productName?: string;
};

type Props = {
  companyId: string;
  companyProducts: ICompanyProduct[];
  handleCancel: () => void;
  handleSubmit: (payload: CreateCompanyProductCommand) => void;
};

const schema = yup.object().shape({
  productId: yup.string(),
  productName: yup.string(),
});

const FormCompanyProduct = (props: Props) => {
  const initialValues = { productId: "" };

  React.useEffect(() => {
    enumerableStore.ensureProductsFetched();
  }, []);

  const handleFormSubmit = async (
    values: FormData,
    actions: FormikActions<FormData>
  ) => {
    const command: CreateCompanyProductCommand = {
      productId: values.productId,
      productName: values.productName,
      companyId: props.companyId,
    };

    try {
      await props.handleSubmit(command);
    } catch (err) {
      defaultErrorHandler(err);
    }
    actions.setSubmitting(false);
  };

  const handleOptionSelected = (
    option: Option,
    formikProps: FormikProps<FormData>
  ) => {
    formikProps.setFieldValue("productId", option.value);
    formikProps.setFieldValue("productName", undefined);
  };

  const handleRawTextSelected = (
    rawText: string,
    formikProps: FormikProps<FormData>
  ) => {
    formikProps.setFieldValue("productId", undefined);
    formikProps.setFieldValue("productName", rawText);
  };

  const renderProductsDropdown = (
    formikProps: FormikProps<FormData>,
    products: IProduct[]
  ) => {
    return (
      <ProductsDropdown
        handleOptionSelected={(option) =>
          handleOptionSelected(option, formikProps)
        }
        handleRawTextSelected={(rawText) =>
          handleRawTextSelected(rawText, formikProps)
        }
        value={formikProps.values.productId}
        error={formikProps.errors.productId}
        touched={formikProps.touched.productId}
        products={products}
      />
    );
  };

  return (
    <Observer
      render={() => {
        const products = enumerableStore.getEnum("products");

        if (enumerableStore.isLoading("products")) {
          return <div />;
        }

        return (
          <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
          >
            {(formikProps) => (
              <ResponsiveModalShell
                handleClose={props.handleCancel}
                title="Add Product"
                disableSaveButton={formikProps.isSubmitting}
                handleSave={() => formikProps.submitForm()}
              >
                {renderProductsDropdown(formikProps, products)}
              </ResponsiveModalShell>
            )}
          </Formik>
        );
      }}
    />
  );
};

export default FormCompanyProduct;
