import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { AccountOption } from "types/options";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDebounce } from "utils/hooks";
import api from "services/api";
import { getAccountString } from "utils/accounts";

type Props = {
  className?: string;
  errorClassName: string;
  errorMessage: string;
  value: AccountOption | null;
  onChange: (newValue: AccountOption) => void;
  onBlur?: (evt: React.FocusEvent) => void;
  label: string;
  optionsFilter?: (options: AccountOption[]) => AccountOption[];
  noRecordsText?: string;
  onlyAvailableOptions?: AccountOption[];
  isInEvents: boolean;
};

const AccountSelect = (props: Props) => {
  const [options, setOptions] = React.useState<AccountOption[]>(
    props.onlyAvailableOptions ?? []
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [textBoxValue, setTextBoxValue] = React.useState("");

  const debouncedSearchTerm = useDebounce(textBoxValue, 500);
  const { optionsFilter } = props;

  React.useEffect(() => {
    if (debouncedSearchTerm && !props.onlyAvailableOptions) {
      const searchCompanies = async (searchTerm: string) => {
        setIsLoading(true);
        const searchResult = await api.admin.search.searchAccounts({
          search: searchTerm,
          isInEvents: props.isInEvents,
        });

        if (!searchResult) {
          setIsLoading(false);
          return;
        }

        if (searchResult.searchTerm === debouncedSearchTerm) {
          let options: AccountOption[] = searchResult.accounts.map((r) => {
            return {
              value: r.id,
              label: getAccountString(r),
              computedBalance: r.computedBalance,
              companyType: r.companyType,
              companyId: r.companyId,
            };
          });

          if (optionsFilter) {
            options = optionsFilter(options);
          }

          window.setTimeout(() => {
            setOptions(options);
            setIsLoading(false);
          }, 100);

          setOptions(options);
          setIsLoading(false);
        }
      };

      searchCompanies(debouncedSearchTerm);
    }
  }, [
    debouncedSearchTerm,
    optionsFilter,
    props.onlyAvailableOptions,
    props.isInEvents,
  ]);

  const handleInputChange = (evt: React.ChangeEvent<any>) => {
    // Setting the inital value of the dropdown triggers this event
    // with a null evt
    if (!evt) return;

    if (!props.onlyAvailableOptions) {
      setOptions([]);
    }

    const searchTerm = (evt.currentTarget as any).value || "";
    setTextBoxValue(searchTerm);
  };

  const noRecordsText = props.noRecordsText ?? "No companies found";

  return (
    <FormControl fullWidth variant="filled" className={props.className ?? ""}>
      <Autocomplete
        value={props.value}
        noOptionsText={textBoxValue ? noRecordsText : "Type to search"}
        onInputChange={handleInputChange}
        options={options}
        loading={isLoading}
        onBlur={props.onBlur}
        loadingText="Searching accounts..."
        getOptionSelected={(option, value) => {
          return option === value;
        }}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (typeof option.label === "string") {
            return option.label;
          }

          return (option as any).label.label;
        }}
        onChange={(event: any, option: any) => {
          if (!props.onlyAvailableOptions) {
            setOptions([]);
          }
          return props.onChange(option);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            error={Boolean(props.errorMessage)}
            label={props.label}
            style={{ margin: 0 }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            margin="dense"
          />
        )}
      />
      <FormHelperText variant="standard" className={props.errorClassName}>
        {props.errorMessage}
      </FormHelperText>
    </FormControl>
  );
};

export default AccountSelect;
