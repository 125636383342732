import * as patches from "utils/patches";
import { action, computed, observable, set, toJS } from "mobx";
import api from "services/api";

import {
  ICompany,
  ICompanyLocationPayload,
  ICompanyNotePayload,
  ICreateCompanyCommand,
  IUpdateCompanyCommand,
  IContactDetailed,
  ILocationDetailed,
  ICompanyCategoryCommand,
  ITransactionViewModel,
  IPurchaserSupplier,
  ISupplierPurchaser,
  INote,
  IGetStatementsResult,
  ISimpleNewContact,
  CreateCompanyBrandCommand,
  CreateCompanyProductCommand,
  IAccountInfo,
} from "services/api/types";

export class CompanyStore {
  @observable
  public loading = false;

  @observable
  public isBusy = false;

  @observable
  public currentAccount: IAccountInfo | undefined;

  @observable
  public lastCompanyPage: string | undefined;

  @observable
  public contacts: IContactDetailed[] | undefined;

  @observable
  public purchaserSuppliers: IPurchaserSupplier[] | undefined;

  @observable
  public supplierPurchasers: ISupplierPurchaser[] | undefined;

  @observable
  public getStatementsResult: IGetStatementsResult | undefined;

  @observable
  public isLoadingStatements = false;

  @observable
  public isSavingContact = false;

  @computed
  public get details() {
    if (this.currentId) {
      const details = this.companies[this.currentId];
      return details;
    }

    return this.currentId ? this.companies[this.currentId] : undefined;
  }

  @action.bound
  public unload() {
    this.companies = {};
    this.contacts = undefined;
    this.purchaserSuppliers = undefined;
    this.supplierPurchasers = undefined;
    this.getStatementsResult = undefined;
    this.currentAccount = undefined;
  }

  @observable
  private currentId?: string;

  @observable
  private companies: { [id: string]: ICompany } = {};

  @action.bound
  public setLastCompanyPage(pageRoute: string) {
    this.lastCompanyPage = pageRoute;
  }

  @action.bound
  public reload() {
    if (!this.details) return;
    this.setCompanyId(this.details!.id);
  }

  @action.bound
  public async setCompanyId(
    companyId: string,
    callback?: () => void
  ): Promise<void> {
    // Transactions are handled differently from other company
    // information, so we have to clear them when the company
    // changes
    if (companyId !== this.currentId) {
      this.getStatementsResult = undefined;
      this.contacts = undefined;
      this.purchaserSuppliers = undefined;
      this.supplierPurchasers = undefined;
      this.currentAccount = undefined;
    }

    this.currentId = companyId;
    this.loading = true;

    try {
      const response = await api.shared.company.getDetails({ id: companyId });
      const companyDetailed = response.data.company;
      // Could add logic here to make sure the events account
      // (for example) is always selected first
      this.currentAccount = companyDetailed.accounts[0];
      set(this.companies, companyId, companyDetailed);

      if (callback) {
        callback();
      }
    } catch (err) {
      console.error(err);
    }

    this.loading = false;
  }

  @action.bound
  public async createCompany(command: ICreateCompanyCommand): Promise<string> {
    const response = await api.admin.company.create(command);
    const companyDetailed = response.data.company;
    set(this.companies, companyDetailed.id, companyDetailed);
    return companyDetailed.id;
  }

  @action.bound
  public async updateCompany(command: IUpdateCompanyCommand) {
    this.isBusy = true;

    const response = await api.admin.company.update(command);

    const companyDetailed = response.data.company;
    if (this.companies[command.id]) {
      this.companies[command.id] = {
        ...this.companies[command.id],
        ...companyDetailed,
      };

      this.isBusy = false;
    }
  }

  @action.bound
  public async setCurrentAccount(account: IAccountInfo) {
    this.currentAccount = account;
    this.getStatementsResult = undefined;
  }

  @action.bound
  public async updateLogo(imageUrl: string) {
    const companyId = this.details!.id;
    await api.admin.company.updateLogo({ companyId, imageUri: imageUrl });
    const newCompany = Object.assign({}, this.companies[companyId], {
      imageUrl,
    });

    this.companies[companyId] = newCompany;
  }

  @action.bound
  public async createCompanyLocation(payload: ICompanyLocationPayload) {
    const response = await api.shared.company.locationCreate(payload);
    const companyLocation = response.data.companyLocation;
    const companyId = payload.companyLocation.companyId;
    if (this.companies[companyId]) {
      const company = this.companies[companyId];
      const newLocations = [...company.locations, companyLocation];
      company.locations = newLocations;
    }
  }

  @action.bound
  public async addContactUser(contact: IContactDetailed) {
    if (this.currentId === contact.companyId) {
      await this.loadContacts(true);
    }
  }

  @action.bound
  public async updateContactUser(contact: IContactDetailed) {
    if (this.currentId === contact.companyId) {
      await this.loadContacts(true);
    }
  }

  @action.bound
  public async setLocationAsPrimary(companyLocationId: string) {
    if (!this.currentId) throw Error("No current company");
    const companyId = this.currentId;
    try {
      await api.shared.company.setLocationAsPrimary(
        companyId,
        companyLocationId
      );

      this.companies[this.currentId] = Object.assign({}, this.details!, {
        primaryLocationId: companyLocationId,
      });
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound
  public async updateCompanyLocation(payload: ICompanyLocationPayload) {
    const companyId = payload.companyLocation.companyId;

    const originalLocation = toJS(
      this.companies[companyId].locations.find(
        (l) => l.id === payload.companyLocation.id
      )
    ) as ILocationDetailed;

    const operations = patches.getLocationPatchDocument(
      originalLocation,
      payload
    );

    const response = await api.shared.company.locationUpdate(
      companyId,
      payload.companyLocation.id as string,
      operations
    );

    const updatedLocation = response.data.companyLocation;

    if (this.companies[companyId]) {
      const company = this.companies[companyId];
      const newLocations = company.locations.map((l) => {
        return l.id === updatedLocation.id ? updatedLocation : l;
      });

      company.locations = newLocations;
    }
  }

  @action.bound
  public async updateLocationActiveStatus(payload: {
    companyLocationId: string;
    companyId: string;
    isActive: boolean;
  }) {
    this.isBusy = true;

    try {
      const updatedLocation = await api.shared.company.updateLocationStatus(
        payload
      );
      const companyId = payload.companyId;
      if (this.companies[companyId]) {
        const newLocations = this.companies[companyId].locations.map((l) => {
          if (l.id === updatedLocation.id) return updatedLocation;
          return l;
        });

        this.companies[companyId].locations = newLocations;
      }
    } finally {
      this.isBusy = false;
    }
  }

  @action.bound
  public async createSimpleCompanyContact(newContact: ISimpleNewContact) {
    this.isSavingContact = true;

    const newPhone = newContact.phone;
    const newEmail = newContact.email;

    // Could've added an endpoint that accepts a simple contact. Chose to
    // instead convert it here to use the endpoint that already exists.
    const convertedContact: IContactDetailed = {
      relatedContacts: [],
      notes: [],
      statementPreference: "None",
      associatedUsers: [],
      companyId: newContact.companyId,
      companyName: "",
      altFullName: "",
      email: "",
      isActive: true,
      isEmployee: newContact.isEmployee,
      extraNewsletters: 0,
      firstName: newContact.firstName,
      lastName: newContact.lastName,
      getNameTag: false,
      getNewsletter: false,
      newspaperPreference: "Never",
      id: "",
      altName: newContact.altName,
      phoneNumbers: newPhone.number
        ? [
            {
              extension: newPhone.extension!,
              number: newPhone.number!,
              type: newPhone.type!,
            },
          ]
        : [],
      emailAddresses: newEmail.emailAddress
        ? [
            {
              emailAddress: newEmail.emailAddress!,
              main: true,
              type: newEmail.type!,
            },
          ]
        : [],
      title: newContact.title,
    };

    const result = await this.createCompanyContact(convertedContact);
    return result;
  }
  @action.bound
  public async createCompanyContact(newContact: IContactDetailed) {
    this.isSavingContact = true;

    try {
      const response = await api.shared.company.createCompanyContact(
        newContact
      );

      await this.loadContacts(true);
      return response.data.companyContact;
    } finally {
      this.isSavingContact = false;
    }
  }

  @action.bound
  public async deleteTransaction(transactionId: string) {
    await api.admin.transactions.deleteTrans(transactionId);

    // Could be called from the marketing fund container. In
    // which case maybe this method shouldn't be in the company
    // store....?
    if (this.details) {
      const newInfo = await api.admin.company.getInfo(this.details!.id);
      this.details!.clubPoints = newInfo.computedBalance;
    }
  }

  @action.bound
  public async createTransaction(payload: ITransactionViewModel) {
    const response = await api.admin.transactions.create(payload);
    const { data } = response;

    if (
      data.fromCompanyId &&
      this.companies[data.fromCompanyId] &&
      data.fromCurrentBalance !== undefined &&
      data.fromAccountId !== undefined
    ) {
      this.updateCompanyPointBalance(
        this.companies[data.fromCompanyId],
        data.fromAccountId,
        data.fromCurrentBalance
      );
    }

    if (
      data.toCompanyId &&
      this.companies[data.toCompanyId] &&
      data.toCurrentBalance !== undefined &&
      data.toAccountId !== undefined
    ) {
      this.updateCompanyPointBalance(
        this.companies[data.toCompanyId],
        data.toAccountId,
        data.toCurrentBalance
      );
    }
  }

  @action.bound
  public async updateTransaction(payload: ITransactionViewModel) {
    await api.admin.transactions.update(payload);
  }

  @action.bound
  public async removeCompanyContact(payload: {
    id: string;
    companyId: string;
  }) {
    this.isBusy = true;
    await api.shared.company.deleteCompanyContact(payload);
    this.isBusy = false;

    // One contact was modified, but because of the related contacts
    // feature multiple contacts may have been affected. Instead of
    // having the update logic here, I though we might as well just
    // reload all of them to simplify things.
    await this.loadContacts(true);
  }

  @action.bound
  public async updateCompanyContact(updatedContact: IContactDetailed) {
    this.isSavingContact = true;

    try {
      await api.shared.company.updateCompanyContact(updatedContact);

      // One contact was modified, but because of the related contacts
      // feature multiple contacts may have been affected. Instead of
      // having the update logic here, I though we might as well just
      // reload all of them to simplify things.
      await this.loadContacts(true);

      return Promise.resolve(true);
    } catch (e) {
      console.error(e);
      this.isSavingContact = false;
      return Promise.resolve(false);
    }
  }

  @action.bound
  public async createCategory(payload: ICompanyCategoryCommand) {
    const response = await api.admin.company.companyCategoryCreate(payload);
    const allCompanyCategories = response.data;
    const companyId = payload.companyId;
    if (this.companies[companyId]) {
      this.companies[companyId].categories = allCompanyCategories;
    }
  }

  @action.bound
  public async updateCategory(payload: ICompanyCategoryCommand) {
    const companyId = payload.companyId;

    const origMarkCat = this.companies[companyId].categories.find(
      (m) => m.id === payload.id
    );

    if (origMarkCat === undefined) {
      throw Error("Cannot find market category to update");
    }

    const operations = patches.getCompanyCategoryPatchDocument(
      toJS(origMarkCat),
      payload
    );

    const response = await api.admin.company.updateCompanyCategory(
      payload.companyId,
      payload.id as string,
      operations
    );

    const allCompanyCategories = response.data;

    if (this.companies[companyId]) {
      this.companies[companyId].categories = allCompanyCategories;
    }
  }

  @action.bound
  public async removeCompanyCategory(payload: {
    id: string;
    companyId: string;
  }) {
    await api.admin.company.removeCompanyCategory(payload);
    const companyId = payload.companyId;
    if (this.companies[companyId]) {
      const categories = this.companies[companyId].categories;

      this.companies[companyId].categories = categories.filter(
        (cat) => cat.id !== payload.id
      );
    }
  }

  @action.bound
  public async createBrand(payload: CreateCompanyBrandCommand) {
    const response = await api.admin.company.brandCreate(payload);
    const companyBrand = response.data.companyBrand;
    const companyId = payload.companyId;
    if (this.companies[companyId]) {
      this.companies[companyId].brands.push(companyBrand);
    }
  }

  @action.bound
  public async removeBrand(payload: { id: string; companyId: string }) {
    await api.admin.company.brandRemove(payload);
    const companyId = payload.companyId;
    if (this.companies[companyId]) {
      const brands = this.companies[companyId].brands;
      const brandIndex = brands.findIndex((b) => b.id === payload.id);
      if (brandIndex >= 0) {
        brands.splice(brandIndex, 1);
      }
    }
  }

  @action.bound
  public async createProduct(command: CreateCompanyProductCommand) {
    const response = await api.admin.company.productCreate(command);
    const companyProduct = response.data.companyProduct;
    const companyId = command.companyId;
    if (this.companies[companyId]) {
      this.companies[companyId].products.push(companyProduct);
    }
  }

  @action.bound
  public async removeProduct(payload: { id: string; companyId: string }) {
    await api.admin.company.productRemove(payload);
    const companyId = payload.companyId;
    if (this.companies[companyId]) {
      const Products = this.companies[companyId].products;
      const ProductIndex = Products.findIndex((b) => b.id === payload.id);
      if (ProductIndex >= 0) {
        Products.splice(ProductIndex, 1);
      }
    }
  }

  @action.bound
  public async createNote(payload: ICompanyNotePayload) {
    this.isBusy = true;
    const response = await api.admin.company.noteCreate(payload);
    const companyNote = response.data.companyNote;
    const companyId = payload.companyNote.companyId;
    if (this.companies[companyId]) {
      this.companies[companyId].notes.unshift(companyNote);
    }
    this.isBusy = false;
  }

  @action.bound
  public async deletePurchaserSupplierCategory(args: {
    purchaserSupplierCategoryId: string;
    purchaserSupplierId: string;
  }) {
    await api.shared.purchasers.deletePurchaserSupplierCategory(
      args.purchaserSupplierCategoryId
    );

    if (this.purchaserSuppliers) {
      this.loadPurchaserSuppliers();
    }

    if (this.supplierPurchasers) {
      this.loadSupplierPurchasers();
    }
  }

  @action.bound
  public async deleteSupplierPurchaser(purchaserSupplierId: string) {
    this.isBusy = true;

    try {
      await api.shared.purchasers.deletePurchaserSupplier(purchaserSupplierId);

      if (this.supplierPurchasers === undefined) return;

      const rootLevelLink = this.supplierPurchasers.find(
        (sm) => sm.id === purchaserSupplierId
      );

      if (rootLevelLink) {
        this.supplierPurchasers = this.supplierPurchasers.filter(
          (s) => s.id !== purchaserSupplierId
        );
      } else {
        const linkWithCategory = this.supplierPurchasers.find((sm) =>
          sm.additionalCategories.some(
            (cat) => cat.purchaserSupplierId === purchaserSupplierId
          )
        );

        if (linkWithCategory) {
          linkWithCategory.additionalCategories =
            linkWithCategory.additionalCategories.filter(
              (cat) => cat.purchaserSupplierId !== purchaserSupplierId
            );

          this.supplierPurchasers = this.supplierPurchasers.map((sm) =>
            sm.id === linkWithCategory.id ? linkWithCategory : sm
          );
        }
      }
    } catch (e) {
      console.error(e);
    }

    this.isBusy = false;
  }

  @action.bound
  public async deletePurchaserSupplier(purchaserSupplierId: string) {
    this.isBusy = true;

    try {
      await api.shared.purchasers.deletePurchaserSupplier(purchaserSupplierId);
      if (this.purchaserSuppliers) {
        // It's possible we could have deleted more member suppliers
        // links then they asked for (if the link was to a sponsor)
        // so just refetch the new list instead of trying to update it
        // locally.
        const purchaserSuppliersResult =
          await api.shared.purchasers.getSuppliers(this.details!.id);
        this.purchaserSuppliers = purchaserSuppliersResult.suppliers;
      }
    } catch (e) {
      console.error(e);
    }

    this.isBusy = false;
  }

  @action.bound
  public async loadContacts(force: boolean) {
    if (this.contacts !== undefined && force === false) {
      return;
    }

    this.isBusy = true;

    try {
      const response = await api.shared.company.getAllCompanyContacts(
        this.details!.id
      );
      this.contacts = response.data;
    } catch (e) {
      console.error(e);
    }

    this.isBusy = false;
  }

  @action.bound
  public async loadPurchaserSuppliers() {
    this.isBusy = true;

    try {
      const response = await api.shared.purchasers.getSuppliers(
        this.details!.id
      );
      this.purchaserSuppliers = response.suppliers;
    } catch (e) {
      console.error(e);
    }

    this.isBusy = false;
  }

  @action.bound
  public async loadSupplierPurchasers() {
    this.isBusy = true;

    try {
      const response = await api.shared.suppliers.getPurchasers(
        this.details!.id
      );
      this.supplierPurchasers = response.members;
    } catch (e) {
      console.error(e);
    }

    this.isBusy = false;
  }

  @action.bound
  public async addPurchaserSupplier(
    purchaserId: string,
    categoryId: string,
    supplierId?: string,
    newSupplierName?: string
  ) {
    await api.shared.purchasers.addSupplier(
      purchaserId,
      categoryId,
      supplierId,
      newSupplierName
    );

    // This method is called from two different screens (one for each side
    // of the member supplier relationship). The if statements below are
    // figuring out which screen we're on and then reloading the appropriate
    // list to reflect the new member supplier record
    if (this.purchaserSuppliers && this.details?.id === purchaserId) {
      const suppliersResult = await api.shared.purchasers.getSuppliers(
        purchaserId
      );
      this.purchaserSuppliers = suppliersResult.suppliers;
    } else if (this.supplierPurchasers && this.details?.id === supplierId) {
      const membersResult = await api.shared.suppliers.getPurchasers(
        supplierId!
      );
      this.supplierPurchasers = membersResult.members;
    }
  }

  @action.bound
  public async updateNote(payload: ICompanyNotePayload, storeNote: INote) {
    this.isBusy = true;
    const original: patches.IPatchNote = {
      content: storeNote.content,
      isImportant: storeNote.isImportant,
    };

    const updates: patches.IPatchNote = {
      content: payload.companyNote.content,
      isImportant: payload.companyNote.isImportant,
    };

    const operations = patches.getNotePatchDocument(original, updates);
    const response = await api.admin.company.noteUpdate(
      payload.companyNote.companyId,
      payload.companyNote.id as string,
      operations
    );

    const companyNote = response.data.companyNote;
    const companyId = payload.companyNote.companyId;
    if (this.companies[companyId]) {
      const notes = this.companies[companyId].notes;
      const noteIndex = notes.findIndex((n) => n.id === payload.companyNote.id);
      if (noteIndex >= 0) notes.splice(noteIndex, 1, companyNote);
    }
    this.isBusy = false;
  }

  @action.bound
  public async removeNote(payload: { id: string; companyId: string }) {
    this.isBusy = true;
    await api.admin.company.noteRemove(payload);
    const companyId = payload.companyId;
    if (this.companies[companyId]) {
      const notes = this.companies[companyId].notes;
      const noteIndex = notes.findIndex((n) => n.id === payload.id);
      if (noteIndex >= 0) notes.splice(noteIndex, 1);
    }
    this.isBusy = false;
  }

  @action.bound
  public async getStatements(accountId: string, force = false) {
    console.log("getStatements called");
    const isCurrentCompany = accountId === this.currentAccount?.id;
    const statementsAreLoaded =
      isCurrentCompany && this.getStatementsResult !== undefined;

    if (statementsAreLoaded && !force) {
      return;
    }

    this.isLoadingStatements = true;
    const result = await api.shared.company.getStatementList(accountId);
    this.getStatementsResult = result;
    this.isLoadingStatements = false;
  }

  @action.bound
  public async setPrimaryCategory(companyCategoryId: string) {
    if (!this.currentId) throw Error("No current company");

    try {
      await api.admin.company.setPrimaryCategory(companyCategoryId);
      const newCategories = this.companies[this.currentId].categories.map(
        (cat) => {
          cat.isPrimary = cat.id === companyCategoryId;
          return cat;
        }
      );

      this.companies[this.currentId].categories = newCategories;
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound
  public async updateContactStatus(contactId: string, isActive: boolean) {
    if (!this.currentId) throw Error("No current company");
    if (!this.contacts) throw Error("No contacts");

    const result = await api.shared.company.updateContactStatus(
      this.currentId,
      contactId,
      isActive
    );

    const currentContacts = this.contacts;
    const newContacts = currentContacts.map((c) => {
      if (c.id === result.id) {
        return result;
      }

      return c;
    });

    this.contacts = newContacts;
  }

  @action.bound
  public async setLastAwarded(contactId: string, date: Date) {
    if (this.contacts === undefined) return;
    const contact = this.contacts.find((c) => c.id === contactId)!;
    const newContact = Object.assign({}, contact, {
      lastAwardedBirthdayPointsOn: date,
    });

    this.contacts = this.contacts.map((c) =>
      c.id === contactId ? newContact : c
    );
  }

  private updateCompanyPointBalance(
    company: ICompany,
    accountId: string,
    accountNewBalance: number
  ) {
    const account = company.accounts.find((pa) => pa.id === accountId)!;
    const newAccount = Object.assign({}, account, {
      computedBalance: accountNewBalance,
    });
    const newAccountArray = company.accounts.map((pa) => {
      if (pa.id === newAccount.id) return newAccount;
      return pa;
    });

    company.accounts = newAccountArray;
    const balanceSum = newAccountArray.reduce(
      (prev, current) => prev + current.computedBalance,
      0
    );

    company.clubPoints = balanceSum;
    if (this.currentAccount?.id === newAccount.id) {
      this.currentAccount = newAccount;
    }
  }
}

export default new CompanyStore();
