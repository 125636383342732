import React from "react";
import MobileGroupHeader from "components/Shell/MobileGroupHeader";
import { IProduct } from "services/api/types";
import Typography from "@material-ui/core/Typography";
import NoDataMessage from "components/Shell/NoDataMessage";

type Props = {
  products: IProduct[];
};

const ProductsInfoBlock = (props: Props) => {
  return (
    <MobileGroupHeader title="Products">
      {props.products.map((b) => (
        <Typography variant="body2" key={b.id}>
          {b.name}
        </Typography>
      ))}
      {props.products.length === 0 && (
        <NoDataMessage message="No products found" />
      )}
    </MobileGroupHeader>
  );
};

export default ProductsInfoBlock;
