import { apiClient } from "services/api/client";
import { IReplinkInfo } from "services/api/types";

export const getReplinkInfo = async () => {
  const url = "administrator/replinkInfo";
  const response = await apiClient.get<IReplinkInfo>(url);
  return response.data;
};

export const updateReplinkInfo = async (info: IReplinkInfo) => {
  const url = "administrator/replinkInfo";
  const response = await apiClient.put<IReplinkInfo>(url, { info });
  return response.data;
};
