import { apiClient } from "services/api/client";

export const updateGrandDestinationVideoLink = async (args: {
  link: string;
  description: string;
}) => {
  const url = "administrator/grandDestinationVideo";
  const response = await apiClient.put(url, args);
  return response.data;
};
