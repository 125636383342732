import { apiClient } from "services/api/client";
import { IListedPublicEvent, IEventSignupRequest } from "services/api/types";

type AttendeeCountResult = {
  attendeeCount: number;
};

export const getAttendeeCount = async (eventId: string) => {
  const url = `shared/events/${eventId}/attendeeCount`;
  const response = await apiClient.get<AttendeeCountResult>(url);
  return response.data.attendeeCount;
};

export const getPastEvents = async () => {
  const url = `shared/pastEvents`;
  const response = await apiClient.get<IListedPublicEvent[]>(url);
  return response.data;
};

export const submitSignUpRequest = async (request: IEventSignupRequest) => {
  const url = `shared/eventSignUps`;
  const response = await apiClient.post(url, request);
  return response;
};
