import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TransactionsTable from "./TransactionsTable";
import {
  TransactionPointsFilter,
  ISummaryTransaction,
  CompanyType,
  ITransactionsQuery,
  IAccountInfo,
  ITransactionViewModel,
} from "services/api/types";
import { Helmet } from "react-helmet";
import companyStore from "stores/shared/company";
import uiStore from "stores/ui";
import { AccountOption } from "types/options";
import { Observer } from "mobx-react";
import enumerablesStore from "stores/enumerable";
import api from "services/api";
import myStore from "stores/my";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import * as params from "utils/params";
import DropDownPill, { Option } from "components/Dropdowns/DropDownPill";
import AddFab from "components/Shell/AddFab";
import { useDebounce, useQuery } from "utils/hooks";
import { useLocation, useHistory } from "react-router";
import { History } from "history";
import TransactionsFilterManager from "./TransactionsFilterManager";
import Page from "components/Shell/Page";
import { getPointsTermProper } from "utils/appText";
import { getAccountString } from "utils/accounts";

export type TransctionScreenMode =
  | "transaction-admin"
  | "general-admin"
  | "member"
  | "sponsor";

type Props = {
  mode: TransctionScreenMode;
  hidePreviewNextStatement?: boolean;
  currentAccount: IAccountInfo;
  companyId?: string;
  transactionsChangedCallback?: () => void;
  companyType: CompanyType;
};

const TransactionsContainer = (props: Props) => {
  const query = useQuery();

  const [totalPages, setTotalPages] = React.useState(0);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [filterTerm, setFilterTerm] = React.useState(query.get("search") ?? "");

  const [isGettingPreviewData, setIsGettingPreviewData] = React.useState(false);
  const [currentPageData, setCurrentPageData] = React.useState<
    ISummaryTransaction[]
  >([]);

  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoaded, setIsLoaded] = React.useState(false);

  const [companyType, setCompanyType] = React.useState<
    CompanyType | undefined
  >();

  React.useEffect(() => {
    enumerablesStore.ensureTransactionTypesFetched();
  }, []);

  const debouncedFilterTerm = useDebounce(filterTerm, 500);
  const history: History = useHistory();

  const pagerRef = React.useRef(new TransactionsFilterManager(history, query));
  const pager = pagerRef.current;

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      content: {
        display: "grid",
        gridTemplateRows: "auto 1fr",
      },

      disabled: {
        pointerEvents: "none",
        opacity: 0.6,
      },
      tooltip: {
        fontSize: "larger",
      },
      fab: {
        margin: "0 1em",
      },
      topRow: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      appBar: {
        top: "auto",
        bottom: 0,
      },
      sortBar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 140,
      },
      exportButton: {
        marginLeft: theme.spacing(2),
      },
    })
  );

  const loadTransactions = async (transactionsQuery: ITransactionsQuery) => {
    setIsLoading(true);

    try {
      const result = await api.shared.transactions.getPage(transactionsQuery);
      // TODO: Validate make sure these are the result of our query
      // somehow
      setCurrentPageData(result.data);
      setCompanyType(result.companyType);
      setTotalPages(result.totalPages);
      setTotalRecords(result.totalCount);
      setIsLoaded(true);
    } catch (e) {
      console.log(e);
      setIsLoaded(false);
    } finally {
      setIsLoading(false);
    }
  };

  const location = useLocation();

  // This is the proper way to use a filter with the filter manager
  // Other components may be doing it wrong.
  React.useEffect(() => {
    if (pager.search !== debouncedFilterTerm) {
      pager.setSearch(debouncedFilterTerm);
    }
  }, [debouncedFilterTerm, pager]);

  React.useEffect(() => {
    const transactionsQuery: ITransactionsQuery = {
      page: pager.page,
      pageSize: pager.pageSize,
      pointsFilter: pager.getQueryValue(
        "pointsFilter"
      ) as TransactionPointsFilter,
      accountId: props.currentAccount.id,
      search: pager.search,
      orderByColumn: pager.orderBy,
      orderByDirection: pager.order,
    };
    loadTransactions(transactionsQuery);
  }, [location, props.currentAccount.id, pager]);

  const reloadTransactionsBecauseDataChanged = async () => {
    const transactionsQuery: ITransactionsQuery = {
      page: 0,
      pageSize: pager.pageSize,
      pointsFilter: pager.getQueryValue(
        "pointsFilter"
      ) as TransactionPointsFilter,
      accountId: props.currentAccount.id,
      search: filterTerm,
      orderByColumn: pager.orderBy,
      orderByDirection: pager.order,
    };
    await loadTransactions(transactionsQuery);
  };

  const classes = useStyles();

  const getAccountOptionForModal = (): AccountOption => {
    return {
      computedBalance: props.currentAccount.computedBalance,
      label: props.currentAccount.name,
      value: props.currentAccount.id,
      companyId: props.companyId,
      companyType: undefined,
    };
  };

  const validTransactionTypes = enumerablesStore
    .getEnum("transactionTypes")
    .filter((tt) => tt.isManual);

  const handleAddClick = () => {
    uiStore.showModal({
      type: "AddEditTransactionModal",
      props: {
        saveCallback: () => {
          reloadTransactionsBecauseDataChanged();
          props.transactionsChangedCallback &&
            props.transactionsChangedCallback();
        },
        transactionTypeOptions: validTransactionTypes,
        mainAccount: getAccountOptionForModal(),
        mode: "Create",
      },
      persisted: true,
    });
  };

  const getContactOption = async (
    companyId: string,
    contactId?: string
  ): Promise<Option | null> => {
    if (!contactId) return null;

    await companyStore.loadContacts(false);

    if (!companyStore.contacts) return null;

    const contact = companyStore.contacts.find((c) => c.id === contactId);

    if (!contact) return null;

    return {
      value: contact.id,
      label: contact.altFullName ?? "",
    };
  };

  const getAccountOption = async (
    accountId?: string
  ): Promise<AccountOption | null> => {
    if (!accountId) return null;

    const info = await api.admin.accounts.getInfo(accountId);
    return {
      value: accountId,
      label: getAccountString(info),
      computedBalance: info.computedBalance,
      companyId: info.companyId,
      companyType: undefined,
    };
  };

  const handleEditTransaction = async (transaction: ISummaryTransaction) => {
    if (transaction.id === undefined) {
      alert("This transaction cannot be edited");
      return;
    }

    const clientModel = await api.admin.transactions.get(transaction.id);
    const toAccountOption = await getAccountOption(clientModel.toAccountId);
    const mainAccountOption = await getAccountOption(clientModel.mainAccountId);
    const contactOption = await getContactOption(
      mainAccountOption!.companyId!,
      clientModel.birthdayContactId
    );

    uiStore.showModal({
      type: "AddEditTransactionModal",
      props: {
        saveCallback: () => {
          // TODO: Ideally, this should find the transaction in the list and
          // just update it
          reloadTransactionsBecauseDataChanged();
          props.transactionsChangedCallback &&
            props.transactionsChangedCallback();
        },
        transaction: clientModel,
        toAccount: toAccountOption,
        mainAccount: mainAccountOption,
        birthdayContact: contactOption,
        mode: "Update",
        transactionTypeOptions: validTransactionTypes,
      },
      persisted: true,
    });
  };

  const handleDeleteTransaction = async (transaction: ISummaryTransaction) => {
    if (window.confirm("Are you sure you want to remove this transaction?")) {
      if (transaction.id === undefined) {
        alert("This transaction cannot be deleted");
        return;
      }

      await companyStore.deleteTransaction(transaction.id);
      await reloadTransactionsBecauseDataChanged();

      props.transactionsChangedCallback && props.transactionsChangedCallback();
    }
  };

  const handleReverseForRealTransaction = async (
    transaction: ISummaryTransaction
  ) => {
    if (!transaction.id) {
      throw Error("Cannot be used with a grouped transaction");
    }

    const clientModel = await api.admin.transactions.get(transaction.id);
    const isTransfer = clientModel.transactionTypeId === 12;

    const toAccount = await getAccountOption(
      isTransfer ? clientModel.mainAccountId : clientModel.toAccountId
    );

    const mainAccount = await getAccountOption(
      isTransfer ? clientModel.toAccountId : clientModel.mainAccountId
    );

    const newData: Partial<ITransactionViewModel> = {
      reverse: !clientModel.reverse,
      mainAccountId: mainAccount?.value,
      toAccountId: toAccount?.value,
      id: undefined,
    };

    const reversedTransaction = Object.assign({}, clientModel, newData);

    uiStore.showModal({
      type: "AddEditTransactionModal",
      props: {
        saveCallback: () => {
          reloadTransactionsBecauseDataChanged();
        },
        transaction: reversedTransaction,
        toAccount: toAccount,
        mainAccount: mainAccount,
        mode: "Create",
        transactionTypeOptions: validTransactionTypes,
      },
      persisted: true,
    });
  };

  const handleReverseTransaction = async (transaction: ISummaryTransaction) => {
    if (transaction.isSummary) {
      throw Error("Summary transactions cannot be edited");
    }
    await handleReverseForRealTransaction(transaction);
  };

  const handleFilterChange = (value: TransactionPointsFilter) => {
    pager.setPointsFilter(value);
  };

  const handlePreviewStatement = async () => {
    setIsGettingPreviewData(true);
    if (!props.companyId) return;

    try {
      const statementPreview = await api.admin.accounts.previewCurrentStatement(
        props.currentAccount.id
      );

      const input = document.getElementById("fileContent") as HTMLInputElement;
      if (!input) throw Error("Cannot file hidden input for file contents");
      const form = document.getElementById(
        "statements-pdf-form"
      ) as HTMLFormElement;
      if (!form) throw Error("Cannot find pdf form");

      input.value = JSON.stringify(statementPreview.message);

      form.submit();
      setIsGettingPreviewData(false);
    } catch (e) {
      setIsGettingPreviewData(false);
      window.setTimeout(() => {
        alert(
          "Unable to get statement. Probably because no transactions were found."
        );
      }, 1000);
      return;
    }
  };

  const filterOptions: Option[] = [
    {
      label: "All Transactions",
      value: "All",
    },
    {
      label: `${getPointsTermProper()} Deducted`,
      value: "Deducted",
    },
    {
      label: `${getPointsTermProper()} Earned`,
      value: "Earned",
    },
    {
      label: `${getPointsTermProper()} Awarded`,
      value: "Awarded",
    },
  ];

  if (props.mode === "sponsor") {
    filterOptions.push({
      label: `${getPointsTermProper()} Awarded`,
      value: "Awarded",
    });
  }

  const pointsFilterLabel = filterOptions.find(
    (o) => o.value === pager.pointsFilter
  )!.label;

  const customPills: JSX.Element[] = [
    <DropDownPill
      value={pager.pointsFilter}
      onChange={(value) => {
        handleFilterChange(value as TransactionPointsFilter);
      }}
      options={filterOptions}
      label={pointsFilterLabel}
      key="transaction-type-pill"
    />,
  ];

  const handleTransactionTypeSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    pager.setPointsFilter(event.target.value as TransactionPointsFilter);
  };

  const handleExport = async () => {
    await api.admin.reports.exportTransactions(props.currentAccount.id);
  };

  const renderTransactionTypeSelect = () => {
    const value = pager.getQueryValue(
      "pointsFilter"
    ) as TransactionPointsFilter;

    return (
      <>
        <FormControlLabel
          labelPlacement="start"
          control={
            <Select
              margin="dense"
              style={{ marginLeft: 8 }}
              variant="outlined"
              onChange={handleTransactionTypeSelectChange}
              value={value}
            >
              <MenuItem value="All">All Transactions</MenuItem>
              <MenuItem value="Earned">{getPointsTermProper()} Earned</MenuItem>
              <MenuItem value="Deducted">
                {getPointsTermProper()} Deducted
              </MenuItem>
              {props.mode !== "member" && (
                <MenuItem value="Awarded">
                  {getPointsTermProper()} Awarded
                </MenuItem>
              )}
            </Select>
          }
          label="Transaction Type:"
        />
      </>
    );
  };

  const isMarketingFund = props.currentAccount.type === "MarketingFund";
  console.log({ mode: props.mode });

  return (
    <Observer
      render={() => {
        if (!companyStore.details && !isMarketingFund) return <div />;
        return (
          <Page
            title={isMarketingFund ? "Marketing Fund" : "Transactions"}
            disableContainer={true}
            disableTopPadding={true}
            isLoading={isLoading}
            // Needed to get sticky headers working
            overflowY="unset"
            nameForDebugging="Transactions"
            render={() => {
              return (
                <div className={classes.root}>
                  <form
                    id="statements-pdf-form"
                    target="_blank"
                    action={params.PDF_DOWNLOAD_URL}
                    method="POST"
                  >
                    <input hidden name="fileContent" id="fileContent" />
                  </form>
                  {props.mode !== "transaction-admin" ? (
                    <Helmet>
                      <title>Transactions</title>
                    </Helmet>
                  ) : undefined}

                  <div className={classes.content}>
                    {props.mode === "transaction-admin" &&
                      props.companyType !== "Prospect" &&
                      props.companyType !== "Competitor" && (
                        <AddFab onClick={handleAddClick} />
                      )}
                    <div className={classes.topRow}>
                      <div>
                        {myStore.isAdminNotImpersonating &&
                          !props.hidePreviewNextStatement && (
                            <Button
                              variant="contained"
                              color="default"
                              size="small"
                              disabled={isGettingPreviewData}
                              onClick={() => handlePreviewStatement()}
                            >
                              {isGettingPreviewData
                                ? "Generating Statement..."
                                : "Preview Next Statement"}
                            </Button>
                          )}
                        {myStore.isAdminNotImpersonating && (
                          <Button
                            variant="contained"
                            size="small"
                            className={classes.exportButton}
                            onClick={handleExport}
                          >
                            Export
                          </Button>
                        )}
                      </div>
                      <div id="transactions-table-toolbar-portal" />
                    </div>
                    <TransactionsTable
                      customPills={customPills}
                      mode={props.mode}
                      editTransaction={handleEditTransaction}
                      deleteTransaction={handleDeleteTransaction}
                      reverseTransaction={handleReverseTransaction}
                      companyType={companyType!}
                      renderLeftToolbarComponent={renderTransactionTypeSelect}
                      transactions={currentPageData ?? []}
                      accountId={props.currentAccount.id}
                      isLoading={isLoading}
                      isLoaded={isLoaded}
                      totalPages={totalPages}
                      page={pager.page}
                      setPage={pager.setPage}
                      order={pager.order}
                      setOrder={pager.setOrder}
                      orderBy={pager.orderBy!}
                      setOrderBy={pager.setOrderBy}
                      pageSize={pager.pageSize}
                      setPageSize={pager.setPageSize}
                      filterTerm={filterTerm}
                      setFilterTerm={(newValue) => {
                        const currentValue = pager.search;
                        setFilterTerm(newValue);

                        // If the new value is empty put the current one
                        // isn't go ahead and set the value right away
                        // since the debounced filter useEffect ignores empty
                        // value
                        if (!newValue && currentValue) {
                          pager.setSearch(undefined);
                        }
                      }}
                      totalRecords={totalRecords}
                      currentPageData={currentPageData}
                      onMobileAddButtonClicked={
                        props.mode === "transaction-admin"
                          ? () => handleAddClick()
                          : undefined
                      }
                    />
                  </div>
                </div>
              );
            }}
          />
        );
      }}
    />
  );
};

export default TransactionsContainer;
