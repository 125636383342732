import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ISummaryTransaction } from "services/api/types";
import Typography from "@material-ui/core/Typography";
import DateFormat from "components/Shell/DateFormat";
import { formatNumber } from "utils/number";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";

type Props = {
  transaction: ISummaryTransaction;
  accountId: string;
};

const TransactionListItem = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        padding: theme.spacing(1),
        width: "100%",
      },
      positive: {
        fontWeight: "bold",
        color: green[500],
        textAlign: "right",
      },
      negative: {
        fontWeight: "bold",
        color: red[500],
        textAlign: "right",
      },
    })
  );

  const classes = useStyles();

  const renderPoints = (row: ISummaryTransaction) => {
    const isCredit = row.toAccountId === props.accountId;
    return isCredit ? (
      <span className={classes.positive}>
        {formatNumber(row.points * row.quantity)} points
      </span>
    ) : (
      <span className={classes.negative}>
        ({formatNumber(row.points * row.quantity)}) points
      </span>
    );
  };

  const { transaction } = props;

  return (
    <div className={classes.root} key={transaction.id}>
      <div className="list-item-row">
        <Typography variant="body2" style={{ fontWeight: "bold" }}>
          {transaction.transactionTypeName}
        </Typography>
        <Typography variant="body2">{renderPoints(transaction)}</Typography>
      </div>
      <div className="list-item-row">
        <Typography variant="body2">
          <DateFormat date={transaction.transactionDate} />
        </Typography>
      </div>
      <div className="list-item-row">
        <Typography variant="body2">
          {transaction.fromCompanyName && (
            <span>From: {transaction.fromCompanyName}</span>
          )}
          {transaction.toCompanyName && (
            <span>To: {transaction.toCompanyName}</span>
          )}
        </Typography>
      </div>
      <Typography variant="body2" className="list-item-row">
        <span style={{ color: "gray" }}>{transaction.notes}</span>
      </Typography>
    </div>
  );
};

export default TransactionListItem;
