import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Omit } from "utils/type";
import FormCompanyNote from "modals/components/CompanyForms/Note/Note";
import { ICompanyNotePayload, INote } from "services/api/types";
import companyStore from "stores/shared/company";

type OriginalProps = {
  handleClose: () => void;
  id: string;
};

type Props = OriginalProps & RouteComponentProps;
class ModalUpdateCompanyNote extends Component<Props> {
  public render() {
    const companyNote =
      companyStore.details &&
      companyStore.details.notes.find((l) => l.id === this.props.id);
    return (
      <FormCompanyNote
        formType="update"
        companyNote={companyNote}
        handleSubmit={this.handleSubmit}
        handleCancel={this.props.handleClose}
      />
    );
  }

  private handleSubmit = async (
    payload: Omit<ICompanyNotePayload["companyNote"], "companyId">
  ) => {
    const originalNote =
      companyStore.details &&
      (companyStore.details.notes.find((l) => l.id === this.props.id) as INote);

    const updatePayload = {
      companyNote: {
        id: this.props.id,
        companyId: companyStore.details ? companyStore.details.id : "",
        ...payload,
      },
    };

    await companyStore.updateNote(updatePayload, originalNote as INote);
    this.props.handleClose();
  };
}

export default withRouter(ModalUpdateCompanyNote);
