import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { getAppInsights } from "applicationInsights/TelemetryService";
import TelemetryProvider from "applicationInsights/telemetry-provider";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

let appInsights = null;
export const AppInsightsContext = React.createContext(appInsights);

// Don't use application insights in develop
if (process.env.NODE_ENV === "development") {
  ReactDOM.render(
    <BrowserRouter basename={baseUrl!}>
      <App />
    </BrowserRouter>,
    rootElement
  );
} else {
  const appInsightsKey = process.env.REACT_APP_APPINSIGHTS_KEY;
  ReactDOM.render(
    <BrowserRouter basename={baseUrl!}>
      <TelemetryProvider
        instrumentationKey={appInsightsKey}
        after={() => {
          appInsights = getAppInsights();
        }}
      >
        <AppInsightsContext.Provider value={appInsights}>
          <App />
        </AppInsightsContext.Provider>
      </TelemetryProvider>
    </BrowserRouter>,
    rootElement
  );
}
