import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import uiStore from "stores/ui";
import Loader from "components/Shell/Loader";
import ContactInfo from "./ContactInfo";
import SponsorInfo from "../../Shared/components/SponsorInfo";
import MemberInfo from "../../Shared/components/MemberInfo";
import EventAttendees from "./EventAttendeeInfo";
import { Observer } from "mobx-react";

const FlyoutPanel = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      sidebar: {
        minWidth: "400px",
        maxWidth: "400px",
        padding: theme.spacing(2),
        height: "100%",
      },
      drawerPaper: {
        overflowY: "scroll",
      },
    })
  );

  const classes = useStyles();

  const handleClose = () => {
    uiStore.setFlyoutOpen(false);
  };

  const renderContent = () => {
    if (uiStore.isFlyoutLoading) {
      return <Loader />;
    }

    if (uiStore.contactDetails) {
      return (
        <ContactInfo
          closePanel={handleClose}
          contact={uiStore.contactDetails}
        />
      );
    }

    if (uiStore.eventAttendeeInfo) {
      return (
        <EventAttendees
          onClose={() => (uiStore.eventAttendeeInfo = undefined)}
          isNested={uiStore.memberInfo !== undefined}
          attendeeInfo={uiStore.eventAttendeeInfo}
        />
      );
    }

    if (uiStore.sponsorInfo) {
      return <SponsorInfo sponsorInfo={uiStore.sponsorInfo} />;
    }

    if (uiStore.memberInfo) {
      return <MemberInfo memberInfo={uiStore.memberInfo} />;
    }

    return <div></div>;
  };

  return (
    <Observer
      render={() => {
        return (
          <Drawer
            variant="temporary"
            classes={{
              paper: classes.drawerPaper,
            }}
            onClose={() => {
              uiStore.setFlyoutOpen(false);
            }}
            anchor="right"
            open={uiStore.isFlyoutOpen}
          >
            <div className={classes.sidebar}>{renderContent()}</div>
          </Drawer>
        );
      }}
    />
  );
};

export default FlyoutPanel;
