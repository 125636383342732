import React from "react";
import DollarPointsInput from "components/Inputs/DollarPointsInput";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import eventsStore from "stores/admin/events";

type Props = {
  existingDollarValue?: number;
  eventPayerId: string;
  dollarsPerPoint: number;
  handleClose: () => void;
};

const AddEditCancellationFee = (props: Props) => {
  const initialPoints = props.existingDollarValue
    ? props.existingDollarValue / props.dollarsPerPoint
    : undefined;

  const [points, setPoints] = React.useState<number | undefined>(initialPoints);
  const [dollars, setDollars] = React.useState<number | undefined>(
    props.existingDollarValue
  );

  const handleSave = async () => {
    if (dollars === undefined) {
      alert("Please enter a value");
      return;
    }

    await eventsStore.setCancellationFee({
      cancellationFee: dollars,
      eventPayerId: props.eventPayerId,
    });

    props.handleClose();
  };

  return (
    <ResponsiveModalShell
      handleClose={props.handleClose}
      handleSave={handleSave}
      width="sm"
      saveButtonCaption="Save"
      title={
        props.existingDollarValue
          ? "Update Cancellation Fee"
          : "Add Cancellation Fee"
      }
    >
      <div>
        <DollarPointsInput
          allowNegative={false}
          pointsError=""
          showPointsError={false}
          dollarsError=""
          showDollarsError={false}
          dollarsPerPoint={props.dollarsPerPoint}
          onChange={(evt) => {
            setPoints(evt.points);
            setDollars(evt.dollars);
          }}
          pointValue={points}
          dollarValue={dollars}
        />
      </div>
    </ResponsiveModalShell>
  );
};

export default AddEditCancellationFee;
