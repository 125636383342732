import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CompanyCategoryForm from "modals/components/CompanyForms/Category/Category";
import { ICompanyCategoryCommand } from "services/api/types";
import companyStore from "stores/shared/company";

type OriginalProps = {
  id: string;
  handleClose: () => void;
};

type Props = OriginalProps & RouteComponentProps;
class ModalAddCompanyMarket extends Component<Props> {
  public render = () => {
    const companyMarket =
      companyStore.details &&
      companyStore.details.categories.find((l) => l.id === this.props.id);
    return (
      <CompanyCategoryForm
        companyId={companyStore.details!.id}
        formType="update"
        companyCategory={companyMarket}
        handleSubmit={this.handleSubmit}
        handleCancel={this.props.handleClose}
      />
    );
  };

  private handleSubmit = async (payload: ICompanyCategoryCommand) => {
    await companyStore.updateCategory(payload);
    this.props.handleClose();
  };
}

export default withRouter(ModalAddCompanyMarket);
