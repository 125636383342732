import { createTheme, darken, lighten } from "@material-ui/core/styles";
import * as bpPalette from "utils/bpColorPalette";
import red from "@material-ui/core/colors/red";

const mainColor = bpPalette.mainColor;

const darkerMain = darken(mainColor, 0.2);
const lighterMain = lighten(mainColor, 0.4);

const darkColor = bpPalette.darkColor;
const darkerDarkBlue = darken(darkColor, 0.2);
const lighterDarkBlue = lighten(darkColor, 0.4);

const buildPerksTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280, // custom
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: mainColor,
      dark: darkerMain,
      light: lighterMain,
    },
    secondary: {
      main: darkColor,
      dark: darkerDarkBlue,
      light: lighterDarkBlue,
    },
    error: red,
    text: {
      secondary: bpPalette.darkColor[900],
    },
  },
  typography: {
    caption: {
      color: bpPalette.mainColor[400],
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    h1: {
      color: bpPalette.darkColor,
      fontSize: "2.25rem",
      fontWeight: "bold",
    },
    h2: {
      color: bpPalette.mainColor,
      fontSize: "1.75rem",
      fontWeight: "bold",
      margin: "1rem 0",
    },
    h3: {
      color: bpPalette.mainColor,
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    h4: {
      color: bpPalette.mainColor,
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
    body1: {
      margin: ".5rem 0",
    },
  },
});

export default buildPerksTheme;
