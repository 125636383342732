import React from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";

const ReplinkInfoForm = () => {
  return (
    <div>
      <Field
        label="Company Name"
        variant="outlined"
        margin="dense"
        fullWidth
        component={TextField}
        name="companyName"
      />
      <Field
        variant="outlined"
        margin="dense"
        fullWidth
        component={TextField}
        name="programName"
        label="Program Name"
      />
      <Field
        variant="outlined"
        margin="dense"
        fullWidth
        component={TextField}
        name="address1"
        label="Address"
      />
      <Field
        variant="outlined"
        margin="dense"
        fullWidth
        component={TextField}
        name="city"
        label="City"
      />
      <Field
        variant="outlined"
        margin="dense"
        fullWidth
        component={TextField}
        name="state"
        label="State"
      />
      <Field
        variant="outlined"
        margin="dense"
        fullWidth
        component={TextField}
        name="emailAddress"
        label="Bill To Email"
      />
      <Field
        variant="outlined"
        margin="dense"
        fullWidth
        component={TextField}
        name="shipToEmail"
        label="Ship To Email"
      />
      <Field
        variant="outlined"
        margin="dense"
        fullWidth
        component={TextField}
        name="phoneNumber"
        label="Bill To Phone"
      />
    </div>
  );
};

export default ReplinkInfoForm;
