import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import Typography from "@material-ui/core/Typography";
import {
  IContactDetailed,
  IGenericContact,
  IMergeContactsResult,
  Option,
} from "services/api/types";
import Chip from "@material-ui/core/Chip";
import GenericDropdown from "components/Dropdowns/GenericDropdown";
import api from "services/api";

type Props = {
  targetContact: IGenericContact;
  allCompanyContacts: IContactDetailed[];
  companyId: string;
  companyName: string;
  handleClose: () => void;
  doneCallback?: (result: IMergeContactsResult) => void;
};

const MergeContactsModal = (props: Props) => {
  const [isMerging, setIsMerging] = React.useState(false);
  const [selectedContact, setSelectedContact] = React.useState<
    Option | undefined
  >();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: 400,
      },
      warningText: {
        fontWeight: "bold",
        marginTop: theme.spacing(4),
      },
      inactiveChip: {
        backgroundColor: "maroon",
        color: "white",
        // display: "inline-block",
        marginLeft: theme.spacing(2),
      },
      dropdown: {
        transform: "translateY(-10px)",
        maxWidth: 400,
      },
      list: {
        "& li": {
          paddingBottom: 10,
        },
      },
    })
  );

  const classes = useStyles();

  const handleOKClick = async () => {
    if (!selectedContact) {
      alert("Please select a contact to merge with");
      return;
    }

    setIsMerging(true);

    try {
      const result = await api.admin.company.mergeContacts({
        companyId: props.companyId,
        contact1Id: props.targetContact.id,
        contact2Id: selectedContact!.value,
      });
      props.handleClose();
      props.doneCallback && props.doneCallback(result.data);
    } finally {
      setIsMerging(false);
    }
  };

  const renderInactiveChip = () => {
    return (
      <Chip className={classes.inactiveChip} size="small" label="Inactive" />
    );
  };

  const options = props.allCompanyContacts.map((cc) => {
    return {
      value: cc.id,
      label: cc.altFullName,
    };
  });

  const saveShouldBeDisabled = isMerging || selectedContact === undefined;

  return (
    <ResponsiveModalShell
      handleSave={handleOKClick}
      handleClose={props.handleClose}
      saveButtonCaption={isMerging ? "Merging..." : "Merge"}
      disableSaveButton={saveShouldBeDisabled}
      title="Merge Contacts"
    >
      <div className={classes.root}>
        <div>
          <Typography color="primary" variant="caption">
            Contacts
          </Typography>
          <ul className={classes.list}>
            <li>
              <Typography component="span">{`${props.targetContact.firstName} ${props.targetContact.lastName}`}</Typography>
              {props.targetContact.status === "Inactive" &&
                renderInactiveChip()}
            </li>
            <li>
              <div className={classes.dropdown}>
                <GenericDropdown
                  options={options}
                  selectedOption={selectedContact}
                  setSelectedOption={(opt) => setSelectedContact(opt)}
                  error={false}
                  canClear={false}
                  label="Select Contact To Merge with..."
                  invalidOptionIds={[props.targetContact.id]}
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </ResponsiveModalShell>
  );
};

export default MergeContactsModal;
