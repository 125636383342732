import { createTheme, darken, lighten } from "@material-ui/core/styles";
import * as palette from "utils/bcnColorPalette";
import red from "@material-ui/core/colors/red";

const blue = palette.newBlue;
const darkerBlue = darken(blue, 0.2);
const lighterBlue = lighten(blue, 0.4);

const darkBlue = palette.newDarkBlue;
const darkerDarkBlue = darken(darkBlue, 0.2);
const lighterDarkBlue = lighten(darkBlue, 0.4);

const bcnTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280, // custom
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: blue,
      dark: darkerBlue,
      light: lighterBlue,
    },
    secondary: {
      main: darkBlue,
      dark: darkerDarkBlue,
      light: lighterDarkBlue,
    },
    error: red,
    text: {
      secondary: palette.newDarkBlue[900],
    },
  },
  typography: {
    caption: {
      color: palette.newBlue[400],
      fontWeight: "bold",
      textTransform: "uppercase",
    },
    h1: {
      color: palette.newBlue,
      fontSize: "2.25rem",
      fontWeight: "bold",
    },
    h2: {
      color: palette.newBlue,
      fontSize: "1.75rem",
      fontWeight: "bold",
      margin: "1rem 0",
    },
    h3: {
      color: palette.newBlue,
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    h4: {
      color: palette.newBlue,
      fontSize: "1.25rem",
      fontWeight: "bold",
    },
    body1: {
      margin: ".5rem 0",
    },
  },
});

export default bcnTheme;
