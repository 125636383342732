import { apiClient } from "services/api/client";
import {
  ICreateOrderCommand,
  IOrderResult,
  IOrderItem,
  IOrderValidationResult,
  IShoppingCartItem,
  IShoppingCartItemIds,
  IUpdateGiftCardOrderCommand,
} from "services/api/types";

const MEMBER_ORDERS_URL = "members/orders";

export const create = async (order: ICreateOrderCommand) => {
  const response = await apiClient.post<IOrderResult>(MEMBER_ORDERS_URL, order);

  return response;
};

export const updateGiftCardOrder = async (
  order: IUpdateGiftCardOrderCommand
) => {
  const response = await apiClient.put<IOrderResult>(MEMBER_ORDERS_URL, order);

  return response;
};

export const verify = async (orderItems: IOrderItem[]) => {
  const url = `${MEMBER_ORDERS_URL}/verify`;
  const response = await apiClient.post<IOrderValidationResult>(url, {
    orderItems,
  });

  return response;
};

export const saveCartItems = async (cartItems: IShoppingCartItemIds[]) => {
  const url = `members/cartItems`;
  const response = await apiClient.put<IShoppingCartItem[]>(url, {
    cartItems,
  });
  return response.data;
};

export const getCartItems = async () => {
  const url = `members/cartItems`;
  const response = await apiClient.get<IShoppingCartItem[]>(url);
  return response.data;
};
