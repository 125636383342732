import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { ICatalogOption } from "services/api/types";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

type Props = {
  productId: string;
  options: ICatalogOption[];
  selectedOptionId?: string;
  setSelectedOptionId: (optionId: string) => void;
  context: "Catalog" | "CheckoutEdit";
};

const ProductOptions = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        marginTop: theme.spacing(2),
      },
      optionDropdown: {
        minWidth: 200,
        marginLeft: 10,
      },
      label: {
        display: "inline-block",
        marginLeft: props.context === "Catalog" ? 0 : 20,
        color:
          props.context === "Catalog" ? theme.palette.primary.main : "black",
      },
    })
  );

  const classes = useStyles();

  if (props.options.length === 0) {
    return <div />;
  }

  const getLabel = () => {
    return props.context === "Catalog" ? "Select Option:" : "Option:";
  };

  const handleOptionChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    props.setSelectedOptionId(event.target.value as string);
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.label} display="inline">
        {getLabel()}
      </Typography>
      <Select
        className={classes.optionDropdown}
        margin="dense"
        variant="outlined"
        id={`${props.productId}+options`}
        value={props.selectedOptionId}
        onChange={handleOptionChange}
      >
        {props.options.map((o) => {
          return (
            <MenuItem key={o.id} value={o.id}>
              {o.name} {o.deletedOn && "(No Longer Available)"}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

export default ProductOptions;
