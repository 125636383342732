import React from "react";
import NumberFormat from "react-number-format";

export interface MaskedNumberInputProps {
  inputRef?: (instance: NumberFormat | null) => void;
  prefix: string;
  allowNegative: boolean;
  decimalScale: number;
  min?: string | number;
  onChange: (event: { target: { value: string; id: string } }) => void;
}

export function MaskedNumberInput(props: MaskedNumberInputProps) {
  const { inputRef, onChange, prefix, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
            // NOTE: without the addition of id below, we get the error:
            // Formik called `handleChange`, but you forgot to pass an `id` or `name` attribute to your input:
            // undefined
            // Formik cannot determine which value to update
            id: (props as any).id,
          },
        });
      }}
      decimalScale={props.decimalScale}
      allowNegative={props.allowNegative}
      min={props.min}
      thousandSeparator
      prefix={props.prefix}
    />
  );
}
