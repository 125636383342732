import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import { Observer } from "mobx-react";
import { formatNumber } from "utils/number";
import { AccountOption } from "../../../types/options";
import TransactionTypeSelect from "../components/TransactionTypeSelect";
import QuantityTextBox from "../components/QuantityTextBox";
import AccountSelect from "../../../components/Dropdowns/AccountSelect";
import companyStore from "stores/shared/company";
import {
  ITransactionViewModel,
  ITransactionType,
  ISummaryTransaction,
  Option,
} from "services/api/types";
import { MaskedNumberInput } from "areas/Shared/components/maskedInputs/MaskedNumberInput";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import api from "services/api";
import ContactBirthdayAwardsDropdown, {
  ContactBirthdayOption,
} from "./ContactBirthdayAwardsDropdown";
import moment from "moment";

type AddEditTransactionModalProps = {
  handleClose: () => void;
  mainAccount: AccountOption | null;
  toAccount?: AccountOption | null;
  birthdayContact?: Option | null;
  transaction?: ITransactionViewModel;
  mode: "Create" | "Update";
  transactionTypeOptions: ITransactionType[];
  saveCallback?: () => void;
};

const birthdayId = 2;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    formControl: {
      width: "100%",
    },
    points: {
      marginRight: theme.spacing(1),
    },
    error: {
      color: theme.palette.error.light,
    },
    noError: {
      opacity: 0,
    },
    contentGrid: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridRowGap: theme.spacing(2),
      gridColumnGap: theme.spacing(2),
    },
    pointsRow: {
      gridColumn: "1 / 4",
      display: "grid",
      gridTemplateColumns: "1fr 1fr 1fr",
      gridGap: theme.spacing(1),
      alignItems: "center",
    },
  })
);

const transferTransactionTypeId = 12;
const pointsTransactionTypeId = 13;

const AddEditTransactionModal = (props: AddEditTransactionModalProps) => {
  const classes = useStyles();

  const selectedTransactionType = props.transaction
    ? props.transactionTypeOptions.find(
        (o) => o.id === props.transaction!.transactionTypeId
      )
    : undefined;

  const [transType, setTransType] = React.useState<
    ITransactionType | undefined
  >(selectedTransactionType);

  const [transTypeErr, setTransTypeErr] = React.useState("");

  const [points, setPoints] = React.useState<number>(
    props.transaction?.points ?? 1
  );
  const [pointsErr, setPointsErr] = React.useState("");
  const [pointsQuantity, setPointsQuantity] = React.useState<number>(
    props.transaction?.quantity ?? 1
  );
  const [quantityErr, setQuantityErr] = React.useState("");
  const [mainAccount, setMainAccount] = React.useState<AccountOption | null>(
    props.mainAccount ?? null
  );
  const [mainAccountErr, setMainAccountError] = React.useState("");
  const [toAccount, setSecondaryAccount] = React.useState<AccountOption | null>(
    props.toAccount ?? null
  );
  const [toAccountError, setToAccountError] = React.useState("");
  const [notes, setNotes] = React.useState(props.transaction?.notes ?? "");
  const [reverseTransaction, setReverseTransaction] = React.useState(
    props.transaction?.reverse ?? false
  );
  const [selectedContact, setSelectedContact] = React.useState<
    Option | undefined
  >(props.birthdayContact || undefined);

  const [isBusy, setIsBusy] = React.useState(false);

  const checkboxEl = React.useRef(null);

  const handleTransactionTypeChange = (newValue: ITransactionType) => {
    if (!newValue) return;

    setTransType(newValue);

    if (
      newValue.fromPartyType === "Company" &&
      newValue.toPartyType === "Company"
    ) {
      // This is a transfer. The checkbox should be hidden but make sure the
      // value is false
      setReverseTransaction(false);
    }

    if (newValue.defaultPoints) {
      setPoints(newValue.defaultPoints);
    }

    setNotes(newValue.defaultNote ?? "");
    validateTransactionType(newValue);
  };

  const handleToAccountChange = (newValue: AccountOption) => {
    setSecondaryAccount(newValue);
    validateTo(newValue);
  };

  const handleMainAccountChange = (newValue: AccountOption) => {
    setMainAccount(newValue);
    validateMainAccount(newValue);
  };

  const renderTransactionTypeSelect = () => {
    return (
      <div style={{ gridColumn: "1 / 3" }}>
        <TransactionTypeSelect
          className={classes.formControl}
          value={transType}
          errorClassName={transTypeErr ? classes.error : classes.noError}
          errorMessage={transTypeErr}
          mode="open"
          transactionTypes={props.transactionTypeOptions}
          onChanged={handleTransactionTypeChange}
        />
      </div>
    );
  };

  const renderMainAccountSelect = () => {
    return (
      <>
        <div style={{ gridColumn: "1 / 3" }}>
          <AccountSelect
            label="Account"
            className={classes.formControl}
            value={mainAccount}
            isInEvents={false}
            errorClassName={mainAccountErr ? classes.error : classes.noError}
            errorMessage={mainAccountErr}
            onChange={handleMainAccountChange}
          />
        </div>
        <Typography style={{ paddingTop: 10 }}>
          {`Point Balance: ${formatNumber(mainAccount?.computedBalance)}`}
        </Typography>
      </>
    );
  };

  const renderContactSelect = () => {
    const shouldBeVisible =
      transType?.id === birthdayId && mainAccount?.companyId !== undefined;
    console.log({ mainAccount });

    return (
      shouldBeVisible && (
        <div style={{ gridColumn: "1 / 3" }}>
          <ContactBirthdayAwardsDropdown
            value={selectedContact}
            onChanged={(opt) => {
              setSelectedContact(opt);
              const altOrFirstName = (opt as ContactBirthdayOption)
                .altOrFirstName;
              if (altOrFirstName) {
                setNotes(`Happy Birthday, ${altOrFirstName}!`);
              }
            }}
          />
        </div>
      )
    );
  };

  const renderToAccountSelect = () => {
    if (shouldShowTo()) {
      return (
        <>
          <div style={{ gridColumn: "1 / 3" }}>
            <AccountSelect
              label="To"
              className={classes.formControl}
              value={toAccount}
              errorClassName={toAccountError ? classes.error : classes.noError}
              errorMessage={toAccountError}
              onChange={handleToAccountChange}
              isInEvents={false}
            />
          </div>
          <Typography style={{ paddingTop: 10 }}>
            {`Point Balance: ${formatNumber(toAccount?.computedBalance)}`}
          </Typography>
        </>
      );
    }

    return undefined;
  };

  const shouldShowTo = () => {
    return (
      transType &&
      transType.fromPartyType === "Company" &&
      transType.toPartyType === "Company"
    );
  };

  const renderPointsInput = () => (
    <FormControl className={`${classes.formControl} ${classes.points}`}>
      <TextField
        variant="outlined"
        margin="dense"
        id="points"
        value={points}
        InputProps={{
          inputComponent: MaskedNumberInput as any,
          inputProps: {
            allowNegative: false,
            prefix: "",
            decimalScale: 0,
            min: 1,
          },
        }}
        onFocus={(arg1: React.FocusEvent<HTMLInputElement>) => {
          arg1.target.select();
        }}
        label="Points"
        onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
          if (isNaN(parseInt(evt.target.value))) {
            setPoints(1);
          } else {
            setPoints(parseInt(evt.target.value, 0));
          }
        }}
      />
      <FormHelperText
        variant="standard"
        className={pointsErr ? classes.error : classes.noError}
      >
        {pointsErr}
      </FormHelperText>
    </FormControl>
  );

  const renderPointsQuantity = () => {
    return (
      <QuantityTextBox
        className={classes.formControl}
        value={pointsQuantity}
        onChanged={(newValue) => {
          setPointsQuantity(newValue);
        }}
        errorClassName={quantityErr ? classes.error : classes.noError}
        errorMessage={quantityErr}
      />
    );
  };

  const handleRefundChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setReverseTransaction(evt.currentTarget.checked);
  };

  const getRefundCaption = () => {
    if (
      !transType ||
      (transType.toPartyType === "Company" &&
        transType.fromPartyType !== "Company")
    ) {
      return "Reverse Transaction";
    } else if (
      transType.toPartyType !== "Company" &&
      transType.fromPartyType === "Company"
    ) {
      return "Refund";
    }

    return "Unknown";
  };

  const renderRefundCheckbox = () => {
    const shouldBeVisible =
      transType?.id !== transferTransactionTypeId &&
      transType?.id !== pointsTransactionTypeId;

    if (!shouldBeVisible) return undefined;
    const caption = getRefundCaption();

    return (
      <FormControl
        style={{ gridColumn: "1 / 4" }}
        fullWidth
        className={classes.formControl}
      >
        <FormControlLabel
          inputRef={checkboxEl}
          control={
            <Checkbox
              checked={reverseTransaction}
              onChange={handleRefundChanged}
            />
          }
          label={caption}
        />
      </FormControl>
    );
  };

  const handleNotesChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(evt.currentTarget.value);
  };

  const renderNotesTextBox = () => (
    <FormControl
      style={{ gridColumn: "1 / 4" }}
      className={classes.formControl}
    >
      <TextField
        margin="none"
        rowsMax={4}
        minRows={4}
        id="name"
        label="Notes"
        variant="outlined"
        multiline={true}
        type="text"
        value={notes}
        onChange={handleNotesChanged}
        fullWidth
      />
    </FormControl>
  );

  const renderTotalPoints = () => (
    <Typography color="secondary">
      Total: {formatNumber(points * pointsQuantity)}
    </Typography>
  );

  const validateTo = (value: AccountOption | null) => {
    if (!value && shouldShowTo()) {
      setToAccountError("This field is required");
      return false;
    } else {
      setToAccountError("");
      return true;
    }
  };

  const validateMainAccount = (value: AccountOption | null) => {
    if (!value) {
      setMainAccountError("This field is required");
      return false;
    } else {
      setMainAccountError("");
      return true;
    }
  };

  const validateTransactionType = (value: ITransactionType | undefined) => {
    if (!value) {
      setTransTypeErr("This field is required");
      return false;
    } else {
      setTransTypeErr("");
      return true;
    }
  };

  const validate = (): boolean => {
    let valid = true;

    if (!validateTransactionType(transType)) {
      valid = false;
    }

    if (!validateTo(toAccount)) {
      valid = false;
    }

    if (!validateMainAccount(mainAccount)) {
      valid = false;
    }

    if (points <= 0) {
      setPointsErr("Must be greater than 0");
      valid = false;
    }

    if (pointsQuantity <= 0) {
      setQuantityErr("Must be greater than 0");
      valid = false;
    }

    if (transType?.id === birthdayId && selectedContact === undefined) {
      window.alert("Please select a contact");
      valid = false;
    }

    return valid;
  };

  const getPreviewSummary = async (transactionModel: ITransactionViewModel) => {
    if (props.mode === "Update") {
      const summary = await api.admin.transactions.previewUpdate({
        ...transactionModel,
        id: props.transaction!.id,
      });

      return summary;
    } else {
      const summary = await api.admin.transactions.previewCreate(
        transactionModel
      );
      return summary;
    }
  };

  const shouldShowBirthdayConfirm = () => {
    const birthdayType = 2;

    if (transType?.id !== birthdayType) {
      return false;
    }

    if (!selectedContact) {
      return false;
    }

    const lastAwarded = (selectedContact as ContactBirthdayOption).lastAwarded;

    if (!lastAwarded) {
      console.log("nope");
      return false;
    }

    return moment(lastAwarded).year === moment(new Date()).year;
  };

  const getNewBalanceFromSummary = (
    accountId: string,
    summary: ISummaryTransaction
  ) => {
    if (summary.fromAccountId === accountId) {
      return summary.fromCurrentBalance!;
    }

    if (summary.toAccountId === accountId) {
      return summary.toCurrentBalance!;
    }

    throw Error(`Could not find account with Id of ${accountId}`);
  };

  const handleSubmitWrapper = async () => {
    const valid = validate();
    if (!valid) return;

    if (shouldShowBirthdayConfirm()) {
      const message =
        "It looks like points have already been awarded this year for this contact. Are you sure you want to award more points?";
      if (!window.confirm(message)) {
        return;
      }
    }

    await handleSubmit();
  };

  const handleSubmit = async () => {
    const transactionModel: ITransactionViewModel = {
      mainAccountId: mainAccount!.value,
      notes,
      toAccountId: toAccount ? toAccount.value : undefined,
      points,
      quantity: pointsQuantity,
      reverse: reverseTransaction,
      transactionTypeId: transType ? transType.id : 1,
      birthdayContactId:
        transType?.id === birthdayId ? selectedContact?.value : undefined,
    };

    const previewSummary = await getPreviewSummary(transactionModel);
    let negativeBalanceWarning = "";
    const mainAccountPreviewBalance = getNewBalanceFromSummary(
      mainAccount!.value,
      previewSummary.data
    );

    if (mainAccount!.computedBalance! >= 0 && mainAccountPreviewBalance < 0) {
      negativeBalanceWarning += `${
        mainAccount!.label
      } Preview Balance: ${formatNumber(mainAccountPreviewBalance)}`;
    }

    if (toAccount) {
      const toPreviewBalance = getNewBalanceFromSummary(
        toAccount.value,
        previewSummary.data
      );
      if (toAccount!.computedBalance! >= 0 && toPreviewBalance < 0) {
        negativeBalanceWarning += `${
          toAccount!.label
        } Preview Balance: ${formatNumber(toPreviewBalance)}`;
      }
    }

    if (negativeBalanceWarning.length > 0) {
      const newLine = "\r\n";
      const message =
        "Applying this transaction would create the following negative point balance. Are you sure you want to create it?" +
        newLine +
        newLine +
        negativeBalanceWarning;
      if (!window.confirm(message)) {
        return;
      }
    }

    try {
      if (props.mode === "Update") {
        setIsBusy(true);
        await companyStore.updateTransaction({
          ...transactionModel,
          id: props.transaction!.id,
        });
      } else {
        setIsBusy(true);
        await companyStore.createTransaction(transactionModel);
        if (transactionModel.birthdayContactId) {
          companyStore.setLastAwarded(
            transactionModel.birthdayContactId,
            new Date()
          );
        }
      }

      if (props.saveCallback) {
        props.saveCallback();
      }

      props.handleClose();
    } finally {
      setIsBusy(false);
    }
  };

  return (
    <Observer
      render={() => {
        return (
          <ResponsiveModalShell
            title="Adjust Points"
            handleSave={handleSubmitWrapper}
            disableSaveButton={isBusy}
            handleClose={props.handleClose}
          >
            <div className={classes.contentGrid}>
              {renderTransactionTypeSelect()}
              {renderMainAccountSelect()}
              {renderToAccountSelect()}
              {renderContactSelect()}
              <div className={classes.pointsRow}>
                {renderPointsInput()}
                {renderPointsQuantity()}
                {renderTotalPoints()}
              </div>
              {renderRefundCheckbox()}
              {renderNotesTextBox()}
            </div>
          </ResponsiveModalShell>
        );
      }}
    />
  );
};

export default AddEditTransactionModal;
