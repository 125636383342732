import { apiClient } from "services/api/client";
import {
  IGlobalSearchResponse,
  IContactSearchResult,
  ICompanySearchResults,
  ISearchAccountsResult,
  CompanyType,
} from "services/api/types";

const BASE_URL = "administrator/search";

export const globalSearch = async (payload: {
  query: string;
  activeOnly: boolean;
}) => {
  const response = await apiClient.get<IGlobalSearchResponse>(`/${BASE_URL}`, {
    params: { search: payload.query, activeOnly: payload.activeOnly },
  });
  return response;
};

export const searchContacts = async (
  searchTerm: string,
  prioritizeCompanyId?: string
) => {
  const url = `${BASE_URL}/contacts`;
  const result = await apiClient.get<IContactSearchResult>(url, {
    params: {
      search: searchTerm,
      prioritizeCompanyId,
    },
  });
  return result.data;
};

export const searchCompanies = async (
  searchTerm: string,
  activeOnly: boolean,
  ...companyTypes: CompanyType[]
) => {
  const url = `${BASE_URL}/companies`;
  const result = await apiClient.get<ICompanySearchResults>(url, {
    params: { search: searchTerm, companyTypes, activeOnly },
  });
  return result.data;
};

export const searchAccounts = async (args: {
  search: string;
  isInEvents: boolean;
}) => {
  const url = `${BASE_URL}/accounts`;
  const result = await apiClient.get<ISearchAccountsResult>(url, {
    params: args,
  });
  return result.data;
};
