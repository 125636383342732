import { apiClient } from "services/api/client";
import { INewTransactionType, ITransactionType } from "services/api/types";

const listsUrl = "administrator/lists";
const transactionTypesUrl = "administrator/transactionTypes";

export const getTransactionTypes = async () => {
  const response = await apiClient.get<ITransactionType[]>(transactionTypesUrl);

  return response;
};

export const deleteTransactionType = async (transactionTypeId: string) => {
  const url = `${transactionTypesUrl}/${transactionTypeId}`;
  const response = await apiClient.delete(url);
  return response;
};

export const updateTransactionType = async (
  transactionType: ITransactionType
) => {
  const url = `${transactionTypesUrl}/${transactionType.id}`;
  const response = await apiClient.put<ITransactionType>(url, transactionType);

  return response;
};

export const createTransactionType = async (
  transactionType: INewTransactionType
) => {
  const response = await apiClient.post<ITransactionType>(
    transactionTypesUrl,
    transactionType
  );

  return response;
};

export const getList = async (name: string) => {
  const response = await apiClient.get<{ id: any }[]>(listsUrl, {
    params: {
      name,
    },
  });

  return response;
};

export const updateListItem = async (command: {
  listName: string;
  data: { id: any };
}) => {
  const url = `${listsUrl}/${command.listName}/${command.data.id}`;
  const response = await apiClient.put<{ id: any }>(url, command);

  return response;
};

export const createListItem = async (command: {
  listName: string;
  data: any;
}) => {
  const url = `${listsUrl}/${command.listName}`;
  const response = await apiClient.post<{ id: any }>(url, command);

  return response;
};

export const deleteListITem = async (command: {
  listName: string;
  id: any;
}) => {
  const url = `${listsUrl}/${command.listName}/${command.id}`;
  const response = await apiClient.delete(url);

  return response;
};
