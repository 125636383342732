import React from "react";
import { IProduct, Option } from "services/api/types";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

type Props = {
  value: string | undefined;
  touched: boolean | undefined;
  error: string | undefined;
  handleOptionSelected: (option: Option) => void;
  handleRawTextSelected: (rawText: string) => void;
  products: IProduct[];
};

const ProductsDropdown = (props: Props) => {
  const options = props.products.map((o) => {
    return {
      label: o.name,
      value: o.id,
    };
  });

  const currentValue = props.value
    ? options.find((o) => o.value === props.value)
    : null;

  const setRawText = (value: string) => {
    // If we have a value, get the corresponding option so
    // we can see what its text is
    const currentOptionText = props.value
      ? options.find((o) => o.value === props.value)!.label
      : "";

    // They could enter text that matches a value without
    // actually selecting a value.
    const optionWithText = options.find((o) => o.label === value);

    if (optionWithText) {
      // This causes problems if an option exists that starts with
      // the raw text they want
      props.handleOptionSelected(optionWithText);
    } else {
      const shouldCallOnChange = currentOptionText !== value;

      // Don't call handle change here if we have a value
      if (shouldCallOnChange) {
        props.handleRawTextSelected(value);
      }
    }
  };

  return (
    <Autocomplete
      placeholder="Product"
      freeSolo
      value={currentValue}
      onInputChange={(evt) => {
        setRawText((evt.target as any).value);
      }}
      onChange={(event: any, option: any) => {
        if (option && typeof option === "object") {
          props.handleOptionSelected(option);
        } else if (typeof option === "string") {
          setRawText(option);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          autoFocus
          onKeyDown={(evt) => {
            // Disabled "Enter" key because there was funky behavior when using it
            if (evt.key === "Enter") {
              evt.preventDefault();
              evt.stopPropagation();
              return false;
            }

            return true;
          }}
          margin="dense"
          label="Select Product"
          fullWidth
        />
      )}
      options={options}
      getOptionLabel={(option) => {
        if (typeof option.label === "string") {
          return option.label;
        }

        return (option as any).label?.label ?? "";
      }}
      loading={false}
    />
  );
};

export default ProductsDropdown;
