import { Form, Formik, FormikActions, FormikProps, FormikErrors } from "formik";
import React from "react";
import { defaultErrorHandler } from "services/api/errors";
import {
  IMarket,
  CompanyType,
  IUpdateCompanyCommand,
  ICompany,
} from "services/api/types";
import enumerableStore from "stores/enumerable";
import { formatDate } from "utils/date";
import { access } from "utils/helpers";
import * as yup from "yup";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import { Observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";
import TaxNumberTypeField from "./TaxNumberTypeField";
import CompanyNameField from "./CompanyNameField";
import WebsiteField from "./WebsiteField";
import EnrollDateField from "./EnrollDateField";
import IsActiveField from "./IsActiveField";
import MarketDropdown from "./MarketDropdown";
import TaxNumberField from "./TaxNumberField";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  website: yup.string().notRequired(),
  enrollDate: yup.date().required(),
  isActive: yup.boolean().required(),
  taxNumber: yup.string().notRequired(),
  taxNumberType: yup.string().oneOf(["EIN", "SSN"]).notRequired(),
  marketId: yup.string().required("Market is required"),
});

type FormData = Partial<IUpdateCompanyCommand>;

type Props = {
  company: ICompany;
  handleCancel: () => void;
  handleUpdate: (payload: IUpdateCompanyCommand) => void;
};

const EditMemberForm = (props: Props) => {
  React.useEffect(() => {
    enumerableStore.ensureTaxNumberTypesFetched();
    enumerableStore.ensureMarketsFetched();
  });

  const { company } = props;

  const initialValues: FormData = {
    name: company.name,
    website: company.website,
    minimumMonthlyPurchase: company.minimumMonthlyPurchase,
    enrollDate: formatDate(company.enrollDate),
    isActive: access(company, "isActive", true),
    taxNumber: company.taxNumber,
    marketId: company.marketId,
    taxNumberType: company.taxNumberType,
  };

  const handleFormSubmit = async (
    values: FormData,
    actions: FormikActions<FormData>
  ) => {
    const command: IUpdateCompanyCommand = {
      id: props.company?.id ?? "",
      name: values.name ?? "",
      website: values.website || undefined,
      enrollDate: formatDate(values.enrollDate ?? new Date()),
      companyType: "Member" as CompanyType,
      isActive: values.isActive ?? true,
      taxNumber: values.taxNumber,
      marketId: values.marketId,
      taxNumberType: values.taxNumberType,
    };

    try {
      await props.handleUpdate(command);
    } catch (err) {
      defaultErrorHandler(err);
    }
    actions.setSubmitting(false);
  };

  const renderCompanyName = (formikProps: FormikProps<FormData>) => {
    return (
      <CompanyNameField
        handleChange={formikProps.handleChange}
        touched={formikProps.touched.name}
        error={formikProps.errors.name}
        value={formikProps.values.name ?? ""}
      />
    );
  };

  const renderWebsite = (formikProps: FormikProps<FormData>) => {
    return (
      <WebsiteField
        handleChange={formikProps.handleChange}
        touched={formikProps.touched.website}
        error={formikProps.errors.website}
        value={formikProps.values.website}
      />
    );
  };

  const renderTaxNumber = (formikProps: FormikProps<FormData>) => {
    return (
      <TaxNumberField
        error={formikProps.errors.taxNumber}
        handleChange={formikProps.handleChange}
        value={formikProps.values.taxNumber}
        touched={formikProps.touched.taxNumber}
        taxNumberType={formikProps.values.taxNumberType}
      />
    );
  };

  const renderTaxNumberType = (
    formikProps: FormikProps<FormData>,
    taxNumberTypes: string[]
  ) => {
    return (
      <TaxNumberTypeField
        touched={formikProps.touched.taxNumberType}
        error={formikProps.errors.taxNumberType}
        value={formikProps.values.taxNumberType}
        handleChange={(e) => {
          formikProps.setFieldValue("taxNumber", "");
          formikProps.handleChange(e);
        }}
        taxNumberTypes={taxNumberTypes}
      />
    );
  };

  const renderMarket = (
    formikProps: FormikProps<FormData>,
    markets: IMarket[]
  ) => {
    return (
      <MarketDropdown
        handleChange={formikProps.handleChange}
        markets={markets}
        setTouched={() => formikProps.setTouched({ marketId: true })}
        value={formikProps.values.marketId}
        error={formikProps.errors.marketId}
        touched={formikProps.touched.marketId}
      />
    );
  };

  const renderEnrollDate = (formikProps: FormikProps<FormData>) => {
    return (
      <EnrollDateField
        touched={formikProps.touched.enrollDate}
        error={formikProps.errors.enrollDate}
        handleChange={formikProps.handleChange}
        value={formatDate(formikProps.values.enrollDate ?? new Date())}
      />
    );
  };

  const renderIsActive = (formikProps: FormikProps<FormData>) => {
    return (
      <IsActiveField
        handleChange={formikProps.handleChange}
        value={formikProps.values.isActive ?? true}
      />
    );
  };

  return (
    <Observer
      render={() => {
        const taxNumberTypes = enumerableStore.getEnum("taxNumberTypes");
        const markets = enumerableStore.getEnum("markets");

        return (
          <Formik
            initialValues={initialValues}
            validate={(values) => {
              const errors: FormikErrors<FormData> = {};

              if (values.taxNumber && !values.taxNumberType) {
                errors.taxNumberType = "This is required";
              }

              if (values.taxNumberType && !values.taxNumber) {
                errors.taxNumber = "This is required";
              }

              return errors;
            }}
            onSubmit={handleFormSubmit}
            validationSchema={schema}
          >
            {(formikProps) => {
              return (
                <Form>
                  <ResponsiveModalShell
                    handleClose={props.handleCancel}
                    title="Update Member"
                    disableSaveButton={formikProps.isSubmitting}
                    handleSave={() => formikProps.submitForm()}
                  >
                    <Grid alignItems="center" container spacing={1}>
                      <Grid item xs={12}>
                        {renderCompanyName(formikProps)}
                      </Grid>
                      <Grid item xs={12}>
                        {renderWebsite(formikProps)}
                      </Grid>
                      <Grid item xs={12}>
                        {renderIsActive(formikProps)}
                      </Grid>
                      <Grid item xs={6}>
                        {renderEnrollDate(formikProps)}
                      </Grid>
                      <Grid item xs={6}>
                        {renderMarket(formikProps, markets)}
                      </Grid>
                      <div style={{ width: "100%" }} />
                      <Grid item xs={6}>
                        {renderTaxNumberType(formikProps, taxNumberTypes)}
                      </Grid>
                      <Grid item xs={6}>
                        {renderTaxNumber(formikProps)}
                      </Grid>
                    </Grid>
                  </ResponsiveModalShell>
                </Form>
              );
            }}
          </Formik>
        );
      }}
    />
  );
};

export default EditMemberForm;
