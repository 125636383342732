import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import DateRange from "components/Inputs/DateRange";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import Typography from "@material-ui/core/Typography";

type Props = {
  title: string;
  instructions: string;
  dateCaption: string;
  handleOk: (start: Date, end: Date) => void;
  handleClose: () => void;
};

const GenericDateRange = (props: Props) => {
  const [start, setStart] = React.useState<Date | null>(null);
  const [end, setEnd] = React.useState<Date | null>(null);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
    })
  );

  const classes = useStyles();

  const handleSave = async () => {
    props.handleOk(start!, end!);
    props.handleClose();
  };

  return (
    <ResponsiveModalShell
      handleClose={props.handleClose}
      title={props.title}
      disableSaveButton={!start || !end}
      handleSave={handleSave}
      saveButtonCaption="Send"
    >
      <Typography variant="body1">{props.instructions}</Typography>
      <DateRange
        title={props.dateCaption}
        startValue={start}
        endValue={end}
        onStartDateChanged={setStart}
        onEndDateChanged={setEnd}
      />
    </ResponsiveModalShell>
  );
};

export default GenericDateRange;
