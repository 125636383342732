import { apiClient } from "services/api/client";
import { StatementType } from "services/api/types";

export const emailRandomStatementToCurrentUser = async (
  statementType: StatementType
) => {
  const url = "administrator/statementEmails/sendTestEmail";
  const response = await apiClient.post(url, { statementType });
  return response.data;
};
