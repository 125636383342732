import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import NewMemberAttachmentsList from "areas/Admin/components/NewMemberAttachmentsList";
import {
  INewMemberConfirmationAttachment,
  IListedMemberUpload,
} from "services/api/types";
import Typography from "@material-ui/core/Typography";
import api from "services/api";
import enumStore from "stores/enumerable";

type Props = {
  memberUploadId: string;
  showNewMemberFields: boolean;
  doneCallback: (upload: IListedMemberUpload) => void;
  handleClose: () => void;
};

const SendMemberUploadEmails = (props: Props) => {
  const [checkedAttachmentIds, setCheckedAttachmentIds] = React.useState<
    string[]
  >([]);

  const [attachments, setAttachments] = React.useState<
    INewMemberConfirmationAttachment[]
  >([]);

  const [loaded, isLoaded] = React.useState(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      instructions: {
        fontWeight: "bold",
        margin: 0,
      },
    })
  );

  React.useEffect(() => {
    const load = async () => {
      await enumStore.ensureMarketsFetched();
      if (props.showNewMemberFields) {
        const attachments =
          await api.admin.members.getAllNewMemberWelcomeAttachments();
        setAttachments(attachments);
        isLoaded(true);
      } else {
        setAttachments([]);
        isLoaded(true);
      }
    };

    load();
  }, [props.showNewMemberFields]);

  const handleAttachmentCheckboxChanged = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    const attachmentId = evt.currentTarget.value;
    if (evt.currentTarget.checked) {
      setCheckedAttachmentIds([attachmentId, ...checkedAttachmentIds]);
    } else {
      setCheckedAttachmentIds(
        checkedAttachmentIds.filter((id) => id !== attachmentId)
      );
    }
  };

  const handleAttachmentAdded = async (
    newAttachment: INewMemberConfirmationAttachment
  ) => {
    setAttachments([newAttachment, ...attachments]);
    setCheckedAttachmentIds([newAttachment.id, ...checkedAttachmentIds]);
  };

  const handleApplyUpload = async () => {
    if (props.showNewMemberFields && checkedAttachmentIds.length === 0) {
      alert("Please select at least one attachment");
      return;
    }

    const result = await api.admin.sponsors.startSendingMemberUploadEmails({
      memberUploadId: props.memberUploadId,
      attachmentIds: checkedAttachmentIds,
    });

    props.doneCallback(result.data);
    props.handleClose();
  };

  const classes = useStyles();

  return (
    <ResponsiveModalShell
      saveButtonCaption="OK"
      handleSave={handleApplyUpload}
      title="Apply Member Upload"
      handleClose={props.handleClose}
      disableSaveButton={false}
    >
      {loaded && (
        <>
          {!props.showNewMemberFields && (
            <div>
              <Typography>
                No new members where detected in this upload so you don't have
                to select any attachments.
              </Typography>
            </div>
          )}
          {props.showNewMemberFields && (
            <div className={classes.root}>
              <div>
                <Typography className={classes.instructions}>
                  Please select one or more attachments to send along with the
                  new member email:
                </Typography>
                <NewMemberAttachmentsList
                  onAttachmentCheckboxChanged={handleAttachmentCheckboxChanged}
                  checkedAttachmentIds={checkedAttachmentIds}
                  attachments={attachments}
                  onAttachmentAdded={handleAttachmentAdded}
                />
              </div>
            </div>
          )}
        </>
      )}
    </ResponsiveModalShell>
  );
};

export default SendMemberUploadEmails;
