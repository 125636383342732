import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaskedNumberInput } from "areas/Shared/components/maskedInputs/MaskedNumberInput";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

type CheckPaymentFormProps = {
  amount: number;
  onAmountChanged: (value: number) => void;
  amountError: string;
  checkNumber: string;
  onCheckNumberChanged: (value: string) => void;
  checkNumberError: string;
  date: Date | null;
  onDateChanged: (value: Date | null) => void;
  touched: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
    },
    formControl: {
      margin: theme.spacing(1),
    },
    buttons: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      marginTop: theme.spacing(4),
    },
    error: {
      color: theme.palette.error.main,
      transform: "translate(3px, -14px)",
    },
  })
);

export default function StatelessCheckPaymentForm(
  props: CheckPaymentFormProps
) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <FormControl
        margin="dense"
        variant="outlined"
        className={classes.formControl}
      >
        <KeyboardDatePicker
          format="MM/dd/yy"
          label="Date"
          inputVariant="outlined"
          margin="dense"
          value={props.date}
          onChange={(e) => props.onDateChanged(e)}
        />
      </FormControl>
      <FormControl
        margin="dense"
        variant="outlined"
        className={classes.formControl}
      >
        <TextField
          variant="outlined"
          margin="dense"
          onFocus={(evt) => evt.target.select()}
          className={classes.formControl}
          value={props.checkNumber}
          onChange={(evt) => props.onCheckNumberChanged(evt.target.value)}
          label="Check #"
        />
        <FormHelperText className={classes.error} variant="standard">
          {props.touched ? props.checkNumberError : ""}
        </FormHelperText>
      </FormControl>
      <FormControl
        margin="dense"
        variant="outlined"
        className={classes.formControl}
      >
        <TextField
          variant="outlined"
          margin="dense"
          value={props.amount}
          onChange={(evt) =>
            props.onAmountChanged(parseFloat(evt.target.value))
          }
          className={classes.formControl}
          onFocus={(evt) => evt.target.select()}
          label="Amount"
          id="amount"
          // Note: has to be uppercase
          InputProps={{
            inputComponent: MaskedNumberInput as any,
            inputProps: {
              allowNegative: false,
              prefix: "$",
              decimalScale: 2,
            },
          }}
        />
        <FormHelperText className={classes.error} variant="standard">
          {props.touched ? props.amountError : ""}
        </FormHelperText>
      </FormControl>
    </div>
  );
}
