import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PublicIcon from "@material-ui/icons/Public";
import { useHistory } from "react-router";
import clsx from "clsx";

type Props = {
  width: Breakpoint;
};

const MobileOnlyHomeListItem = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        opacity: props.width === "xs" ? 1 : 0,
        pointerEvents: props.width === "xs" ? "all" : "none",
      },
    })
  );

  const classes = useStyles();
  const history = useHistory();

  return (
    <ListItem
      className={clsx("bcn-closes-drawer", classes.root)}
      button
      onClick={() => history.push("/")}
    >
      <ListItemIcon className="bcn-closes-drawer">
        <PublicIcon style={{ color: "white" }} className="bcn-closes-drawer" />
      </ListItemIcon>
      <div className="bcn-closes-drawer">Return to Website Home</div>
    </ListItem>
  );
};

export default withWidth()(MobileOnlyHomeListItem);
