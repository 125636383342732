import { apiClient } from "services/api/client";
import { getFileName } from "services/api/modules/helpers";
import {
  IUploadMemberSpreadsheetResult,
  IListedMemberUpload,
  ITinyCompany,
} from "services/api/types";

const BASE_URL = "administrator/sponsors";
const MEMBER_UPLOADS_BASE_URL = "administrator/memberUploads";
const SEND_MEMBER_UPLOADS_EMAIL_URL = "administrator/emailedMemberUploads";

export const getCostPerPoint = async () => {
  const url = `${BASE_URL}/costPerPoint`;
  const response = await apiClient.get<{ sponsorCostPerPoint: number }>(url);
  return response.data.sponsorCostPerPoint;
};

export const findByCategory = async (categoryId: string) => {
  const url = `${BASE_URL}/category/${categoryId}`;
  const response = await apiClient.get<ITinyCompany[]>(url);
  return response.data;
};

export const downloadNewMemberTemplate = async () => {
  const url = `${MEMBER_UPLOADS_BASE_URL}/template`;

  const response = await apiClient.get(url, { responseType: "blob" });
  const fileName = getFileName(response, "newMemberTemplate.xlsx");

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const downloadErrorResults = async (memberUploadId: string) => {
  const url = `${MEMBER_UPLOADS_BASE_URL}/${memberUploadId}/downloadErrorResults`;

  const response = await apiClient.get(url, { responseType: "blob" });
  const fileName = getFileName(response, "uploadErrors.xlsx");

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const downloadAppliedResults = async (memberUploadId: string) => {
  const url = `${MEMBER_UPLOADS_BASE_URL}/${memberUploadId}/downloadAppliedResults`;

  const response = await apiClient.get(url, { responseType: "blob" });
  const fileName = getFileName(response, "appliedResults.xlsx");

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const getUploadsForSponsor = async (sponsorId: string) => {
  const url = `${BASE_URL}/${sponsorId}/memberUploads`;
  const result = await apiClient.get<IListedMemberUpload[]>(url);
  return result;
};

export const validateMemberUpload = async (memberUploadId: string) => {
  const url = `${MEMBER_UPLOADS_BASE_URL}/${memberUploadId}/validate`;
  const result = await apiClient.post(url);
  return result;
};

export const applyMemberUpload = async (memberUploadId: string) => {
  const url = `${MEMBER_UPLOADS_BASE_URL}/${memberUploadId}/apply`;
  const result = await apiClient.post<IListedMemberUpload>(url, {
    memberUploadId,
  });
  return result;
};

export const startSendingMemberUploadEmails = async (args: {
  memberUploadId: string;
  attachmentIds: string[];
}) => {
  const result = await apiClient.post<IListedMemberUpload>(
    SEND_MEMBER_UPLOADS_EMAIL_URL,
    args
  );
  return result;
};

export const deleteMemberUpload = async (memberUploadId: string) => {
  const url = `${MEMBER_UPLOADS_BASE_URL}/${memberUploadId}`;
  const response = await apiClient.delete(url);
  return response;
};

export const uploadMemberListSpreadhseet = async (
  file: File,
  sponsorId: string,
  previousUploadId: string | undefined
) => {
  const url = `${BASE_URL}/${sponsorId}/memberUploads`;

  const formData = new FormData();
  formData.append("file", file);

  const result = await apiClient.post<IUploadMemberSpreadsheetResult>(
    url,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: {
        previousUploadId,
      },
    }
  );

  return result;
};
