import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { formatNumber, formatMoney } from "utils/number";
import { IAccountInfo } from "services/api/types";

type Props = {
  accountInfo: IAccountInfo;
  mode: "Add" | "Edit";
  balanceDueDollars?: number;
};

const EventGroupPaymentDialogTitle = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      subtitle: {
        color: theme.palette.primary.main,
      },
    })
  );

  const classes = useStyles();
  return (
    <>
      <div
        className={classes.subtitle}
      >{`from ${props.accountInfo.name} (${props.accountInfo.identifier})`}</div>
      <div className={classes.subtitle}>{`Point Balance: ${formatNumber(
        props.accountInfo.computedBalance
      )}`}</div>
      {props.balanceDueDollars !== undefined ? (
        <div className={classes.subtitle}>{`Balance Due: ${formatMoney(
          props.balanceDueDollars
        )}`}</div>
      ) : undefined}{" "}
    </>
  );
};

export default EventGroupPaymentDialogTitle;
