import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { IMemberReferralData } from "services/api/types";
import { Field, FormikProps } from "formik";
import Grid from "@material-ui/core/Grid";
import { TextField } from "formik-material-ui";
import InputPhoneNumber from "components/Inputs/InputPhoneNumber";
import clsx from "clsx";

type Props = {
  formikProps: FormikProps<IMemberReferralData>;
};

const MemberReferralForm = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        transition: theme.transitions.create(["opacity"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        [theme.breakpoints.up("sm")]: {
          maxWidth: 600,
          margin: "0 auto",
        },
      },
      formControl: {
        [theme.breakpoints.up("sm")]: {
          margin: theme.spacing(1),
        },
      },
      disabled: {
        opacity: 0.5,
      },
      submitButton: {
        marginTop: theme.spacing(3),
      },
      noVerticalPaddingGrid: {
        "& .MuiGrid-item": {
          padding: theme.spacing(0, 1),
        },
      },
    })
  );

  const classes = useStyles();

  const { formikProps } = props;

  return (
    <>
      <Grid
        container
        spacing={2}
        className={clsx(classes.root, {
          [classes.disabled]: props.formikProps.isSubmitting,
        })}
        classes={{
          "spacing-xs-2": classes.noVerticalPaddingGrid,
        }}
      >
        <Grid item xs={12}>
          <Field
            component={TextField}
            variant="outlined"
            margin="dense"
            fullWidth
            className={classes.formControl}
            name="companyName"
            label="Company Name"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={TextField}
            variant="outlined"
            margin="dense"
            fullWidth
            className={classes.formControl}
            name="address"
            label="Address"
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            variant="outlined"
            margin="dense"
            className={classes.formControl}
            style={{ marginRight: 50 }}
            name="city"
            fullWidth
            label="City"
          />
        </Grid>
        <Grid item xs={2}>
          <Field
            component={TextField}
            variant="outlined"
            fullWidth
            margin="dense"
            className={classes.formControl}
            name="state"
            label="State"
            inputProps={{ maxLength: 2 }}
          />
        </Grid>
        <Grid item xs={4}>
          <Field
            component={TextField}
            variant="outlined"
            fullWidth
            margin="dense"
            className={classes.formControl}
            name="zipCode"
            label="Zip"
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            variant="outlined"
            margin="dense"
            fullWidth
            className={classes.formControl}
            name="firstName"
            label="FirstName"
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            variant="outlined"
            margin="dense"
            fullWidth
            className={classes.formControl}
            name="lastName"
            label="LastName"
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={TextField}
            variant="outlined"
            margin="dense"
            fullWidth
            className={classes.formControl}
            name="emailAddress"
            label="Email"
          />
        </Grid>
        <Grid item xs={6}>
          <InputPhoneNumber
            fullWidth
            disabled={formikProps.isSubmitting}
            onBlur={() =>
              formikProps.setTouched({
                ...formikProps.touched,
                phoneNumber: true,
              })
            }
            className={classes.formControl}
            label="Phone Number"
            onChange={(evt) => {
              formikProps.setFieldValue(
                "phoneNumber",
                evt.target.value as string
              );
            }}
            value={formikProps.values.phoneNumber}
            helperText={
              formikProps.touched.phoneNumber
                ? formikProps.errors.phoneNumber
                : undefined
            }
            error={
              formikProps.touched.phoneNumber === true &&
              formikProps.errors.phoneNumber !== undefined
            }
            type="tel"
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={TextField}
            variant="outlined"
            margin="dense"
            fullWidth
            multiline={true}
            minRows={5}
            className={classes.formControl}
            name="notes"
            label="Notes"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default MemberReferralForm;
