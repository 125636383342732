import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import { formatMoney, formatNumber } from "utils/number";
import Typography from "@material-ui/core/Typography";
import { IEventGroupRefundInfo } from "services/api/types";
import clsx from "clsx";

type Props = {
  handleClose: () => void;
  eventGroupId: string;
  refundInfo: IEventGroupRefundInfo;
  // totalPointsToRefund: number;  << move to state, get from api
  // nonVoidInvoiceNumbers: number[];
  handleRefund: (
    eventGroupId: string,
    issueRefund: boolean,
    moveToWaitingList: boolean
  ) => Promise<void>;
};

type RefundOptions = "IssueRefund" | "DoNotIssueRefund";
type StatusOptions = "WaitingList" | "Cancelled";

const ConfirmRefundEventGroupModal = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      question: {
        margin: theme.spacing(2, 0, 1, 0),
        display: "inline-block",
      },
      critical: {
        color: theme.palette.error.main,
        marginTop: theme.spacing(4),
      },
      indented: {
        paddingLeft: theme.spacing(2),
      },
    })
  );

  const classes = useStyles();

  const [isSaving, setIsSaving] = React.useState(false);
  const [refundAction, setRefundAction] =
    React.useState<RefundOptions>("IssueRefund");
  const [statusAction, setStatusAction] =
    React.useState<StatusOptions>("Cancelled");

  const handleRefundRadioChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRefundAction(evt.target.value as RefundOptions);
  };

  const handleStatusRadioChange = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStatusAction(evt.target.value as StatusOptions);
  };

  const renderPointRefundRadios = () => {
    return (
      <>
        <FormLabel className={classes.question}>
          Issue a refund for all point payments?
        </FormLabel>
        <div className={classes.indented}>
          <RadioGroup
            name="company-type"
            value={refundAction}
            row={true}
            onChange={handleRefundRadioChange}
          >
            <FormControlLabel
              value="IssueRefund"
              control={<Radio size="small" />}
              label={`Yes, issue a refund of ${formatNumber(
                props.refundInfo.totalPointsToRefund
              )} points`}
            />
            <FormControlLabel
              value="DoNotIssueRefund"
              control={<Radio size="small" />}
              label="No, do not issue a refund"
            />
          </RadioGroup>
        </div>
      </>
    );
  };

  const renderStatusRadios = () => {
    return (
      <>
        <FormLabel className={classes.question}>
          Which list should the event group be moved to?
        </FormLabel>
        <div className={classes.indented}>
          <RadioGroup
            value={statusAction}
            row={true}
            onChange={handleStatusRadioChange}
          >
            <FormControlLabel
              value="Cancelled"
              control={<Radio size="small" />}
              label="Cancelled Groups"
            />
            <FormControlLabel
              value="WaitingList"
              control={<Radio size="small" />}
              label="Waiting List"
            />
          </RadioGroup>
        </div>
      </>
    );
  };

  const renderInvoicesThatWillBeVoided = () => {
    return (
      <>
        <FormLabel className={classes.question}>
          The following invoices will be voided:
        </FormLabel>
        <div className={classes.indented}>
          {props.refundInfo.nonVoidInvoiceNumbers.map((i) => {
            return (
              <Typography component="div">
                <strong>{i}</strong>
              </Typography>
            );
          })}
        </div>
      </>
    );
  };

  const renderCheckPaymentsRadios = () => {
    return (
      <>
        <FormLabel className={classes.question}>
          Should check payments be refunded?
        </FormLabel>
        <div className={classes.indented}>
          <RadioGroup
            value={refundAction}
            row={true}
            onChange={handleRefundRadioChange}
          >
            <FormControlLabel
              value="IssueRefund"
              control={<Radio size="small" />}
              label="Issue Refund Manually"
            />
            <FormControlLabel
              value="DoNotIssueRefund"
              control={<Radio size="small" />}
              label="No, do not issue a refund"
            />
          </RadioGroup>
          {refundAction === "IssueRefund" && (
            <FormLabel className={clsx(classes.question, classes.critical)}>
              There are check payments totalling{" "}
              {formatMoney(props.refundInfo.totalCheckPayments)}
              {" that should be refunded."}
            </FormLabel>
          )}
        </div>
      </>
    );
  };

  const renderContent = () => {
    return (
      <>
        <Typography style={{ fontWeight: "bold" }} component="div">
          {props.refundInfo.companyName} will no longer be attending the{" "}
          {props.refundInfo.eventName}
          {" event."}
        </Typography>
        <div className={classes.indented}>
          {props.refundInfo.totalPointsToRefund > 0 &&
            renderPointRefundRadios()}
          {renderStatusRadios()}
          {props.refundInfo.nonVoidInvoiceNumbers.length > 0 &&
            renderInvoicesThatWillBeVoided()}
          {props.refundInfo.totalCheckPayments > 0 &&
            renderCheckPaymentsRadios()}
        </div>
      </>
    );
  };

  const handleOkClick = async () => {
    setIsSaving(true);
    await props.handleRefund(
      props.eventGroupId,
      refundAction === "IssueRefund",
      statusAction === "WaitingList"
    );
    setIsSaving(false);
    props.handleClose();
  };

  return (
    <ResponsiveModalShell
      handleSave={handleOkClick}
      saveButtonCaption="Ok"
      disableSaveButton={isSaving}
      handleClose={props.handleClose}
      title=""
    >
      {renderContent()}
    </ResponsiveModalShell>
  );
};

export default ConfirmRefundEventGroupModal;
