import { apiClient } from "services/api/client";
import { ICompanySearchResults, IPurchaserInfo } from "services/api/types";

const BASE_URL = "sponsors/purchasers";

// used by the monthly report editor and company dropdown when user is a sponsor
export const search = async (query: string) => {
  const url = `${BASE_URL}/search`;
  const response = await apiClient.get<ICompanySearchResults>(url, {
    params: { search: query },
  });

  return response.data;
};

export const getInfo = async (purchaserId: string) => {
  const url = `${BASE_URL}/${purchaserId}`;
  const response = await apiClient.get<IPurchaserInfo>(url);
  return response.data;
};
