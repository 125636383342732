import React from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { ICategory } from "services/api/types";

type Props = {
  value: string | undefined;
  touched: boolean | undefined;
  error: string | undefined;
  handleChange: (e: React.ChangeEvent<any>) => void;
  categories: ICategory[];
  name: string;
  label: string;
};

const CategoryDropdown = (props: Props) => {
  return (
    <FormControl fullWidth>
      <TextField
        select
        variant="outlined"
        margin="dense"
        label={props.label}
        error={props.touched && Boolean(props.error)}
        name={props.name}
        onChange={props.handleChange}
        value={props.value ?? ""}
        helperText={props.touched && props.error}
      >
        {props.categories.map((category) => {
          return (
            <MenuItem key={category.id} value={category.id}>
              {category.name}
            </MenuItem>
          );
        })}
      </TextField>
    </FormControl>
  );
};

export default CategoryDropdown;
