import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import NoDataMessage from "components/Shell/NoDataMessage";
import { IDetailedLocation } from "services/api/types";
import MobileGroupHeader from "components/Shell/MobileGroupHeader";
import PhoneNumber from "components/PhoneNumber";

type Props = {
  locations: IDetailedLocation[] | undefined;
};

const LocationsInfoBlock = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      location: {
        marginBottom: theme.spacing(2),
      },
    })
  );

  const classes = useStyles();

  const renderLocations = () => {
    if (!props.locations) return undefined;
    if (props.locations.length === 0) {
      return <NoDataMessage message="No locations found"></NoDataMessage>;
    }

    return props.locations.map((location, index) => {
      return (
        <div className={classes.location} key={index}>
          <div>{location.address1}</div>
          <div>{location.address2}</div>
          {location.city ? (
            <div>{`${location.city}, ${location.state} ${location.zip}`}</div>
          ) : undefined}
          {location.primaryContact ? (
            <div>
              <strong>{location.primaryContact}</strong>
            </div>
          ) : undefined}
          {location.phoneNumbers.map((ph, phIndex) => {
            return <PhoneNumber key={phIndex} number={ph.number} />;
          })}
        </div>
      );
    });
  };

  return (
    <MobileGroupHeader title="Locations">{renderLocations()}</MobileGroupHeader>
  );
};

export default LocationsInfoBlock;
