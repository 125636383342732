import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ReactPlayer from "react-player";
import myStore from "stores/my";
import { Observer } from "mobx-react";

const EarnPoints = () => {
  React.useEffect(() => {
    const load = async () => {
      await myStore.ensureGrandDestinationVideoInfoIsLoaded();
    };

    load();
  });

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: "rgba(27, 97, 177, 0.8)",
        // backgroundColor: "rgba(27, 97, 177, 1)",
        padding: "10px 0 20px 0",
        color: "white",
        "& h2": {
          fontFamily: "Oswald, sans-serif",
          fontWeight: 400,
          textAlign: "center",
          margin: ".2em 0",
          [theme.breakpoints.between("xs", "lg")]: {
            fontSize: "2em",
          },
          [theme.breakpoints.between("lg", "xl")]: {
            fontSize: "2.5em",
          },
        },
      },
      header: {
        padding: "0 5%",
      },
      item: {
        backgroundColor: "#94C83D",
        overflow: "hidden",
        "& h3": {
          marginTop: 0,
          fontFamily: "Oswald, sans-serif",
          fontWeight: 400,
          fontSize: "1.8em",
          textTransform: "uppercase",
        },
        [theme.breakpoints.between("xs", "sm")]: {
          width: "95%",
          maxHeight: "unset",
          margin: "20px auto",
        },
        [theme.breakpoints.between("sm", "md")]: {
          width: "90%",
          maxHeight: "350px",
          margin: "20px auto",
        },
        [theme.breakpoints.between("md", "lg")]: {
          width: "80%",
          maxHeight: "300px",
          margin: "20px 0",
        },
        [theme.breakpoints.between("lg", "xl")]: {
          width: "80%",
          maxHeight: "250px",
          margin: "20px 0",
        },
      },
      bandText: {
        margin: "40px",
        position: "relative",
      },
      bandImageHost: {
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        background: "#6C93C1",
        [theme.breakpoints.between("xs", "sm")]: {
          width: "unset",
        },
        [theme.breakpoints.between("sm", "md")]: {
          width: "300px",
        },
        [theme.breakpoints.between("md", "xl")]: {
          width: "400px",
        },
      },
      imageLeft: {
        [theme.breakpoints.between("xs", "sm")]: {
          display: "flex",
          flexDirection: "column-reverse",
        },
        [theme.breakpoints.between("sm", "xl")]: {
          display: "grid",
          gridTemplateColumns: "auto 1fr",
        },
      },
      imageRight: {
        [theme.breakpoints.between("xs", "sm")]: {
          display: "flex",
          flexDirection: "column",
        },
        [theme.breakpoints.between("sm", "xl")]: {
          display: "grid",
          gridTemplateColumns: "1fr auto",
        },
      },
      grandImage: {
        width: "100%",
        [theme.breakpoints.between("xs", "sm")]: {
          transform: "scale(1)",
        },
        [theme.breakpoints.between("sm", "md")]: {
          transform: "scale(2.1) translateX(-50px)",
        },
        [theme.breakpoints.between("md", "lg")]: {
          transform: "scale(1.3) translateX(-15px)",
        },
        [theme.breakpoints.between("lg", "xl")]: {
          transform: "scale(1)",
        },
      },
      airfareImage: {
        width: "100%",
        [theme.breakpoints.between("xs", "sm")]: {
          transform: "scale(1)",
        },
        [theme.breakpoints.between("sm", "md")]: {
          transform: "scale(1.8) translate(10px, -2px)",
        },
        [theme.breakpoints.between("md", "lg")]: {
          transform: "scale(1.3) translateY(-10px)",
        },
        [theme.breakpoints.between("lg", "xl")]: {
          transform: "scale(1)",
        },
      },
      bandImage: {
        width: "100%",
        [theme.breakpoints.between("xs", "sm")]: {
          transform: "scale(1)",
        },
        [theme.breakpoints.between("sm", "md")]: {
          transform: "scale(1.8)",
        },
        [theme.breakpoints.between("md", "lg")]: {
          transform: "scale(1.3)",
        },
        [theme.breakpoints.between("lg", "xl")]: {
          transform: "scale(1)",
        },
      },
      merchImage: {
        width: "100%",
        transform: "scale(.6)",
      },
      pushRight: {
        display: "flex",
        justifyContent: "flex-end",
      },
      videoDescription: {
        fontSize: 12,
        position: "absolute",
        bottom: -20,
        left: 0,
        whiteSpace: "pre-wrap",
      },
      playerWrapper: {
        position: "relative",
        paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */,
        width: "100%",
        height: "100%",
      },
    })
  );

  const classes = useStyles();

  return (
    <Observer
      render={() => {
        const { grandDestinationVideoInfo } = myStore;
        const videoLink = grandDestinationVideoInfo?.link ?? "";
        const videoDescription = grandDestinationVideoInfo?.description ?? "";
        console.log({ videoDescription });
        return (
          <div className={classes.root}>
            <div className={classes.header}>
              <h2>Earn Perk Points for Every Purchase</h2>
              <p>
                BuildPerks is a loyalty program for Home Builders and Remodelers
                who earn Perk Points for doing business with an exclusive group
                of suppliers.
              </p>
              <p>
                These Perk Points can be redeemed for name brand merchandise,
                Cabela's gift cards, airline travel, hotel/resort packages, and
                of course our fabulous annual Grand Destination. It’s a valuable
                way for suppliers to say, “Thank you!” to Builders and
                Remodelers for their business loyalty and prompt payments.
              </p>
            </div>
            <div className={clsx(classes.item, classes.imageLeft)}>
              <div className={classes.bandImageHost}>
                {videoLink.length === 0 && (
                  <img
                    className={classes.grandImage}
                    src="/images/grand-destination.png"
                    alt="Grand Destination"
                  />
                )}
                {videoLink.length > 0 && (
                  <div className={classes.playerWrapper}>
                    <ReactPlayer
                      width="100%"
                      style={{ top: 0, left: 0, position: "absolute" }}
                      controls={true}
                      light="/images/grand-destination.png"
                      config={{
                        youtube: {
                          playerVars: { showinfo: 0, controls: 0, autoplay: 1 },
                        },
                      }}
                      height="100%"
                      url={videoLink}
                    />
                  </div>
                )}
              </div>
              <div className={classes.bandText}>
                <h3>Grand Destination</h3>
                <p>
                  Our yearly Grand Destination is the ultimate travel
                  experience. We select first-class accommodations in exotic
                  locations worldwide. These premium trips are planned and
                  hosted by BuildPerks.
                </p>
                {videoDescription.length > 0 && (
                  <p className={classes.videoDescription}>{videoDescription}</p>
                )}
              </div>
            </div>
            <div className={classes.pushRight}>
              <div className={clsx(classes.item, classes.imageRight)}>
                <div className={classes.bandText}>
                  <h3>Merchandise/ Cabela's Gift Cards</h3>
                  <p>
                    Perk Points can be redeemed for thousands of name brand
                    merchandise items, including Cabela's gift cards. Brands
                    include Apple, Coach, Titleist, Nike, Cub Cadet, and many
                    others!
                  </p>
                </div>
                <div className={classes.bandImageHost}>
                  <img
                    className={clsx(classes.bandImage, classes.merchImage)}
                    src="/images/merch-bp.png"
                    alt="Merchandise"
                  />
                </div>
              </div>
            </div>
            <div className={clsx(classes.item, classes.imageLeft)}>
              <div className={classes.bandImageHost}>
                <img
                  className={classes.airfareImage}
                  src="/images/airfare2 normalized.png"
                  alt="Airfare and Hotel/Resort"
                />
              </div>
              <div className={classes.bandText}>
                <h3>Airfare and Hotel/Resort</h3>
                <p>
                  Perk Points can be redeemed for all major airlines, and
                  high-end hotels/resorts such as Hyatt, JW Marriott, The
                  Weston, The Ritz Carlton and many more.
                </p>
              </div>
            </div>
          </div>
        );
      }}
    />
  );
};

export default EarnPoints;
