import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";

const Legal = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        textAlign: "center",
        padding: 20,
        fontFamily: "Oswald, sans-serif",
        fontWeight: 400,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      },
      anchor: {
        textDecoration: "none",
        color: "black",
      },
    })
  );

  const classes = useStyles();
  const year = new Date().getFullYear();

  return (
    <div className={classes.root}>
      <div>
        <Link className={classes.anchor} to="/LegalNotice">
          Legal Notice
        </Link>
        <span> | </span>
        <Link className={classes.anchor} to="/Privacy">
          Privacy Policy
        </Link>
      </div>
      <div>{`Copyright ${year} BuildPerks LLC.`}</div>
    </div>
  );
};

export default Legal;
