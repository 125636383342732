import React from "react";
import SponsorDrawer from "areas/Sponsors/SponsorDrawer";
import MemberDrawer from "areas/Members/Shell/MemberDrawer";
import AdminDrawer from "areas/Admin/AdminDrawer";
import AdminRoutes from "routes/AdminRoutes";
import MemberRoutes from "routes/MemberRoutes";
import SponsorRoutes from "routes/SponsorRoutes";

import { UserType } from "services/api/types";

type Props = {
  userType: UserType | undefined;
  closeDrawer: () => void;
  isDrawerOpen: boolean;
  drawerWidth: number;
};

const GenericDrawer = (props: Props) => {
  if (props.userType === "Admin") {
    return (
      <AdminDrawer>
        <AdminRoutes />
      </AdminDrawer>
    );
  } else if (props.userType === "Sponsor") {
    return (
      <SponsorDrawer>
        <SponsorRoutes />
      </SponsorDrawer>
    );
  } else if (props.userType === "Member") {
    return (
      <MemberDrawer>
        <MemberRoutes />
      </MemberDrawer>
    );
  }

  return <div />;
};

export default GenericDrawer;
