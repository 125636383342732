import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ReactDOM from "react-dom";
import withWidth from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import Typography from "@material-ui/core/Typography";

type Props = {
  title: string;
  width: Breakpoint;
};

const PageTitle = (props: Props) => {
  // eslint-disable-next-line
  const [renderHack, setRenderHack] = React.useState(0);

  window.setTimeout(() => {
    setRenderHack(1);
  }, 100);

  const titlePortal = document.getElementById("mobile-title-portal");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        margin: theme.spacing(0, 0, 1, 0),
      },
    })
  );

  const classes = useStyles();

  const renderMobileTitle = () => {
    if (!titlePortal) throw Error("no add portal");

    return ReactDOM.createPortal(<div>{props.title}</div>, titlePortal);
  };

  if (titlePortal) {
    return renderMobileTitle();
  }

  return (
    <Typography variant="body1" component="span" className={classes.root}>
      {props.title}
    </Typography>
  );
};

export default withWidth()(PageTitle);
