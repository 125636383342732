import { AxiosResponse } from "axios";

export const getFileName = (
  response: AxiosResponse<any>,
  defaultFileName: string
) => {
  const contentDisposition = response.headers["content-disposition"] as string;
  const parts = contentDisposition.split(";");
  const fileNamePart = parts.find((p) => p.trim().startsWith("filename"));

  if (fileNamePart) {
    return fileNamePart.split("=")[1].replace('"', "").replace('"', "");
  }

  return defaultFileName;
};

export const downloadFile = (response: AxiosResponse<any>) => {
  const fileName = getFileName(response, `CompanyExport.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};
