import { apiClient } from "services/api/client";
import { IFileUploadResult } from "services/api/types";

export const uploadFile = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  const result = await apiClient.post<IFileUploadResult>("files", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return result.data;
};

export const uploadPublicFile = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  const result = await apiClient.post<IFileUploadResult>(
    "publicFiles",
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return result.data;
};
