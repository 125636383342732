import React from "react";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import myStore from "stores/my";
import SearchIcon from "@material-ui/icons/Search";
import uiStore from "stores/ui";
import SearchBoxContainer from "components/Dropdowns/SearchBoxContainer";
import { ApplicationPaths } from "components/api-authorization/ApiAuthorizationConstants";
import { useHistory } from "react-router";
import BcnToolbarLogo from "components/Shell/BcnToolbarLogo";
import BuildPerksToolbarLogo from "components/Shell/BuildPerksToolbarLogo";
import ToggleButton from "@material-ui/lab/ToggleButton";
import withWidth from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import * as appConstants from "applications/appConstants";

import clsx from "clsx";

type Props = {
  width: Breakpoint;
};

const AdminToolbarItems = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [titleVisible, setTitleVisible] = React.useState(true);
  const isMenuOpen = Boolean(anchorEl);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      grow: {
        flexGrow: 1,
      },
      mobileTitlePortal: {
        width: "100%",
        textAlign: "center",
        "& div": {
          fontSize: "smaller",
          textTransform: "uppercase",
        },
        "& div:first-child": {
          display: "block",
          fontSize: "large",
          textTransform: "unset",
        },
      },
      title: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
          display: "block",
          minWidth: 200,
          textAlign: "right",
        },
        color: theme.palette.common.white,
      },
      selectedToggle: {
        backgroundColor: "rgba(110, 110, 110, 0.50) !important",
        color: "white !important",
      },
      toggleRoot: {
        color: "white",
      },
      hidden: {
        display: "none",
      },
    })
  );

  const classes = useStyles();
  const history = useHistory();

  function handleProfileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget as any);
  }

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleChangePassword() {
    handleMenuClose();
    uiStore.showModal({
      type: "ChangePasswordModal",
      persisted: true,
      props: {},
    });
  }

  const handleSignOut = () => {
    const logoutPath = {
      pathname: `${ApplicationPaths.LogOut}`,
      state: { local: true },
    };
    handleMenuClose();
    history.push(logoutPath);
  };

  const renderSearchButton = () => {
    return (
      <ToggleButton
        classes={{
          selected: classes.selectedToggle,
          root: classes.toggleRoot,
        }}
        color="white"
        value="check"
        selected={!titleVisible}
        onChange={() => {
          const searchBoxWillBeVisible = titleVisible;
          setTitleVisible(!titleVisible);

          window.setTimeout(() => {
            if (searchBoxWillBeVisible) {
              const searchEl = document.getElementById("global-search-box");
              searchEl?.focus();
            }
          }, 250);
        }}
      >
        <SearchIcon />
      </ToggleButton>
    );
  };

  React.useEffect(() => {
    const unregisterCallback: () => void | undefined = history.listen(() => {
      setTitleVisible(true);
    });
    return () => {
      if (unregisterCallback) unregisterCallback();
    };
  }, [history]);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleChangePassword}>Change My Password</MenuItem>
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      <MenuItem onClick={handleSignOut}>Log Out</MenuItem>
    </Menu>
  );

  const showSearchBox = props.width !== "xs" || !titleVisible;

  return (
    <>
      {process.env.REACT_APP_BRANDING === appConstants.BuildPerks && (
        <BuildPerksToolbarLogo />
      )}
      {process.env.REACT_APP_BRANDING === appConstants.BuildersClubNorth && (
        <BcnToolbarLogo />
      )}{" "}
      {showSearchBox && <SearchBoxContainer />}
      <div
        className={clsx(classes.mobileTitlePortal, {
          [classes.hidden]: !titleVisible,
        })}
        id="mobile-title-portal"
      />
      <div className={classes.grow} />
      <Typography className={classes.title} variant="subtitle2" noWrap>
        {myStore.currentUserInfo
          ? myStore.currentUserInfo.emailAddress
          : undefined}
        {myStore.currentUserInfo?.environmentName === "Staging" && (
          <span> (UAT) </span>
        )}
        {myStore.currentUserInfo?.environmentName === "Development" && (
          <span> (Development) </span>
        )}
      </Typography>
      {props.width === "xs" && renderSearchButton()}
      <IconButton edge="end" onClick={handleProfileMenuOpen} color="inherit">
        <AccountCircle />
      </IconButton>
      {renderMenu}
    </>
  );
};

export default withWidth()(AdminToolbarItems);
