import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import { IAccountInfo } from "services/api/types";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { formatNumber } from "utils/number";

type Props = {
  accounts: IAccountInfo[];
  currentAccountId: string;
  onChange: (account: IAccountInfo) => void;
};

const AccountSelector = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        marginLeft: theme.spacing(4),
        color: "#943A3A",
      },
      accountInfo: {
        fontSize: "smaller",
        display: "flex",
        minWidth: 300,
        justifyContent: "space-between",
      },
      listItem: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    })
  );

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (company: IAccountInfo) => {
    handleClose();
    props.onChange(company);
  };

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.accounts.map((p) => {
          return (
            <MenuItem
              onClick={() => handleItemClick(p)}
              classes={{
                root: classes.listItem,
              }}
              key={p.id}
              value={p.id}
            >
              <div>
                <strong>{p.name}</strong>&nbsp;
              </div>
              <div className={classes.accountInfo}>
                <span>{p.identifier}</span>
                <span>{`Balance = ${formatNumber(p.computedBalance)}`}</span>
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  const currentAccount = props.accounts.find(
    (pa) => pa.id === props.currentAccountId
  )!;

  return (
    <div>
      {renderMenu()}
      <Button
        size="small"
        variant="contained"
        className={classes.button}
        onClick={handleClick}
      >
        <span>Account:</span>&nbsp;
        <strong>{currentAccount.name}</strong>&nbsp;
        <span style={{ fontSize: "smaller" }}>(click to change)</span>
      </Button>
    </div>
  );
};

export default AccountSelector;
