import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

type Props = {
  handleClose: () => void;
  showIsImportant: boolean;
  existingNote?: string;
  handleSubmit: (note: string, isImportant?: boolean) => Promise<void>;
} & RouteComponentProps;

const GenericAddNote = (props: Props) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [note, setNote] = React.useState(props.existingNote ?? "");
  const [isImportant, setIsImportant] = React.useState(false);

  const handleSave = async () => {
    setIsSaving(true);

    try {
      await props.handleSubmit(
        note,
        // Send undefined if they didn't want the text box in the first place
        props.showIsImportant ? isImportant : undefined
      );
      props.handleClose();
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <ResponsiveModalShell
      handleClose={props.handleClose}
      title="Add Note"
      disableSaveButton={isSaving || note.length === 0}
      handleSave={handleSave}
    >
      <TextField
        variant="outlined"
        id="content"
        margin="dense"
        label="Note"
        fullWidth
        multiline={true}
        minRows={5}
        value={note}
        onChange={(evt) => setNote(evt.target.value)}
        placeholder="Note"
      />
      {props.showIsImportant && (
        <FormControlLabel
          control={
            <Checkbox
              checked={isImportant}
              onChange={(e) => setIsImportant(e.target.checked)}
            />
          }
          label="Alert"
        />
      )}
    </ResponsiveModalShell>
  );
};

export default withRouter(GenericAddNote);
