import { action, observable } from "mobx";
import api from "services/api";
import {
  ICompanyUser,
  ICreateUserCommand,
  UserListType,
  IUpdateUserCommand,
} from "services/api/types";

export class UsersStore {
  @observable
  public isLoading = false;

  @observable
  public isLoaded = false;

  @observable
  public isSendingReminders = false;

  @observable
  public totalReminders: number | undefined;

  @observable
  public remainingReminders: number | undefined;

  @observable
  public users: ICompanyUser[] = [];

  @observable
  public currentListType: UserListType = "RecentLogins";

  @action.bound
  public async loadCompanyUserList(
    companyId: string,
    showDisabledAccounts: boolean
  ) {
    this.isLoading = true;

    try {
      this.currentListType = "CompanyUsers";
      const result = await api.shared.users.getCompanyUserList(
        companyId,
        showDisabledAccounts
      );
      this.users = result;
    } catch (e) {
      console.error(e);
    }

    this.isLoading = false;
    this.isLoaded = true;
  }

  @action.bound
  public async loadAdminUserList(listType: UserListType) {
    this.isLoading = true;

    try {
      this.currentListType = listType;
      const result = await api.admin.users.getUserList(listType);
      this.users = result;
    } catch (e) {
      console.error(e);
    }

    this.isLoading = false;
    this.isLoaded = true;
  }

  @action.bound
  public async sendReminderByCreatingDate(args: {
    startDate: Date;
    endDate: Date;
  }) {
    await api.admin.users.sendReminderByCreatingDate(args);
  }

  @action.bound
  public async sendReminders() {
    this.isSendingReminders = true;

    try {
      let result = await api.admin.users.sendReminders();

      if (this.totalReminders === undefined) {
        this.totalReminders = result.data.remaining + result.data.sent;
      }

      this.remainingReminders = result.data.remaining;
      while (result.data.remaining > 0) {
        result = await api.admin.users.sendReminders();
        this.remainingReminders = result.data.remaining;
      }

      this.isSendingReminders = false;
      return result.data;
    } catch (e) {
      this.isSendingReminders = false;
      throw e;
    }
  }

  @action.bound
  public async findUsers(query: string, includeDisabled: boolean) {
    this.isLoading = true;

    try {
      this.currentListType = "SearchResults";
      const result = await api.admin.users.searchUsres(query, includeDisabled);
      this.users = result;
    } catch (e) {
      console.error(e);
    }

    this.isLoading = false;
    this.isLoaded = true;
  }

  @action.bound
  public async enableUserAccount(userId: string) {
    this.isLoading = true;

    try {
      const user = await api.shared.users.enableUserAccount(userId);
      const newUsers = this.users.map((u) => {
        if (u.id === user.id) return user;
        return u;
      });

      this.users = newUsers;
    } catch (e) {
      console.error(e);
    }

    this.isLoading = false;
  }

  @action.bound
  public async disableUserAccount(userId: string) {
    this.isLoading = true;

    try {
      const user = await api.shared.users.disableUserAccount(userId);
      const newUsers = this.users.map((u) => {
        if (u.id === user.id) return user;
        return u;
      });

      this.users = newUsers;
    } catch (e) {
      console.error(e);
    }

    this.isLoading = false;
  }

  @action.bound
  public async updateUser(command: IUpdateUserCommand) {
    this.isLoading = true;
    try {
      const result = await api.shared.users.updateUser(command);

      // If the user ended up with no permission, the API
      // might've deleted it. In that case it will return
      // null instead of the updated user.
      if (result && result.permissions.length > 0) {
        this.users = this.users.map((u) => {
          if (u.id === result.id) return result;
          return u;
        });
      } else {
        this.users = this.users.filter((u) => u.id !== command.userId);
      }

      this.isLoading = false;
      return result;
    } catch (e) {
      this.isLoading = false;
      console.log(e);
      return undefined;
    }
  }

  @action.bound
  public async createUser(command: ICreateUserCommand) {
    this.isLoading = true;
    try {
      const result = await api.shared.users.createUser(command);
      if (this.users) {
        const newUsers = [...this.users, result.data];
        this.users = newUsers;
      }
      this.isLoading = false;
      return result;
    } catch (e) {
      this.isLoading = false;
      console.log(e);
      return undefined;
    }
  }
}

export default new UsersStore();
