import React from "react";
import NoDataMessage from "components/Shell/NoDataMessage";
import MobileGroupHeader from "components/Shell/MobileGroupHeader";
import PhoneNumber from "components/PhoneNumber";

type Props = {
  contactInfo: {
    primaryContactName?: string;
    primaryContactPhoneNumber?: string;
  };
};

const PrimaryContactBlock = (props: Props) => {
  const renderPrimaryContact = () => {
    if (!props.contactInfo) return undefined;
    if (!props.contactInfo.primaryContactName) {
      return <NoDataMessage message="Not found" />;
    }

    return (
      <div>
        <div>{props.contactInfo.primaryContactName}</div>
        {props.contactInfo.primaryContactPhoneNumber && (
          <div>
            <PhoneNumber number={props.contactInfo.primaryContactPhoneNumber} />
          </div>
        )}
      </div>
    );
  };

  return (
    <MobileGroupHeader title="Primary Contact">
      {renderPrimaryContact()}
    </MobileGroupHeader>
  );
};

export default PrimaryContactBlock;
