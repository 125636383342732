import React from "react";
import { toast } from "react-toastify";
import { access } from "utils/helpers";

export const defaultErrorHandler = (error: any) => {
  console.error(error);
  toast.error(
    access(
      error,
      (e: any) => (
        <>
          <div>Something went wrong</div>
          <code>
            <small>{e.data.message}</small>
          </code>
        </>
      ),
      "Something went wrong, please try again later"
    )
  );
};
