import { apiClient } from "services/api/client";
import {
  IRecentMemberSponsorLink,
  ISendLinkNotificationResponse,
} from "services/api/types";

const NEW_LINKS_URL = "administrator/memberSponsorLinks";

export const getRecentLinks = async () => {
  const url = `${NEW_LINKS_URL}/recent`;
  const response = await apiClient.get<IRecentMemberSponsorLink[]>(url);
  return response.data;
};

export const sendLinkNotification = async (args: {
  purchaserSupplierId: string;
  contactIds: string[];
}) => {
  // Server determines the template to use (member or sponsor)
  const url = `${NEW_LINKS_URL}/linkNotifications`;
  const response = await apiClient.post<ISendLinkNotificationResponse>(
    url,
    args
  );
  return response.data;
};

export const markLinkAsProcessed = async (purchaserSupplierId: string) => {
  // Server determines the template to use (member or sponsor)
  const url = `${NEW_LINKS_URL}/processedLinks`;
  const response = await apiClient.post(url, {
    purchaserSupplierId,
  });
  return response.data;
};
