import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import CategorySelect from "components/Dropdowns/CategorySelect";
import { Option } from "services/api/types";
import clsx from "clsx";
import { Typography } from "@material-ui/core";

export type SponsorFilterType = "ByName" | "ByCategory";

type Props = {
  selectedFilterType: SponsorFilterType;
  onFilterTypeChanged: (filterType: SponsorFilterType) => void;
  searchText: string;
  onSearchTextCHanged: (text: string) => void;
  selectedCategory: Option | undefined;
  onSelectedCategoryChanged: (value: Option | undefined) => void;
  categoryIdsEarningPointsIn: string[];
  renderOption: (option: Option) => React.ReactNode;
  disabled: boolean;
};

const SponsorFilters = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "grid",
        gridTemplateRows: "auto 1fr",
      },
      disabled: {
        opacity: 0.5,
        pointerEvents: "none",
      },
      radioGroup: {
        padding: theme.spacing(0, 2),
        marginTop: theme.spacing(2),
      },
    })
  );

  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.disabled]: props.disabled,
      })}
    >
      <Typography variant="caption" color="primary">
        Sponsor Search Options
      </Typography>
      <RadioGroup
        className={classes.radioGroup}
        value={props.selectedFilterType}
        onChange={(evt) =>
          props.onFilterTypeChanged(evt.target.value as SponsorFilterType)
        }
      >
        <FormControlLabel
          value="ByName"
          control={<Radio />}
          label="Find Sponsor by Name"
        />
        <TextField
          variant="outlined"
          margin="dense"
          value={props.searchText}
          onChange={(evt) => props.onSearchTextCHanged(evt.target.value)}
          placeholder="Name or ClubId"
        />
        <FormControlLabel
          value="ByCategory"
          control={<Radio />}
          label="Find Sponsor by Category"
        />
        <CategorySelect
          hasError={false}
          hideLabel={true}
          onChanged={(opt) => props.onSelectedCategoryChanged(opt)}
          value={props.selectedCategory}
          renderOption={props.renderOption}
        />
      </RadioGroup>
    </div>
  );
};

export default SponsorFilters;
