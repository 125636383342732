import React from "react";
import MobileGroupHeader from "components/Shell/MobileGroupHeader";
import { ICompanyCategory } from "services/api/types";
import Typography from "@material-ui/core/Typography";
import NoDataMessage from "components/Shell/NoDataMessage";

type Props = {
  categories: ICompanyCategory[];
};

const CategoriesInfoBlock = (props: Props) => {
  return (
    <MobileGroupHeader title="Categories">
      {props.categories.map((c) => (
        <Typography variant="body2" key={c.id}>
          {c.isPrimary ? (
            <strong>{c.category.name}</strong>
          ) : (
            <span>{c.category.name}</span>
          )}
        </Typography>
      ))}
      {props.categories.length === 0 && (
        <NoDataMessage message="No categories found" />
      )}
    </MobileGroupHeader>
  );
};

export default CategoriesInfoBlock;
