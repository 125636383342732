import { apiClient } from "services/api/client";
import {
  ISponsorDirectoryResults,
  ISponsorDirectoryCategory,
  ISponsorDirectoryQuery,
  ISponsorDirectoryCompany,
  ICompanySearchResults,
} from "services/api/types";

const sponsorsUrl = "shared/sponsors";

export const get = async (query: ISponsorDirectoryQuery) => {
  const response = await apiClient.get<ISponsorDirectoryResults>(sponsorsUrl, {
    params: query,
  });
  return response.data;
};

export const getSponsorDirectoryCategories = async () => {
  const url = "shared/sponsorDirectoryCategories";
  const response = await apiClient.get<ISponsorDirectoryCategory[]>(url);
  return response.data;
};

export const getSponsorInfo = async (sponsorId: string) => {
  const url = `${sponsorsUrl}/${sponsorId}`;
  const response = await apiClient.get<ISponsorDirectoryCompany>(url);
  return response;
};

export const searchSponsors = async (
  query: string,
  includeCompetitors = false
) => {
  const url = `${sponsorsUrl}/search`;

  const response = await apiClient.get<ICompanySearchResults>(url, {
    params: { search: query, includeCompetitors },
  });

  return response.data;
};
