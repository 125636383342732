import React, { useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import red from "@material-ui/core/colors/red";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { AddressAction, IFullOrderModel } from "services/api/types";

type Props = {
  handleClose: () => void;
  order: IFullOrderModel;
  onConfirmCancellation: (addressAction: AddressAction) => void;
};

const ConfirmCancelOrderModal = (props: Props) => {
  const [addressAction, setAddressAction] = useState<AddressAction>("NoAction");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      instructions: {
        marginBottom: theme.spacing(2),
      },
      error: {
        color: red[500],
      },
      errorHost: {
        marginBottom: theme.spacing(2),
      },
      formLabel: {
        fontSize: ".9rem",
      },
    })
  );

  const handleRadioChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setAddressAction(evt.target.value as AddressAction);
  };

  // Left this in on the off chance they'd like to see the address in the dialog
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const renderAddress = () => {
    const { address } = props.order;
    const { address1, address2, city, state, zip } = address;

    return (
      <div>
        <div>{`${props.order.shipToFirstName} ${props.order.shipToLastName}`}</div>
        <div>{address1}</div>
        <div>{address2}</div>
        <div>{`${city}, ${state} ${zip}`}</div>
      </div>
    );
  };

  const renderRadios = () => {
    return (
      <RadioGroup
        name="company-type"
        value={addressAction}
        onChange={handleRadioChange}
      >
        <FormControlLabel
          classes={{ label: classes.formLabel }}
          value="NoAction"
          control={<Radio size="small" />}
          label="Leave the address active"
        />
        <FormControlLabel
          classes={{ label: classes.formLabel }}
          value="Deactivate"
          control={<Radio size="small" />}
          label="DEACTIVATE the address when cancelling the order"
        />
      </RadioGroup>
    );
  };

  const classes = useStyles();

  return (
    <ResponsiveModalShell
      handleClose={props.handleClose}
      handleSave={() => {
        props.onConfirmCancellation(addressAction);
      }}
      title="Are you sure you want to cancel this order?"
    >
      {/* {renderAddress()} */}
      {renderRadios()}
    </ResponsiveModalShell>
  );
};

export default ConfirmCancelOrderModal;
