import ReactDOM from "react-dom";
import React from "react";
import Table from "@material-ui/core/Table";
import TablePagination from "@material-ui/core/TablePagination";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import InputAdornment from "@material-ui/core/InputAdornment";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {
  createStyles,
  Theme,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import UpArrowIcon from "@material-ui/icons/ArrowUpward";
import DownArrowIcon from "@material-ui/icons/ArrowDownward";
import ClearIcon from "@material-ui/icons/Clear";
import NoDataMessage from "components/Shell/NoDataMessage";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import MyGenericListItem from "./MyGenericListItem";
import uiStore from "stores/ui";
import {
  Column,
  Order,
  BaseTableProps,
  PagingProps,
} from "./MyGenericTableTypes";
import { colorForPrimaryThings } from "utils/colorPalette";
import DropDownPill, { Option } from "components/Dropdowns/DropDownPill";
import BottomAppBar from "components/Dropdowns/BottomAppBar";
import MobilePager from "components/Shell/MobilePager";
import Loader from "components/Shell/Loader";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import main from "merge";

type Props<T extends { id: string }> = BaseTableProps<T> & PagingProps<T>;

interface HeadCell<T extends { id: string }> {
  label: string;
  id?: keyof T;
  numeric?: boolean;
  styleWidth?: string;
  canSort: boolean;
  center?: boolean;
}

// The trailing comma in  <T, > is added due to contraints of the  .tsx file extension
// (not really accurate for this component anymore but still true and good to know)
const MyGenericTable = <T extends { id: string }>(mainProps: Props<T>) => {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<
    HTMLElement | undefined
  >(undefined);

  const [detailsId, setDetailsId] = React.useState<string | undefined>();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [mainProps.page]);

  const DarkTableCell = withStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: "gainsboro",
      },
    })
  )(TableCell);

  const columnsToShow = mainProps.columns.filter((col) => !col.hidden);

  const headCells: HeadCell<T>[] = columnsToShow.map((col) => {
    return {
      label: col.label,
      numeric: col.numeric,
      styleWidth: col.styleWidth,
      id: col.propertyName,
      canSort: !col.disableSorting,
      center: col.centerHeader,
    };
  });

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        marginBottom: "4em",
        "& a, a:visited, a:active": {
          color: "rgb(0, 0, 238)",
        },
        width: "100%",
        // overflowX: "hidden",
      },
      primaryIndicator: {
        color: colorForPrimaryThings,
      },
      visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
      },
      row: {
        cursor: mainProps.onRowClicked ? "pointer" : "unset",
        transition: theme.transitions.create(["opacity"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      toolbar: {
        display: "flex",
        justifyContent:
          (mainProps.onExport || mainProps.renderLeftToolbarComponent) &&
          (mainProps.hideFilter === undefined || mainProps.hideFilter === false)
            ? "space-between"
            : "flex-end",
        alignItems: "center",
        // minHeight: "70px",
        // position: "absolute",
        // top: 118,
        // right: 20,
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
      nonPortalToolbar: {
        // paddingBottom: 12,
      },
      filter: {},
      pagination: {},
      pillHost: {
        width: "100%",
        overflowX: "scroll",
        overflowY: "hidden",
        whiteSpace: "nowrap",
        marginBottom: 4,
        marginTop: theme.spacing(2),
      },
      collapse: {
        // transform: "translateY(10px)",
      },
      disabled: {
        opacity: 0.7,
        pointerEvents: "none",
      },
      bodyCell: {
        verticalAlign: "center",
      },
      headerCell: {
        [theme.breakpoints.down("lg")]: {
          padding: 4,
        },
      },
      filterTextField: {
        margin: 0,
      },
      mobileList: {
        marginTop: 0,
        paddingTop: 0,
      },
      tableHost: {
        marginBottom: "5rem",
      },
      ghostRow: {
        "& td": {
          color: "silver !important",
        },
        "& a": {
          color: "silver !important",
        },
        "& td .subText": {
          color: "silver !important",
        },
      },
    })
  );

  const classes = useStyles();

  const handleDesktopMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(undefined);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    mainProps.setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    mainProps.setPageSize(parseInt(event.target.value, 10));
    mainProps.setPage(0);

    window.setTimeout(() => {
      const element = document.querySelectorAll("div[role=tabpanel]");
      if (element.length === 1) {
        element[0].scrollTop = 0;
      }
    }, 100);
  };

  interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
    onRequestSort: (
      event: React.MouseEvent<unknown>,
      property: keyof T
    ) => void;
    order: Order;
    orderBy: keyof T;
    showMenu: boolean;
  }

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    const isCurrentlyAscending =
      mainProps.orderBy === property && mainProps.order === "asc";

    const column = mainProps.columns.filter(
      (c) => c.propertyName === property
    )[0];
    const defaultSortOrder = column?.defaultSortOrder ?? "asc";

    mainProps.setOrder(isCurrentlyAscending ? "desc" : defaultSortOrder);
    mainProps.setOrderBy(property);
  };

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof T) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell, index) => {
            let align = "left";
            if (headCell.center) {
              align = "center";
            } else if (headCell.numeric) {
              align = "right";
            }

            const column = mainProps.columns.filter(
              (c) => c.propertyName === headCell.id
            )[0];

            const defaultSortOrder = column.defaultSortOrder ?? "asc";

            return (
              <DarkTableCell
                key={index}
                className={classes.headerCell}
                style={{
                  width: headCell.styleWidth ? headCell.styleWidth : "unset",
                  maxWidth: headCell.styleWidth ? headCell.styleWidth : "unset",
                  minWidth: headCell.styleWidth ? headCell.styleWidth : "unset",
                }}
                align={align as any}
                sortDirection={orderBy === headCell.id ? order : false}
              >
                {headCell.canSort ? (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={
                      orderBy === headCell.id ? order : defaultSortOrder
                    }
                    onClick={
                      headCell.id && headCell.canSort
                        ? createSortHandler(headCell.id)
                        : () => {
                            console.warn("Not going to sort");
                          }
                    }
                  >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  <div>{headCell.label}</div>
                )}
              </DarkTableCell>
            );
          })}
          {/* for menu */}
          {props.showMenu && <DarkTableCell />}
        </TableRow>
      </TableHead>
    );
  }

  const renderMenu = () => {
    if (
      !menuAnchorEl ||
      !mainProps.showMenu ||
      !mainProps.actions ||
      mainProps.actions.length === 0
    )
      return;
    const id = menuAnchorEl.getAttribute("data-id");

    // Using == here and not === because we need the type conversion
    // (some pieces are using numbers for Ids)
    // eslint-disable-next-line
    const row = mainProps.currentPageData.find((d) => d.id == id);
    if (!row) {
      throw Error("Could not find row");
    }

    const visibleActions = mainProps.actions.filter(
      (a) => !a.visibilityPredicate || a.visibilityPredicate(row)
    );

    const dynamicActions = mainProps.getDynamicActions
      ? mainProps.getDynamicActions(row)
      : [];

    const allActions = [...visibleActions, ...dynamicActions];

    return (
      <Menu
        anchorEl={menuAnchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id="table-menu"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        {allActions.map((action, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                handleMenuClose();
                uiStore.closeModal();
                action.execute(row);
                setDetailsId(undefined);
              }}
            >
              {action.label}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  const renderBodyCell = (column: Column<T>, row: T, index: number) => {
    const value = column.propertyName ? row[column.propertyName] : undefined;

    return (
      <TableCell
        className={classes.bodyCell}
        align={column.numeric ? "right" : "inherit"}
        key={index}
      >
        {column.render ? column.render(row, value) : undefined}
        {column.propertyName && !column.render ? value : undefined}
      </TableCell>
    );
  };

  const emptyRows = mainProps.pageSize - mainProps.currentPageData.length;

  const clearFilter = () => {
    mainProps.setFilterTerm("");
  };

  const handleFilterKeyDown = (evt: React.KeyboardEvent) => {
    if (evt.keyCode === 27) {
      // Escape
      clearFilter();
    }
  };

  const renderTablePaginationControls = () => {
    return (
      <TablePagination
        align="left"
        className={classes.pagination}
        rowsPerPageOptions={[25, 50, 100, 200]}
        component="div"
        count={mainProps.totalRecords}
        rowsPerPage={mainProps.pageSize}
        page={mainProps.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    );
  };

  const renderFilterTextBox = (fullWidth: boolean, elementId: string) => {
    return (
      <TextField
        fullWidth={fullWidth}
        value={mainProps.filterTerm}
        classes={{
          root: classes.filterTextField,
        }}
        autoComplete="off"
        id={elementId}
        onKeyDown={handleFilterKeyDown}
        onChange={(e) => mainProps.setFilterTerm(e.currentTarget.value)}
        variant="outlined"
        margin="dense"
        placeholder={mainProps.filterPlaceholder ?? "Filter"}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={clearFilter}>
                {mainProps.filterTerm ? <ClearIcon /> : undefined}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  };

  const renderToolbar = () => {
    if (mainProps.hideToolbar) return undefined;

    return (
      <div
        className={clsx(classes.toolbar, {
          [classes.nonPortalToolbar]: mainProps.toolbarPortalId === undefined,
        })}
      >
        {mainProps.renderLeftToolbarComponent &&
          mainProps.renderLeftToolbarComponent()}
        {mainProps.onExport ? (
          <Button
            disabled={mainProps.isExporting}
            onClick={() =>
              mainProps.onExport
                ? mainProps.onExport()
                : console.log("No export function")
            }
            variant="contained"
            color="default"
            size="medium"
          >
            Export
          </Button>
        ) : undefined}
        {renderTablePaginationControls()}
        {mainProps.hideFilter === undefined || mainProps.hideFilter === false
          ? renderFilterTextBox(false, "desktop-filter-textbox")
          : undefined}
      </div>
    );
  };

  const renderDialogActions = () => {
    if (!mainProps.renderListItemDetails) {
      return undefined;
    }

    if (!detailsId) {
      return undefined;
    }

    const shouldRenderActions =
      mainProps.renderActionsInMobileDetailsView === undefined ||
      mainProps.renderActionsInMobileDetailsView;

    if (!shouldRenderActions) {
      return undefined;
    }

    const validActions = getValidActions(
      mainProps.currentPageData.find((d) => d.id === detailsId)!
    );

    if (validActions.length === 0) return undefined;

    return (
      <IconButton
        data-id={detailsId}
        size="small"
        onClick={handleDesktopMenuClick}
      >
        <MoreVertIcon />
      </IconButton>
    );
  };

  const renderDialogContents = () => {
    const dialogPortal = document.getElementById(
      "mobile-dialog-contents-portal"
    );

    if (!dialogPortal) {
      return undefined;
    }

    if (!mainProps.renderListItemDetails) {
      return undefined;
    }

    if (!detailsId) {
      return undefined;
    }

    const detailsRow = mainProps.currentPageData.find(
      (d) => d.id === detailsId
    )!;

    return ReactDOM.createPortal(
      <ResponsiveModalShell
        renderMenuIcon={renderDialogActions}
        handleClose={() => {
          setDetailsId(undefined);
          uiStore.closeModal();
        }}
        title={mainProps.detailsTitle}
      >
        {mainProps.renderListItemDetails(detailsRow)}
      </ResponsiveModalShell>,
      dialogPortal
    );
  };

  const getValidActions = (row: T) => {
    return (
      mainProps.actions?.filter(
        (a) => !a.visibilityPredicate || a.visibilityPredicate(row)
      ) ?? []
    );
  };

  const renderTable = () => {
    return (
      <div className={classes.tableHost}>
        <Table size="small" stickyHeader>
          {mainProps.columns.length > 1 && (
            <EnhancedTableHead
              classes={classes}
              showMenu={mainProps.showMenu}
              order={mainProps.order}
              orderBy={mainProps.orderBy}
              onRequestSort={handleRequestSort}
            />
          )}
          <TableBody>
            {mainProps.totalRecords === 0 ? (
              <TableRow>
                <TableCell colSpan={99}>
                  <NoDataMessage
                    disableTopMargin
                    message={mainProps.noDataMessage}
                  />
                </TableCell>
              </TableRow>
            ) : undefined}
            {mainProps.currentPageData.map((row, index) => {
              const validActions = getValidActions(row);
              return (
                <TableRow
                  className={clsx(classes.row, {
                    [classes.ghostRow]:
                      mainProps.ghostRow && mainProps.ghostRow(row),
                  })}
                  onClick={() => {
                    if (mainProps.onRowClicked) {
                      mainProps.onRowClicked(row);
                    }
                  }}
                  hover={mainProps.onRowClicked ? true : false}
                  style={{
                    backgroundColor:
                      mainProps.dimRow && mainProps.dimRow(row)
                        ? "whitesmoke"
                        : "unset",
                  }}
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                >
                  {columnsToShow.map((col, index) => {
                    return renderBodyCell(col, row, index);
                  })}
                  {mainProps.showMenu && (
                    <TableCell valign="top" padding="checkbox">
                      {validActions.length > 0 ? (
                        <IconButton
                          size="small"
                          onClick={handleDesktopMenuClick}
                          data-id={row.id}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      ) : (
                        <div />
                      )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            {mainProps.showEmptyRows && emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
        {renderTablePaginationControls()}
      </div>
    );
  };

  const renderNonMobileTable = () => {
    if (
      mainProps.showMobileViewAt === undefined ||
      mainProps.showMobileViewAt === "xs"
    ) {
      return <Hidden xsDown>{renderTable()}</Hidden>;
    } else if (mainProps.showMobileViewAt === "sm") {
      return <Hidden smDown>{renderTable()}</Hidden>;
    } else if (mainProps.showMobileViewAt === "md") {
      return <Hidden mdDown>{renderTable()}</Hidden>;
    }

    return undefined;
  };

  const renderMaybeHiddenTable = () => {
    return mainProps.getPrimaryText || mainProps.renderListItemContent
      ? renderNonMobileTable()
      : renderTable();
  };

  const renderListItem = (row: T) => {
    return (
      <MyGenericListItem
        row={row}
        key={row.id}
        detailsTitle={mainProps.detailsTitle}
        onViewDetails={(row, title) => {
          if (mainProps.renderListItemDetails) {
            setDetailsId(row.id);
            uiStore.showModal();
          }
        }}
        canViewDetails={mainProps.canViewDetails}
        getSecondaryText={mainProps.getSecondaryText}
        onClick={(row) =>
          // We're in a list item. Only honor the onRowClicked
          // if we don't have renderListItemDetails (if we so
          // onViewDetails handles things)
          mainProps.renderListItemDetails === undefined &&
          mainProps.onRowClicked &&
          mainProps.onRowClicked(row)
        }
        getPrimaryText={mainProps.getPrimaryText}
        getIsNotActive={mainProps.getIsNotActive}
        avatarColor={mainProps.avatarColor}
        getIsPrimary={mainProps.getIsPrimary}
        renderListItemDetails={mainProps.renderListItemDetails}
        renderAvatarIcon={mainProps.renderAvatarIcon}
        renderListItemContent={mainProps.renderListItemContent}
      />
    );
  };

  const getIcon = (col: Column<T>): JSX.Element | undefined => {
    if (col.propertyName === mainProps.orderBy) {
      return mainProps.order === "asc" ? (
        <UpArrowIcon fontSize="small" />
      ) : (
        <DownArrowIcon fontSize="small" />
      );
    } else {
      return undefined;
    }
  };

  const renderSortOrderPill = () => {
    const options: Option[] = mainProps.columns
      .filter((c) => !c.disableSorting)
      .map((c) => {
        return {
          label: c.label,
          value: c.propertyName?.toString() ?? "",
          icon: getIcon(c),
        };
      });

    if (options.length === 0) {
      return undefined;
    }

    const orderByLabel = options.find(
      (o) => o.value === mainProps.orderBy
    )?.label;

    return (
      <DropDownPill
        key="sort-order-pill"
        options={options}
        onChange={(value) => {
          if (value === mainProps.orderBy) {
            if (mainProps.order === "asc") {
              mainProps.setOrder("desc");
            } else {
              mainProps.setOrder("asc");
            }
          } else {
            mainProps.setOrderBy(value as keyof T);
          }
        }}
        label={`by ${orderByLabel} (${mainProps.order})`}
        value={mainProps.orderBy.toString()}
      />
    );
  };

  const renderPills = () => {
    return (
      <div className={classes.pillHost}>
        {mainProps.customPills}
        {mainProps.totalRecords > 0 && renderSortOrderPill()}
      </div>
    );
  };

  const renderMobileFilter = () => {
    if (mainProps.hideFilter === undefined || mainProps.hideFilter === false) {
      return <div>{renderFilterTextBox(true, "mobile-filter-textbox")}</div>;
    }

    return undefined;
  };

  const getTotalPages = () => {
    if (mainProps.totalRecords === 0) return 0;
    let totalPages = Math.floor(mainProps.totalRecords / mainProps.pageSize);
    if (totalPages * mainProps.pageSize < mainProps.totalRecords) {
      totalPages += 1;
    }

    return totalPages;
  };

  const renderMobilePager = () => {
    const totalPages = getTotalPages();

    if (totalPages < 2 || mainProps.isLoading) {
      return null;
    }

    return (
      <MobilePager
        canGoNext={mainProps.page + 1 < totalPages}
        canGoPrevious={mainProps.page > 0}
        currentPage={mainProps.page}
        goToPage={mainProps.setPage}
        totalPages={totalPages}
      />
    );
  };

  const renderBottomAppBar = () => {
    if (mainProps.hideBottomAppBar) return;
    const totalPages = getTotalPages();

    return (
      <BottomAppBar
        page={mainProps.page}
        onPageChanged={mainProps.setPage}
        onSave={mainProps.onSave}
        totalPages={totalPages}
        onAdd={mainProps.onMobileAddButtonClicked}
      />
    );
  };

  const renderMobileList = (items: T[]) => {
    return (
      <>
        {renderPills()}
        {renderMobileFilter()}
        <List classes={{ root: classes.mobileList }}>
          {items.map((row, index) => {
            return renderListItem(row);
          })}
        </List>
        {mainProps.totalRecords === 0 && !mainProps.isLoading && (
          <NoDataMessage message="No records found" />
        )}
        {mainProps.isLoading && (
          <div style={{ position: "absolute", top: 50, width: "100%" }}>
            <Loader />
          </div>
        )}
        {renderMobilePager()}
        {renderBottomAppBar()}
      </>
    );
  };

  const renderMaybeHiddenList = () => {
    const items = mainProps.currentPageData;
    const { getPrimaryText, renderListItemContent, showMobileViewAt } =
      mainProps;

    if (getPrimaryText || renderListItemContent) {
      if (showMobileViewAt === undefined || showMobileViewAt === "xs") {
        return <Hidden smUp>{renderMobileList(items)}</Hidden>;
      } else if (showMobileViewAt === "sm") {
        return <Hidden mdUp>{renderMobileList(items)}</Hidden>;
      } else if (showMobileViewAt === "md") {
        return <Hidden lgUp>{renderMobileList(items)}</Hidden>;
      }
    }

    return undefined;
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.disabled]: mainProps.isLoading,
      })}
    >
      {renderToolbar()}
      {renderMenu()}
      {renderMaybeHiddenTable()}
      {renderMaybeHiddenList()}
      {renderDialogContents()}

      {/* {renderDialogActions()} */}
    </div>
  );
};

export default MyGenericTable;
