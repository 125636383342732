import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { TransactionTypeOption } from "../../../types/options";
import { ITransactionType } from "services/api/types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export type AddTransactionMode = "credit" | "debit" | "open";

type Props = {
  className: string;
  value: ITransactionType | undefined;
  errorClassName: string;
  errorMessage: string;
  mode: AddTransactionMode;
  transactionTypes: ITransactionType[];
  onChanged: (newValue: ITransactionType) => void;
};

const TransactionTypeSelect = (props: Props) => {
  const getValidTransactionTypes = (types: ITransactionType[]) => {
    if (props.mode === "credit") {
      return types.filter((t) => t.isManual && t.toPartyType === "Company");
    } else if (props.mode === "debit") {
      return types.filter((t) => t.isManual && t.fromPartyType === "Company");
    }
    return types;
  };

  const getTransactionTypeOptions = (): TransactionTypeOption[] => {
    const validTransactionTypes = getValidTransactionTypes(
      props.transactionTypes
    );
    const options = validTransactionTypes.map((tt) => {
      return {
        value: tt.id,
        label: tt.description,
        fromPartyType: tt.fromPartyType,
        toPartyType: tt.toPartyType,
        defaultPoints: tt.defaultPoints,
        defaultNote: tt.defaultNote,
      };
    });

    return options;
  };

  const options = getTransactionTypeOptions();
  const currentSelection = options.find((tt) => tt.value === props.value?.id);

  return (
    <FormControl
      variant="filled"
      className={props.className}
      //   className={`${classes.formControl} ${classes.firstTwoCols}`}
    >
      <Autocomplete
        options={options as any}
        loading={false}
        onChange={(event: any, option: any) => {
          const transactionType = props.transactionTypes.find(
            (tt) => tt.id === option.value
          );

          if (transactionType) {
            props.onChanged(transactionType);
          }
        }}
        value={currentSelection}
        getOptionSelected={(option, value) => option.value === value.value}
        getOptionLabel={(option) => {
          if (typeof option.label === "string") {
            return option.label;
          }

          return (option as any).label.label;
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            autoFocus
            label="Transaction Type"
            placeholder="Select transaction type"
            style={{ margin: 0 }}
            InputProps={{
              ...params.InputProps,
            }}
            margin="dense"
          />
        )}
      />
      <FormHelperText
        variant="standard"
        className={props.errorClassName}
        // className={transTypeErr ? classes.error : classes.noError}
      >
        {props.errorMessage}
      </FormHelperText>
    </FormControl>
  );
};

export default TransactionTypeSelect;
