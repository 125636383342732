import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Badge from "@material-ui/core/Badge";
import StarIcon from "@material-ui/icons/Star";
import { Color, Globals } from "csstype";
import Typography from "@material-ui/core/Typography";
import { colorForPrimaryThings } from "utils/colorPalette";
import clsx from "clsx";

type Props<T extends { id: string }> = {
  renderAvatarIcon?: (row: T) => JSX.Element;
  onClick?: (row: T) => void;
  getSecondaryText?: (row: T) => string;
  getPrimaryText?: (row: T) => string;
  getIsNotActive?: (row: T) => boolean;
  getIsPrimary?: (row: T) => boolean;
  renderListItemDetails?: (row: T) => JSX.Element;
  row: T;
  onViewDetails?: (row: T, title: string) => void;
  canViewDetails?: (row: T) => boolean;
  detailsTitle?: string;
  avatarColor?: Globals | Color;
  renderListItemContent?: (row: T) => JSX.Element;
};

const MyGenericListItem = <T extends { id: string }>(props: Props<T>) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      primaryIndicator: {
        color: colorForPrimaryThings,
      },
      primaryText: {
        fontWeight: "bold",
      },
      secondaryText: {
        color: theme.palette.grey[600],
      },
      avatarRoot: {
        backgroundColor: props.avatarColor || theme.palette.grey[400],
      },
      inactive: {
        textDecoration: "line-through",
      },
      customListItem: {
        fontSize: 12,
        display: "flex",
        width: "100%",
        flexDirection: "column",
        "& div.list-item-row": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        },
      },
    })
  );

  const classes = useStyles();

  const inactive = props.getIsNotActive && props.getIsNotActive(props.row);

  return (
    <ListItem
      className={clsx({
        [classes.inactive]: inactive,
      })}
      classes={{
        root: props.renderListItemContent ? classes.customListItem : undefined,
      }}
      divider
      onClick={() => {
        if (props.onViewDetails) {
          const canViewDetails =
            props.canViewDetails === undefined ||
            props.canViewDetails(props.row);
          if (canViewDetails) {
            props.onViewDetails(props.row, props.detailsTitle ?? "Details");
          }
        }
        props.onClick && props.onClick(props.row);
      }}
      // NOTE: Keep getting error here w/o casting as any
      button={Boolean(props.renderListItemDetails) as any}
      key={props.row.id}
      dense
      disableGutters
    >
      {props.renderAvatarIcon && !props.getIsPrimary && (
        <ListItemAvatar>
          <Avatar className={classes.avatarRoot}>
            {props.renderAvatarIcon(props.row)}
          </Avatar>
        </ListItemAvatar>
      )}
      {props.renderAvatarIcon && props.getIsPrimary && (
        <ListItemAvatar>
          <Badge
            badgeContent={
              props.getIsPrimary(props.row) ? (
                <StarIcon className={classes.primaryIndicator} />
              ) : null
            }
          >
            <Avatar className={classes.avatarRoot}>
              {props.renderAvatarIcon(props.row)}
            </Avatar>
          </Badge>
        </ListItemAvatar>
      )}
      {!props.renderAvatarIcon &&
        props.getIsPrimary &&
        props.getIsPrimary(props.row) && (
          <StarIcon
            style={{ marginRight: 4 }}
            className={classes.primaryIndicator}
          />
        )}
      {props.renderListItemContent && props.renderListItemContent(props.row)}
      {props.getPrimaryText && !props.renderListItemContent && (
        <ListItemText
          classes={{ primary: classes.primaryText }}
          primary={props.getPrimaryText(props.row)}
          secondary={
            props.getSecondaryText && (
              <Typography className={classes.secondaryText} variant="body2">
                {props.getSecondaryText(props.row)}
              </Typography>
            )
          }
        />
      )}
    </ListItem>
  );
};

export default MyGenericListItem;
