import React from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ReactDOM from "react-dom";
import withWidth from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

type Props = {
  onClick: () => void;
  width: Breakpoint;
  useEditIcon?: boolean;
};

const AddFab = (props: Props) => {
  const addPortal = document.getElementById("add-button-portal");

  // eslint-disable-next-line
  const [hack, setHack] = React.useState(false);

  window.setTimeout(() => setHack(true), 500);

  const renderAddButton = () => {
    if (!addPortal) throw Error("no add portal");

    return ReactDOM.createPortal(
      props.width !== "xs" && (
        <Fab color="secondary" onClick={props.onClick}>
          {props.useEditIcon ? <EditIcon /> : <AddIcon />}
        </Fab>
      ),
      addPortal
    );
  };

  if (addPortal) {
    return <div>{renderAddButton()}</div>;
  }

  return <div />;
};

export default withWidth()(AddFab);
