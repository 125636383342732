import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ReplinkInfoForm from "../Config/ReplinkInfoForm";
import { IReplinkInfo } from "services/api/types";
import api from "services/api";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import * as yup from "yup";
import { Formik } from "formik";

type Props = {
  handleClose: () => void;
};

const ReplinkInfoModal = (props: Props) => {
  const [info, setInfo] = React.useState<IReplinkInfo | undefined>();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
    })
  );

  const classes = useStyles();

  const schema = yup.object().shape({
    companyName: yup.string().required("Required"),
    programName: yup.string().required("Required"),
    address1: yup.string().required("Required"),
    zip: yup.string().required("Required"),
    city: yup.string().required("Required"),
    state: yup.string().required("Required"),
    emailAddress: yup.string().email().required("Required"),
    shipToEmail: yup.string().email().required("Required"),
    phoneNumber: yup.string().required("Required"),
  });

  React.useEffect(() => {
    const load = async () => {
      const result = await api.admin.replinkInfo.getReplinkInfo();
      setInfo(result);
    };

    load();
  }, []);

  const handleSubmit = async (data: IReplinkInfo) => {
    await api.admin.replinkInfo.updateReplinkInfo(data);
    props.handleClose();
  };

  if (!info) return <div />;

  return (
    <Formik
      initialValues={info!}
      validationSchema={schema}
      onSubmit={handleSubmit}
      render={(formikProps) => {
        return (
          <ResponsiveModalShell
            handleSave={() => formikProps.submitForm()}
            saveButtonCaption="Save"
            handleClose={props.handleClose}
            title="Update Replink Info"
          >
            <div className={classes.root}>{info && <ReplinkInfoForm />}</div>
          </ResponsiveModalShell>
        );
      }}
    />
  );
};

export default ReplinkInfoModal;
