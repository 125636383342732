import { apiClient } from "services/api/client";
import { getFileName } from "services/api/modules/helpers";
import {
  IMemberDirectoryQuery,
  IMemberDirectoryResult,
  IMemberExportQuery,
  IListedMember,
  IMemberReferralData,
} from "services/api/types";

const BASE_URL = "sponsors/members";

export const getList = async (query: IMemberDirectoryQuery) => {
  const url = `${BASE_URL}/list`;
  const response = await apiClient.get<IMemberDirectoryResult>(url, {
    params: query,
  });

  return response.data;
};

export const getNewest = async () => {
  const url = `${BASE_URL}/newest`;
  const response = await apiClient.get<IListedMember[]>(url);
  return response.data;
};

export const downloadMemberList = async (query: IMemberExportQuery) => {
  const url = `${BASE_URL}/export`;
  const response = await apiClient.get(url, {
    responseType: "blob",
    params: query,
  });

  const fileName = getFileName(response, "Member Export.xlsx");
  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const createReferral = async (command: IMemberReferralData) => {
  const url = "sponsors/memberReferrals";
  const response = await apiClient.post(url, command);
  return response;
};
