import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import { IExistingGenericPayment } from "services/api/types";
import DollarPointsInput from "components/Inputs/DollarPointsInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "baseline",
    },
    formControl: {
      margin: theme.spacing(1),
    },
    buttons: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      marginTop: theme.spacing(4),
    },
    error: {
      color: theme.palette.error.main,
    },
  })
);

type PointsPaymentFormProps = {
  onCancel: () => void;
  onSave: (points: number, dollarAmount: number, date: Date) => void;
  payment?: IExistingGenericPayment;
  pointBalance?: number;
  mode: "Add" | "Edit";
  costPerPoint: number;
  customAmountValidator?: (dollarAmount: number) => {
    passed: boolean;
    errorMessage?: string;
  };
};

export default function PointsPaymentForm(
  componentProps: PointsPaymentFormProps
) {
  const classes = useStyles();

  const [touched, setTouched] = React.useState(false);
  const [points, setPoints] = React.useState<number | undefined>();
  const [dollars, setDollars] = React.useState<number | undefined>();
  const [date, setDate] = React.useState<Date | null>(new Date());
  const [pointsError, setPointsError] = React.useState<string | undefined>(
    undefined
  );
  const [dollarsError, setDollarsError] = React.useState<string | undefined>(
    undefined
  );
  const [dateError, setDateError] = React.useState<string | undefined>(
    undefined
  );

  React.useEffect(() => {
    validate();
    // eslint-disable-next-line
  }, [dollars, points, date]);

  const validate = () => {
    let foundError = false;

    if (!date) {
      setDateError("This is required");
      foundError = true;
    } else {
      setDateError(undefined);
    }

    if (dollars === undefined) {
      setDollarsError("This is required");
      foundError = true;
    } else if (dollars === 0) {
      setDollarsError("Amount must be greater than zero");
      foundError = true;
    } else {
      if (componentProps.customAmountValidator) {
        const validationResult = componentProps.customAmountValidator(dollars);
        if (validationResult.passed) {
          setDollarsError(undefined);
        } else {
          setDollarsError(validationResult.errorMessage!);
          foundError = true;
        }
      }
      setDollarsError(undefined);
    }

    if (points === undefined) {
      setPointsError("This is required");
      foundError = true;
    } else if (points === 0) {
      setPointsError("Points must be greater than zero");
      foundError = true;
    } else {
      setPointsError(undefined);
    }

    return !foundError;
  };

  const handleSave = () => {
    setTouched(true);
    const valid = validate();
    if (valid) {
      componentProps.onSave(points!, dollars!, date!);
    }
  };

  return (
    <div>
      <div className={classes.container}>
        <FormControl
          margin="dense"
          variant="outlined"
          className={classes.formControl}
        >
          <KeyboardDatePicker
            format="MM/dd/yy"
            label="Date"
            error={touched && dateError !== undefined}
            inputVariant="outlined"
            margin="dense"
            value={date}
            onChange={(e) => setDate(e)}
          />
          <FormHelperText className={classes.error} variant="standard">
            {touched ? dateError : ""}
          </FormHelperText>
        </FormControl>
        <DollarPointsInput
          onChange={(evt) => {
            setDollars(evt.dollars);
            setPoints(evt.points);
          }}
          dollarValue={dollars}
          pointValue={points}
          allowNegative={false}
          dollarsPerPoint={componentProps.costPerPoint}
          dollarsError={dollarsError}
          pointsError={pointsError}
          showDollarsError={touched && dollarsError !== undefined}
          showPointsError={touched && pointsError !== undefined}
        />
      </div>
      <div className={classes.buttons}>
        <Button color="secondary" onClick={handleSave}>
          Save
        </Button>
        <Button onClick={componentProps.onCancel} color="secondary">
          Cancel
        </Button>
      </div>
    </div>
  );
}
