import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";

type Props = {
  onQueryCleared: () => void;
  onEnterPressed?: () => void;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  showBorder: boolean;
  onFocus?: (evt: React.FocusEvent<HTMLInputElement>) => void;
};

const SearchBox = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      searchBoxRoot: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.common.white,
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: "100%",
        whiteSpace: "nowrap",
        border: props.showBorder ? "1px solid gainsboro" : "none",
      },
      inputInput: {
        padding: theme.spacing(0, 1),
        transition: theme.transitions.create("width"),
        width: "100%",
        minHeight: 34,
        [theme.breakpoints.up("md")]: {
          width: 200,
        },
      },
      inputRoot: {
        margin: 0,
      },
      clearButton: {
        float: "right",
        marginTop: "3px",
        marginRight: "4px",
        zIndex: 1000,
      },
    })
  );

  const classes = useStyles();

  const handleKeyDown = (evt: React.KeyboardEvent) => {
    if (evt.key === "Escape") {
      props.onQueryCleared();
    }

    if (evt.key === "Enter" && props.onEnterPressed) {
      props.onEnterPressed();
    }
  };

  const handleSearchChange = (evt: React.ChangeEvent) => {
    const value = (evt.currentTarget as any).value;
    props.onChange(value);
  };

  const handleOnFocus = (evt: React.FocusEvent<HTMLInputElement>) => {
    if (props.onFocus) {
      props.onFocus(evt);
    }
  };

  return (
    <div onKeyDown={handleKeyDown} className={classes.searchBoxRoot}>
      <IconButton
        className={classes.clearButton}
        size="small"
        onClick={() => {
          props.onQueryCleared();
        }}
      >
        <ClearIcon />
      </IconButton>
      <InputBase
        placeholder={props.placeholder || "Search (Ctrl+Space)"}
        value={props.value}
        autoFocus={false}
        margin="dense"
        id="global-search-box"
        onFocus={handleOnFocus}
        onChange={handleSearchChange}
        classes={{
          input: classes.inputInput,
          root: classes.inputRoot,
        }}
        inputProps={{ "aria-label": "search", autoComplete: "off" }}
      />
    </div>
  );
};

export default SearchBox;
