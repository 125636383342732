import { action, observable } from "mobx";
import {
  ModalComponentProps,
  ModalType,
} from "modals/containers/GlobalModal/GlobalModal";
import api from "services/api";
import {
  IContactDetailed,
  IPurchaserInfo,
  ISponsorDirectoryCompany,
  IHostCompanyInfo,
  EventAttendeeInfo,
} from "services/api/types";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";

type ModalPayload<T extends ModalType> = {
  type: T;
  props?: ModalComponentProps<T>;
  persisted?: boolean;
};

export class UiStore {
  @observable
  public mobileDialogTitle = "";

  @observable
  public isSidebarOpen = false;

  @observable
  public isFlyoutOpen = false;

  @observable
  public isFlyoutLoading = false;

  @observable
  public pendingAjaxCalls = 0;

  @observable
  public hostCompanyInfo: IHostCompanyInfo | undefined;

  @observable
  public contactDetails: IContactDetailed | undefined;

  @observable
  public sponsorInfo: ISponsorDirectoryCompany | undefined;

  @observable
  public eventAttendeeInfo: EventAttendeeInfo | undefined;

  @observable
  public memberInfo: IPurchaserInfo | undefined;

  @observable
  public memberInfoRedemptionsExpanded = false;

  @observable
  public memberInfoEventsExpanded = false;

  @observable
  public memberInfoGrandDestinationsExpanded = false;

  @observable
  public memberUsedCategoriesExpanded = false;

  @observable
  public isModalOpen = false;

  @observable
  public modelWidth: Breakpoint = "md";

  @observable
  public modal?: ModalPayload<ModalType>;

  @action.bound
  public setFlyoutOpen(isFlyoutOpen: boolean): void {
    this.isFlyoutOpen = isFlyoutOpen;
  }

  @action.bound
  public setMemberInfoRedemptionsExpanded(expanded: boolean) {
    this.memberInfoRedemptionsExpanded = expanded;
  }

  @action.bound
  public setMemberUsedCategoriesExpanded(expanded: boolean) {
    this.memberUsedCategoriesExpanded = expanded;
  }

  @action.bound
  public setMemberInfoGrandDestinationsExpanded(expanded: boolean) {
    this.memberInfoGrandDestinationsExpanded = expanded;
  }

  @action.bound
  public setMemberInfoEventsExpanded(expanded: boolean) {
    this.memberInfoEventsExpanded = expanded;
  }

  @action.bound
  public incrementPendingAjaxCalls(): void {
    this.pendingAjaxCalls += 1;
  }

  @action.bound
  public decrementPendingAjaxCalls(): void {
    this.pendingAjaxCalls -= 1;
  }

  @action.bound
  public showEventAttendees(
    eventName: string,
    companyName: string,
    attendeeNames: string[]
  ) {
    this.isFlyoutLoading = false;
    this.isFlyoutOpen = true;
    this.clear();

    const data: EventAttendeeInfo = {
      attendees: attendeeNames,
      eventName,
      companyName,
    };

    this.eventAttendeeInfo = data;
  }

  @action.bound
  public async ensureHostCompanyInfoIsLoaded() {
    if (this.hostCompanyInfo) return;

    const result = await api.public.getHostCompanyInfo();
    this.hostCompanyInfo = result;
  }

  @action.bound
  public async refreshHostCompanyInfo() {
    this.hostCompanyInfo = undefined;
    this.ensureHostCompanyInfoIsLoaded();
  }

  @action.bound
  public async loadContactDetails(companyId: string, contactId: string) {
    this.isFlyoutLoading = true;
    this.isFlyoutOpen = true;
    this.clear();

    const result = await api.shared.company.getCompanyContact({
      companyId,
      id: contactId,
    });

    this.contactDetails = result.data.companyContact;

    this.isFlyoutLoading = false;
  }

  @action.bound
  public async loadSponsorInfo(companyId: string) {
    this.isFlyoutLoading = true;
    this.isFlyoutOpen = true;
    this.clear();

    const result = await api.shared.sponsors.getSponsorInfo(companyId);
    this.sponsorInfo = result.data;
    this.isFlyoutLoading = false;
  }

  @action.bound
  public async loadPurchaserInfo(companyId: string) {
    this.isFlyoutLoading = true;
    this.isFlyoutOpen = true;
    this.clear();
    const result = await api.sponsors.purchasers.getInfo(companyId);
    this.memberInfo = result;
    this.isFlyoutLoading = false;
  }

  @action.bound
  private clear() {
    this.sponsorInfo = undefined;
    this.contactDetails = undefined;
    this.eventAttendeeInfo = undefined;
  }

  @action.bound
  public showModal<T extends ModalType>(
    modal?: ModalPayload<T>,
    maxWidth: Breakpoint = "md"
  ): void {
    this.isModalOpen = true;
    this.modal = modal;
    this.modelWidth = maxWidth;
  }

  @action.bound
  public closeModal() {
    this.isModalOpen = false;
    this.modal = undefined;
  }
}

export default new UiStore();
