import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

type Props = {
  title: string;
  children: React.ReactNode;
  isExpanded: boolean;
  onIsExpandedChanged: (isExpanded: boolean) => void;
};

const CollapsibleMobileGroupHeader = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      mobileGroupHeaderRoot: {
        marginBottom: theme.spacing(2),
      },
      title: {
        // margin: theme.spacing(4, 0, 2, 0),
        display: "block",
        padding: "3px 6px",
        cursor: "pointer",
      },
      content: {
        paddingLeft: theme.spacing(1),
      },
    })
  );

  const classes = useStyles();

  return (
    <div className={classes.mobileGroupHeaderRoot}>
      <Typography
        classes={{
          root: classes.title,
        }}
        variant="caption"
        color="primary"
        onClick={() => props.onIsExpandedChanged(!props.isExpanded)}
      >
        {props.title}
        <IconButton
          size="small"
          onClick={() => props.onIsExpandedChanged(!props.isExpanded)}
        >
          {props.isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Typography>
      <Collapse in={props.isExpanded}>
        <div className={classes.content}>{props.children}</div>
      </Collapse>
    </div>
  );
};

export default CollapsibleMobileGroupHeader;
