import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { EventAttendeeInfo } from "services/api/types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

type Props = {
  attendeeInfo: EventAttendeeInfo;
  isNested: boolean;
  onClose: () => void;
};

const EventAttendees = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      heading: {
        marginBottom: theme.spacing(2),
      },
      button: {
        marginBottom: theme.spacing(2),
      },
    })
  );

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography
        className={classes.heading}
        variant="h4"
      >{`${props.attendeeInfo.eventName} attendees for ${props.attendeeInfo.companyName}`}</Typography>
      <Button
        variant="contained"
        className={classes.button}
        startIcon={<ChevronLeft />}
        size="small"
        onClick={() => props.onClose()}
      >
        Back to Member Info
      </Button>
      {props.attendeeInfo.attendees.map((name, index) => {
        return <Typography key={index}>{name}</Typography>;
      })}
      {props.attendeeInfo.attendees.length === 0 && (
        <div>(no attendees found)</div>
      )}
    </div>
  );
};

export default EventAttendees;
