import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";

type Props = {
  touched: boolean | undefined;
  error: string | undefined;
  handleChange: (date: Date) => void;
  value: string;
};

const EnrollDateField = (props: Props) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <FormControl margin="dense" variant="outlined">
        <KeyboardDatePicker
          format="MM/dd/yy"
          label="Enroll Date"
          id="enrollDate"
          inputVariant="outlined"
          margin="dense"
          name="enrollDate"
          value={props.value}
          onChange={(e) => props.handleChange(e as any)}
        />
        <FormHelperText style={{ color: "red" }} variant="standard">
          {props.touched && props.error}
        </FormHelperText>
      </FormControl>
    </MuiPickersUtilsProvider>
  );
};

export default EnrollDateField;
