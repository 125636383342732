import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import { formatNumber } from "utils/number";
import { IShoppingCartItem } from "services/api/types";
import Typography from "@material-ui/core/Typography";
import { withRouter, RouteComponentProps } from "react-router-dom";
import NoDataMessage from "components/Shell/NoDataMessage";
import { paths } from "routes/Paths";

type Props = {
  badgeCount: number;
  cartItems: IShoppingCartItem[];
  removeItem: (productId: string) => void;
  totalPoints: number;
  availablePoints: number;
} & RouteComponentProps;

const CartIcon = (props: Props) => {
  const [popperAnchor, setPopperAnchor] = React.useState<HTMLElement | null>(
    null
  );
  const [isPopperOpen, setIsPopperOpen] = React.useState(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      cartItem: {
        display: "grid",
        gridTemplateColumns: "auto 1fr auto",
        borderBottom: "1px solid gainsboro",
        fontSize: "10pt",
        gridGap: "5px",
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
        "& p": {
          margin: 0,
        },
      },
      productImage: {
        width: "50px",
      },
      popperContent: {
        padding: theme.spacing(2),
      },
      productName: {
        fontWeight: "bold",
        fontSize: "10pt",
      },
      action: {
        marginTop: theme.spacing(2),
        display: "flex",
        flexGrow: 1,
        justifyContent: "space-between",
      },
      cartTotal: {
        textAlign: "right",
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
      },
      itemBody: {
        fontSize: "10pt",
      },
      productBody: {
        marginLeft: "1em",
      },
    })
  );

  const handleCartClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setPopperAnchor(event.currentTarget as any);
    setIsPopperOpen(!isPopperOpen);
  };

  const renderItems = () => {
    return (
      <div>
        {props.cartItems.map((i) => (
          <Typography className={classes.cartItem} key={i.product.id}>
            <img
              className={classes.productImage}
              src={i.product.imageUrl}
              alt={i.product.productName}
            />
            <Typography noWrap className={classes.productBody}>
              <Typography noWrap className={classes.productName}>
                {i.product.productName}
              </Typography>
              <Typography noWrap className={classes.itemBody}>
                Quantity: {i.quantity}
              </Typography>
              <Typography noWrap className={classes.itemBody}>
                Points: {formatNumber(i.quantity * i.product.points)}
              </Typography>
            </Typography>
            <IconButton onClick={() => props.removeItem(i.product.id)}>
              <DeleteIcon />
            </IconButton>
          </Typography>
        ))}
      </div>
    );
  };

  const renderNoItemsMessage = () => {
    if (props.cartItems.length === 0) {
      return (
        <NoDataMessage
          disableTopMargin={true}
          message="There are no items in your cart"
        />
      );
    }

    return undefined;
  };

  const renderPopover = () => {
    return (
      <Popover
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        open={isPopperOpen}
        elevation={10}
        onClose={() => {
          setIsPopperOpen(false);
        }}
        anchorEl={popperAnchor}
      >
        <Paper className={classes.popperContent}>
          {renderItems()}
          {renderNoItemsMessage()}
          {props.cartItems.length > 0 ? (
            <>
              <Typography className={classes.cartTotal}>
                <strong>Total Points:</strong> {formatNumber(props.totalPoints)}
              </Typography>
              {props.totalPoints > props.availablePoints ? (
                <Typography color="error">
                  You don't have enough points to purchase these items
                </Typography>
              ) : undefined}
              <div className={classes.action}>
                <Button
                  onClick={() => {
                    setIsPopperOpen(false);
                    props.history.push(`${paths.app.members.root}/checkout`);
                  }}
                  size="small"
                  variant="contained"
                  color="secondary"
                  disabled={props.totalPoints > props.availablePoints}
                >
                  View Cart
                </Button>
              </div>
            </>
          ) : undefined}
        </Paper>
      </Popover>
    );
  };

  const classes = useStyles();
  return (
    <div>
      <IconButton edge="end" onClick={handleCartClick} color="inherit">
        <Badge
          overlap="rectangular"
          color="secondary"
          badgeContent={props.badgeCount}
        >
          <ShoppingCart />
        </Badge>
      </IconButton>
      {renderPopover()}
    </div>
  );
};

export default withRouter(CartIcon);
