import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import eventsStore from "stores/admin/events";
import FormControl from "@material-ui/core/FormControl";
import { formatNumber } from "utils/number";
import { MaskedNumberInput } from "areas/Shared/components/maskedInputs/MaskedNumberInput";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

type Props = {
  handleClose: () => void;
  eventPayerId: string;
  onInvoiceCreated: (invoiceId: string) => void;
  currentBalanceDue: number;
  isFirstInvoice: boolean;
  outstandingInvoiceAmount: number;
};

const CreateInvoiceModal = (props: Props) => {
  const [invoiceAmount, setInvoiceAmount] = React.useState(
    props.currentBalanceDue - props.outstandingInvoiceAmount
  );
  const [message, setMessage] = React.useState("");
  const [dateDue, setDateDue] = React.useState<Date | null>(
    moment(new Date()).add(30, "days").toDate()
  );
  const [dueOnReceipt, setDueOnReceipt] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        minWidth: "400px",
      },
      textField: {
        minWidth: "400px",
      },
      radios: {
        marginLeft: theme.spacing(2),
      },
      radioHeader: {
        fontWeight: "bold",
        marginBottom: "1em",
      },
      datePicker: {
        marginLeft: theme.spacing(2),
        transform: "translateY(8px)",
      },
      checkbox: {
        transform: "translate(13px, 15px)",
      },
    })
  );

  const classes = useStyles();

  const handleMessageChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(evt.currentTarget.value);
  };

  const handleCreateClicked = async () => {
    if (!dueOnReceipt && !dateDue) {
      alert("Please enter a due date or check 'Due on Receipt'");
      return;
    }
    setIsSaving(true);
    const newInvoice = await eventsStore.addInvoice({
      amount: invoiceAmount,
      message,
      eventPayerId: props.eventPayerId,
      dateDue: dateDue ?? undefined,
    });

    setIsSaving(false);

    if (newInvoice) {
      props.onInvoiceCreated(newInvoice.id);
    }

    props.handleClose();
  };

  const handleDollarsChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const adjustedValue = evt.target.value.replace("$", "");
    const dollarsFloat = parseFloat(adjustedValue);
    setInvoiceAmount(dollarsFloat);
  };

  return (
    <ResponsiveModalShell
      handleClose={props.handleClose}
      handleSave={handleCreateClicked}
      disableSaveButton={isSaving}
      title="Create Invoice"
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div>
          <FormControl>
            <TextField
              fullWidth={true}
              variant="outlined"
              value={formatNumber(invoiceAmount, 2) ?? ""}
              label={"Invoice Amount (Dollars)"}
              onChange={handleDollarsChange}
              margin="dense"
              InputProps={{
                inputComponent: MaskedNumberInput as any,
                inputProps: {
                  allowNegative: false,
                  prefix: "$",
                  decimalScale: 2,
                },
              }}
            />
          </FormControl>
          <FormControl className={classes.datePicker}>
            <KeyboardDatePicker
              disabled={dueOnReceipt}
              style={{ margin: 0 }}
              format="MM/dd/yyyy"
              label="Due Date"
              required={false}
              inputVariant="outlined"
              onErrorCapture={(evt) => alert("Hey")}
              margin="dense"
              value={dateDue}
              onChange={(e) => setDateDue(e)}
            />
          </FormControl>
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                checked={dueOnReceipt}
                onChange={(e) => {
                  setDueOnReceipt(e.target.checked);
                  setDateDue(new Date());
                }}
              />
            }
            label="Due on Receipt"
          />
        </div>
        <div>
          <TextField
            style={{ width: "100%" }}
            className={classes.textField}
            value={message}
            variant="outlined"
            margin="dense"
            autoFocus
            label="Invoice Message"
            onChange={handleMessageChanged}
            multiline={true}
            minRows={4}
          />
        </div>
      </MuiPickersUtilsProvider>
    </ResponsiveModalShell>
  );
};

export default CreateInvoiceModal;
