import { Form, Formik, FormikActions, FormikProps } from "formik";
import React from "react";
import { defaultErrorHandler } from "services/api/errors";
import {
  ICompanyCategory,
  ICompanyCategoryCommand,
  ICategory,
  IUnit,
} from "services/api/types";
import enumerableStore from "stores/enumerable";
import CategoryDropdown from "./CategoryDropdown";
import RateDescriptionField from "./RateDescriptionField";
import RateField from "./RateField";
import ShowOnInternetCheckbox from "./ShowOnInternetCheckbox";
import ShowOnReportCheckbox from "./ShowOnReportCheckbox";
import IsPrimaryCategoryCheckbox from "./IsPrimaryCategoryCheckbox";
import UnitsDropdown from "./UnitsDropdown";
import Grid from "@material-ui/core/Grid";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import { Observer } from "mobx-react";

type FormData = {
  categoryId: string;
  rate: number;
  rateDesc: string;
  showOnInternet: boolean;
  showOnReport: boolean;
  isPrimary: boolean;
  unitId: string;
};

type Props = {
  companyCategory?: ICompanyCategory;
  existingCategoryCount?: number;
  companyId: string;
  handleCancel: () => void;
  handleSubmit: (payload: ICompanyCategoryCommand) => void;
  formType: "add" | "update";
};

const CategoryForm = (props: Props) => {
  React.useEffect(() => {
    enumerableStore.ensureCategoriesFetched();
    enumerableStore.ensureUnitsFetched();
  }, []);

  const handleFormSubmit = async (
    values: FormData,
    actions: FormikActions<FormData>
  ) => {
    const companyCategoryPayload = {
      id: props.companyCategory?.id,
      categoryId: values.categoryId,
      rate: values.rate as number,
      rateDesc: values.rateDesc,
      showOnInternet: values.showOnInternet,
      showOnReport: values.showOnReport,
      companyId: props.companyId,
      isPrimary: values.isPrimary,
      unitId: values.unitId,
    };

    try {
      await props.handleSubmit(companyCategoryPayload);
    } catch (err) {
      defaultErrorHandler(err);
    }
    actions.setSubmitting(false);
  };

  const { companyCategory } = props;

  const renderCategoryDropdown = (
    formikProps: FormikProps<FormData>,
    categories: ICategory[]
  ) => {
    return (
      <CategoryDropdown
        name="categoryId"
        label="Category"
        value={formikProps.values.categoryId}
        error={formikProps.errors.categoryId}
        touched={formikProps.touched.categoryId}
        handleChange={formikProps.handleChange}
        categories={categories}
      />
    );
  };

  const renderRateField = (formikProps: FormikProps<FormData>) => {
    return (
      <RateField
        value={formikProps.values.rate}
        error={formikProps.errors.rate}
        touched={formikProps.touched.rate}
        handleChange={formikProps.handleChange}
      />
    );
  };

  const renderRateDescriptionField = (formikProps: FormikProps<FormData>) => {
    return (
      <RateDescriptionField
        value={formikProps.values.rateDesc}
        touched={formikProps.touched.rateDesc}
        error={formikProps.errors.rateDesc}
        handleChange={formikProps.handleChange}
      />
    );
  };

  const renderUnitsDropdown = (
    formikProps: FormikProps<FormData>,
    units: IUnit[]
  ) => {
    return (
      <UnitsDropdown
        units={units}
        handleChange={formikProps.handleChange}
        touched={formikProps.touched.unitId}
        error={formikProps.errors.unitId}
        value={formikProps.values.unitId}
      />
    );
  };

  const renderShowOnInternetCheckbox = (formikProps: FormikProps<FormData>) => {
    return (
      <ShowOnInternetCheckbox
        value={formikProps.values.showOnInternet}
        handleChange={formikProps.handleChange}
      />
    );
  };

  const renderShowOnReportCheckbox = (formikProps: FormikProps<FormData>) => {
    return (
      <ShowOnReportCheckbox
        value={formikProps.values.showOnReport}
        handleChange={formikProps.handleChange}
      />
    );
  };

  const renderIsPrimaryCategoryCheckbox = (
    formikProps: FormikProps<FormData>
  ) => {
    return (
      <IsPrimaryCategoryCheckbox
        value={formikProps.values.isPrimary}
        handleChange={formikProps.handleChange}
      />
    );
  };

  const initialValues: FormData = {
    categoryId: companyCategory?.category?.id ?? "",
    rate: companyCategory ? companyCategory.rate : 1,
    rateDesc: companyCategory?.rateDesc ?? "",
    showOnInternet: companyCategory?.showOnInternet ?? true,
    showOnReport: companyCategory?.showOnReport ?? true,
    isPrimary:
      companyCategory?.isPrimary ?? props.existingCategoryCount === 0
        ? true
        : false,
    unitId: companyCategory?.unit?.id ?? "",
  };

  return (
    <Observer
      render={() => {
        const categories = enumerableStore.getEnum("categories");
        const units = enumerableStore.getEnum("units");

        if (
          enumerableStore.isLoading("categories") ||
          enumerableStore.isLoading("units")
        ) {
          return <div />;
        }

        return (
          <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
            {(formikProps) => (
              <Form>
                <ResponsiveModalShell
                  handleClose={props.handleCancel}
                  title={
                    props.formType === "add"
                      ? "Add Category"
                      : "Update Category"
                  }
                  disableSaveButton={formikProps.isSubmitting}
                  handleSave={() => formikProps.submitForm()}
                >
                  <Grid alignItems="center" container spacing={1}>
                    <Grid item xs={12}>
                      {renderCategoryDropdown(formikProps, categories)}
                    </Grid>
                    <Grid item xs={6}>
                      {renderRateField(formikProps)}
                    </Grid>
                    <Grid item xs={6}>
                      {renderUnitsDropdown(formikProps, units)}
                    </Grid>
                    <Grid item xs={12}>
                      {renderRateDescriptionField(formikProps)}
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      {renderShowOnInternetCheckbox(formikProps)}
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      {renderShowOnReportCheckbox(formikProps)}
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      {renderIsPrimaryCategoryCheckbox(formikProps)}
                    </Grid>
                  </Grid>
                </ResponsiveModalShell>
              </Form>
            )}
          </Formik>
        );
      }}
    />
  );
};

export default CategoryForm;
