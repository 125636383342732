import React from "react";
import TextField from "@material-ui/core/TextField";
import { MaskedNumberInput } from "areas/Shared/components/maskedInputs/MaskedNumberInput";

type Props = {
  value: number | undefined;
  touched: boolean | undefined;
  error: string | undefined;
  handleChange: (e: React.ChangeEvent<any>) => void;
};

const MonthlyMinimumField = (props: Props) => {
  return (
    <TextField
      variant="outlined"
      id="rate"
      margin="dense"
      label="Rate"
      fullWidth
      value={props.value}
      onChange={props.handleChange}
      error={props.touched && Boolean(props.error)}
      name="rate"
      placeholder="Rate"
      inputProps={{
        min: "1",
        step: "1",
      }}
      InputProps={{
        inputComponent: MaskedNumberInput as any,
        inputProps: {
          allowNegative: false,
          prefix: "",
          decimalScale: 0,
        },
      }}
      helperText={props.touched && props.error}
    />
  );
};

export default MonthlyMinimumField;
