import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ISponsorDirectoryCompany } from "services/api/types";
import NoDataMessage from "components/Shell/NoDataMessage";
import MobileGroupHeader from "components/Shell/MobileGroupHeader";
import LocationsInfoBlock from "areas/Shared/components/InfoBlocks/LocationsInfoBlock";
import MarketingTextInfoBlock from "areas/Shared/components/InfoBlocks/MarketingTextInfoBlock";
import CompanyInfoHeader from "areas/Shared/components/InfoBlocks/CompanyInfoHeader";
import PrimaryContactBlock from "areas/Shared/components/InfoBlocks/PrimaryContactBlock";
import BrandsInfoBlock from "areas/Shared/components/InfoBlocks/BrandsInfoBlock";
import ProductsInfoBlock from "areas/Shared/components/InfoBlocks/ProductsInfoBlock";
import CategoriesInfoBlock from "areas/Shared/components/InfoBlocks/CategoriesInfoBlock";

type Props = {
  sponsorInfo: ISponsorDirectoryCompany | undefined;
};

const SponsorInfo = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        paddingBottom: theme.spacing(4),
      },
      rate: {
        marginBottom: theme.spacing(1),
      },
    })
  );

  const classes = useStyles();

  if (!props.sponsorInfo) {
    return <div />;
  }

  const { name, identifier, website, marketingInformation, imageUrl } =
    props.sponsorInfo;

  const renderRates = () => {
    if (!props.sponsorInfo) return undefined;
    if (props.sponsorInfo.rates.length === 0) {
      return <NoDataMessage message="No rates found"></NoDataMessage>;
    }

    const formattedRates: string[] = [];
    props.sponsorInfo.rates.forEach((rate) => {
      const formatted = `${rate.rate} points/${rate.unit} in ${rate.category} (${rate.rateDesc})`;
      if (formattedRates.indexOf(formatted) < 0) {
        formattedRates.push(formatted);
      }
    });

    return formattedRates.map((rate, index) => {
      return (
        <div className={classes.rate} key={index}>
          {rate}
        </div>
      );
    });
  };

  return (
    <div className={classes.root}>
      <CompanyInfoHeader
        companyName={name}
        identifier={identifier}
        website={website}
        imageUrl={imageUrl}
      />
      <MarketingTextInfoBlock marketingInformation={marketingInformation} />
      <PrimaryContactBlock contactInfo={props.sponsorInfo} />
      <MobileGroupHeader title="Rates">{renderRates()}</MobileGroupHeader>
      <LocationsInfoBlock locations={props.sponsorInfo?.locations ?? []} />
      <CategoriesInfoBlock categories={props.sponsorInfo?.categories ?? []} />
      <BrandsInfoBlock brands={props.sponsorInfo?.brands ?? []} />
      <ProductsInfoBlock products={props.sponsorInfo?.products ?? []} />
    </div>
  );
};

export default SponsorInfo;
