import { apiClient } from "services/api/client";
import {
  IContactDetailed,
  IAccountInfo,
  IStatementPreview,
  IMarketingFundEvents,
  IEventAccountSummary,
} from "services/api/types";

const ACCOUNTS_URL = "administrator/accounts";
const EVENT_ACCOUNTS_URL = "administrator/eventAccounts";

export const getContactsForAccount = async (accountId: string) => {
  const url = `${ACCOUNTS_URL}/${accountId}/contacts`;
  const response = await apiClient.get<IContactDetailed[]>(url);
  return response.data;
};

export const getMarketingFundAccountInfo = async () => {
  const response = await apiClient.get<IAccountInfo>(
    `administrator/marketingFund`
  );
  return response.data;
};

export const getMarketingFundEvents = async () => {
  const response = await apiClient.get<IMarketingFundEvents>(
    `administrator/marketingFund/events`
  );
  return response.data;
};

export const getInfo = async (accountId: string) => {
  const response = await apiClient.get<IAccountInfo>(
    `${ACCOUNTS_URL}/${accountId}`
  );
  return response.data;
};

export const previewCurrentStatement = async (accountId: string) => {
  const response = await apiClient.get<IStatementPreview>(
    `${ACCOUNTS_URL}/${accountId}/statementPreview`
  );

  return response.data;
};

export const getEventAccounts = async () => {
  const response = await apiClient.get<IEventAccountSummary[]>(
    EVENT_ACCOUNTS_URL
  );
  return response.data;
};

export const createEventAccount = async (companyId: string) => {
  const url = `${EVENT_ACCOUNTS_URL}/${companyId}`;
  const response = await apiClient.post(url);
  return response.data;
};

export const deleteEventAccount = async (companyId: string) => {
  const url = `${EVENT_ACCOUNTS_URL}/${companyId}`;
  const response = await apiClient.delete(url);
  return response.data;
};
