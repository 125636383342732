import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import companyStore from "stores/shared/company";
import CategorySelect from "components/Dropdowns/CategorySelect";
import CompanyComboBox, {
  CompanyOption,
} from "components/Dropdowns/CompanyComboBox";
import Typography from "@material-ui/core/Typography";
import { Option } from "services/api/types";
import api from "services/api";
import myStore from "stores/my";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

type Props = {
  handleClose: () => void;
  purchaserId: string;
  currentUserType: "Admin" | "Member";
};

const AdminAddPurchaserSupplier = (props: Props) => {
  const [isSelfLink, setIsSelfLink] = React.useState(false);
  const [companyCategoryOptions, setCompanyCategoryOptions] = React.useState<
    Option[] | undefined
  >();
  const [saveError, setSaveError] = React.useState<string | undefined>();
  const [selectedCategory, setSelectedCategory] = React.useState<
    Option | undefined
  >();
  const [selectedCompany, setSelectedCompany] = React.useState<
    CompanyOption | undefined
  >();
  const [newCompanyName, setNewCompanyName] = React.useState("");
  const [isFetchingCategories, setIsFetchingCategories] = React.useState(false);

  const [companyError, setCompanyError] = React.useState<string | undefined>();
  const [categoryError, setCategoryError] = React.useState<
    string | undefined
  >();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      error: {
        color: "red",
      },
      supplierHost: {
        display: "grid",
        gridTemplateColumns: "1fr auto",
        gridGap: theme.spacing(2),
      },
      selfLabel: {
        transform: "translateY(-10px)",
      },
    })
  );

  const classes = useStyles();

  const handleSave = async () => {
    setSaveError(undefined);
    const categoryErrorLocal =
      selectedCategory === undefined ? "Category is required" : undefined;
    const companyErrorLocal =
      selectedCompany === undefined &&
      newCompanyName.length === 0 &&
      !isSelfLink
        ? "Company is required"
        : undefined;

    setCategoryError(categoryErrorLocal);
    setCompanyError(companyErrorLocal);

    const supplierId = isSelfLink ? props.purchaserId : selectedCompany?.value;

    if (categoryErrorLocal === undefined && companyErrorLocal === undefined) {
      try {
        await companyStore.addPurchaserSupplier(
          props.purchaserId,
          selectedCategory!.value,
          supplierId,
          newCompanyName
        );
        props.handleClose();
      } catch (e) {
        const error = e as any;
        if (typeof error === "string") {
          setSaveError(error);
        } else if (error.status === 400) {
          setSaveError(error.data);
        }
      }
    }
  };

  const handleCategoryChange = (value: Option) => {
    if (value) {
      setCategoryError(undefined);
    }

    setSelectedCategory(value);
  };

  const handleCompanyChange = async (value: CompanyOption) => {
    setCompanyCategoryOptions(undefined);
    setSelectedCategory(undefined);
    setSelectedCompany(value);
    setNewCompanyName("");

    if (value) {
      setCompanyError(undefined);
      setIsFetchingCategories(true);

      const categories = await api.members.suppliers.getSupplierCategories(
        value.value
      );

      const options: Option[] = categories.map<Option>((c) => {
        return { value: c.id, label: c.name };
      });

      setCompanyCategoryOptions(options);
      setIsFetchingCategories(false);

      if (options.length === 1) {
        setSelectedCategory(options[0]);
      }
    }
  };

  const handleNewValueChange = (value: string) => {
    if (value) {
      setCompanyError(undefined);
    }

    setSelectedCompany(undefined);
    setNewCompanyName(value);
  };

  const limitCategoriesTo =
    !myStore.isAdminNotImpersonating && selectedCompany
      ? companyCategoryOptions
      : undefined;

  return (
    <ResponsiveModalShell
      handleClose={props.handleClose}
      handleSave={handleSave}
      title="Add Supplier"
    >
      {saveError ? (
        <Typography className={classes.error}>{saveError}</Typography>
      ) : undefined}
      <div className={classes.supplierHost}>
        <FormControl disabled={isSelfLink} fullWidth={true}>
          <CompanyComboBox
            disabled={isSelfLink}
            label="Supplier"
            existingValue={selectedCompany}
            setExistingValue={handleCompanyChange}
            newValue={newCompanyName}
            setNewValue={handleNewValueChange}
            currentUserType={props.currentUserType}
          />
          <FormHelperText className={classes.error}>
            {companyError}
          </FormHelperText>
        </FormControl>
        <FormControlLabel
          className={classes.selfLabel}
          control={
            <Checkbox
              checked={isSelfLink}
              onChange={(e) => {
                setIsSelfLink(e.target.checked);
                if (e.target.checked) {
                  setSelectedCompany(undefined);
                }
              }}
            />
          }
          label="Self"
        />
      </div>
      <FormControl fullWidth={true}>
        <CategorySelect
          disabled={isFetchingCategories}
          onlyAvailableOptions={limitCategoriesTo}
          hasError={Boolean(categoryError)}
          onChanged={handleCategoryChange}
          value={selectedCategory}
        />
        <FormHelperText className={classes.error}>
          {categoryError}
        </FormHelperText>
      </FormControl>
    </ResponsiveModalShell>
  );
};

export default AdminAddPurchaserSupplier;
