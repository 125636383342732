import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { KeyboardDatePicker } from "@material-ui/pickers";
import DollarPointsInput from "components/Inputs/DollarPointsInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "baseline",
    },
    formControl: {
      margin: theme.spacing(1),
    },
    buttons: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      marginTop: theme.spacing(4),
    },
    error: {
      color: theme.palette.error.main,
    },
  })
);

type PointsPaymentFormProps = {
  costPerPoint: number;
  points: number | undefined;
  pointsError: string | undefined;
  onPointsChanged: (value?: number) => void;
  dollars: number | undefined;
  dollarsError: string | undefined;
  onDollarsChanged: (value?: number) => void;
  date: Date | null;
  onDateChanged: (value: Date | null) => void;
  dateError: string | undefined;
  touched: boolean;
  notes: string;
  setNotes: (value: string) => void;
};

export default function PointsPaymentForm(props: PointsPaymentFormProps) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.container}>
        <FormControl
          margin="dense"
          variant="outlined"
          className={classes.formControl}
        >
          <KeyboardDatePicker
            format="MM/dd/yy"
            label="Date"
            error={props.touched && props.dateError !== undefined}
            inputVariant="outlined"
            margin="dense"
            value={props.date}
            onChange={props.onDateChanged}
          />
          <FormHelperText className={classes.error} variant="standard">
            {props.touched ? props.dateError : ""}
          </FormHelperText>
        </FormControl>
        <DollarPointsInput
          onChange={(evt) => {
            props.onDollarsChanged(evt.dollars);
            props.onPointsChanged(evt.points);
          }}
          dollarValue={props.dollars}
          pointValue={props.points}
          allowNegative={false}
          dollarsPerPoint={props.costPerPoint}
          dollarsError={props.dollarsError}
          pointsError={props.pointsError}
          showDollarsError={props.touched && props.dollarsError !== undefined}
          showPointsError={props.touched && props.pointsError !== undefined}
        />
      </div>
      <FormControl
        margin="dense"
        variant="outlined"
        fullWidth
        className={classes.formControl}
      >
        <TextField
          minRows={2}
          multiline={true}
          value={props.notes}
          margin="dense"
          variant="outlined"
          fullWidth
          label="Memo"
          onChange={(evt) => props.setNotes(evt.target.value)}
        />
      </FormControl>
    </div>
  );
}
