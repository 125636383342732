import React from "react";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import eventsStore from "stores/admin/events";
import { DeleteAttendeeOption } from "services/api/types";

type Props = {
  refundAmountPoints: number;
  refundCompanyName: string;
  handleClose: () => void;
  eventGroupId: string;
  attendeeId: string;
};

type RemoveAction = DeleteAttendeeOption | "DoNothing";

const ConfirmRemoveAttendeeModal = (props: Props) => {
  const [isSaving, setIsSaving] = React.useState(false);
  const [removeAction, setRemoveAction] = React.useState<RemoveAction>(
    "MakeAnonymousAttendee"
  );

  const handleRadioChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setRemoveAction(evt.target.value as RemoveAction);
  };

  const renderRadios = () => {
    return (
      <RadioGroup
        name="company-type"
        value={removeAction}
        onChange={handleRadioChange}
      >
        <FormControlLabel
          value="MakeAnonymousAttendee"
          control={<Radio size="small" />}
          label="Yes, and make an anonymous attendee"
        />
        <FormControlLabel
          value="JustDelete"
          control={<Radio size="small" />}
          label="Yes, and don't do anything else--just delete them"
        />
        <FormControlLabel
          value="DoNothing"
          control={<Radio size="small" />}
          label="No, don't delete this attendee"
        />
      </RadioGroup>
    );
  };

  const handleOkClick = async () => {
    if (removeAction !== "DoNothing") {
      const { attendeeId, eventGroupId } = props;
      setIsSaving(true);
      await eventsStore.deleteAttendee(eventGroupId, attendeeId, removeAction);
      setIsSaving(false);
    }

    props.handleClose();
  };

  return (
    <ResponsiveModalShell
      handleSave={handleOkClick}
      saveButtonCaption="Ok"
      disableSaveButton={isSaving}
      handleClose={props.handleClose}
      title="Remove Attendee?"
    >
      {renderRadios()}
    </ResponsiveModalShell>
  );
};

export default ConfirmRemoveAttendeeModal;
