import { observer } from "mobx-react";
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Omit } from "utils/type";
import FormCompanyLocation from "modals/components/CompanyForms/Location/Location";
import { ICompanyLocationPayload } from "services/api/types";
import companyStore from "stores/shared/company";

type OriginalProps = {
  handleClose: () => void;
};

type Props = OriginalProps & RouteComponentProps;

@observer
class ModalAddCompanyLocation extends Component<Props> {
  public render() {
    const companyDetailsLoading =
      !companyStore.details && !companyStore.contacts;
    return (
      <FormCompanyLocation
        formType="add"
        companyContacts={companyStore.contacts ?? []}
        companyContactsLoading={companyDetailsLoading}
        handleSubmit={this.handleSubmit}
        handleCancel={this.props.handleClose}
      />
    );
  }

  public componentDidMount() {
    companyStore.loadContacts(false);
  }

  private handleSubmit = async (
    payload: Omit<ICompanyLocationPayload["companyLocation"], "companyId">
  ) => {
    await companyStore.createCompanyLocation({
      companyLocation: {
        companyId: companyStore.details ? companyStore.details.id : "",
        ...payload,
      },
    });
    this.props.handleClose();
  };
}

export default withRouter(ModalAddCompanyLocation);
