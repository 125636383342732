import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import clsx from "clsx";
import CompanyDropdown from "components/Dropdowns/CompanyDropdown";
import { Option, CompanyType } from "services/api/types";
import GenericDropdown from "components/Dropdowns/GenericDropdown";
import api from "services/api";

type Props = {
  handleClose: () => void;
  sentCallback: () => void;
  memberId: string;
  referredBy?: {
    name: string;
    identifier: string;
    id: string;
  };
};

const SendNewSponsorReferralConfirmationModal = (props: Props) => {
  const defaultReferredByOption: Option | undefined = props.referredBy
    ? {
        label: `${props.referredBy.name} (${props.referredBy.identifier})`,
        value: props.referredBy.id,
      }
    : undefined;

  const [selectedSponsorOption, setSelectedSponsorOption] = React.useState<
    Option | undefined
  >(defaultReferredByOption);

  const [selectedContactOption, setSelectedContactOption] = React.useState<
    Option | undefined
  >();
  const [companyEmployees, setCompanyEmployees] = React.useState<
    Option[] | undefined
  >();

  const [isSending, setIsSending] = React.useState(false);

  React.useEffect(() => {
    const loadEmployees = async (selectedOption: Option | undefined) => {
      setCompanyEmployees(undefined);
      setSelectedContactOption(undefined);

      if (selectedSponsorOption) {
        const employees = await api.admin.company.getActiveCompanyEmployees(
          selectedOption!.value
        );
        const options = employees
          .filter((e) => e.email)
          .map<Option>((e) => {
            return {
              label: `${e.firstName} ${e.lastName}`,
              value: e.id,
            };
          });

        setCompanyEmployees(options);
      }
    };

    loadEmployees(selectedSponsorOption);
  }, [selectedSponsorOption]);

  const useStyles = makeStyles(() =>
    createStyles({
      root: {},
      disabled: {
        opacity: 0.5,
        pointerEvents: "none",
      },
    })
  );

  const classes = useStyles();

  const handleSend = async () => {
    if (!selectedSponsorOption) {
      alert("Please select a company");
      return;
    }

    if (!selectedContactOption) {
      alert("Please select a contact");
      return;
    }

    try {
      setIsSending(true);
      await api.admin.members.sendNewSponsorReferralConfirmation({
        newMemberId: props.memberId,
        referringContactId: selectedContactOption.value,
        referringSponsorId: selectedSponsorOption.value,
      });

      props.sentCallback();
      props.handleClose();
    } finally {
      setIsSending(false);
    }
  };

  const sponsorCompanyTypeRef = React.useRef<CompanyType[]>(["Sponsor"]);

  return (
    <ResponsiveModalShell
      handleSave={handleSend}
      disableSaveButton={isSending}
      handleClose={props.handleClose}
      title="Send New Sponsor Referral Confirmation Email"
      saveButtonCaption={isSending ? "Sending..." : "Send"}
    >
      <div
        className={clsx(classes.root, {
          [classes.disabled]: isSending,
        })}
      >
        <CompanyDropdown
          userType="Admin"
          minWidth="200px"
          label="Select Referring Sponsor"
          companyTypes={sponsorCompanyTypeRef}
          selectedOption={selectedSponsorOption}
          onSelectedOptionChanged={setSelectedSponsorOption}
        />
        {selectedSponsorOption && (
          <GenericDropdown
            selectedOption={selectedContactOption}
            setSelectedOption={setSelectedContactOption}
            error={false}
            canClear={false}
            invalidOptionIds={[]}
            noOptionsText="No Contacts Found"
            options={companyEmployees ?? []}
            label="Select Contact"
            disabled={companyEmployees === undefined}
          />
        )}
      </div>
    </ResponsiveModalShell>
  );
};

export default SendNewSponsorReferralConfirmationModal;
