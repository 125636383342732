import { IAppText, Option } from "services/api/types";
import api from "services/api";
import { observable, action } from "mobx";

export class AppTextStore {
  @observable
  public appText: IAppText[] = [];

  @observable
  public options: Option[] = [];

  @observable
  public isLoaded = false;

  @action.bound
  public async ensureAllAppTextLoaded() {
    if (this.isLoaded) return;

    const result = await api.shared.appText.getAllAppText();
    this.appText = result;
    this.options = result.map<Option>((r) => {
      return {
        value: r.id,
        label: r.friendlyName,
      };
    });

    this.isLoaded = true;
  }

  @action.bound
  public async updateAppText(id: string, text: string) {
    await api.admin.appText.updateAppText({ id, text });
    this.isLoaded = false;
    await this.ensureAllAppTextLoaded();
  }
}

const appTextStore = new AppTextStore();

export default appTextStore;
