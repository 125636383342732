import { apiClient } from "services/api/client";
import {
  ISummaryTransaction,
  ITransactionViewModel,
  IJob,
} from "services/api/types";

export const get = async (transactionId: string) => {
  const response = await apiClient.get<ITransactionViewModel>(
    `administrator/transactions/${transactionId}`
  );

  return response.data;
};

export const create = async (transaction: ITransactionViewModel) => {
  const response = await apiClient.post<ISummaryTransaction>(
    "administrator/transactions",
    transaction
  );

  return response;
};

export const update = async (transaction: ITransactionViewModel) => {
  const response = await apiClient.put<ISummaryTransaction>(
    "administrator/transactions",
    transaction
  );

  return response;
};

export const previewCreate = async (transaction: ITransactionViewModel) => {
  const response = await apiClient.post<ISummaryTransaction>(
    "administrator/previewTransactions",
    transaction
  );

  return response;
};

export const previewUpdate = async (transaction: ITransactionViewModel) => {
  const response = await apiClient.put<ISummaryTransaction>(
    "administrator/previewTransactions",
    transaction
  );

  return response;
};

export const deleteTrans = async (transactionId: string) => {
  const response = await apiClient.delete(
    `administrator/transactions/${transactionId}`
  );
  return response;
};

export const deductYearlyFees = async () => {
  const response = await apiClient.post(`administrator/deductYearlyFees`);
  return response;
};

export const getYearlyFeeStatus = async () => {
  const response = await apiClient.get<IJob>(`administrator/deductYearlyFees`);
  return response.data;
};
