import { apiClient } from "services/api/client";
import { Operation } from "fast-json-patch";

import {
  ICompanyBrand,
  CreateCompanyBrandCommand,
  CreateCompanyProductCommand,
  ICompany,
  ICompanyNotePayload,
  ICreateCompanyCommand,
  IMergeContactsResult,
  ICompanyProduct,
  ICompanyInfo,
  ICompanyCategory,
  ICompanyCategoryCommand,
  INote,
  IUpdateCompanyCommand,
  ICompanyInEvent,
  IChartData,
  ISmallContact,
  ITinyContact,
  IPotentialDuplicateCompany,
  ISentExternalEmailModel,
  IMergeCompaniesResult,
  IPotentialDuplicateContact,
} from "services/api/types";

const COMPANIES_URL = "administrator/companies";

export const setPrimaryCategory = async (companyCategoryId: string) => {
  const url = `administrator/primaryCategories/${companyCategoryId}`;
  const response = await apiClient.post<ICompanyCategory>(url);
  return response.data;
};

export const getInfo = async (companyId: string) => {
  const url = `${COMPANIES_URL}/${companyId}/info`;
  const response = await apiClient.get<ICompanyInfo>(url);
  return response.data;
};

export const create = async (command: ICreateCompanyCommand) => {
  const response = await apiClient.post<{ company: ICompany }>(
    COMPANIES_URL,
    command
  );
  return response;
};

export const updateLogo = async (args: {
  companyId: string;
  imageUri: string;
}) => {
  const url = `${COMPANIES_URL}/${args.companyId}/logo`;
  const response = await apiClient.post(url, args);
  return response.data;
};

export const mergeCompanies = async (args: {
  keepCompanyId: string;
  deleteCompanyId: string;
}) => {
  const url = `${COMPANIES_URL}/merge`;
  const response = await apiClient.post<IMergeCompaniesResult>(url, args);
  return response;
};

export const update = async (command: IUpdateCompanyCommand) => {
  const response = await apiClient.put<{ company: ICompany }>(
    `${COMPANIES_URL}/${command.id}`,
    command
  );
  return response;
};

export const companyCategoryCreate = async (
  payload: ICompanyCategoryCommand
) => {
  const response = await apiClient.post<ICompanyCategory[]>(
    `${COMPANIES_URL}/${payload.companyId}/companyCategories`,
    payload
  );
  return response;
};

export const updateCompanyCategory = async (
  companyId: string,
  companyCategory: string,
  operations: Operation[]
) => {
  const response = await apiClient.patch<ICompanyCategory[]>(
    `${COMPANIES_URL}/${companyId}/companyCategories/${companyCategory}`,
    operations
  );
  return response;
};

export const removeCompanyCategory = async (payload: {
  id: string;
  companyId: string;
}) => {
  const response = await apiClient.delete(
    `${COMPANIES_URL}/${payload.companyId}/companyCategories/${payload.id}`
  );
  return response;
};

export const brandCreate = async (payload: CreateCompanyBrandCommand) => {
  const response = await apiClient.post<{ companyBrand: ICompanyBrand }>(
    `${COMPANIES_URL}/${payload.companyId}/brands`,
    payload
  );
  return response;
};

export const brandRemove = async (payload: {
  id: string;
  companyId: string;
}) => {
  const response = await apiClient.delete(
    `${COMPANIES_URL}/${payload.companyId}/brands/${payload.id}`
  );
  return response;
};

export const productCreate = async (command: CreateCompanyProductCommand) => {
  const response = await apiClient.post<{ companyProduct: ICompanyProduct }>(
    `${COMPANIES_URL}/${command.companyId}/products`,
    command
  );
  return response;
};

export const productRemove = async (payload: {
  id: string;
  companyId: string;
}) => {
  const response = await apiClient.delete(
    `${COMPANIES_URL}/${payload.companyId}/products/${payload.id}`
  );
  return response;
};

export const noteCreate = async (payload: ICompanyNotePayload) => {
  const response = await apiClient.post<{ companyNote: INote }>(
    `${COMPANIES_URL}/${payload.companyNote.companyId}/notes`,
    payload
  );
  return response;
};

export const noteUpdate = async (
  companyId: string,
  noteId: string,
  operations: Operation[]
) => {
  const response = await apiClient.patch<{ companyNote: INote }>(
    `${COMPANIES_URL}/${companyId}/notes/${noteId}`,
    operations
  );
  return response;
};

export const noteRemove = async (payload: {
  id: string;
  companyId: string;
}) => {
  const response = await apiClient.delete(
    `${COMPANIES_URL}/${payload.companyId}/notes/${payload.id}`
  );
  return response;
};

export const getEvents = async (companyId: string) => {
  const response = await apiClient.get<ICompanyInEvent[]>(
    `${COMPANIES_URL}/${companyId}/events`
  );

  return response;
};

export const getMemberPointHistoryChartData = async (companyId: string) => {
  const url = `${COMPANIES_URL}/${companyId}/transactions/memberChart`;
  const response = await apiClient.get<IChartData>(url);
  return response.data;
};

export const getSponsorPointHistoryChartData = async (companyId: string) => {
  const url = `${COMPANIES_URL}/${companyId}/transactions/sponsorChart`;
  const response = await apiClient.get<IChartData>(url);
  return response.data;
};

export const getActiveCompanyContacts = async (companyId: string) => {
  const url = `${COMPANIES_URL}/${companyId}/contacts`;
  const response = await apiClient.get<ISmallContact[]>(url);
  return response.data;
};

export const getActiveCompanyEmployees = async (companyId: string) => {
  const response = await apiClient.get<ITinyContact[]>(
    `${COMPANIES_URL}/${companyId}/contacts/employees`
  );

  return response.data;
};

export const getPotentialDuplicateCompanies = async () => {
  const url = `administrator/companies/potentialDuplicates`;
  const response = await apiClient.get<IPotentialDuplicateCompany[]>(url);
  return response.data;
};

export const hidePotentialDuplciateCompany = async (
  potentialDuplicateCompanyId: string
) => {
  const url = `administrator/companies/potentialDuplicates/hide`;
  const response = await apiClient.post(url, { potentialDuplicateCompanyId });
  return response.data;
};

export const getPotentialDuplicateContacts = async () => {
  const url = `administrator/contacts/potentialDuplicates`;
  const response = await apiClient.get<IPotentialDuplicateContact[]>(url);
  return response.data;
};

export const hidePotentialDuplciateContact = async (
  potentialDuplicateContactId: string
) => {
  const url = `administrator/contacts/potentialDuplicates/hide`;
  const response = await apiClient.post(url, {
    potentialDuplicateContactId,
  });
  return response.data;
};

export const getRecentEmailHistory = async (companyId: string) => {
  const url = `administrator/companies/${companyId}/emailHistory/recent`;
  const response = await apiClient.get<ISentExternalEmailModel[]>(url);
  return response.data;
};

export const mergeContacts = async (command: {
  companyId: string;
  contact1Id: string;
  contact2Id: string;
}) => {
  const url = `administrator/companies/${command.companyId}/contacts/merge`;
  const response = await apiClient.post<IMergeContactsResult>(url, command);
  return response;
};

export const createDeactivatorBatch = async (args: {
  rawData: string;
  notes: string;
}) => {
  const url = `administrator/deactivatorBatches`;
  await apiClient.post(url, args);
};
