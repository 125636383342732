import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import EventGroupPaymentDialogTitle from "../components/EventGroupPaymentDialogTitle";
import { formatMoney } from "utils/number";
import { Observer } from "mobx-react";
import {
  PaymentType,
  IUpdateEventGroupPaymentCommand,
  IAddEventPayerPaymentCommand,
  IEventPayerPayment,
  ITinyEventPayerInvoice,
  IInvoiceAmount,
  IAccountInfo,
} from "services/api/types";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import StatelessCheckPaymentForm from "../../Payments/StatelessCheckPaymentForm";
import StatelessPointsPaymentForm from "../../Payments/StatelessPointsPaymentForm";
import { Form } from "formik";
import InvoiceUpdater from "../components/InvoiceUpdater";
import AddPaymentHeader from "../components/AddPaymentHeader";

type Props = {
  handleClose: () => void;
  paymentId?: string;
  accountInfo: IAccountInfo;
  payment?: IEventPayerPayment;
  pointBalance?: number;
  mode: "Add" | "Edit";
  addPayment: (payment: IAddEventPayerPaymentCommand) => void;
  updatePayment: (payment: IUpdateEventGroupPaymentCommand) => void;
  costPerPoint: number;
  balanceDueDollars?: number;
  eventPayerId: string;
  maximumRefundDollarAmount: number;
  existingInvoices: ITinyEventPayerInvoice[];
};

type AppliedAmounts = {
  [key: string]: number;
};

const AddEditEventPayerPaymentModal = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        width: 800,
      },
      invoiceHeader: {
        margin: theme.spacing(2, 0),
        fontWeight: "bold",
        background: theme.palette.primary.main,
        color: "white",
        padding: 4,
        borderRadius: 4,
        fontSize: "12pt",
      },
      error: {
        color: theme.palette.error.main,
      },
    })
  );

  const getDefaultPointValue = () => {
    if (props.mode === "Edit" && props.payment!.paymentType === "Points") {
      const value = props.payment?.points;
      if (value === undefined)
        throw Error("Unable to get existing point payment value");
      return value;
    }

    if (props.pointBalance && props.pointBalance > 0) {
      return props.pointBalance;
    }

    return undefined;
  };

  const getDefaultDollarValue = () => {
    if (props.mode === "Edit") {
      const value = props.payment?.amount;
      if (value === undefined)
        throw Error("Unable to get existing dollar payment value");
      return value;
    }

    if (props.pointBalance && props.pointBalance > 0) {
      return props.pointBalance * props.costPerPoint!;
    }

    return undefined;
  };

  const getAppliedAmountsObject = React.useCallback(() => {
    if (!props.payment) return {};

    const result = {};
    props.payment.appliedInvoiceAmounts.forEach((appliedInvoiceAmount) => {
      console.log({ appliedInvoiceAmount });
      result[appliedInvoiceAmount.eventPayerInvoiceId] =
        appliedInvoiceAmount.amountApplied;
    });

    return result;
  }, [props.payment]);

  const [paymentType, setPaymentType] = React.useState<PaymentType>(
    props.payment?.paymentType ?? "Check"
  );

  const [notes, setNotes] = React.useState(props.payment?.notes ?? "");
  const [refund, setRefund] = React.useState(props.payment?.refund ?? false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [invoiceErrorMsg, setInvoiceErrorMsg] = React.useState("");

  // Check payment form props
  const [checkDate, setCheckDate] = React.useState<Date | null>(
    props.payment?.date ?? new Date()
  );
  const [checkNumber, setCheckNumber] = React.useState(
    props.payment?.checkNumber ?? ""
  );
  const [checkNumberError, setCheckNumberError] = React.useState("");
  const [checkAmount, setCheckAmount] = React.useState(
    props.payment?.amount ?? 0
  );
  const [checkAmountError, setCheckAmountError] = React.useState("");
  const [invoiceAppliedAmounts, setInvoiceAppliedAmounts] =
    React.useState<AppliedAmounts>(getAppliedAmountsObject());

  // Used by all
  const [touched, setTouched] = React.useState(false);

  // We'll automatically try to set invoice amounts when the payment
  // amount has changed, but once they change the amount they're on
  // their own
  const [invoicesTouched, setInvoicesTouched] = React.useState(false);

  // Point payment form props
  const [points, setPoints] = React.useState(getDefaultPointValue());
  const [pointDollars, setPointDollars] = React.useState(
    getDefaultDollarValue()
  );
  const [pointsDate, setPointsDate] = React.useState<Date | null>(new Date());
  const [pointsError, setPointsError] = React.useState<string | undefined>(
    undefined
  );
  const [pointDollarsError, setPointDollarsError] = React.useState<
    string | undefined
  >(undefined);
  const [pointsDateError, setPointsDateError] = React.useState<
    string | undefined
  >(undefined);

  React.useEffect(() => {
    if (checkNumber.length > 0) {
      setCheckNumberError("");
    }
  }, [checkNumber]);

  React.useEffect(() => {
    if (paymentType === "Points") {
      const emptyAppliedAmounts = getAppliedAmountsObject();
      setInvoiceAppliedAmounts(emptyAppliedAmounts);
    }
  }, [paymentType, getAppliedAmountsObject]);

  const validateRefundAmount = (
    dollarAmount: number | undefined
  ): { passed: boolean; errorMessage?: string } => {
    if (!refund || !dollarAmount) {
      setErrorMsg("");
      return { passed: true };
    }

    if (dollarAmount > props.maximumRefundDollarAmount) {
      const error = `Cannot exceed ${formatMoney(
        props.maximumRefundDollarAmount
      )}`;

      setErrorMsg(error);
      return {
        passed: false,
        errorMessage: error,
      };
    }

    setErrorMsg("");
    return {
      passed: true,
    };
  };

  const getInvoiceAmountsArray = (): IInvoiceAmount[] => {
    const result: IInvoiceAmount[] = Object.keys(invoiceAppliedAmounts).map(
      (invoiceId) => {
        const amount = invoiceAppliedAmounts[invoiceId];
        return { invoiceId, amount };
      }
    );

    return result;
  };

  const saveCheckPayment = async () => {
    const { eventPayerId } = props;

    const validationResult = validateRefundAmount(checkAmount);
    if (!validationResult.passed) return;

    const invoiceAmounts = getInvoiceAmountsArray();

    if (!props.payment) {
      const command: IAddEventPayerPaymentCommand = {
        payment: {
          amount: checkAmount,
          checkNumber,
          date: checkDate!,
          paymentType,
          eventPayerId,
          notes,
          refund,
        },
        invoiceAmounts,
      };

      props.addPayment(command);
    } else {
      props.updatePayment({
        payment: {
          amount: checkAmount,
          checkNumber,
          refund,
          notes,
          date: checkDate!,
          eventPayerId,
          paymentType,
          id: props.payment!.id,
        },
        invoiceAmounts,
      });
    }

    props.handleClose();
  };

  const savePointPayment = async () => {
    const { eventPayerId } = props;
    const validationResult = validateRefundAmount(pointDollars!);
    if (!validationResult.passed) return;
    const invoiceAmounts = getInvoiceAmountsArray();

    if (!props.payment) {
      const command: IAddEventPayerPaymentCommand = {
        payment: {
          amount: pointDollars!,
          points,
          checkNumber: "",
          notes,
          date: pointsDate!,
          paymentType,
          eventPayerId,
          refund,
        },
        invoiceAmounts,
      };
      props.addPayment(command);
    } else {
      props.updatePayment({
        payment: {
          amount: pointDollars!,
          points,
          notes,
          checkNumber: "",
          date: pointsDate!,
          paymentType,
          eventPayerId,
          refund,
          id: props.payment!.id,
        },
        invoiceAmounts,
      });
    }
    props.handleClose();
  };

  const handleSave = async () => {
    setTouched(true);
    if (paymentType === "Points") {
      const isValid = validatePointPayment();
      if (isValid) {
        await savePointPayment();
      }
    } else {
      const isValid = validateCheckPayment();
      if (isValid) {
        await saveCheckPayment();
      }
    }
  };

  const getTotalApplied = () => {
    const total = Object.keys(invoiceAppliedAmounts).reduce(
      (acc, currentInvoiceId) => {
        return acc + invoiceAppliedAmounts[currentInvoiceId];
      },
      0
    );

    return total;
  };

  const validateCheckPayment = () => {
    let foundError = false;

    if (checkAmount === 0) {
      setCheckAmountError("Required");
      foundError = true;
    }

    const validationResult = validateRefundAmount(checkAmount);

    if (!validationResult.passed) {
      setCheckAmountError(validationResult.errorMessage!);
      foundError = true;
    } else {
      setCheckAmountError("");
    }

    if (checkNumber.length === 0) {
      setCheckNumberError("Required");
      foundError = true;
    } else {
      setCheckNumberError("");
    }

    const totalApplied = getTotalApplied();
    const openInvoices = props.existingInvoices.filter(
      (i) => !i.void && !i.isPaidInFull
    );

    if (!refund && openInvoices.length > 0 && checkAmount !== totalApplied) {
      setInvoiceErrorMsg("You must apply the total amount to all invoices");
      foundError = true;
    } else {
      setInvoiceErrorMsg("");
    }

    return !foundError;
  };

  const validatePointPayment = () => {
    let foundError = false;

    if (!pointsDate) {
      setPointsDateError("This is required");
      foundError = true;
    } else {
      setPointsDateError(undefined);
    }

    if (pointDollars === undefined) {
      setPointDollarsError("This is required");
      foundError = true;
    } else if (pointDollars === 0) {
      setPointDollarsError("Amount must be greater than zero");
      foundError = true;
    } else {
      const validationResult = validateRefundAmount(pointDollars);
      if (validationResult.passed) {
        setPointDollarsError(undefined);
      } else {
        setPointDollarsError(validationResult.errorMessage!);
        foundError = true;
      }
      setPointDollarsError(undefined);
    }

    if (points === undefined) {
      setPointsError("This is required");
      foundError = true;
    } else if (points === 0) {
      setPointsError("Points must be greater than zero");
      foundError = true;
    } else {
      setPointsError(undefined);
    }

    return !foundError;
  };

  const renderCheckForm = () => {
    return (
      paymentType === "Check" && (
        <StatelessCheckPaymentForm
          amount={checkAmount}
          onAmountChanged={(amount) => {
            setCheckAmount(amount);
            addDefaultPaymentAmountToInvoices(amount);
          }}
          amountError={checkAmountError}
          date={checkDate}
          onDateChanged={setCheckDate}
          checkNumber={checkNumber}
          onCheckNumberChanged={setCheckNumber}
          checkNumberError={checkNumberError}
          touched={touched}
        />
      )
    );
  };

  const getInvoiceToApplyTo = () => {
    if (paymentType === "Points") return undefined;

    const openInvoices = props.existingInvoices.filter(
      (i) => !i.void && !i.isPaidInFull
    );

    if (openInvoices.length === 0) return undefined;

    const invoiceWithLowestAmountDue = openInvoices
      .filter((i) => !i.void)
      .reduce((acc, current) => {
        if (acc && acc.amount > current.amount) {
          return current;
        }

        return acc;
      });

    return invoiceWithLowestAmountDue;
  };

  const addDefaultPaymentAmountToInvoices = (
    paymentAmount: number | undefined
  ) => {
    // Don't do anything if they've manually changed an amount
    if (invoicesTouched) {
      console.warn("Invoices have been touched");
      return;
    }

    const invoiceToUse = getInvoiceToApplyTo();

    if (invoiceToUse) {
      const newObj = Object.assign({}, invoiceAppliedAmounts, {
        [invoiceToUse.id]: paymentAmount ?? 0,
      });

      setInvoiceAppliedAmounts(newObj);
    }
  };

  const renderPointsForm = () => {
    const showForm = paymentType === "Points";

    return (
      showForm && (
        <StatelessPointsPaymentForm
          costPerPoint={props.costPerPoint}
          notes={notes}
          setNotes={setNotes}
          points={points}
          pointsError={pointsError}
          onPointsChanged={(points) => {
            setPoints(points);
          }}
          dollars={pointDollars}
          dollarsError={pointDollarsError}
          onDollarsChanged={(dollars) => {
            setPointDollars(dollars);
            addDefaultPaymentAmountToInvoices(dollars);
          }}
          date={pointsDate}
          onDateChanged={setPointsDate}
          dateError={pointsDateError}
          touched={touched}
        />
      )
    );
  };

  const maybeRenderInvoices = () => {
    if (paymentType === "Points") return null;

    return (
      <>
        <div className={classes.invoiceHeader}>Apply Payment to Invoices:</div>
        {invoiceErrorMsg && (
          <div
            style={{ fontWeight: "bold", margin: "20px 0" }}
            className={classes.error}
          >
            {invoiceErrorMsg}
          </div>
        )}
        <InvoiceUpdater
          isRefund={refund}
          appliedAmounts={invoiceAppliedAmounts}
          originalAppliedAmounts={getAppliedAmountsObject()}
          activeInvoices={props.existingInvoices.filter((i) => !i.void)}
          onInvoiceAmountPaidChanged={(invoiceId, newAmount) => {
            const currentAmount = invoiceAppliedAmounts[invoiceId];

            if (
              currentAmount === newAmount ||
              isNaN(currentAmount) ||
              isNaN(newAmount)
            ) {
              return;
            }

            const newObj = Object.assign({}, invoiceAppliedAmounts, {
              [invoiceId]: newAmount,
            });

            setInvoicesTouched(true);
            setInvoiceAppliedAmounts(newObj);
          }}
        />
      </>
    );
  };

  const classes = useStyles();

  return (
    <Observer
      render={() => {
        return (
          <div className={classes.root}>
            <ResponsiveModalShell
              title={
                props.mode === "Edit"
                  ? `Edit Event Group Payment`
                  : `Add Event Group Payment`
              }
              handleSave={handleSave}
              handleClose={props.handleClose}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Form>
                  <EventGroupPaymentDialogTitle
                    balanceDueDollars={props.balanceDueDollars}
                    accountInfo={props.accountInfo}
                    mode={props.mode}
                  />
                  {errorMsg && <div className={classes.error}>{errorMsg}</div>}
                  <AddPaymentHeader
                    mode={props.mode}
                    refund={refund}
                    onRefundChanged={setRefund}
                    onPaymentTypeChanged={setPaymentType}
                    paymentType={paymentType}
                  />
                  {renderCheckForm()}
                  {renderPointsForm()}
                  {maybeRenderInvoices()}
                </Form>
              </MuiPickersUtilsProvider>
            </ResponsiveModalShell>
          </div>
        );
      }}
    />
  );
};

export default AddEditEventPayerPaymentModal;
