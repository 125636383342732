import React from "react";
import NumberFormat from "react-number-format";

export interface MaskedNumberInputProps {
  inputRef?: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { value: string; id: string } }) => void;
}

export function EINInput(props: MaskedNumberInputProps) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      mask="_"
      format="##-#######"
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
            // NOTE: without the addition of id below, we get the error:
            // Formik called `handleChange`, but you forgot to pass an `id` or `name` attribute to your input:
            // undefined
            // Formik cannot determine which value to update
            id: (props as any).id,
          },
        });
      }}
      decimalScale={0}
      allowNegative={false}
    />
  );
}
