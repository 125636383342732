import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import CompanyCategoryForm from "modals/components/CompanyForms/Category/Category";
import { ICompanyCategoryCommand } from "services/api/types";
import companyStore from "stores/shared/company";

type OriginalProps = {
  handleClose: () => void;
  existingCategoryCount: number;
};

type Props = OriginalProps & RouteComponentProps;
class ModalAddCompanyMarket extends Component<Props> {
  public render = () => (
    <CompanyCategoryForm
      existingCategoryCount={this.props.existingCategoryCount}
      companyId={companyStore.details!.id}
      formType="add"
      handleSubmit={this.handleSubmit}
      handleCancel={this.props.handleClose}
    />
  );

  private handleSubmit = async (payload: ICompanyCategoryCommand) => {
    await companyStore.createCategory(payload);
    this.props.handleClose();
  };
}

export default withRouter(ModalAddCompanyMarket);
