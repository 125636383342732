import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import { Formik, Field, Form, FormikActions, FormikProps } from "formik";
import Button from "@material-ui/core/Button";
import {
  INewEventData,
  IEventType,
  EventVisibility,
  IEventDetails,
  IExistingEventData,
  EventSignUpStatus,
} from "services/api/types";
import { TextField } from "formik-material-ui";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiTextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import DollarPointsInput from "components/Inputs/DollarPointsInput";
import moment from "moment";
import EventVisibilityRadioGroup from "./EventVisibilityRadioGroup";
import EventStatusRadioGroup from "./EventStatusRadioGroup";

type Props = {
  saveNewEvent: (event: INewEventData) => void;
  saveExistingEvent: (event: IExistingEventData) => void;
  handleClose: () => void;
  eventTypes: IEventType[];
  event?: IEventDetails;
  eventCostPerPoint: number;
};

const EventForm = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      formGrid: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: theme.spacing(1),
      },
      error: {
        color: theme.palette.error.main,
        marginLeft: theme.spacing(2),
      },
      formLabel: {
        fontSize: ".9rem",
      },
    })
  );

  const schema = yup.object().shape({
    eventName: yup.string().required("Event name is required"),
    location: yup.string().required("Location is required"),
    displayDate: yup.string().required("Date (for web) is required"),
    startDate: yup
      .date()
      .when("isPastEvent", {
        is: true,
        then: yup.date().required("Start date is required"),
      })
      .when("isPastEvent", {
        is: false,
        then: yup
          .date()
          .min(new Date(), "Start date must be in the future")
          .required("Start date is required"),
      }),
    endDate: yup
      .date()
      .min(yup.ref("startDate"), "End date cannot be before start date")
      .required("End date is required"),
    description: yup
      .string()
      .when("isPastEvent", {
        is: true,
        then: yup.string(),
      })
      .when("isPathEvent", {
        is: false,
        then: yup.string().required("Event description is required"),
      }),
    costPerPersonDollars: yup.number().nullable(),
    costPerPersonPoints: yup.number().nullable(),
    eventType: yup
      .object<Option>()
      .nullable()
      .required("Event type is required"),
  });

  type Option = { label: string; value: number };

  type FormData = {
    eventName: string;
    description: string;
    location: string;
    displayDate: string;
    startDate: Date | undefined;
    endDate: Date | undefined;
    eventType: Option | null;
    notes: string;
    costPerPersonDollars: number | undefined;
    costPerPersonPoints: number | undefined;
    visibility: EventVisibility;
    signUpStatus: EventSignUpStatus;
  };

  const submit = async (values: FormData, actions: FormikActions<FormData>) => {
    if (props.event) {
      props.saveExistingEvent({
        costPerPersonDollars: values.costPerPersonDollars,
        description: values.description,
        displayDate: values.displayDate,
        endDate: values.endDate!,
        startDate: values.startDate!,
        eventTypeId: values.eventType!.value,
        id: props.event.id,
        location: values.location,
        name: values.eventName,
        notes: values.notes,
        visibility: values.visibility,
        signUpStatus: values.signUpStatus,
      });
    } else {
      props.saveNewEvent({
        costPerPersonDollars: values.costPerPersonDollars,
        description: values.description,
        name: values.eventName,
        displayDate: values.displayDate,
        endDate: values.endDate!,
        startDate: values.startDate!,
        eventTypeId: values.eventType!.value,
        location: values.location,
        notes: values.notes,
        visibility: values.visibility,
        signUpStatus: values.signUpStatus,
      });
    }
  };

  const options = props.eventTypes.map((eventType) => {
    return { label: eventType.name, value: eventType.id };
  });

  const renderEventType = (formikProps: FormikProps<FormData>) => {
    const error = formikProps.errors.eventType;
    const showError =
      Boolean(error) && (formikProps.touched.eventType || false);

    return (
      <div style={{ gridColumn: "1 / 3" }}>
        <Autocomplete
          placeholder="Event Type"
          onBlur={formikProps.handleBlur}
          onChange={(event: any, option: any) => {
            return formikProps.setFieldValue("eventType", option);
          }}
          getOptionLabel={(option) => {
            if (typeof option.label === "string") {
              return option.label;
            }

            return (option as any).label.label;
          }}
          options={options}
          value={formikProps.values.eventType}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label="Event Type"
              variant="outlined"
              name="eventType"
              error={showError}
              margin="dense"
            />
          )}
        />
        {showError ? (
          <FormHelperText className={classes.error}>{error}</FormHelperText>
        ) : undefined}
      </div>
    );
  };

  const renderCostFields = (formikProps: FormikProps<FormData>) => {
    const pointCostError = formikProps.errors.costPerPersonPoints;
    const showPointCostError =
      Boolean(pointCostError) &&
      (formikProps.touched.costPerPersonPoints || false);

    const dollarCostError = formikProps.errors.costPerPersonDollars;
    const showDollarCostError =
      Boolean(dollarCostError) &&
      (formikProps.touched.costPerPersonDollars || false);

    return (
      <div style={{ gridColumn: "1 / 3" }}>
        <DollarPointsInput
          onChange={(evt) => {
            formikProps.setFieldValue("costPerPersonDollars", evt.dollars);
            formikProps.setFieldValue("costPerPersonPoints", evt.points);
          }}
          allowNegative={false}
          dollarsPerPoint={props.eventCostPerPoint}
          dollarValue={formikProps.values.costPerPersonDollars}
          pointValue={formikProps.values.costPerPersonPoints}
          showDollarsError={showDollarCostError}
          showPointsError={showPointCostError}
          pointsError={pointCostError}
          dollarsError={dollarCostError}
          dollarLabel="Cost Per Person (dollars)"
          pointLabel="Cost Per Person (points)"
        />
      </div>
    );
  };

  const getDateWithoutTime = (value: any) => {
    if (!moment.isDate(value)) return undefined;
    return moment(value).startOf("day").toDate();
  };

  const renderDates = (formikProps: FormikProps<FormData>) => {
    const startDateError = formikProps.errors.startDate;
    const showStartDateError =
      Boolean(startDateError) && (formikProps.touched.startDate || false);

    const endDateError = formikProps.errors.endDate;
    const showEndDateError =
      Boolean(endDateError) && (formikProps.touched.endDate || false);

    return (
      <>
        <div>
          <Field
            format="MM/dd/yyyy"
            margin="dense"
            onBlur={formikProps.handleBlur}
            disabled={formikProps.isSubmitting}
            name="startDate"
            id="startDate"
            label="Start Date"
            fullWidth={true}
            error={
              formikProps.errors.startDate && formikProps.touched.startDate
            }
            onChange={(newValue) => {
              const dateWithoutTime = getDateWithoutTime(newValue);
              formikProps.setFieldValue("startDate", dateWithoutTime);
            }}
            value={formikProps.values.startDate ?? null}
            component={KeyboardDatePicker}
            inputVariant="outlined"
          />
          {showStartDateError ? (
            <FormHelperText className={classes.error}>
              {startDateError}
            </FormHelperText>
          ) : undefined}
        </div>
        <div>
          <Field
            format="MM/dd/yyyy"
            margin="dense"
            id="endDate"
            onBlur={formikProps.handleBlur}
            disabled={formikProps.isSubmitting}
            name="endDate"
            fullWidth={true}
            error={formikProps.errors.endDate && formikProps.touched.endDate}
            label="End Date"
            onChange={(newValue) => {
              const dateWithoutTime = getDateWithoutTime(newValue);
              formikProps.setFieldValue("endDate", dateWithoutTime);
            }}
            value={formikProps.values.endDate ?? null}
            component={KeyboardDatePicker}
            inputVariant="outlined"
          />
          {showEndDateError ? (
            <FormHelperText className={classes.error}>
              {endDateError}
            </FormHelperText>
          ) : undefined}
        </div>
      </>
    );
  };

  const renderEventName = (formikProps: FormikProps<FormData>) => {
    return (
      <Field
        style={{ gridColumn: "1 / 3" }}
        variant="outlined"
        label="Event Name"
        margin="dense"
        component={TextField}
        fullWidth={true}
        disabled={formikProps.isSubmitting}
        name="eventName"
      />
    );
  };

  const renderDescription = (formikProps: FormikProps<FormData>) => {
    return (
      <Field
        style={{ gridColumn: "1 / 3" }}
        variant="outlined"
        label="Description"
        margin="dense"
        multiline={true}
        rows={3}
        component={TextField}
        fullWidth={true}
        disabled={formikProps.isSubmitting}
        name="description"
        InputProps={{
          style: { lineHeight: 1.4 },
        }}
      />
    );
  };

  const renderLocation = (formikProps: FormikProps<FormData>) => {
    return (
      <Field
        style={{ gridColumn: "1 / 3" }}
        variant="outlined"
        label="Location"
        margin="dense"
        component={TextField}
        fullWidth={true}
        disabled={formikProps.isSubmitting}
        name="location"
      />
    );
  };

  const renderDisplayDate = (formikProps: FormikProps<FormData>) => {
    return (
      <Field
        style={{ gridColumn: "1 / 3" }}
        variant="outlined"
        label="Date (for web)"
        margin="dense"
        component={TextField}
        fullWidth={true}
        disabled={formikProps.isSubmitting}
        name="displayDate"
      />
    );
  };

  const renderNotes = (formikProps: FormikProps<FormData>) => {
    return (
      <Field
        style={{ gridColumn: "1 / 3" }}
        variant="outlined"
        label="Notes"
        margin="dense"
        multiline={true}
        rows={4}
        component={TextField}
        fullWidth={true}
        disabled={formikProps.isSubmitting}
        name="notes"
      />
    );
  };

  const renderVisibility = (formikProps: FormikProps<FormData>) => {
    return (
      <div style={{ gridColumn: "1 / 2" }}>
        <EventVisibilityRadioGroup
          value={formikProps.values.visibility}
          onChange={(visibility) =>
            formikProps.setFieldValue("visibility", visibility)
          }
        />
      </div>
    );
  };

  const renderStatus = (formikProps: FormikProps<FormData>) => {
    return (
      <div style={{ gridColumn: "2 / 3" }}>
        <EventStatusRadioGroup
          value={formikProps.values.signUpStatus}
          onChange={(signUpStatus) =>
            formikProps.setFieldValue("signUpStatus", signUpStatus)
          }
        />
      </div>
    );
  };

  const getEventTypeOption = () => {
    return props.event
      ? options.find((o) => o.value === props.event!.eventTypeId)!
      : null;
  };

  const getCostPerPersonPoints = () => {
    return props.event && props.event.costPerPersonDollars
      ? props.event.costPerPersonDollars / props.eventCostPerPoint
      : undefined;
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Formik
        validationSchema={schema}
        render={(formikProps) => {
          return (
            <Form>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div className={classes.formGrid}>
                  {renderEventName(formikProps)}
                  {renderDescription(formikProps)}
                  {renderEventType(formikProps)}
                  {renderLocation(formikProps)}
                  {renderDates(formikProps)}
                  {renderDisplayDate(formikProps)}
                  {renderCostFields(formikProps)}
                  {renderVisibility(formikProps)}
                  {renderStatus(formikProps)}
                  {renderNotes(formikProps)}
                </div>
                <Button
                  disabled={formikProps.isSubmitting}
                  onClick={() => {
                    formikProps.handleSubmit();
                  }}
                >
                  Save
                </Button>
                <Button
                  disabled={formikProps.isSubmitting}
                  onClick={() => props.handleClose()}
                >
                  Cancel
                </Button>
              </MuiPickersUtilsProvider>
            </Form>
          );
        }}
        onSubmit={submit}
        initialValues={{
          eventName: props.event?.name ?? "",
          isPastEvent: props.event?.isPastEvent ?? false,
          description: props.event?.description ?? "",
          eventType: getEventTypeOption(),
          location: props.event?.location ?? "",
          startDate: props.event?.startDate ?? undefined,
          endDate: props.event?.endDate ?? undefined,
          displayDate: props.event?.displayDate ?? "",
          notes: props.event?.notes ?? "",
          costPerPersonDollars: props.event?.costPerPersonDollars,
          costPerPersonPoints: getCostPerPersonPoints(),
          visibility: props.event?.visibility ?? "Both",
          signUpStatus: props.event?.signUpStatus ?? "Open",
        }}
      ></Formik>
    </div>
  );
};

export default EventForm;
