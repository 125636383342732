import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { formatNumber } from "utils/number";
import { IRedemptionSummaryLine } from "services/api/types";

type Props = {
  redemptions: IRedemptionSummaryLine[];
  size: "small" | "normal";
};

const RedemptionSummaryTable = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      tableCellRoot: {
        fontSize: props.size === "small" ? 12 : "unset",
      },
    })
  );

  const classes = useStyles();
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell classes={{ root: classes.tableCellRoot }}>
            Category
          </TableCell>
          <TableCell classes={{ root: classes.tableCellRoot }} align="right">
            Qty
          </TableCell>
          <TableCell classes={{ root: classes.tableCellRoot }} align="right">
            Points
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.redemptions.map((l) => {
          return (
            <TableRow key={l.typeId}>
              <TableCell classes={{ root: classes.tableCellRoot }}>
                {l.typeName}
              </TableCell>
              <TableCell
                classes={{ root: classes.tableCellRoot }}
                align="right"
              >
                {formatNumber(l.quantity)}
              </TableCell>
              <TableCell
                classes={{ root: classes.tableCellRoot }}
                align="right"
              >
                {formatNumber(l.points)}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default RedemptionSummaryTable;
