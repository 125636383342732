import { Form, Formik, FormikActions, FormikProps } from "formik";
import React from "react";
import { Omit } from "utils/type";
import Grid from "@material-ui/core/Grid";
import { defaultErrorHandler } from "services/api/errors";
import {
  ICompanyLocationPayload,
  ISmallContact,
  ILocationDetailed,
} from "services/api/types";
import enumerableStore from "stores/enumerable";
import { Observer } from "mobx-react";
import Address1Field from "./Address1Field";
import Address2Field from "./Address2Field";
import AddressTypeField from "./AddressTypeField";
import CityField from "./CityField";
import PrimaryContactField from "./PrimaryContactField";
import ShowOnInternetField from "./ShowOnInternetField";
import StateField from "./StateField";
import ZipField from "./ZipField";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import * as yup from "yup";

const schema = yup.object().shape({
  primaryContactId: yup.string().required("Required"),
  address1: yup.string().required("Required"),
  address2: yup.string().notRequired(),
  city: yup.string().required("Required"),
  state: yup.string().required("Required"),
  zip: yup.string().required("Required"),
  addressType: yup.string().required("Required"),
  showOnInternet: yup.boolean().required("Required"),
});

type FormData = {
  primaryContactId: string;
  addressId?: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  addressType: string;
  showOnInternet: boolean;
};

type Props = {
  companyLocation?: ILocationDetailed;
  companyContacts: ISmallContact[];
  companyContactsLoading?: boolean;
  handleCancel: () => void;
  handleSubmit: (
    payload: Omit<ICompanyLocationPayload["companyLocation"], "companyId">
  ) => void;
  formType: "add" | "update";
};

const LocationForm = (props: Props) => {
  React.useEffect(() => {
    enumerableStore.ensureAddressTypesFetched();
  }, []);

  const { companyLocation } = props;

  const handleFormSubmit = async (
    values: FormData,
    actions: FormikActions<FormData>
  ) => {
    const locationPayload = {
      primaryContactId: values.primaryContactId,
      address: {
        id: values.addressId,
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        zip: values.zip,
        type: values.addressType,
      },
      showOnInternet: !!values.showOnInternet,
    };

    try {
      await props.handleSubmit(locationPayload);
    } catch (err) {
      defaultErrorHandler(err);
    }
    actions.setSubmitting(false);
  };

  const renderPrimaryContact = (formikProps: FormikProps<FormData>) => {
    return (
      <PrimaryContactField
        error={formikProps.errors.primaryContactId}
        touched={formikProps.touched.primaryContactId}
        companyContacts={props.companyContacts}
        value={formikProps.values.primaryContactId}
        handleChange={(newId) =>
          formikProps.setFieldValue("primaryContactId", newId)
        }
      />
    );
  };

  const renderState = (formikProps: FormikProps<FormData>) => {
    return (
      <StateField
        value={formikProps.values.state}
        touched={formikProps.touched.state}
        handleChange={formikProps.handleChange}
        error={formikProps.errors.state}
      />
    );
  };

  const renderAddress1 = (formikProps: FormikProps<FormData>) => {
    return (
      <Address1Field
        value={formikProps.values.address1}
        touched={formikProps.touched.address1}
        handleChange={formikProps.handleChange}
        error={formikProps.errors.address1}
      />
    );
  };

  const renderAddressType = (
    formikProps: FormikProps<FormData>,
    addressTypes: string[]
  ) => {
    return (
      <AddressTypeField
        addressTypes={addressTypes}
        error={formikProps.errors.addressType}
        touched={formikProps.touched.addressType}
        handleChange={formikProps.handleChange}
        value={formikProps.values.addressType}
      />
    );
  };

  const renderAddress2 = (formikProps: FormikProps<FormData>) => {
    return (
      <Address2Field
        value={formikProps.values.address2}
        touched={formikProps.touched.address2}
        handleChange={formikProps.handleChange}
        error={formikProps.errors.address2}
      />
    );
  };

  const renderCity = (formikProps: FormikProps<FormData>) => {
    return (
      <CityField
        value={formikProps.values.city}
        touched={formikProps.touched.city}
        handleChange={formikProps.handleChange}
        error={formikProps.errors.city}
      />
    );
  };

  const renderZip = (formikProps: FormikProps<FormData>) => {
    return (
      <ZipField
        value={formikProps.values.zip}
        touched={formikProps.touched.zip}
        handleChange={formikProps.handleChange}
        error={formikProps.errors.zip}
      />
    );
  };

  const renderShowOnInternet = (formikProps: FormikProps<FormData>) => {
    return (
      <ShowOnInternetField
        value={formikProps.values.showOnInternet}
        handleChange={formikProps.handleChange}
      />
    );
  };

  return (
    <Observer
      render={() => {
        const addressTypes = enumerableStore.getEnum("addressTypes");

        if (enumerableStore.isLoading("addressTypes")) {
          return <div />;
        }

        const initialValues: FormData = {
          addressId: companyLocation?.address?.id,
          primaryContactId: companyLocation?.primaryContact?.id ?? "",
          address1: companyLocation?.address?.address1 ?? "",
          address2: companyLocation?.address?.address2 ?? "",
          city: companyLocation?.address?.city ?? "",
          state: companyLocation?.address?.state ?? "MN",
          zip: companyLocation?.address?.zip ?? "",
          showOnInternet: companyLocation?.showOnInternet ?? false,
          addressType: companyLocation?.address?.type ?? "Business",
        };

        return (
          <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={handleFormSubmit}
          >
            {(formikProps) => (
              <Form>
                <ResponsiveModalShell
                  handleClose={props.handleCancel}
                  title={
                    props.formType === "add"
                      ? "Add Location"
                      : "Update Location"
                  }
                  disableSaveButton={formikProps.isSubmitting}
                  handleSave={() => formikProps.submitForm()}
                >
                  <Grid alignItems="center" container spacing={1}>
                    <Grid item xs={12}>
                      {renderPrimaryContact(formikProps)}
                    </Grid>
                    <Grid item xs={12}>
                      {renderAddressType(formikProps, addressTypes)}
                    </Grid>
                    <Grid item xs={12}>
                      {renderAddress1(formikProps)}
                    </Grid>
                    <Grid item xs={12}>
                      {renderAddress2(formikProps)}
                    </Grid>
                    <Grid item xs={5}>
                      {renderCity(formikProps)}
                    </Grid>
                    <Grid item xs={3}>
                      {renderState(formikProps)}
                    </Grid>
                    <Grid item xs={4}>
                      {renderZip(formikProps)}
                    </Grid>
                    <Grid item xs={12}>
                      {renderShowOnInternet(formikProps)}
                    </Grid>
                  </Grid>
                </ResponsiveModalShell>
              </Form>
            )}
          </Formik>
        );
      }}
    />
  );
};

export default LocationForm;
