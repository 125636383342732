import { apiClient } from "services/api/client";
import {
  ITrackedLead,
  ICreateTrackedLeadCommand,
  TrackedLeadType,
} from "services/api/types";
import { downloadFile } from "services/api/modules/helpers";

const TRACKED_LEADS_URL = "administrator/trackedLeads";

export const emailReportToCurrentUser = async () => {
  const url = `${TRACKED_LEADS_URL}/emailReport`;
  const response = await apiClient.post(url);
  return response.data;
};

export const getTrackedLeads = async (type: TrackedLeadType) => {
  const response = await apiClient.get<ITrackedLead[]>(TRACKED_LEADS_URL, {
    params: {
      type,
    },
  });
  return response.data;
};

export const getTrackedLeadsExport = async (type: TrackedLeadType) => {
  const url = `${TRACKED_LEADS_URL}/export`;
  const response = await apiClient.get<ITrackedLead[]>(url, {
    params: {
      type,
    },
    responseType: "blob",
  });

  downloadFile(response);
};

export const updateNote = async (trackedLeadId: string, notes: string) => {
  const url = `${TRACKED_LEADS_URL}/${trackedLeadId}`;
  const response = await apiClient.put<ITrackedLead>(url, {
    trackedLeadId,
    notes,
  });
  return response.data;
};

export const createTrackedLeads = async (
  command: ICreateTrackedLeadCommand
) => {
  const response = await apiClient.post<ITrackedLead[]>(
    TRACKED_LEADS_URL,
    command
  );
  return response.data;
};

export const deleteTrackedLead = async (trackedLeadId: string) => {
  const url = `${TRACKED_LEADS_URL}/${trackedLeadId}`;
  const response = await apiClient.delete(url);
  return response.data;
};
