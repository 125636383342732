import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { IGlobalSearchResponseCompany } from "services/api/types";
import Typography from "@material-ui/core/Typography";
import { Link } from "react-router-dom";
import { paths } from "routes/Paths";
import clsx from "clsx";
import { companyColors } from "areas/Shared/companyColors";

type Props = {
  suppliers: IGlobalSearchResponseCompany[];
  members: IGlobalSearchResponseCompany[];
  header?: string;
  onLinkClicked?: () => void;
};

const CompaniesGrid = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "1fr",
          gridGap: 0,
        },
      },
      sponsor: {
        color: companyColors.sponsor,
      },
      competitor: {
        color: companyColors.competitor,
      },
      prospect: {
        color: companyColors.prospect,
      },
      member: {
        color: companyColors.member,
      },
      link: {
        display: "inline-block",
        textDecoration: "none",
        cursor: "pointer",
        fontSize: theme.typography.fontSize,
        margin: ".2em 0",
        "&:hover": {
          color: theme.palette.primary.main,
        },
        [theme.breakpoints.only("xs")]: {
          padding: "2px 0",
        },
      },
      header: {
        marginBottom: theme.spacing(1),
        display: "inline-block",
        color: theme.palette.grey[500],
      },
      title: {
        gridColumn: "1 / 3",
        marginBottom: theme.spacing(2),
        [theme.breakpoints.only("xs")]: {
          gridColumn: "unset",
        },
      },
      companyGroup: {
        marginBottom: theme.spacing(3),
      },
    })
  );

  const classes = useStyles();

  const renderCompanyGroup = (
    groupTitle: string,
    companies: IGlobalSearchResponseCompany[]
  ) => {
    return (
      <div className={classes.companyGroup}>
        <Typography className={classes.header} variant="caption">
          {groupTitle}
        </Typography>
        {companies.map((company) => {
          return (
            <div key={company.id}>
              <Link
                onClick={() => {
                  if (props.onLinkClicked) {
                    props.onLinkClicked();
                  }
                }}
                className={clsx(classes.link, {
                  [classes.sponsor]: company.companyType === "Sponsor",
                  [classes.competitor]: company.companyType === "Competitor",
                  [classes.prospect]: company.companyType === "Prospect",
                  [classes.member]: company.companyType === "Member",
                })}
                to={`${paths.app.admin.companyDetails}/${company.id}/overview`}
              >
                {company.name}
              </Link>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={classes.root}>
      {props.header ? (
        <div className={classes.title}>
          <strong>{props.header}</strong>
        </div>
      ) : undefined}
      {renderCompanyGroup("Members", props.members)}
      {renderCompanyGroup("Suppliers", props.suppliers)}
    </div>
  );
};

export default CompaniesGrid;
