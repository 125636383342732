import { AxiosResponse } from "axios";
import { action, observable, set, computed } from "mobx";
import api from "services/api";
import {
  IBrand,
  ICategory,
  IMarket,
  IProduct,
  IUnit,
  ITransactionType,
  IEventType,
  IRelationType,
  IRelationshipType,
} from "services/api/types";
import { Diff } from "utils/type";

type EnumerablesDict = {
  eventTypes?: IEventType[];
  markets?: IMarket[];
  categories?: ICategory[];
  brands?: IBrand[];
  products?: IProduct[];
  taxNumberTypes?: string[];
  addressTypes?: string[];
  phoneNumberTypes?: string[];
  emailTypes?: string[];
  units?: IUnit[];
  transactionTypes?: ITransactionType[];
  relationTypes?: IRelationType[];
  relationshipTypes?: IRelationshipType[];
};

type LoadingDict = { [key in keyof EnumerablesDict]?: boolean };

export class EnumerableStore {
  @observable
  private dict: EnumerablesDict = {};

  @observable
  private loading: LoadingDict = {};

  @computed
  public get profileFormCategories() {
    const categories = this.getEnum("categories");
    return categories.filter((c) => c.showOnSupplierProfile);
  }

  public getEnum<T extends keyof EnumerablesDict>(key: T) {
    return (this.dict[key] || []) as Diff<EnumerablesDict[T], undefined>;
  }

  public isLoading(key: keyof EnumerablesDict) {
    return !!this.loading[key];
  }

  @action.bound
  public async ensureMarketsFetched(): Promise<void> {
    if (this.shouldFetch("markets")) {
      this.fetchEnumerables("markets", api.enumerables.marketsShow);
    }
  }

  @action.bound
  public async ensureEventTypesFetched(): Promise<void> {
    if (this.shouldFetch("eventTypes")) {
      this.fetchEnumerables("eventTypes", api.enumerables.eventTypes);
    }
  }

  @action.bound
  public async ensureCategoriesFetched(): Promise<void> {
    if (this.shouldFetch("categories")) {
      this.fetchEnumerables("categories", api.enumerables.categoriesShow);
    }
  }

  @action.bound
  public async ensureBrandsFetched(force = false): Promise<void> {
    if (force || this.shouldFetch("brands")) {
      this.fetchEnumerables("brands", api.enumerables.brandsShow);
    }
  }

  @action.bound
  public async ensureProductsFetched(force = false): Promise<void> {
    if (force || this.shouldFetch("products")) {
      this.fetchEnumerables("products", api.enumerables.productsShow);
    }
  }

  @action.bound
  public async ensureTaxNumberTypesFetched(): Promise<void> {
    if (this.shouldFetch("taxNumberTypes")) {
      this.fetchEnumerables(
        "taxNumberTypes",
        api.enumerables.taxNumberTypesShow
      );
    }
  }

  @action.bound
  public async ensureAddressTypesFetched(): Promise<void> {
    if (this.shouldFetch("addressTypes")) {
      this.fetchEnumerables("addressTypes", api.enumerables.addressTypesShow);
    }
  }

  @action.bound
  public async ensurePhoneNumberTypesFetched(): Promise<void> {
    if (this.shouldFetch("phoneNumberTypes")) {
      this.fetchEnumerables(
        "phoneNumberTypes",
        api.enumerables.phoneNumberTypesShow
      );
    }
  }

  @action.bound
  public async ensureEmailTypesFetched(): Promise<void> {
    if (this.shouldFetch("emailTypes")) {
      this.fetchEnumerables("emailTypes", api.enumerables.emailTypesShow);
    }
  }

  @action.bound
  public async ensureUnitsFetched(): Promise<void> {
    if (this.shouldFetch("units")) {
      this.fetchEnumerables("units", api.enumerables.units);
    }
  }

  @action.bound
  public async ensureTransactionTypesFetched(): Promise<void> {
    if (this.shouldFetch("transactionTypes")) {
      this.fetchEnumerables(
        "transactionTypes",
        api.enumerables.transactionTypes
      );
    }
  }

  @action.bound
  public async ensureRelationTypesFetched(): Promise<void> {
    if (this.shouldFetch("relationTypes")) {
      this.fetchEnumerables("relationTypes", api.enumerables.relationTypes);
    }
  }

  @action.bound
  public async ensureRelationshipTypesFetched(): Promise<void> {
    if (this.shouldFetch("relationshipTypes")) {
      this.fetchEnumerables(
        "relationshipTypes",
        api.enumerables.relationshipTypes
      );
    }
  }

  private shouldFetch(key: keyof EnumerablesDict) {
    return !this.dict[key] && !this.loading[key];
  }

  @action.bound
  private async fetchEnumerables<T extends keyof EnumerablesDict>(
    key: T,
    fetchItems: () => Promise<AxiosResponse<{ [k in T]: EnumerablesDict[T] }>>
  ) {
    set(this.loading, key, true);
    try {
      const response = await fetchItems();
      set(this.dict, key, response.data[key]);
    } catch (e) {
      console.log(e);
    }
    set(this.loading, key, false);
  }
}

export default new EnumerableStore();
