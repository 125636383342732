import React from "react";

type Props = {
  message?: string;
};

// This component should be removed. It used to show a spinner but that got annoying
const Loader = (props: Props) => {
  return <div />;
};

export default Loader;
