import { compare, Operation } from "fast-json-patch";

import {
  IAddress,
  ICompanyLocationPayload,
  IContactDetailed,
  ILocationDetailed,
  ICompanyCategory,
  ICompanyCategoryCommand,
  StatementPreference
} from "services/api/types";

export type IPatchLocation = {
  address: IAddress;
  primaryContactId?: string;
  showOnInternet: boolean;
};

export type IPatchCompanyCategory = {
  id?: string;
  companyId: string;
  categoryId: string;
  rate: number;
  doNotAdvertise?: boolean;
  showOnInternet: boolean;
  showOnReport: boolean;
};

export type IPatchContact = {
  firstName: string;
  altName: string;
  lastName: string;
  email: string;
  role: string;
  birthdate?: Date;
  anniversary?: Date;
  statementPreference: StatementPreference;
  getNameTag: boolean;
  getNewsletter: boolean;
  extraNewsletters: number;
};

export type IPatchNote = {
  content: string;
  isImportant: boolean;
};

export const getNotePatchDocument = (
  originalNote: IPatchNote,
  updatedNote: IPatchNote
) => {
  const operations = compare(originalNote, updatedNote);
  return operations;
};

export const getCompanyCategoryPatchDocument = (
  original: ICompanyCategory,
  updated: ICompanyCategoryCommand
) => {
  const originalMarket: IPatchCompanyCategory = {
    id: original.id,
    categoryId: original.category.id,
    companyId: updated.companyId,
    rate: original.rate,
    showOnInternet: original.showOnInternet,
    showOnReport: original.showOnReport
  };

  const operations = compare(originalMarket, updated);
  return operations;
};

export const getContactPatchDocument = (
  original: IContactDetailed,
  updated: IContactDetailed
) => {
  const originalCopy = Object.assign({}, original, {
    primaryPhoneIndex: undefined
  });
  const updatedCopy = Object.assign({}, updated, {
    primaryPhoneIndex: undefined
  });

  const patchDocument = compare(originalCopy, updatedCopy);
  return patchDocument;
};

export const getLocationPatchDocument = (
  storeLocation: ILocationDetailed,
  updatePayload: ICompanyLocationPayload
): Operation[] => {
  const originalShaped: IPatchLocation = {
    primaryContactId: storeLocation.primaryContact
      ? storeLocation.primaryContact.id
      : undefined,
    address: storeLocation.address,
    showOnInternet: storeLocation.showOnInternet,
  };

  const newShaped: IPatchLocation = {
    primaryContactId: updatePayload.companyLocation.primaryContactId,
    address: updatePayload.companyLocation.address,
    showOnInternet: updatePayload.companyLocation.showOnInternet,
  };

  const operations = compare(originalShaped, newShaped);
  return operations;
};
