import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import { paths } from "./Paths";
import SubtleLinearProgress from "components/Shell/SubtleLinearProgress";

const SponsorLandingPage = React.lazy(
  () => import("areas/Sponsors/SponsorLandingPage")
);
const MemberDirectoryContainer = React.lazy(
  () => import("areas/Sponsors/MemberDirectory/MemberDirectoryContainer")
);
const MonthlyReportListContainer = React.lazy(
  () => import("../areas/Sponsors/MonthlyReportListContainer")
);
const PointsHistoryContainer = React.lazy(
  () => import("areas/Shared/PointsHistory/PointsHistoryContainer")
);
const SponsorDirectoryContainer = React.lazy(
  () => import("areas/Shared/SponsorDirectory/SponsorDirectoryContainer")
);
const UpcomingEvents = React.lazy(() => import("areas/Shared/Events/Events"));
const EventDetails = React.lazy(
  () => import("areas/Shared/Events/EventDetails")
);
const EventAttendeesContainer = React.lazy(
  () => import("areas/Shared/Events/EventAttendeesContainer")
);
const PastEvents = React.lazy(
  () => import("areas/Shared/Events/PastEventsContainer")
);
const EditorContainer = React.lazy(
  () => import("../areas/Shared/MonthlyReports/EditorContainer")
);
const ContactUs = React.lazy(() => import("areas/Shared/ContactUs"));
const CompanyDetails = React.lazy(
  () => import("areas/Shared/CompanyDetails/CompanyDetailsContainer")
);

const NewspapersContainer = React.lazy(
  () => import("areas/Shared/Newspapers/NewspaperIssuesContainer")
);

const SponsorRoutes = () => {
  const history = useHistory();
  return (
    <React.Suspense fallback={<SubtleLinearProgress />}>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path={paths.app.root}
            render={() => <Redirect to={`${paths.app.sponsors.root}`} />}
          />
          <Route
            exact
            path={paths.app.sponsors.root}
            component={SponsorLandingPage}
          />
          <Route
            path={paths.app.sponsors.myCompany}
            component={CompanyDetails}
          />
          <Route
            exact
            path={`${paths.app.sponsors.root}/newspapers`}
            render={(p) => (
              <NewspapersContainer {...p} audience="CompanyUsers" />
            )}
          />
          <Route
            exact
            path={`${paths.app.sponsors.root}/members`}
            component={MemberDirectoryContainer}
          />
          <Route
            exact
            path={`${paths.app.sponsors.root}/monthlyReports`}
            component={MonthlyReportListContainer}
          />
          <Route
            exact
            path={`${paths.app.sponsors.root}/monthlyReports/history`}
            render={(p) => (
              <PointsHistoryContainer
                {...p}
                mode="SponsorPointsAwarded"
                hasToolbar={true}
              />
            )}
          />
          <Route
            path={`${paths.app.sponsors.root}/sponsors`}
            render={(p) => <SponsorDirectoryContainer {...p} mode="Sponsor" />}
          />
          <Route
            exact
            path={`${paths.app.sponsors.root}/events`}
            render={(p) => <UpcomingEvents {...p} audience="Sponsor" />}
          />
          <Route
            exact
            path={`${paths.app.sponsors.root}/events/:eventId/signup`}
            component={EventDetails}
          />
          <Route
            exact
            path={`${paths.app.sponsors.root}/events/:eventId/attendees`}
            component={EventAttendeesContainer}
          />
          <Route
            exact
            path={`${paths.app.sponsors.root}/past-events`}
            component={PastEvents}
          />
          <Route
            exact
            path={`${paths.app.sponsors.root}/newMonthlyReport`}
            render={(p) => <EditorContainer audience="sponsor" {...p} />}
          />
          <Route
            exact
            path={`${paths.app.sponsors.root}/monthlyReports/:monthlyReportId`}
            render={(p) => <EditorContainer audience="sponsor" {...p} />}
          />
          <Route
            exact
            path={`${paths.app.sponsors.root}/contact`}
            component={ContactUs}
          />
          <Route exact path="*" render={() => <div>Not found</div>} />
        </Switch>
      </Router>
    </React.Suspense>
  );
};

export default SponsorRoutes;
