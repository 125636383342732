import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Drawer from "@material-ui/core/Drawer";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Typography from "@material-ui/core/Typography";

export type Option = {
  value: string;
  label: string;
  icon?: JSX.Element;
};

type BaseProps = {
  label: string;
};

type DropDownProps = {
  options: Option[];
  value: string;
  onChange: (value: string) => void;
} & BaseProps;

type DrawerProps = {
  renderDrawerContents: () => JSX.Element;
  isDrawerOpen: boolean;
  setIsDrawerOpen: (value: boolean) => void;
} & BaseProps;

const DropDownPill = (props: DropDownProps | DrawerProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const isDropDownProps = (
    testProps: DropDownProps | DrawerProps
  ): testProps is DropDownProps => {
    return (testProps as DropDownProps).options !== undefined;
  };

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        marginRight: theme.spacing(1),
      },
      pill: {
        display: "inline-block",
        borderRadius: 22,
        padding: isDropDownProps(props) ? "6px 31px 6px 14px" : "6px 14px",
        backgroundColor: "#E2EEFF",
        marginRight: theme.spacing(1),
        overflow: "hidden",
        whiteSpace: "nowrap",
        position: "relative",
        fontSize: "small",
      },
      arrow: {
        position: "absolute",
        transform: "translate(2px, -2px)",
      },
      icon: {
        marginRight: 7,
        transform: "translate(-5px, 3px)",
      },
    })
  );

  const classes = useStyles();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {isDropDownProps(props) &&
          props.options.map((option, index) => {
            return (
              <MenuItem
                onClick={() => {
                  props.onChange(option.value);
                  handleMenuClose();
                }}
                key={index}
              >
                {option.icon ? (
                  <>
                    <span className={classes.icon}>{option.icon}</span>
                    <Typography variant="inherit">{option.label}</Typography>
                  </>
                ) : (
                  <div>{option.label}</div>
                )}
              </MenuItem>
            );
          })}
      </Menu>
    );
  };

  const handlePillClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isDropDownProps(props)) {
      setAnchorEl(event.currentTarget as any);
    } else {
      props.setIsDrawerOpen(true);
    }
  };

  const renderPill = () => {
    return (
      <span onClick={handlePillClick} className={classes.pill}>
        <span>{props.label}</span>
        {isDropDownProps(props) && <ArrowDropDown className={classes.arrow} />}
      </span>
    );
  };

  return (
    <>
      {renderPill()}
      {renderMenu()}
      {!isDropDownProps(props) && (
        <Drawer
          open={props.isDrawerOpen}
          onClose={() => props.setIsDrawerOpen(false)}
        >
          {props.renderDrawerContents()}
        </Drawer>
      )}
    </>
  );
};

export default DropDownPill;
