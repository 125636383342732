import { apiClient } from "services/api/client";
import { ISupplierPurchaserResult } from "services/api/types";
import { getFileName } from "services/api/modules/helpers";

const SUPPLIERS_URL = "shared/suppliers";

export const getPurchasers = async (supplierId: string) => {
  const url = `${SUPPLIERS_URL}/${supplierId}/purchasers`;
  const response = await apiClient.get<ISupplierPurchaserResult>(url);
  return response.data;
};

export const getPurchasersExport = async (supplierId: string) => {
  const url = `${SUPPLIERS_URL}/${supplierId}/purchasers/export`;

  const response = await apiClient.post(url, {}, { responseType: "blob" });

  // NOTE: Don't use "purchasers" in the UI or export names
  const fileName = getFileName(response, "Members.xlsx");

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};
