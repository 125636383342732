import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import HomeIcon from "@material-ui/icons/Home";
import EventsIcon from "@material-ui/icons/Event";
import MyCompanyIcon from "@material-ui/icons/Stars";
import MonthlyReportsIcon from "@material-ui/icons/ViewColumn";
import MembersIcon from "@material-ui/icons/House";
import SponsorsIcon from "@material-ui/icons/Business";
import ContactUsIcon from "@material-ui/icons/ContactPhone";
import { makeStyles } from "@material-ui/core/styles";
import NewspaperIcon from "@material-ui/icons/Receipt";
import BarChartIcon from "@material-ui/icons/BarChart";
import myStore from "stores/my";
import * as roles from "types/roles";
import { paths } from "routes/Paths";
import { useHistory } from "react-router";
import MobileOnlyHomeListItem from "components/Shell/MobileOnlyHomeListItem";
import clsx from "clsx";

const SponsorSidebar = () => {
  const useStyles = makeStyles((t) => {
    return {
      drawerIcon: {
        color: t.palette.common.white,
      },
      nested: {
        paddingLeft: t.spacing(4),
      },
    };
  });

  const classes = useStyles();
  const history = useHistory();

  const renderListItem = (
    nested: boolean,
    subRoute: string,
    label: string,
    icon: JSX.Element,
    ...permissionsParam: roles.CompanyRoleKey[]
  ) => {
    if (myStore.hasAnyCompanyRole(permissionsParam)) {
      return (
        <ListItem
          className={clsx("bcn-closes-drawer", {
            [classes.nested]: nested,
          })}
          selected={history.location.pathname.endsWith(subRoute)}
          button
          onClick={() => history.push(`${paths.app.sponsors.root}/${subRoute}`)}
        >
          <ListItemIcon className="bcn-closes-drawer">{icon}</ListItemIcon>
          <div className="bcn-closes-drawer">{label}</div>
        </ListItem>
      );
    }

    return undefined;
  };

  const renderList = () => {
    return (
      <List>
        {renderListItem(
          false,
          "",
          "Home",
          <HomeIcon className={classes.drawerIcon} />,
          roles.sponsorAdmin,
          roles.sponsorAccounting,
          roles.sponsorSales
        )}
        {renderListItem(
          false,
          "events",
          "Events/Trips",
          <EventsIcon className={classes.drawerIcon} />,
          roles.sponsorAdmin,
          roles.sponsorAccounting,
          roles.sponsorSales
        )}
        {renderListItem(
          false,
          "myCompany/overview",
          "My Company",
          <MyCompanyIcon className={classes.drawerIcon} />,
          roles.sponsorAdmin,
          roles.sponsorSales,
          roles.sponsorAccounting
        )}
        {renderListItem(
          false,
          "monthlyReports",
          "Monthly Reports",
          <MonthlyReportsIcon className={classes.drawerIcon} />,
          roles.sponsorAdmin,
          roles.sponsorAccounting
        )}
        {renderListItem(
          true,
          "monthlyReports/history",
          "Points Awarded History",
          <BarChartIcon className={classes.drawerIcon} />,
          roles.sponsorAdmin,
          roles.sponsorSales,
          roles.sponsorAccounting
        )}
        {renderListItem(
          false,
          "members",
          "Members",
          <MembersIcon className={classes.drawerIcon} />,
          roles.sponsorAdmin,
          roles.sponsorAccounting,
          roles.sponsorSales
        )}
        {renderListItem(
          false,
          "sponsors",
          "Sponsors",
          <SponsorsIcon className={classes.drawerIcon} />,
          roles.sponsorAdmin,
          roles.sponsorAccounting,
          roles.sponsorSales
        )}
        {renderListItem(
          false,
          "newspapers",
          "Newspapers",
          <NewspaperIcon className={classes.drawerIcon} />,
          roles.sponsorAdmin,
          roles.sponsorAccounting,
          roles.sponsorSales
        )}
        {renderListItem(
          false,
          "contact",
          "Contact Us",
          <ContactUsIcon className={classes.drawerIcon} />,
          roles.sponsorAdmin,
          roles.sponsorAccounting,
          roles.sponsorSales
        )}
        <MobileOnlyHomeListItem />
      </List>
    );
  };

  return renderList();
};

export default SponsorSidebar;
