import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ITinyCompany } from "services/api/types";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import clsx from "clsx";

type Props = {
  sponsors: ITinyCompany[];
  checkedSponsorIds: string[];
  onSponsorCheckedChange: (sponsor: ITinyCompany, isChecked: boolean) => void;
  linkedSponsorIds: string[];
  existingLeadSponsorIds: string[];
  disabled: boolean;
};

const SponsorChecklist = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        height: "100%",
        display: "grid",
        gridTemplateRows: "auto 1fr",
      },
      box: {
        overflowY: "scroll",
        border: "1px solid gainsboro",
        padding: theme.spacing(1),
        margin: theme.spacing(2, 0),
        height: "100%",
      },
      checkbox: {
        display: "block",
      },
      label: {
        transform: "translateY(2px)",
        display: "inline-block",
      },
      disabled: {
        opacity: 0.5,
        pointerEvents: "none",
      },
    })
  );

  const classes = useStyles();

  const getSponsorLabel = (
    name: string,
    alreadyLinked: boolean,
    leadExists: boolean
  ) => {
    if (alreadyLinked) {
      return `${name} (linked)`;
    }

    if (leadExists) {
      return `${name} (lead exists)`;
    }

    return name;
  };

  return (
    <div className={classes.root}>
      <Typography
        color="primary"
        variant="caption"
      >{`Matching Sponsors (${props.sponsors.length}):`}</Typography>
      <div
        className={clsx(classes.box, {
          [classes.disabled]: props.disabled,
        })}
      >
        {props.sponsors.length === 0 && (
          <Typography>No sponsors found</Typography>
        )}
        {props.sponsors.map((s) => {
          const alreadyLinked = props.linkedSponsorIds.includes(s.id);
          const leadExists = props.existingLeadSponsorIds.includes(s.id);

          return (
            <FormControlLabel
              className={classes.checkbox}
              disabled={alreadyLinked || leadExists}
              classes={{
                label: classes.label,
              }}
              control={
                <Checkbox
                  checked={props.checkedSponsorIds.includes(s.id)}
                  onChange={(e) => {
                    props.onSponsorCheckedChange(s, e.target.checked);
                  }}
                />
              }
              label={getSponsorLabel(s.name, alreadyLinked, leadExists)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SponsorChecklist;
