import { apiClient } from "services/api/client";
import {
  IListedOrder,
  IFullOrderModel,
  IGiftCardModel,
} from "services/api/types";

export const getAllForMember = async (memberId: string) => {
  const url = `shared/companies/${memberId}/orders`;
  const response = await apiClient.get<IListedOrder[]>(url);
  return response;
};

export const getCatalogOrderDetails = async (purchaseOrderNumber: string) => {
  const url = `shared/catalogOrders/${purchaseOrderNumber}`;
  const response = await apiClient.get<IFullOrderModel>(url);
  return response;
};

export const getGiftCardOrderDetails = async (purchaseOrderNumber: string) => {
  const url = `shared/giftCardOrders/${purchaseOrderNumber}`;
  const response = await apiClient.get<IGiftCardModel>(url);
  return response;
};

export const getGenericOrderDetails = async (purchaseOrderNumber: string) => {
  const url = `shared/orders/${purchaseOrderNumber}`;
  const response = await apiClient.get<IFullOrderModel>(url);
  return response;
};
