import { PagingParams, PagingQueryManager } from "utils/PagingQueryManager";
import {
  TransactionPointsFilter,
  ISummaryTransaction,
} from "services/api/types";
import { History } from "history";

type TransactionPagingParams = {
  pointsFilter: TransactionPointsFilter;
} & PagingParams<ISummaryTransaction>;

const defaultPagerValues: TransactionPagingParams = {
  order: "desc",
  orderBy: "transactionDate",
  page: "0",
  pageSize: "25",
  pointsFilter: "All",
  search: "",
};

export default class TransactionsFilterManager extends PagingQueryManager<
  ISummaryTransaction,
  TransactionPagingParams
> {
  constructor(history: History, query: URLSearchParams) {
    super(history, query, defaultPagerValues);
  }

  get pointsFilter() {
    return this.getRawValue("pointsFilter") as TransactionPointsFilter;
  }

  setPointsFilter(value: TransactionPointsFilter) {
    this.setMultipleValuesWithUpdate(
      { prop: "pointsFilter", value },
      { prop: "page", value: "0" }
    );
  }
}
