import React from "react";
import { Field } from "formik";
import { TextField } from "formik-material-ui";

const HostCompanyInfoForm = () => {
  return (
    <div>
      <Field
        label="Company Name"
        variant="outlined"
        margin="dense"
        fullWidth
        component={TextField}
        name="companyName"
      />
      <Field
        variant="outlined"
        margin="dense"
        fullWidth
        component={TextField}
        name="address1"
        label="Address"
      />
      <Field
        variant="outlined"
        margin="dense"
        fullWidth
        component={TextField}
        name="city"
        label="City"
      />
      <Field
        variant="outlined"
        margin="dense"
        fullWidth
        component={TextField}
        name="state"
        label="State"
      />
      <Field
        variant="outlined"
        margin="dense"
        fullWidth
        component={TextField}
        name="zip"
        label="Zip Code"
      />
      <Field
        variant="outlined"
        margin="dense"
        fullWidth
        component={TextField}
        name="phoneNumber"
        label="Phone #"
      />
      <Field
        variant="outlined"
        margin="dense"
        fullWidth
        component={TextField}
        name="emailAddress"
        label="Email"
      />
    </div>
  );
};

export default HostCompanyInfoForm;
