import { apiClient } from "services/api/client";
import { ICatalogProductSettings } from "services/api/types";

const url = "administrator/catalogProducts/settings";

// Note: params are strings...let the server conver
export const updateSettings = async (tax: string, markup: string) => {
  const response = await apiClient.post(url, { tax, markup });
  return response.data;
};

export const getSettings = async () => {
  const response = await apiClient.get<ICatalogProductSettings>(url);
  return response.data;
};
