import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";

const Legal = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& h1": {
          fontFamily: "Oswald, sans-serif",
          fontWeight: 400,
          color: theme.typography.h1.color,
        },
        "& h2": {
          fontFamily: "Oswald, sans-serif",
          fontWeight: 400,
          color: theme.typography.h2.color,
        },
      },
      container: {
        padding: theme.spacing(0, 4),
      },
    })
  );

  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container className={classes.container} disableGutters={true}>
        <h1>CONDITIONS OF USE</h1>
        <p>
          Welcome to Builders Club North. All content included on this site is
          the property of Builders Club North IP, LLC or its content suppliers
          and is protected by United States and international copyright laws.
          Builders Club North is a trademark of Builders Club North IP, LLC.
        </p>
        <p>
          Builders Club North attempts to be as accurate as possible with this
          site. However, Builders Club North does not warrant that content of
          this site is accurate, complete, reliable, current, or error-free.
        </p>
        <h2>SITE ACCESS</h2>
        <p>
          Builders Club North grants a limited license to access and make
          personal use of this site. The "Member" area and "Sponsor" area is
          restricted to active participants (Members and Sponsors) in the
          Builders Club North program. The "Guest" area is open to those who are
          interested in learning more about the program. Builders Club North
          grants a limited, revocable right to create a hyperlink to its site,
          so long as the link does not portray Builders Club North, its
          affiliates, or their products or services in a false, misleading,
          derogatory, or otherwise offensive manner as judged by Builders Club.
        </p>
        <h2>YOUR ACCOUNT AND PASSWORD</h2>
        <p>
          You are responsible for maintaining the confidentiality of your
          account and password, and you agree to accept responsibility for all
          activities that occur under your account or password.
        </p>
        <h2>DISCLAIMER OF WARRANTIES AND LIMITATION OF LIABILITY</h2>
        <p>
          THIS SITE IS PROVIDED BY Builders Club North IP, LLC ON AN "AS IS" AND
          "AS AVAILABLE" BASIS. BUILDERS CLUB NORTH MAKES NO REPRESENTATIONS OR
          WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF
          THIS SITE OR THE INFORMATION, CONTENT, MATERIALS, OR PRODUCTS INCLUDED
          ON THIS SITE. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT
          YOUR SOLE RISK. BUILDERS CLUB NORTH WILL NOT BE LIABLE FOR ANY DAMAGES
          OF ANY KIND ARISING FROM THE USE OF THIS SITE.
        </p>
        <div style={{ textAlign: "center" }}>
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={() => history.push("/")}
          >
            Home
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Legal;
