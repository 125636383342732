import { apiClient } from "services/api/client";

import { ITransactionsResult, ITransactionsQuery } from "services/api/types";

const TRANSACTIONS_URL = "shared/transactions";

export const getPage = async (query: ITransactionsQuery) => {
  const url = `${TRANSACTIONS_URL}/${query.accountId}/page`;
  const response = await apiClient.post<ITransactionsResult>(url, query);
  return response.data;
};
