import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import { EventVisibility } from "services/api/types";

type Props = {
  value: EventVisibility;
  onChange: (newValue: EventVisibility) => void;
};

const EventVisibilityRadioGroup = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      visibilityLabel: {
        display: "inline-block",
      },
      visibilityGroup: {
        display: "inline-block",
        marginLeft: theme.spacing(2),
      },
      radioRoot: {
        padding: 3,
      },
    })
  );

  const classes = useStyles();

  const handleVisibilityChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(evt.currentTarget.value as EventVisibility);
  };

  return (
    <div>
      <FormLabel className={classes.visibilityLabel}>Visibility:</FormLabel>
      <RadioGroup
        className={classes.visibilityGroup}
        row={true}
        name="visibility"
        onChange={handleVisibilityChange}
        value={props.value}
      >
        <FormControlLabel
          label="Members"
          value="Members"
          control={<Radio classes={{ root: classes.radioRoot }} />}
        />
        <FormControlLabel
          label="Sponsors"
          value="Sponsors"
          control={<Radio classes={{ root: classes.radioRoot }} />}
        />
        <FormControlLabel
          label="Both"
          value="Both"
          control={<Radio classes={{ root: classes.radioRoot }} />}
        />
        <FormControlLabel
          label="None"
          value="None"
          control={<Radio classes={{ root: classes.radioRoot }} />}
        />
      </RadioGroup>
    </div>
  );
};

export default EventVisibilityRadioGroup;
