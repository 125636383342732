export const formatNumber = (
  num: number | string | undefined,
  decimalPlaces?: number
) => {
  if (num === undefined || num === null) return "---";
  const actualNum =
    typeof num === "number" && decimalPlaces !== undefined
      ? num.toFixed(decimalPlaces)
      : num.toString();

  return actualNum.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const formatMoney = (
  num: number | undefined,
  omitDollarSign = false
) => {
  if (num === undefined || num === null) return "---";

  if (num < 0) {
    return omitDollarSign
      ? `(${formatNumber(Math.abs(num).toFixed(2))})`
      : `($${formatNumber(Math.abs(num).toFixed(2))})`;
  } else {
    return omitDollarSign
      ? `${formatNumber(num.toFixed(2))}`
      : `$${formatNumber(num.toFixed(2))}`;
  }
};

export const getRange = (start: number, end: number) => {
  return [...Array(end - start + 1)].map((el, ind) => ind + start);
};

export const round = (num, decimalPlaces = 0) => {
  const p = Math.pow(10, decimalPlaces);
  const n = num * p * (1 + Number.EPSILON);
  return Math.round(n) / p;
};
