import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import DialogActionsPortal from "./DialogActionsPortal";

type BasicProps = {
  children: React.ReactNode;
  title?: string;
  handleClose: () => void;
  width: Breakpoint;
  hideDoneButton?: boolean;
  hideAllButtons?: boolean;
};

type InfoProps = BasicProps & {
  renderMenuIcon?: () => JSX.Element | undefined;
};

type SaveProps = BasicProps & {
  // isSaving: boolean;
  disableSaveButton?: boolean;
  handleSave: () => void;
  width: Breakpoint;
  // useOkForSave?: boolean;
  saveButtonCaption?: string;
};

const ResponsiveModalShell = (props: InfoProps | SaveProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "grid",
      },
      actionsHost: {
        display: "flex",
        alignItems: "center",
        justifyContent: props.width === "xs" ? "space-between" : "flex-end",
        marginBottom: props.width === "xs" ? "1em" : "unset",
        marginTop: props.width === "xs" ? "unset" : "2em",
        gridRow: props.width === "xs" ? 1 : "unset",
      },
      mobileTitleRow: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        color: "gray",
        padding: 6,
        // borderBottom: "1px solid gainsboro",
        boxShadow: "0px 3px 15px rgba(0,0,0,0.2);",
        background: "white",
        zIndex: 1000,
      },
      title: {
        textAlign: "center",
        position: "absolute",
        fontSize: "large",
        left: 0,
        top: 4,
        width: "100%",
        pointerEvents: "none",
        fontWeight: "normal",
      },
      content: {
        paddingTop:
          props.width === "xs"
            ? theme.spacing(6)
            : props.title
            ? theme.spacing(3)
            : 0,
      },
    })
  );

  const classes = useStyles();

  const isSaveProps = (props: InfoProps | SaveProps): props is SaveProps => {
    return (props as SaveProps).handleSave !== undefined;
  };

  const renderXsSaveButtons = (saveProps: SaveProps) => {
    return (
      <>
        <Button color="default" onClick={saveProps.handleClose}>
          Cancel
        </Button>
        {saveProps.title && (
          <Typography className={classes.title}>
            <strong>{saveProps.title}</strong>
          </Typography>
        )}
        <Button
          color="primary"
          disabled={saveProps.disableSaveButton}
          onClick={saveProps.handleSave}
        >
          {saveProps.saveButtonCaption ?? "Save"}
        </Button>
      </>
    );
  };

  const renderSmUpButtons = (saveProps: SaveProps) => {
    return (
      <DialogActionsPortal>
        <Button
          variant={saveProps.width === "xs" ? undefined : "contained"}
          color="primary"
          disabled={saveProps.disableSaveButton}
          onClick={saveProps.handleSave}
        >
          {saveProps.saveButtonCaption ?? "Save"}
        </Button>
        <Button
          variant="contained"
          color="default"
          onClick={saveProps.handleClose}
        >
          Cancel
        </Button>
      </DialogActionsPortal>
    );
  };

  const renderSaveButtons = (props: SaveProps) => {
    if (props.width === "xs") {
      return renderXsSaveButtons(props);
    } else {
      return renderSmUpButtons(props);
    }
  };

  const renderXsDownInfoButtons = (props: InfoProps) => {
    return (
      <>
        <Button color="primary" onClick={props.handleClose}>
          Done
        </Button>
        {props.title && (
          <Typography className={classes.title}>
            <strong>{props.title}</strong>
          </Typography>
        )}
        {props.renderMenuIcon && props.renderMenuIcon()}
      </>
    );
  };

  const renderSmUpInfoButtons = (props: InfoProps) => {
    if (props.hideDoneButton) return undefined;

    return (
      <DialogActionsPortal>
        <Button variant="contained" color="primary" onClick={props.handleClose}>
          Done
        </Button>
        {props.renderMenuIcon && props.renderMenuIcon()}
      </DialogActionsPortal>
    );
  };

  const renderInfoButtons = (props: InfoProps) => {
    if (props.width === "xs") {
      return renderXsDownInfoButtons(props);
    } else {
      return renderSmUpInfoButtons(props);
    }
  };

  const hideAllButtons = Boolean(props.hideAllButtons);

  return (
    <div className={classes.root}>
      {props.width !== "xs" && (
        <Typography variant="h6">
          <strong>{props.title}</strong>
        </Typography>
      )}
      <div className={classes.content}>{props.children}</div>
      <div
        className={clsx(classes.actionsHost, {
          [classes.mobileTitleRow]: props.width === "xs",
        })}
      >
        {!hideAllButtons && (
          <>
            {isSaveProps(props)
              ? renderSaveButtons(props)
              : renderInfoButtons(props)}
          </>
        )}
      </div>
    </div>
  );
};

export default withWidth()(ResponsiveModalShell);
