import React from "react";
import Red from "@material-ui/core/colors/red";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import { companyColors } from "areas/Shared/companyColors";
import clsx from "clsx";
import PageHeader from "components/Shell/PageHeader";
import { formatNumber } from "utils/number";
import { ICompany, IAccountInfo } from "services/api/types";
import CompanyAvatar from "areas/Shared/components/CompanyAvatar";
import * as appConstants from "applications/appConstants";
import MergeIcon from "@material-ui/icons/MergeType";
import Button from "@material-ui/core/Button";
import uiStore from "stores/ui";
import { useHistory } from "react-router";
import { paths } from "routes/Paths";
import myStore from "stores/my";
import AccountSelector from "components/Shell/AccountSelector";

type Props = {
  company: ICompany;
  alertCount?: number;
  onImageUploaded: (url: string) => Promise<void>;
  onRemoveImage: () => void;
  onChangeAccount: (account: IAccountInfo) => void;
  currentAccountId: string;
};

const CompanyHeader = (props: Props) => {
  const history = useHistory();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      header: {
        display: "grid",
        fontWeight: "bold",
        fontSize: "1.5rem",
        gridTemplateColumns: "auto auto 1fr auto",
        gridGap: theme.spacing(2),
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
          display: "flex",
          fontSize: "1.25rem",
        },
      },
      superHeader: {
        color: theme.palette.primary.main,
        fontWeight: "bold",
        margin: "0.65rem 0",
        transform: "translateY(12px)",
      },
      icons: {
        float: "right",
      },
      sponsor: {
        color: companyColors.sponsor,
      },
      competitor: {
        color: companyColors.competitor,
      },
      prospect: {
        color: companyColors.prospect,
      },
      member: {
        color: companyColors.member,
      },
      inactiveReason: {
        color: Red[600],
        fontWeight: "normal",
        fontStyle: "italic",
      },
      avatar: {
        height: "80px",
        width: "80px",
      },
      badge: {
        transform: "translateY(-8px)",
      },
      badgeBadge: {
        transform: "scale(1) translate(-2%, -17%)",
      },
      headerHost: {
        transform: "translate(-10px, -15px)",
      },
      editButton: {
        marginLeft: "1em",
      },
      toolbarPortal: {
        transform: "translateY(25px)",
        // [theme.breakpoints.down("md")]: {
        //   display: "none",
        // },
      },
      pointBalance: {
        margin: 0,
        color: "gray",
      },
    })
  );

  const classes = useStyles();

  const renderAvatar = () => {
    return (
      <Badge
        classes={{
          badge: classes.badgeBadge,
        }}
        className={classes.badge}
        color="error"
        badgeContent={props.alertCount}
        variant="standard"
      >
        <CompanyAvatar
          size="large"
          website={props.company.website}
          companyName={props.company.name}
          onImageUploaded={props.onImageUploaded}
          onRemoveImage={props.onRemoveImage}
          imageUri={props.company.imageUrl}
          companyId={props.company.id}
          companyType={props.company.companyType}
        />
      </Badge>
    );
  };

  const renderAfterComponent = () => {
    return props.company.isActive ? (
      <span />
    ) : (
      <span className={classes.inactiveReason}>
        {props.company.inactiveReason
          ? `Inactive - ${props.company.inactiveReason}`
          : "Inactive"}
      </span>
    );
  };

  const handleMergeCompany = () => {
    const { id, name, identifier } = props.company;
    uiStore.showModal({
      type: "MergeCompanyModal",
      props: {
        companyType: props.company.companyType,
        company1: {
          id,
          name,
          identifier,
        },
        company1Inactive: !props.company.isActive,
        doneCallback: handleMerged,
      },
    });
  };

  const handleMerged = (
    keptCompanyId: string,
    potentialDuplicateId?: string
  ) => {
    if (keptCompanyId !== props.company.id) {
      history.push(
        `${paths.app.admin.companyDetails}/${keptCompanyId}/overview`
      );
    }
  };

  const showPointBalance =
    props.company.companyType === "Member" ||
    props.company.companyType === "Sponsor";

  const pointName =
    process.env.REACT_APP_BRANDING === appConstants.BuildPerks
      ? "Perk"
      : "Club";

  const { companyType } = props.company;

  const isSysAdminMergingMember =
    myStore.isAdminNotImpersonating &&
    myStore.hasAdminRole("bcn:sysadmin") &&
    companyType === "Member";

  const isAdminMergingCompOrProsp =
    myStore.isAdminNotImpersonating &&
    (companyType === "Competitor" || companyType === "Prospect");

  const showMergeButton = isSysAdminMergingMember || isAdminMergingCompOrProsp;
  const showAccountSelector = props.company.accounts.length > 1;

  return (
    <div className={classes.header}>
      <PageHeader
        renderAvitar={renderAvatar}
        renderAfterComponent={renderAfterComponent}
        text={`${props.company.name} (${props.company.identifier})`}
        superHeaderClassName={clsx({
          [classes.member]: props.company.companyType === "Member",
          [classes.sponsor]: props.company.companyType === "Sponsor",
          [classes.prospect]: props.company.companyType === "Prospect",
          [classes.competitor]: props.company.companyType === "Competitor",
        })}
        subHeader={
          showPointBalance
            ? `${pointName} Point Balance: ${formatNumber(
                props.company.clubPoints
              )}`
            : undefined
        }
        superHeader={props.company.companyType}
      />
      {showMergeButton && (
        <Button
          onClick={handleMergeCompany}
          variant="contained"
          startIcon={<MergeIcon />}
        >
          Merge With Other Company
        </Button>
      )}
      {!showMergeButton && <div />}
      {showAccountSelector && (
        <AccountSelector
          currentAccountId={props.currentAccountId}
          onChange={props.onChangeAccount}
          accounts={props.company.accounts}
        />
      )}
      <div
        className={classes.toolbarPortal}
        id="company-details-toolbar-portal"
      ></div>
    </div>
  );
};

export default CompanyHeader;
