import React from "react";
import { ITinyContact } from "services/api/types";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import ContactCheckedListBox from "components/ContactCheckedListBox";

type Props = {
  handleClose: () => void;
  contacts: ITinyContact[];
  companyName: string;
  handleSend: (selectedContactIds: string[]) => Promise<void>;
  modalTitle: string;
};

const CheckedContactsModal = (props: Props) => {
  const [isSending, setIsSending] = React.useState(false);
  const [checkedContactIds, setCheckedContactIds] = React.useState<string[]>(
    []
  );

  const handleOKClick = async () => {
    if (window.confirm("Are you sure you want to send this email?")) {
      try {
        setIsSending(true);
        await props.handleSend(checkedContactIds);
        props.handleClose();
      } finally {
        setIsSending(false);
      }
    }
  };

  const handleContactCheckboxChanged = (
    contactId: string,
    isChecked: boolean
  ) => {
    if (isChecked) {
      setCheckedContactIds([contactId, ...checkedContactIds]);
    } else {
      setCheckedContactIds(checkedContactIds.filter((id) => id !== contactId));
    }
  };

  return (
    <ResponsiveModalShell
      handleSave={handleOKClick}
      handleClose={props.handleClose}
      disableSaveButton={checkedContactIds.length < 1 || isSending}
      saveButtonCaption="Send"
      title={props.modalTitle}
    >
      <ContactCheckedListBox
        handleContactCheckedChanged={handleContactCheckboxChanged}
        contacts={props.contacts}
        checkedContactIds={checkedContactIds}
        title={`Active ${props.companyName} employees (${props.contacts.length}):`}
      />
    </ResponsiveModalShell>
  );
};

export default CheckedContactsModal;
