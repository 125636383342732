import React from "react";
import TextField from "@material-ui/core/TextField";
import { EINInput } from "areas/Shared/components/maskedInputs/EINInput";
import { SSNInput } from "areas/Shared/components/maskedInputs/SSNInput";

type Props = {
  taxNumberType?: string;
  value: string | undefined;
  touched: boolean | undefined;
  error: string | undefined;
  handleChange: (e: React.ChangeEvent<any>) => void;
};

const TaxNumberField = (props: Props) => {
  const labelPlaceholder = props.taxNumberType ? props.taxNumberType : "Tax #";

  const getInputComponent = (): any => {
    if (!props.taxNumberType) return undefined;

    if (props.taxNumberType === "EIN") {
      return EINInput;
    }

    return SSNInput;
  };

  return (
    <TextField
      variant="outlined"
      id="taxNumber"
      margin="dense"
      fullWidth
      label={labelPlaceholder}
      value={props.value}
      onChange={props.handleChange}
      disabled={!props.taxNumberType}
      error={props.touched && Boolean(props.error)}
      name="taxNumber"
      placeholder={labelPlaceholder}
      helperText={props.touched && props.error}
      InputProps={{
        inputComponent: getInputComponent(),
      }}
    />
  );
};

export default TaxNumberField;
