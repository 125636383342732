import { apiClient } from "services/api/client";
import { IListedCompanyEvent, IEventAttendeeResult } from "services/api/types";

export const getUpcomingEvents = async () => {
  const url = "companies/events";
  const response = await apiClient.get<IListedCompanyEvent[]>(url);

  return response.data;
};

export const getEventAttendees = async (eventId: string) => {
  const url = `companies/events/${eventId}/attendees`;
  const response = await apiClient.get<IEventAttendeeResult>(url);

  return response.data;
};
