import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import LoginForm from "./LoginForm";
import { useHistory } from "react-router";

type Props = {
  isSignedIn: boolean;
  signedInUserName: string;
  onLogIn: (userName: string, password: string, rememberMe: boolean) => void;
  handleLogOut: () => void;
  isLoggingIn: boolean;
  loginFailed: boolean;
  isLockedOut: boolean;
  failedLoginAttempts: number;
};

const blue = "#1B61B1";
const red = "#943A3A";

const Hero = (props: Props) => {
  const history = useHistory();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      heroRoot: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      },
      signedInBlock: {
        margin: "auto",
        fontFamily: "Oswald, sans-serif",
      },
      greeting: {
        fontSize: "2.5em",
        marginBottom: ".5em",
      },
      h1: {
        fontFamily: "Oswald, sans-serif",
        lineHeight: 1.1,
        fontWeight: 400,
        color: blue,
        marginTop: "auto",
        marginBottom: 0,
        textShadow: "2px 3px 5px rgba(0,0,0,0.3)",
        [theme.breakpoints.between("xs", "sm")]: {
          fontSize: "3em",
        },
        [theme.breakpoints.between("sm", "md")]: {
          fontSize: "4em",
        },
        [theme.breakpoints.between("md", "lg")]: {
          fontSize: "5.5em",
        },
        [theme.breakpoints.between("lg", "xl")]: {
          fontSize: "7em",
        },
      },
      superTitle: {
        fontFamily: "Nunito",
      },
      subtitle: {
        fontFamily: "Oswald, sans-serif",
        fontWeight: 400,
        margin: "20px 0",
        lineHeight: 1.1,
        [theme.breakpoints.between("xs", "md")]: {
          fontSize: "1.5em",
        },
        [theme.breakpoints.between("md", "lg")]: {
          fontSize: "2em",
        },
        [theme.breakpoints.between("lg", "xl")]: {
          fontSize: "2.5em",
        },
        "& div": {
          whiteSpace: "nowrap",
        },
      },
      slogan: {
        fontFamily: "Caveat, cursive",
        fontWeight: "bold",
        color: red,
        margin: 0,
        letterSpacing: "5px",
        [theme.breakpoints.between("xs", "sm")]: {
          fontSize: "1em",
        },
        [theme.breakpoints.between("sm", "md")]: {
          fontSize: "1.5em",
        },
        [theme.breakpoints.between("md", "lg")]: {
          fontSize: "1.5em",
        },
        [theme.breakpoints.between("lg", "xl")]: {
          fontSize: "2em",
        },
      },
      whiteBox: {
        background: "rgba(255, 255, 255, .75)",
        display: "grid",
        width: "100%",
        gridGap: "20px",
        [theme.breakpoints.between("xs", "sm")]: {
          padding: "35px 15px",
          gridTemplateColumns: "1fr",
          textAlign: "center",
          margin: "0 auto",
        },
        [theme.breakpoints.between("sm", "md")]: {
          padding: "35px 25px",
          gridTemplateColumns: "3fr 2fr",
          textAlign: "unset",
        },
        [theme.breakpoints.between("md", "xl")]: {
          padding: "35px 75px",
          gridTemplateColumns: "3fr 2fr",
        },
      },
      title: {
        [theme.breakpoints.between("xs", "md")]: {
          margin: "5% 0",
        },
        [theme.breakpoints.between("md", "xl")]: {
          margin: "unset",
        },
      },
      buttonRoot: {
        backgroundColor: red,
        color: "white",
        borderRadius: "30px",
      },
      button: {},
      message: {
        textAlign: "center",
        fontSize: "x-large",
      },
      noMobile: {
        display: "flex",
        fontFamily: "Oswald",
        fontSize: "1.5em",
        alignItems: "center",
        textAlign: "center",
        color: "#a30326",
        margin: "0 1em",
      },
    })
  );

  const renderTitleBlock = () => {
    return (
      <div className={classes.title}>
        <h1 className={classes.h1}>Builders Club</h1>
        <div className={classes.subtitle}>
          <div>The Ultimate Rewards Program</div>
          <div>for Builders and Remodelers</div>
        </div>
        <div className={classes.slogan}>Where Loyalty is Rewarded</div>
      </div>
    );
  };

  const renderSignedIn = () => {
    return (
      <div className={classes.signedInBlock}>
        <div
          className={classes.greeting}
        >{`Hello, ${props.signedInUserName}!`}</div>
        <Button
          classes={{
            root: classes.buttonRoot,
          }}
          onClick={props.handleLogOut}
          variant="contained"
          className={classes.button}
        >
          Logout
        </Button>
        <Button
          style={{ marginLeft: "5px" }}
          classes={{
            root: classes.buttonRoot,
          }}
          onClick={() => history.push("/app")}
          variant="contained"
          className={classes.button}
        >
          Go To Sponsor/Member Area
        </Button>
      </div>
    );
  };

  const renderLoginForm = () => {
    return (
      <div>
        <LoginForm {...props} />
      </div>
    );
  };

  const renderSignInBlock = () => {
    if (props.isSignedIn) {
      return renderSignedIn();
    } else {
      return renderLoginForm();
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.heroRoot}>
      <div className={classes.whiteBox}>
        {renderTitleBlock()}
        {renderSignInBlock()}
      </div>
    </div>
  );
};

export default Hero;
