import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AddIcon from "@material-ui/icons/Add";
import PageIcon from "@material-ui/icons/MenuBook";
import SaveIcon from "@material-ui/icons/Save";
import { getRange } from "utils/number";
import ReactDOM from "react-dom";

type Props = {
  onAdd?: () => void;
  page?: number;
  totalPages?: number;
  onPageChanged?: (page: number) => void;
  onSave?: () => void;
};

const BottomAppBar = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(
    undefined
  );

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        top: "auto",
        bottom: 0,
      },
      toolbarRoot: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        justifyContent: "space-between",
      },
      selectedToggle: {
        backgroundColor: "rgba(110, 110, 110, 0.25) !important",
        color: "white !important",
      },
      toggleRoot: {
        color: "white",
      },
    })
  );

  const classes = useStyles();

  const renderPageMenu = () => {
    if (!anchorEl) return;
    if (!props.totalPages) return;

    const pageArray = getRange(1, props.totalPages);

    return (
      <Menu
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id="table-menu"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        open={Boolean(anchorEl)}
      >
        {pageArray.map((page) => {
          return (
            <MenuItem
              key={page}
              onClick={() => {
                props.onPageChanged && props.onPageChanged(page - 1);
                setAnchorEl(undefined);
              }}
            >
              {page === props.page! + 1 ? (
                <strong>{page}</strong>
              ) : (
                <span>{page}</span>
              )}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  const handlePageMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const showPageButton = props.page !== undefined && props.totalPages;
  const statusBarPortal = document.getElementById("status-bar-portal");

  return ReactDOM.createPortal(
    <>
      <Toolbar
        classes={{
          root: classes.toolbarRoot,
        }}
        variant="dense"
      >
        {showPageButton && (
          <IconButton color="inherit" onClick={handlePageMenuClick}>
            <PageIcon />
          </IconButton>
        )}
        {props.onAdd && (
          <IconButton color="inherit" onClick={props.onAdd}>
            <AddIcon />
          </IconButton>
        )}
        {props.onSave && (
          <IconButton color="inherit" onClick={props.onSave}>
            <SaveIcon />
          </IconButton>
        )}
      </Toolbar>
      {renderPageMenu()}
    </>,
    statusBarPortal!
  );
};

export default BottomAppBar;
