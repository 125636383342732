import React from "react";
import { IUnit } from "services/api/types";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";

type Props = {
  value: string | undefined;
  touched: boolean | undefined;
  error: string | undefined;
  handleChange: (e: React.ChangeEvent<any>) => void;
  units: IUnit[];
};

const UnitsDropdown = (props: Props) => {
  return (
    <FormControl fullWidth>
      <TextField
        select
        variant="outlined"
        margin="dense"
        label="Units"
        error={props.touched && Boolean(props.error)}
        name="unitId"
        onChange={props.handleChange}
        value={props.value}
        helperText={props.touched && props.error}
      >
        {props.units.map((unit) => {
          return (
            <MenuItem key={unit.id} value={unit.id}>
              {unit.description}
            </MenuItem>
          );
        })}
      </TextField>
    </FormControl>
  );
};

export default UnitsDropdown;
