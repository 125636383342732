import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { IEventParticipationInfo, IPurchaserInfo } from "services/api/types";
import { formatNumber } from "utils/number";
import moment from "moment";
import LocationsInfoBlock from "areas/Shared/components/InfoBlocks/LocationsInfoBlock";
import MarketingTextInfoBlock from "areas/Shared/components/InfoBlocks/MarketingTextInfoBlock";
import CompanyInfoHeader from "areas/Shared/components/InfoBlocks/CompanyInfoHeader";
import PrimaryContactBlock from "areas/Shared/components/InfoBlocks/PrimaryContactBlock";
import MobileGroupHeader from "components/Shell/MobileGroupHeader";
import CollapsibleMobileGroupHeader from "components/Shell/CollapsibleMobileGroupHeader";
import * as appConstants from "applications/appConstants";
import RedemptionSummaryTable from "areas/Shared/components/RedemptionSummaryTable";
import { Link } from "react-router-dom";
import uiStore from "stores/ui";
import { Observer } from "mobx-react";

type Props = {
  memberInfo: IPurchaserInfo;
  // On sizes greater than "xs", we swap out the member info for attendee names
  // when an event is clicked. I didn't want to waste time implementing this for
  // the "xs" view, so I just disabled the links until someone complains.
  disableLinks?: boolean;
};

const MemberInfo = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        paddingBottom: theme.spacing(4),
      },
      points: {
        display: "flex",
        justifyContent: "space-between",
      },
      pointHistory: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        marginLeft: theme.spacing(2),
      },
      number: {
        textAlign: "right",
      },
      group: {
        marginTop: theme.spacing(2),
      },
      eventLink: {
        display: "block",
        marginBottom: 2,
      },
    })
  );
  const {
    name,
    identifier,
    marketingInformation: marketingText,
    computedBalance,
    website: websiteUrl,
    enrollDate,
    last12Months,
    last36Months,
    yearToDate,
    imageUrl,
  } = props.memberInfo;

  const classes = useStyles();

  const pointName =
    process.env.REACT_APP_BRANDING === appConstants.BuildPerks
      ? "Perk"
      : "Club";

  const totalRedemptions = props.memberInfo.redemptions.reduce(
    (acc, current) => {
      return acc + current.quantity;
    },
    0
  );

  const handleEventClick = (evt: React.MouseEvent<HTMLElement>) => {
    if (props.disableLinks) return;
    evt.stopPropagation();
    evt.preventDefault();
    const eventId = (evt.target as any).getAttribute("data-event-id");
    const event = props.memberInfo.events.find((e) => e.eventId === eventId);
    const grandDestination = props.memberInfo.grandDestinations.find(
      (e) => e.eventId === eventId
    );

    const eventToUse = event || grandDestination!;

    uiStore.setFlyoutOpen(false);

    uiStore.showEventAttendees(
      eventToUse.eventName,
      props.memberInfo.name,
      eventToUse.attendees
    );
  };

  const renderEventName = (eventInfo: IEventParticipationInfo) => {
    if (props.disableLinks) {
      return <div key={eventInfo.companyId}>{eventInfo.eventName}</div>;
    } else {
      return (
        <Link
          key={eventInfo.eventId}
          className={classes.eventLink}
          data-event-id={eventInfo.eventId}
          onClick={handleEventClick}
          to="/bogus"
        >
          {eventInfo.eventName}
        </Link>
      );
    }
  };

  return (
    <Observer
      render={() => {
        return (
          <div className={classes.root}>
            <CompanyInfoHeader
              companyName={name}
              identifier={identifier}
              website={websiteUrl}
              imageUrl={imageUrl}
            />
            <MarketingTextInfoBlock marketingInformation={marketingText} />
            <PrimaryContactBlock contactInfo={props.memberInfo} />
            <MobileGroupHeader title="Enroll Date">
              <span>{moment(enrollDate).format("LL")}</span>
            </MobileGroupHeader>
            <MobileGroupHeader title="Point Balance">
              <span>{formatNumber(computedBalance)}</span>
            </MobileGroupHeader>
            <MobileGroupHeader
              title={`${pointName} Point Accumulation History`}
            >
              <div className={classes.pointHistory}>
                <strong>Year-to-Date</strong>
                <div className={classes.number}>{formatNumber(yearToDate)}</div>
                <strong>Last 12 Months</strong>
                <div className={classes.number}>
                  {formatNumber(last12Months)}
                </div>
                <strong>Last 36 Months</strong>
                <div className={classes.number}>
                  {formatNumber(last36Months)}
                </div>
              </div>
            </MobileGroupHeader>
            <LocationsInfoBlock locations={props.memberInfo?.locations} />
            <CollapsibleMobileGroupHeader
              title={`Redemptions (${totalRedemptions})`}
              onIsExpandedChanged={uiStore.setMemberInfoRedemptionsExpanded}
              isExpanded={uiStore.memberInfoRedemptionsExpanded}
            >
              <div>
                <RedemptionSummaryTable
                  size="small"
                  redemptions={props.memberInfo.redemptions}
                />
              </div>
            </CollapsibleMobileGroupHeader>
            <CollapsibleMobileGroupHeader
              onIsExpandedChanged={uiStore.setMemberInfoEventsExpanded}
              isExpanded={uiStore.memberInfoEventsExpanded}
              title={`Events (${props.memberInfo.events.length})`}
            >
              {props.memberInfo.events.map(renderEventName)}
              {props.memberInfo.events.length === 0 && (
                <div>No events found</div>
              )}
            </CollapsibleMobileGroupHeader>
            <CollapsibleMobileGroupHeader
              onIsExpandedChanged={
                uiStore.setMemberInfoGrandDestinationsExpanded
              }
              isExpanded={uiStore.memberInfoGrandDestinationsExpanded}
              title={`Grand Destinations (${props.memberInfo.grandDestinations.length})`}
            >
              {props.memberInfo.grandDestinations.map(renderEventName)}
              {props.memberInfo.grandDestinations.length === 0 && (
                <div>No Grand Destinations found</div>
              )}
            </CollapsibleMobileGroupHeader>
            <CollapsibleMobileGroupHeader
              onIsExpandedChanged={uiStore.setMemberUsedCategoriesExpanded}
              isExpanded={uiStore.memberUsedCategoriesExpanded}
              title={`Used Categories (${props.memberInfo.usedCategories.length})`}
            >
              {props.memberInfo.usedCategories.map((cat) => (
                <div>{cat}</div>
              ))}
              {props.memberInfo.usedCategories.length === 0 && (
                <div>No Used Categories found</div>
              )}
            </CollapsibleMobileGroupHeader>
          </div>
        );
      }}
    />
  );
};

export default MemberInfo;
