import React from "react";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import withWidth from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import clsx from "clsx";
import myStore from "stores/my";
import { Observer } from "mobx-react";
import { formatNumber } from "utils/number";
import * as appConstants from "applications/appConstants";

import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerRoot: {
      display: "flex",
      width: "100%",
      position: "absolute",
    },
    drawer: {
      [theme.breakpoints.up("lg")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    smallToolbar: {
      minHeight: "50px !important",
    },
    appBar: {
      [theme.breakpoints.up("lg")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      marginBottom: theme.spacing(4),
    },
    secondaryAppBar: {
      backgroundColor: "#e6edf7",
      color: "black",
      padding: theme.spacing(0, 2),
      transform: "translateY(50px)",
      flexDirection: "row",
      justifyContent: "space-between",
      height: theme.spacing(5),
      transition: theme.transitions.create(["transform"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.standard,
      }),
    },
    hiddenSecondaryAppBar: {
      transform: "translateY(0)",
    },
    devAppBar: {
      background: "DarkTurquoise",
    },
    stagingAppBar: {
      background: "#a832a0",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("lg")]: {
        display: "none",
      },
    },
    // necessary for content to be below app bar
    drawerToolbar: theme.mixins.toolbar,
    drawerPaper: {
      color: theme.palette.common.white,
      width: drawerWidth,
      backgroundImage: `linear-gradient(180deg, rgb(21, 77, 141) 0%, rgba(21, 77, 141,.6) 100%), url('/images/hero-background.jpg')`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      borderRight: "1px solid white",
    },
    content: {
      display: "grid",
      gridTemplateRows: "auto 1fr",
      flexGrow: 1,
      height: "100vh",
      overflow: "hidden",
      position: "relative",
    },
    fab: {
      bottom: 30,
      right: 30,
      position: "absolute",
    },
    statusBarPortal: {
      // position: "absolute",
      top: "auto",
      bottom: 0,
      [theme.breakpoints.up("lg")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    centerContent: {
      gridRow: 2,
      overflowY: "hidden",
      height: "calc(100vh - 64px)",
      scrollBehavior: "smooth",
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    subHeaderCompanyName: {
      [theme.breakpoints.only("xs")]: {
        display: "none",
      },
    },
  })
);

type Props = {
  renderSidebarContent: () => JSX.Element;
  renderDesktopToolbarItems: () => JSX.Element;
  renderMobileToolbarItems: () => JSX.Element;
  children: React.ReactNode;
  width?: Breakpoint;
};

const ResponsiveDrawer = (props: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClick = (evt: React.MouseEvent<HTMLElement>) => {
    const element = evt.target as HTMLElement;

    if (element.classList.contains("bcn-closes-drawer")) {
      setMobileOpen(false);
    }
  };

  const drawer = (
    <div onClick={handleClick}>
      <div className={clsx(classes.drawerToolbar, classes.smallToolbar)} />
      {props.renderSidebarContent()}
    </div>
  );

  const showSecondaryToobar =
    !window.location.href.endsWith("app/members/") &&
    !window.location.href.endsWith("app/sponsors/") &&
    !window.location.href.endsWith("app/members") &&
    !window.location.href.endsWith("app/sponsors") &&
    !window.location.href.includes("myCompany");

  return (
    <Observer
      render={() => {
        const name = myStore.currentCompany?.companyName;
        const identifier = myStore.currentCompany?.companyIdentifier;
        const points = myStore.currentCompany?.pointBalance;
        const isAdmin = myStore.isAdminNotImpersonating;

        const balanceLabel =
          process.env.REACT_APP_BRANDING === appConstants.BuildPerks
            ? "Perk Point Balance:"
            : "Club Point Balance:";

        return (
          <div className={classes.drawerRoot}>
            <CssBaseline />
            {!isAdmin && (
              <AppBar
                elevation={0}
                className={clsx(classes.appBar, classes.secondaryAppBar, {
                  [classes.hiddenSecondaryAppBar]: !showSecondaryToobar,
                })}
              >
                <Typography
                  className={classes.subHeaderCompanyName}
                >{`${name} (${identifier})`}</Typography>
                <Typography>{`${balanceLabel} ${formatNumber(
                  points
                )}`}</Typography>
              </AppBar>
            )}
            <AppBar
              position="fixed"
              className={clsx(classes.appBar, {
                [classes.stagingAppBar]:
                  myStore.currentUserInfo?.environmentName === "Staging",
                [classes.devAppBar]:
                  myStore.currentUserInfo?.environmentName === "Development",
              })}
            >
              <Toolbar className={classes.smallToolbar}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                {/* Implementation must be js otherwise you'll screw up the layout  */}
                <Hidden smUp implementation="js">
                  {props.renderMobileToolbarItems()}
                </Hidden>
                <Hidden xsDown implementation="js">
                  {props.renderDesktopToolbarItems()}
                </Hidden>
              </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
              <Hidden lgUp implementation="css">
                <Drawer
                  variant="temporary"
                  anchor={theme.direction === "rtl" ? "right" : "left"}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                >
                  {drawer}
                </Drawer>
              </Hidden>
              <Hidden mdDown implementation="css">
                <Drawer
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
                >
                  {drawer}
                </Drawer>
              </Hidden>
            </nav>
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: mobileOpen,
              })}
            >
              <div
                style={{ height: 51 }}
                className={clsx(classes.drawerToolbar, classes.smallToolbar)}
              />
              <div id="center-content" className={classes.centerContent}>
                {/* These are routes */}
                {props.children}
              </div>
              <div id="add-button-portal" className={classes.fab} />
              <AppBar
                id="status-bar-portal"
                className={classes.statusBarPortal}
              ></AppBar>
            </main>
          </div>
        );
      }}
    />
  );
};

export default withWidth()(ResponsiveDrawer);
