import { apiClient } from "services/api/client";
import {
  IPurchaserSuppliersResult,
  IPurchaserSupplier,
} from "services/api/types";
import { getFileName } from "services/api/modules/helpers";

const PURCHASERS_URL = "shared/purchasers";

export const getSuppliers = async (purchaserId: string) => {
  const url = `${PURCHASERS_URL}/${purchaserId}/suppliers`;
  const response = await apiClient.get<IPurchaserSuppliersResult>(url);
  return response.data;
};

export const getSuppliersExport = async (purchaserId: string) => {
  const url = `${PURCHASERS_URL}/${purchaserId}/suppliers/export`;

  const response = await apiClient.post(url, {}, { responseType: "blob" });
  const fileName = getFileName(response, "Suppliers.xlsx");

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const addSupplier = async (
  purchaserId: string,
  categoryId: string,
  supplierId?: string,
  newSupplierName?: string
) => {
  const url = `${PURCHASERS_URL}/${purchaserId}/suppliers`;
  const response = await apiClient.post<IPurchaserSupplier[]>(url, {
    purchaserId,
    supplierId,
    categoryId,
    newSupplierName,
  });

  return response.data;
};

export const deletePurchaserSupplier = async (purchaserSupplierId: string) => {
  const url = `shared/purchaserSuppliers/${purchaserSupplierId}`;
  await apiClient.delete(url);
};

export const deletePurchaserSupplierCategory = async (
  purchaserSupplierCategoryId: string
) => {
  const url = `shared/purchaserSupplierCategories/${purchaserSupplierCategoryId}`;
  await apiClient.delete(url);
};
