import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";
import LinearProgress from "@material-ui/core/LinearProgress";
import Button from "@material-ui/core/Button";
import clsx from "clsx";

type Props = {
  onLogIn: (userName: string, password: string, rememberMe: boolean) => void;
  isLoggingIn: boolean;
  loginFailed: boolean;
  isLockedOut: boolean;
  failedLoginAttempts: number;
};

const LoginForm = (props: Props) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [rememberMe, setRememberMe] = React.useState(false);
  const [touched, setTouched] = React.useState(false);

  const blue = "#1B61B1";
  const red = "#943A3A";

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      error: {
        color: "red",
      },
      subControls: {
        display: "flex",
        width: "100%",
        marginBottom: "10px",
        [theme.breakpoints.between("xs", "sm")]: {
          justifyContent: "space-between",
          flexDirection: "column",
        },
        [theme.breakpoints.between("sm", "xl")]: {
          justifyContent: "space-between",
        },
      },
      buttonRoot: {
        backgroundColor: red,
        color: "white",
        borderRadius: "30px",
      },
      loginForm: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        "& h1": {
          fontFamily: "Oswald, sans-serif",
          fontWeight: 400,
          color: blue,
        },
      },
      button: {},
      link: {
        margin: "10px 0",
      },
      progressHost: {
        width: "100%",
        transform: "translateY(20px)",
      },
      disabled: {
        opacity: 0.5,
        pointerEvents: "none",
      },
      failedLogin: {
        color: "red",
        fontWeight: "bold",
        marginBottom: 15,
      },
    })
  );

  const handleLoginClick = () => {
    setTouched(true);

    if (email && password) {
      props.onLogIn(email, password, rememberMe);
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setRememberMe(checked);
  };

  const renderFailureMessage = () => {
    if (props.isLockedOut) {
      return (
        <div className={classes.failedLogin}>
          Your account is locked temporarily. Please try again in 30 minutes.
          Call 612-359-1130 for anything urgent.
        </div>
      );
    }

    if (props.failedLoginAttempts > 1) {
      return (
        <div className={classes.failedLogin}>
          Invalid Login. Try the “Forgot Password” option. After 3 failed
          attempts, your account will be locked.
        </div>
      );
    }

    if (props.failedLoginAttempts === 1) {
      return (
        <div className={classes.failedLogin}>Invalid Email or Password</div>
      );
    }

    return undefined;
  };

  const classes = useStyles();
  return (
    <div className={classes.loginForm}>
      {props.isLoggingIn ? (
        <div className={classes.progressHost}>
          <LinearProgress />
        </div>
      ) : undefined}
      <h1>SIGN IN</h1>
      <form
        onKeyDown={(evt) => {
          if (evt.keyCode === 13) {
            handleLoginClick();
          }
        }}
        className={clsx({
          [classes.disabled]: props.isLoggingIn,
        })}
      >
        {renderFailureMessage()}
        <FormControl fullWidth={true}>
          <TextField
            margin="dense"
            variant="outlined"
            value={email}
            onChange={(evt) => {
              const value = evt.currentTarget.value;
              setEmail(value);
              setEmailError(value ? "" : "Email is required");
            }}
            label="Email"
            placeholder="Email"
          />
          {touched && emailError ? (
            <FormHelperText className={classes.error}>
              {emailError}
            </FormHelperText>
          ) : undefined}
        </FormControl>
        <FormControl fullWidth={true}>
          <TextField
            variant="outlined"
            value={password}
            onChange={(evt) => {
              const value = evt.currentTarget.value;
              setPassword(value);
              setPasswordError(value ? "" : "Password is required");
            }}
            margin="dense"
            label="Password"
            type="password"
            placeholder="Password"
          />
          {touched && passwordError ? (
            <FormHelperText className={classes.error}>
              {passwordError}
            </FormHelperText>
          ) : undefined}
        </FormControl>
        <div className={classes.subControls}>
          <FormControlLabel
            label="Remember Me"
            control={
              <Checkbox
                color="default"
                onChange={handleCheckboxChange}
                checked={rememberMe}
                name="noCharge"
              />
            }
          />
          <a className={classes.link} href="Identity/Account/ForgotPassword">
            Forgot Password?
          </a>
        </div>
        <Button
          classes={{
            root: classes.buttonRoot,
            //   contained: classes.buttonRoot,
          }}
          onClick={handleLoginClick}
          variant="contained"
          // type="submit"  // This causes problems for some reason
          className={classes.button}
        >
          Login
        </Button>
      </form>
    </div>
  );
};

export default LoginForm;
