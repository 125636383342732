import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { PaymentType } from "services/api/types";

type Props = {
  mode: "Add" | "Edit";
  paymentType: PaymentType;
  onPaymentTypeChanged: (value: PaymentType) => void;
  refund: boolean;
  onRefundChanged: (value: boolean) => void;
};

const AddPaymentHeadeer = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      refundCheckbox: {
        marginLeft: 32,
        marginTop: -16,
      },
      formLabel: {
        fontSize: ".9rem",
      },
      optionsGroup: {
        display: "flex",
      },
      radios: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        cursor: props.mode === "Edit" ? "not-allowed" : "unset",
      },
      paymentTypeLabel: {
        marginRight: theme.spacing(2),
      },
    })
  );

  const classes = useStyles();

  const renderTypeRadios = () => {
    return (
      <div className={classes.radios}>
        <FormLabel className={classes.paymentTypeLabel}>
          Payment Type:
        </FormLabel>
        <FormControlLabel
          classes={{ label: classes.formLabel }}
          value="Check"
          control={<Radio disabled={props.mode === "Edit"} size="small" />}
          label="Check"
        />
        <FormControlLabel
          classes={{ label: classes.formLabel }}
          value="Points"
          control={<Radio disabled={props.mode === "Edit"} size="small" />}
          label="Points"
        />
      </div>
    );
  };

  const handlePaymentTypeChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    props.onPaymentTypeChanged(event.target.value as any);
  };

  const handleRefundChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    props.onRefundChanged(evt.currentTarget.checked);
  };

  return (
    <div className={classes.optionsGroup}>
      <RadioGroup
        row={true}
        onChange={handlePaymentTypeChange}
        value={props.paymentType}
        name="payment-type"
      >
        {renderTypeRadios()}
      </RadioGroup>
      <FormControlLabel
        disabled={props.mode === "Edit"}
        className={classes.refundCheckbox}
        control={
          <Checkbox checked={props.refund} onChange={handleRefundChanged} />
        }
        label="Refund"
      />
    </div>
  );
};

export default AddPaymentHeadeer;
