import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ITinyContact } from "services/api/types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";

type Props = {
  contacts: ITinyContact[]; // for now should be more generic
  checkedContactIds: string[];
  handleContactCheckedChanged: (contactId: string, isChecked: boolean) => void;
  title?: string;
};

const ContactCheckedListBox = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      checkedListBox: {
        minHeight: 400,
        maxHeight: 400,
        overflowY: "scroll",
        border: "1px solid gainsboro",
        margin: theme.spacing(2, 0),
        padding: theme.spacing(1),
      },
      label: {
        marginTop: 2,
        marginBottom: 2,
      },
      checkbox: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    })
  );

  const classes = useStyles();

  const handleContactCheckboxChanged = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    const contactId = evt.currentTarget.value;
    props.handleContactCheckedChanged(contactId, evt.currentTarget.checked);
  };

  return (
    <div>
      {props.title && (
        <Typography color="primary" component="div" variant="caption">
          {props.title}
        </Typography>
      )}
      <div className={classes.checkedListBox}>
        {props.contacts.map((contact) => {
          const checked = props.checkedContactIds.includes(contact.id);
          return (
            <div>
              <FormControlLabel
                key={contact.id}
                classes={{
                  label: classes.label,
                }}
                label={contact.altFullName}
                control={
                  <Checkbox
                    value={contact.id}
                    onChange={handleContactCheckboxChanged}
                    checked={checked}
                    className={classes.checkbox}
                  />
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ContactCheckedListBox;
