import { apiClient } from "services/api/client";
import {
  ICompanyUser,
  ICreateUserCommand,
  IUpdateUserCommand,
} from "services/api/types";

const USERS_URL = "shared/users";

export const getCompanyUserList = async (
  companyId: string,
  showDisabledAccounts: boolean
) => {
  const response = await apiClient.get<ICompanyUser[]>(`${USERS_URL}/list`, {
    params: {
      companyId,
      showDisabledAccounts,
    },
  });
  return response.data;
};

export const resendInvite = async (userId: string) => {
  const url = `${USERS_URL}/${userId}/resendInvite`;
  const response = await apiClient.post<any>(url);
  return response;
};

export const updateUser = async (command: IUpdateUserCommand) => {
  const url = `${USERS_URL}/${command.userId}`;
  const response = await apiClient.put<ICompanyUser>(url, command);
  return response.data;
};

export const createUser = async (command: ICreateUserCommand) => {
  const response = await apiClient.post<ICompanyUser>(USERS_URL, command);
  return response;
};

export const getUserByUserName = async (userName: string) => {
  const url = `${USERS_URL}/userName/${userName}`;
  const response = await apiClient.get<{ id: string; userName: string }>(url);
  return response;
};

export const disableUserAccount = async (userId: string) => {
  const url = `administrator/disabledUsers/${userId}`;
  const response = await apiClient.post<ICompanyUser>(url);
  return response.data;
};

export const enableUserAccount = async (userId: string) => {
  const url = `administrator/enabledUsers/${userId}`;
  const response = await apiClient.post<ICompanyUser>(url);
  return response.data;
};
