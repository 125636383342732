import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { stringToOption } from "utils/helpers";
import enumerableStore from "stores/enumerable";

type Props = {
  // formikProps: FormikProps<IContactDetailed>;
  error: boolean;
  handleBlur: (evt: React.FocusEvent<any>) => void;
  onChange: (value: string) => void;
  value: string | null;
};

const PhoneTypeDropdown = (props: Props) => {
  React.useEffect(() => {
    enumerableStore.ensurePhoneNumberTypesFetched();
  }, []);

  const isLoading = enumerableStore.isLoading("phoneNumberTypes");
  const phoneTypes = enumerableStore.getEnum("phoneNumberTypes");
  const phoneNumberTypesOptions = phoneTypes.map(stringToOption);

  const valueOption =
    phoneNumberTypesOptions.find((o) => o.value === props.value) ?? null;

  return (
    <Autocomplete
      placeholder="Type"
      value={valueOption}
      onChange={(event: any, o: any) => {
        props.onChange(o.value);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          error={props.error}
          margin="dense"
          // ****************************************************
          // NOTE: The autoselect behavior isn't perfect
          // Getting this onChange working might help
          // ****************************************************
          //   onChange={evt =>
          //     handleNewMemberSearchChange(evt.target.value)
          //   }
          label="Type"
          fullWidth
        />
      )}
      onBlur={props.handleBlur}
      options={phoneNumberTypesOptions}
      getOptionLabel={(option) => {
        // Note: this is odd. Sometimes we
        // get option as { label: <string>, value: <string> } like
        // we should. Other times we get
        // { label: { label: <string>, value: <string> }, value: { label: <string>, value: <string> }}
        if (typeof option.label === "string") {
          return option.label;
        }

        return (option as any).label.label;
      }}
      loading={isLoading}
    />
  );
};

export default PhoneTypeDropdown;
