import React from "react";
import myStore from "stores/my";

type Props = {
  number?: string;
  extension?: string;
  type?: string;
};

const PhoneNumber = (props: Props) => {
  const displayExtension = myStore.isAdminNotImpersonating;

  if (!props.number) {
    return <div />;
  } else if (props.extension && displayExtension) {
    return (
      <div>{`${props.number} Ext: ${props.extension} (${props.type})`}</div>
    );
  } else if (props.extension && !displayExtension) {
    return <div>{`${props.number} (${props.type})`}</div>;
  } else {
    return (
      <div>
        <a onClick={(e) => e.stopPropagation()} href={`tel:${props.number}`}>
          {props.number}
        </a>
        {props.type && <span>{`  (${props.type})`}</span>}
      </div>
    );
  }
};

export default PhoneNumber;
