import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ITinyEventPayerInvoice } from "services/api/types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import { formatMoney } from "utils/number";
import DateFormat from "components/Shell/DateFormat";

type Props = {
  handleClose: () => void;
  activeInvoices: ITinyEventPayerInvoice[];
};

const UpdateEventGroupInvoicesModal = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
    })
  );

  const classes = useStyles();

  const renderInvoices = () => {
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Invoice #</TableCell>
            <TableCell>Created On</TableCell>
            <TableCell>Created By</TableCell>
            <TableCell align="right">Amount Due</TableCell>
            <TableCell align="right">Amount Paid</TableCell>
            <TableCell align="right">Additional Amount Paid</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.activeInvoices.map((invoice) => {
            return (
              <TableRow key={invoice.id}>
                <TableCell>{invoice.invoiceNumber}</TableCell>
                <TableCell>
                  <DateFormat date={invoice.createdOn} />
                </TableCell>
                <TableCell>{invoice.createdBy}</TableCell>
                <TableCell align="right">
                  {formatMoney(invoice.amount)}
                </TableCell>
                <TableCell align="right">
                  {formatMoney(invoice.amountPaid)}
                </TableCell>
                <TableCell align="right">
                  <div>Text Field Here</div>T
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  };

  return (
    <div className={classes.root}>
      <ResponsiveModalShell
        handleClose={props.handleClose}
        title="Update Event Group Invoices"
      >
        {renderInvoices()}
      </ResponsiveModalShell>
    </div>
  );
};

export default UpdateEventGroupInvoicesModal;
