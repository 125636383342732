import { apiClient } from "services/api/client";
import { getFileName } from "services/api/modules/helpers";

import {
  INewMonthlyReportData,
  ICreateMonthyReportCommand,
  IFullMonthlyReportResult,
  IUpdateMonthlyReportCommand,
  ISpreadsheetError,
} from "services/api/types";

const MONTHLY_REPORTS_URL = "shared/monthlyReports";

export const getNewMonthlyReportData = async (sponsorId: string) => {
  const response = await apiClient.get<INewMonthlyReportData>(
    `${MONTHLY_REPORTS_URL}/newReportData`,
    {
      params: {
        sponsorId,
      },
    }
  );

  return response;
};

export const downloadTemplate = async (sponsorId: string) => {
  const url = `${MONTHLY_REPORTS_URL}/template/${sponsorId}`;

  const response = await apiClient.get(url, { responseType: "blob" });
  const fileName = getFileName(response, "monthlyReportTemplate.xlsx");

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const uploadSpreadsheet = async (file: File, sponsorId: string) => {
  const url = `${MONTHLY_REPORTS_URL}/upload/${sponsorId}`;

  const formData = new FormData();
  formData.append("file", file);

  const result = await apiClient.post<
    IFullMonthlyReportResult | ISpreadsheetError[]
  >(url, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return result.data;
};

export const saveNewMonthlyReport = async (
  payload: ICreateMonthyReportCommand
) => {
  const response = await apiClient.post<IFullMonthlyReportResult>(
    MONTHLY_REPORTS_URL,
    payload
  );

  return response;
};

export const updateMonthlyReport = async (
  payload: IUpdateMonthlyReportCommand
) => {
  const response = await apiClient.put<IFullMonthlyReportResult>(
    MONTHLY_REPORTS_URL,
    payload
  );
  return response;
};

export const getNextBatchNumber = async (
  month: number,
  year: number,
  sponsorId: string,
  currentReportId?: string
) => {
  const response = await apiClient.get<number>(
    `${MONTHLY_REPORTS_URL}/nextBatchNumber`,
    {
      params: {
        month,
        year,
        sponsorId,
        currentReportId,
      },
    }
  );

  return response.data;
};

export const getFullReport = async (monthlyReportId: string) => {
  const response = await apiClient.get<IFullMonthlyReportResult>(
    `${MONTHLY_REPORTS_URL}/${monthlyReportId}`
  );
  return response;
};

export const getDataForReport = async (monthlyReportId: string) => {
  const response = await apiClient.get<string>(
    `${MONTHLY_REPORTS_URL}/${monthlyReportId}/data`
  );
  return response.data;
};
