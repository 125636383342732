import { apiClient } from "services/api/client";
import {
  INewMemberConfirmationAttachment,
  ISentEmail,
  IListedMemberReferral,
  IUpdateMemberReferralClaimStatusCommand,
  IApplySupplierProfileCommand,
  ISendNewMemberConfirmationResult,
  IListedMember,
  IExistingProfileViewModel,
  ITrackedLead,
  IPurchaserInfo,
  IListedSupplierProfile,
} from "services/api/types";

const MEMBERS_URL = "administrator/members";

export const getInfo = async (memberId: string) => {
  const url = `${MEMBERS_URL}/${memberId}/info`;
  const response = await apiClient.get<IPurchaserInfo>(url);
  return response.data;
};

export const getCategoryIdsEarningPointsIn = async (memberId: string) => {
  const url = `${MEMBERS_URL}/${memberId}/categories/earningPoints`;
  const response = await apiClient.get<string[]>(url);
  return response.data;
};

export const getTrackedLeads = async (memberId: string) => {
  const url = `${MEMBERS_URL}/${memberId}/trackedLeads`;
  const response = await apiClient.get<ITrackedLead[]>(url);
  return response.data;
};

export const createNewMemberWelcomeAttachment = async (args: {
  fileUri: string;
  originalFileName: string;
}) => {
  const url = `${MEMBERS_URL}/welcomeEmails/attachments`;
  const response = await apiClient.post<INewMemberConfirmationAttachment>(
    url,
    args
  );
  return response.data;
};

export const getAllNewMemberWelcomeAttachments = async () => {
  const url = `${MEMBERS_URL}/welcomeEmails/attachments`;
  const response = await apiClient.get<INewMemberConfirmationAttachment[]>(url);
  return response.data;
};

export const sendNewMemberWelcome = async (args: {
  contactIds: string[];
  sponsorId?: string;
  attachmentIds: string[];
}) => {
  const url = `${MEMBERS_URL}/welcomeEmails`;
  const response = await apiClient.post<ISendNewMemberConfirmationResult>(
    url,
    args
  );
  return response;
};

export const sendNewSponsorReferralConfirmation = async (args: {
  referringContactId: string;
  referringSponsorId: string;
  newMemberId: string;
}) => {
  const url = `administrator/sponsors/referralConfirmations`;
  const response = await apiClient.post(url, args);
  return response;
};

export const getSentMemberWelcomeEmails = async (memberId: string) => {
  const url = `${MEMBERS_URL}/welcomeEmails/${memberId}`;
  const response = await apiClient.get<ISentEmail[]>(url);
  return response.data;
};

export const getSentSponsorReferralConfirmations = async (memberId: string) => {
  const url = `administrator/sponsors/referralConfirmations/${memberId}`;
  const response = await apiClient.get<ISentEmail[]>(url);
  return response.data;
};

export const getMemberReferralsNeedAttentionCount = async () => {
  const url = "administrator/memberReferrals/needAttentionCount";
  const response = await apiClient.get<number>(url);
  return response.data;
};

export const getAllMemberReferrals = async () => {
  const url = "administrator/memberReferrals";
  const response = await apiClient.get<IListedMemberReferral[]>(url);
  return response.data;
};

export const getReferral = async (referralId: string) => {
  const url = `administrator/memberReferrals/${referralId}`;
  const response = await apiClient.get<IListedMemberReferral>(url);
  return response.data;
};

export const resolveReferralUsingExistingCompany = async (args: {
  memberReferralId: string;
  existingMemberId: string;
}) => {
  const url = `administrator/duplicateReferrals`;
  const response = await apiClient.post(url, args);
  return response.data;
};

export const dismissReferral = async (
  referralId: string,
  connectionId: string
) => {
  const url = "administrator/dismissedReferrals";
  const response = await apiClient.post(url, { referralId, connectionId });
  return response;
};

export const updateClaimStatus = async (
  args: IUpdateMemberReferralClaimStatusCommand
) => {
  const url = `administrator/memberReferrals/${args.referralId}`;
  const response = await apiClient.put<IListedMemberReferral>(url, args);
  return response;
};

export const getNewest = async () => {
  const url = "administrator/members/newest";
  const response = await apiClient.get<IListedMember[]>(url);
  return response.data;
};

export const getAllSupplierProfiles = async () => {
  const url = `administrator/supplierProfiles`;
  const response = await apiClient.get<IListedSupplierProfile[]>(url);
  return response;
};

export const getCompletedSupplierProfile = async (profileId: string) => {
  const url = `administrator/supplierProfiles/${profileId}`;
  const response = await apiClient.get<IExistingProfileViewModel>(url);
  return response.data;
};

export const getSupplierProfilesNeedAttentionCount = async () => {
  const url = `administrator/supplierProfiles/needAttentionCount`;
  const response = await apiClient.get<number>(url);
  return response.data;
};

export const applySupplierProfile = async (
  command: IApplySupplierProfileCommand
) => {
  const url = `administrator/supplierProfiles/${command.profileId}`;
  const response = await apiClient.post(url, command);
  return response;
};

export const closeAccount = async (command: {
  memberId: string;
  notes: string;
}) => {
  const url = `administrator/members/closedAccounts`;
  const response = await apiClient.post(url, command);
  return response;
};
