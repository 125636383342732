import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import { Observer } from "mobx-react";
import Typography from "@material-ui/core/Typography";
import api from "services/api";

type Props = {
  handleClose: () => void;
  handleFileAdded: (uri: string) => void;
};

const AddUpdateContactPhotoModal = (props: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [fileSet, setFileSet] = React.useState(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      instructions: {
        marginBottom: theme.spacing(2),
      },
      formLabel: {
        fontSize: ".9rem",
      },
    })
  );

  const classes = useStyles();

  const handleFileInputChanged = () => {
    setFileSet(true);
  };

  const handleAddClick = async () => {
    const file = inputRef.current!.files![0];
    const uploadedFile = await api.admin.files.uploadPublicFile(file);
    props.handleFileAdded(uploadedFile.uri);
    props.handleClose();
  };

  return (
    <Observer
      render={() => {
        return (
          <ResponsiveModalShell
            handleSave={handleAddClick}
            handleClose={props.handleClose}
            disableSaveButton={!fileSet}
            title="Add Image"
          >
            <Typography className={classes.instructions}>
              Click the "Choose File" button below to find an image to upload.
              Then click "Add Image".
            </Typography>
            <div>
              <input
                ref={inputRef}
                type="file"
                name="file"
                accept=".jpg,.png"
                onChange={handleFileInputChanged}
              />
            </div>
          </ResponsiveModalShell>
        );
      }}
    />
  );
};

export default AddUpdateContactPhotoModal;
