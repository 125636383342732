import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import { getRange } from "utils/number";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

type Props = {
  goToPage: (pageIndex: number) => void;
  canGoNext: boolean;
  canGoPrevious: boolean;
  currentPage: number;
  totalPages: number;
};

const MobilePager = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | undefined>(
    undefined
  );

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
      },
    })
  );

  const classes = useStyles();

  const renderPageMenu = () => {
    if (!anchorEl) return;
    if (!props.totalPages) return;

    const pageArray = getRange(1, props.totalPages);

    return (
      <Menu
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id="table-menu"
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(undefined)}
        open={Boolean(anchorEl)}
      >
        {pageArray.map((page) => {
          return (
            <MenuItem
              key={page}
              onClick={() => {
                const pageIndex = page - 1;
                props.goToPage(pageIndex);
                setAnchorEl(undefined);
              }}
            >
              {<span>{page}</span>}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  return (
    <div className={classes.root}>
      <IconButton
        size="small"
        onClick={() => {
          window.scrollTo(0, 0);
          props.goToPage(0);
        }}
        disabled={props.currentPage === 0}
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => {
          window.scrollTo(0, 0);
          props.goToPage(props.currentPage - 1);
        }}
        disabled={!props.canGoPrevious}
      >
        <NavigateBeforeIcon />
      </IconButton>
      {renderPageMenu()}
      <div onClick={(e) => setAnchorEl(e.currentTarget)}>{`Page ${
        props.currentPage + 1
      } of ${props.totalPages}`}</div>
      <IconButton
        size="small"
        onClick={() => {
          window.scrollTo(0, 0);
          props.goToPage(props.currentPage + 1);
        }}
        disabled={!props.canGoNext}
      >
        <NavigateNextIcon />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => {
          window.scrollTo(0, 0);
          props.goToPage(props.totalPages - 1);
        }}
        disabled={props.currentPage + 1 >= props.totalPages}
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};

export default MobilePager;
