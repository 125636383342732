import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Hero from "./Components/Hero";
import EarnPoints from "./Components/EarnPoints";
import HowItWorks from "./Components/HowItWorks";
import ContactUs from "../shared/ContactUs";
import { Observer } from "mobx-react";
import Legal from "./Components/Legal";
import CssBaseline from "@material-ui/core/CssBaseline";
import api from "services/api";
import { useHistory } from "react-router";
import authService from "components/api-authorization/AuthorizeService";
import { ApplicationPaths } from "components/api-authorization/ApiAuthorizationConstants";
import uiStore from "stores/ui";
import appTextStore from "stores/shared/appText";
import AboutUs from "../shared/AboutUs";

const menuHeight = 60;

const BuildPerksHome = () => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [signedInUserName, setSignedInUserName] = React.useState("");
  const [failedLogin, setFailedLogin] = React.useState(false);
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);
  const [failedLoginAttempts, setFailedLoginAttempts] = React.useState(0);
  const [isLockedOut, setIsLockedOut] = React.useState(false);

  const backgoundImage = `linear-gradient(180deg, rgb(100, 177, 241) 0%, rgba(100, 177, 241,.6) 100%), url('/images/hero-background.jpg')`;
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundImage: backgoundImage,
      },
      containerRoot: {
        fontSize: "1.0rem",
        // backgroundSize: "contain",
        backgroundImage: "url('images/hero-background.jpg')",
        boxShadow: "2px 3px 27px 0px rgba(0,0,0,0.75)",
      },
      menu: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        width: "100%",
        zIndex: 100,
        backgroundColor: "white",
        minHeight: menuHeight,
        maxHeight: menuHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gridTemplateColumns: "auto 1fr auto",
        borderBottom: "1px solid #dadada",
        fontWeight: "bold",
        fontSize: "1.4rem",
        color: theme.palette.primary.light,
        padding: theme.spacing(0, 2),
        boxShadow: "0 6px 6px -6px #777",
      },
      logo: {
        width: "150px",
        display: "inline-block",
      },
      container: {
        paddingTop: 10,
      },
      link: {
        fontSize: "1rem",
      },
    })
  );

  const classes = useStyles();
  const history = useHistory();

  const handleLogOut = async () => {
    const logoutPath = {
      pathname: `${ApplicationPaths.LogOut}`,
      state: { local: true },
    };
    history.push(logoutPath);
  };

  const populateState = async () => {
    const [isAuthenticated, user] = await Promise.all([
      authService.isAuthenticated(),
      authService.getUser(),
    ]);

    setSignedInUserName(user && user.name);
    setIsAuthenticated(isAuthenticated);
  };

  React.useEffect(() => {
    populateState();
  });

  React.useEffect(() => {
    uiStore.ensureHostCompanyInfoIsLoaded();
    appTextStore.ensureAllAppTextLoaded();
  }, []);

  const handleLogIn = async (
    userName: string,
    password: string,
    rememberMe: boolean
  ) => {
    try {
      setIsLoggingIn(true);
      setFailedLogin(false);
      setIsLockedOut(false);
      await api.public.login(userName, password, rememberMe);
      setFailedLoginAttempts(0);
      history.push("/app");
    } catch (e) {
      if ((e as any).data.isLockedOut) {
        setIsLockedOut(true);
      }
      setFailedLoginAttempts(failedLoginAttempts + 1);
      setFailedLogin(true);
      setIsLoggingIn(false);
    }
  };

  return (
    <Observer
      render={() => {
        const { hostCompanyInfo } = uiStore;
        const { appText } = appTextStore;
        const aboutUs = appText.find((a) => a.key === "about-us");

        return (
          <div className={classes.root}>
            <CssBaseline />
            <Container>
              <div className={classes.menu}>
                <img
                  alt="BuildPerks Logo"
                  className={classes.logo}
                  src="/images/buildperks.png"
                />
              </div>
            </Container>
            <Container className={classes.container} disableGutters={true}>
              <div className={classes.containerRoot}>
                <br />
                <br />
                <Hero
                  onLogIn={handleLogIn}
                  isLoggingIn={isLoggingIn}
                  handleLogOut={handleLogOut}
                  failedLoginAttempts={failedLoginAttempts}
                  isLockedOut={isLockedOut}
                  isSignedIn={isAuthenticated}
                  loginFailed={failedLogin}
                  signedInUserName={signedInUserName}
                />
              </div>
              <br />
              <br />
              <HowItWorks />
              <EarnPoints />
              <AboutUs text={aboutUs?.text ?? ""} />
              <ContactUs hostCompanyInfo={hostCompanyInfo} />
              <Legal />
            </Container>
          </div>
        );
      }}
    />
  );
};

export default BuildPerksHome;
