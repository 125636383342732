import { apiClient } from "services/api/client";
import { IListNewspapersResult } from "services/api/types";

export const getAllNewspapers = async () => {
  const url = "public/newspapers";
  const response = await apiClient.get<IListNewspapersResult>(url);
  return response.data;
};

export const getLatestNewspaper = async () => {
  const url = "public/latestNewspaper";
  const response = await apiClient.get<string>(url);
  return response.data;
};
