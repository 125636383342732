import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

const HowItWorks = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        padding: "10px 0 20px 0",
        background: "#2B3F4F",
        color: "white",
        "& h2": {
          fontFamily: "Oswald, sans-serif",
          fontWeight: 400,
          textAlign: "center",
          margin: ".2em 0",
          [theme.breakpoints.between("xs", "md")]: {
            fontSize: "1.5em",
          },
          [theme.breakpoints.between("md", "lg")]: {
            fontSize: "2em",
          },
          [theme.breakpoints.between("lg", "xl")]: {
            fontSize: "2.5em",
          },
        },
      },
      steps: {
        display: "grid",
        textAlign: "center",
        margin: "0 5%",
        // [theme.breakpoints.between("xs", "sm")]: {
        //   gridTemplateColumns: "1fr",
        // },
        [theme.breakpoints.between("xs", "xl")]: {
          gridTemplateColumns: "1fr auto 1fr auto 1fr auto 1fr",
        },
      },
      step: {
        display: "grid",
        gridTemplateRows: "1fr 35px",
      },
      stepCaption: {
        [theme.breakpoints.between("xs", "sm")]: {
          fontWeight: "normal",
          fontSize: "small",
        },
        [theme.breakpoints.between("sm", "xl")]: {
          fontWeight: "bold",
          fontSize: "unset",
        },
      },
      stepImage: {
        margin: "auto",
        width: "100%",
      },
      arrow: {
        margin: "auto",
        transform: "translateY(-20px)",
        [theme.breakpoints.between("xs", "sm")]: {
          width: "20px",
        },
        [theme.breakpoints.between("sm", "xl")]: {
          width: "40px",
        },
      },
    })
  );

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h2>How It Works</h2>
      <div className={classes.steps}>
        <div className={classes.step}>
          <img
            className={classes.stepImage}
            src="/images/buy.png"
            alt="Buy Materials"
          />
          <div className={classes.stepCaption}>Buy Materials</div>
        </div>
        <img className={classes.arrow} src="/images/right.png" alt="then" />
        <div className={classes.step}>
          <img
            className={classes.stepImage}
            src="/images/pay.png"
            alt="Pay Within Terms"
          />
          <div className={classes.stepCaption}>Pay Within Terms</div>
        </div>
        <img className={classes.arrow} src="/images/right2.png" alt="then" />
        <div className={classes.step}>
          <img
            className={classes.stepImage}
            src="/images/earn.png"
            alt="Receive Club Points"
          />
          <div className={classes.stepCaption}>Receive Club Points</div>
        </div>
        <img className={classes.arrow} src="/images/right.png" alt="then" />
        <div className={classes.step}>
          <img
            className={classes.stepImage}
            src="/images/redeem.png"
            alt="Redeem for Rewards"
          />
          <div className={classes.stepCaption}>Redeem for Rewards</div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
