import { apiClient } from "services/api/client";
import {
  IPointsHistoryResult,
  IMemberGroupedRow,
  IMemberDetailsRow,
} from "services/api/types";
import { downloadFile } from "services/api/modules/helpers";

const MEMBERS_POINTS_URL = "members/pointsHistory";

export const getPointHistoryDetails = async (params: {
  startDate: Date;
  endDate: Date;
  companyId: string;
  otherCompanyId: string;
  pointRate?: string;
  unitName?: string;
}) => {
  const url = `${MEMBERS_POINTS_URL}/details`;
  const response = await apiClient.get<IPointsHistoryResult<IMemberDetailsRow>>(
    url,
    {
      params,
    }
  );
  return response;
};

export const getPointHistoryGrouped = async (params: {
  companyId: string;
  startDate: Date;
  endDate: Date;
}) => {
  const url = `${MEMBERS_POINTS_URL}/grouped`;
  const response = await apiClient.get<IPointsHistoryResult<IMemberGroupedRow>>(
    url,
    {
      params,
    }
  );
  return response;
};

export const getPointsHistoryDetailsExport = async (args: {
  startDate: Date;
  endDate: Date;
  companyId: string;
  otherCompanyId: string;
  pointRate?: string;
  unitName?: string;
}) => {
  const url = `${MEMBERS_POINTS_URL}/details/export`;

  const response = await apiClient.post(url, args, {
    responseType: "blob",
  });

  downloadFile(response);
};

export const getPointsHistoryGroupedExport = async (args: {
  startDate: Date;
  endDate: Date;
}) => {
  const url = `${MEMBERS_POINTS_URL}/grouped/export`;

  const response = await apiClient.post(url, args, {
    responseType: "blob",
  });

  downloadFile(response);
};
