import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";

type Props = {
  message?: string;
  dim?: boolean;
  alignLeft?: boolean;
  disableTopMargin?: boolean;
};

const NoDataMessage = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        color: theme.palette.grey[700],
        marginTop: props.disableTopMargin ? 0 : theme.spacing(2),
        fontSize: "12pt",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
          fontSize: "11pt",
          textAlign: "left",
          marginTop: 0,
        },
      },
      dim: {
        color: theme.palette.grey[400],
      },
      left: {
        textAlign: "left",
      },
    })
  );

  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.dim]: props.dim,
        [classes.left]: props.alignLeft,
      })}
    >
      {props.message || "No data available"}
    </div>
  );
};

export default NoDataMessage;
