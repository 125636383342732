import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import DownloadIcon from "@material-ui/icons/OpenInBrowser";
import { INewMemberConfirmationAttachment } from "services/api/types";
import Typography from "@material-ui/core/Typography";
import api from "services/api";

type Props = {
  onAttachmentCheckboxChanged: (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => void;
  attachments: INewMemberConfirmationAttachment[];
  checkedAttachmentIds: string[];
  onAttachmentAdded: (newAttachment: INewMemberConfirmationAttachment) => void;
};

const NewMemberAttachmentsList = (props: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      iconButton: {
        marginLeft: theme.spacing(1),
        color: theme.palette.primary.main,
      },
      checkbox: {
        paddingTop: 0,
        paddingBottom: 0,
      },
      checkedListBox: {
        // minHeight: 400,
        // maxHeight: 400,
        overflowY: "scroll",
        border: "1px solid gainsboro",
        margin: theme.spacing(2, 0),
        padding: theme.spacing(1),
      },
      label: {
        marginTop: 2,
        marginBottom: 2,
      },
    })
  );

  const classes = useStyles();

  const navigateToAttachment = (attachmentId: string) => {
    const url = `api/administrator/members/welcomeEmails/attachments/${attachmentId}/download`;
    window.open(url, "_blank");
  };

  const handleFileInputChanged = async () => {
    // Upload and create attachment right away...?
    const file = inputRef.current!.files![0];
    const uploadResult = await api.admin.files.uploadFile(file);

    const newAttachment =
      await api.admin.members.createNewMemberWelcomeAttachment({
        fileUri: uploadResult.uri,
        originalFileName: uploadResult.originalFileName,
      });

    props.onAttachmentAdded(newAttachment);
    inputRef.current!.value = "";
  };

  return (
    <>
      <div className={classes.checkedListBox}>
        {props.attachments.map((a) => {
          const checked = props.checkedAttachmentIds.includes(a.id);
          return (
            <div>
              <FormControlLabel
                key={a.id}
                classes={{
                  label: classes.label,
                }}
                label={
                  <div>
                    {a.originalFileName}{" "}
                    <IconButton
                      className={classes.iconButton}
                      onClick={() => navigateToAttachment(a.id)}
                      size="small"
                    >
                      <DownloadIcon />
                    </IconButton>
                  </div>
                }
                control={
                  <Checkbox
                    value={a.id}
                    onChange={props.onAttachmentCheckboxChanged}
                    checked={checked}
                    className={classes.checkbox}
                  />
                }
              />
            </div>
          );
        })}
      </div>
      <Typography>
        Click the "Choose File" button below to upload and use a new attachment
      </Typography>
      <div>
        <input
          ref={inputRef}
          type="file"
          name="file"
          onChange={handleFileInputChanged}
        />
      </div>
    </>
  );
};

export default NewMemberAttachmentsList;
