import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import MemberReferralForm from "./MemberReferralForm";
import { IMemberReferralData } from "services/api/types";
import api from "services/api";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import { Formik, FormikActions } from "formik";
import * as yup from "yup";
import * as appConstants from "applications/appConstants";

type Props = {
  handleClose: () => void;
  openedFromMonthlyReport: boolean;
  defaultCompanyName: string;
  onReferralCreated: () => void;
};

type SubmissionState = "NotSubmitted" | "InProgress" | "Submitted" | "Error";

const CreateMemberReferral = (props: Props) => {
  const [submissionState, setSubmissionState] =
    React.useState<SubmissionState>("NotSubmitted");
  const [messageVisible, setMessageVisible] = React.useState(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      introduction: {
        marginBottom: theme.spacing(4),
      },
      submittedMessage: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        opacity: 0.0,
        fontSize: "larger",
        textAlign: "center",
        transition: theme.transitions.create(["opacity"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        minHeight: 500,
      },
      visibleMessage: {
        opacity: 1,
      },
      errorMessage: {
        color: theme.palette.error.main,
      },
      infoMesage: {
        color: theme.palette.error.main,
        margin: theme.spacing(4, 0),
      },
    })
  );

  const classes = useStyles();

  const handleSubmitForm = async (data: IMemberReferralData) => {
    setSubmissionState("InProgress");
    try {
      await api.sponsors.members.createReferral(data);
      setSubmissionState("Submitted");
      props.onReferralCreated();
      setTimeout(() => {
        setMessageVisible(true);
      }, 100);
      setTimeout(() => {
        props.handleClose();
      }, 4000);
    } catch {
      setSubmissionState("Error");
    }
  };

  const showForm =
    submissionState === "InProgress" || submissionState === "NotSubmitted";

  const initialValues: IMemberReferralData = {
    address: "",
    city: "",
    companyName: props.defaultCompanyName,
    emailAddress: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    state: "",
    zipCode: "",
    notes: "",
  };

  const schema = yup.object().shape({
    companyName: yup.string().required("Required"),
    address: yup.string().notRequired(),
    city: yup.string(),
    state: yup.string(),
    zipCode: yup.string(),
    emailAddress: yup.string().email("Please enter a valid email address"),
    firstName: yup.string(),
    lastName: yup.string(),
    phoneNumber: yup.string(),
    notes: yup.string(),
  });

  const onSubmit = async (
    values: IMemberReferralData,
    actions: FormikActions<IMemberReferralData>
  ) => {
    await handleSubmitForm(values);
    actions.setSubmitting(false);
  };

  const done = submissionState === "Error" || submissionState === "Submitted";

  const renderSubheader = () => {
    return (
      props.openedFromMonthlyReport && (
        <Typography className={classes.infoMesage}>
          A {process.env.REACT_APP_TITLE} representative will complete the
          process and add the member to your report shortly.
        </Typography>
      )
    );
  };

  const companyName = process.env.REACT_APP_TITLE;
  const idName =
    process.env.REACT_APP_BRANDING === appConstants.BuildPerks
      ? "Perk"
      : "Club";

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      render={(formikProps) => {
        return (
          <ResponsiveModalShell
            saveButtonCaption="Submit"
            hideAllButtons={done}
            handleSave={formikProps.submitForm}
            disableSaveButton={submissionState !== "NotSubmitted"}
            title={done ? "" : "Enroll Member"}
            handleClose={props.handleClose}
          >
            <div className={classes.root}>
              {showForm && (
                <>
                  <Typography component="div" className={classes.introduction}>
                    <strong>
                      Thank You for enrolling a NEW {companyName} Member!
                    </strong>
                    <div>
                      Please provide as much information as possible when
                      enrolling a New Member.
                    </div>
                    <div>
                      Once you submit this form, {companyName} will complete the
                      enrollment process.
                    </div>
                    <div>
                      We will email you with their {idName} ID# and the New
                      Member will be added to your online reporting form.
                    </div>
                  </Typography>
                  <MemberReferralForm formikProps={formikProps} />
                </>
              )}
              {submissionState === "Submitted" && (
                <Typography
                  component="div"
                  className={clsx(classes.submittedMessage, {
                    [classes.visibleMessage]: messageVisible,
                  })}
                >
                  <h2>THANK YOU!</h2>
                  <div>Your referral has been submitted.</div>
                  {renderSubheader()}
                </Typography>
              )}
              {submissionState === "Error" && (
                <Typography component="div" className={classes.errorMessage}>
                  <h2>Sorry!</h2>
                  <div>
                    We've encountered a problem. Please try again later.
                  </div>
                </Typography>
              )}
            </div>
          </ResponsiveModalShell>
        );
      }}
    />
  );
};

export default CreateMemberReferral;
