import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import api from "services/api";
import {
  IFullMonthlyReportResult,
  ISpreadsheetError,
} from "services/api/types";
import red from "@material-ui/core/colors/red";
import myStore from "stores/my";
import * as messages from "utils/messages";
import { useHistory } from "react-router";
import { paths } from "routes/Paths";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import clsx from "clsx";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";

type Props = {
  sponsorId: string;
  audience: "sponsor" | "admin";
  handleClose: () => void;
  onMonthlyReportCreated: (reportId: string) => void;
  onValidationErrorsReceived: (errors: ISpreadsheetError[]) => void;
};

const CreateMonthlyReportModal = (props: Props) => {
  const [createOption, setCreateOption] = React.useState<"Upload" | "Manual">(
    "Manual"
  );
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = React.useState<boolean>(false);
  const [isDownloadingTemplate, setIsDownloadingTemplate] =
    React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );

  const history = useHistory();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      instructions: {
        marginBottom: theme.spacing(2),
      },
      error: {
        color: red[500],
      },
      errorHost: {
        marginBottom: theme.spacing(2),
      },
      uploadInstructions: {
        margin: "0 0 1em 0",
        fontSize: "smaller",
      },
      link: {
        color: "blue",
        cursor: "pointer",
      },
      uploadInfoHost: {
        marginLeft: 30,
      },
      disabled: {
        opacity: 0,
        pointerEvents: "none",
        maxHeight: 20,
      },
      disabledLink: {
        color: "gray",
        textDecoration: "none",
        pointerEvents: "none",
      },
    })
  );

  function isFullMonthlyReportResult(
    result: IFullMonthlyReportResult | ISpreadsheetError[]
  ): result is IFullMonthlyReportResult {
    return (result as IFullMonthlyReportResult).report !== undefined;
  }

  const handleUpload = async () => {
    if (!props.sponsorId) {
      alert("No sponsorId");
      return;
    }

    if (!inputRef.current!.files || inputRef.current!.files.length === 0) {
      alert("Please select a file");
    } else {
      const file = inputRef!.current!.files[0];
      setIsUploading(true);

      try {
        const result = await api.shared.monthlyReports.uploadSpreadsheet(
          file,
          props.sponsorId
        );

        setIsUploading(false);

        if (isFullMonthlyReportResult(result)) {
          props.onMonthlyReportCreated(result.report.id);
          props.handleClose();
        } else {
          props.onValidationErrorsReceived(result);
          props.handleClose();
        }
      } catch (e) {
        const eAny: any = e;
        if (eAny.response) {
          setErrorMessage(eAny.response.data);
        }

        setIsUploading(false);
      }
    }
  };

  const navigateToNewMonthlyReport = async () => {
    if (props.audience === "sponsor") {
      history.push(`${paths.app.sponsors.root}/newMonthlyReport`);
    } else {
      history.push(
        `${paths.app.admin.companyDetails}/${props.sponsorId!}/newMonthlyReport`
      );
    }
  };

  const handleOkButtonClicked = async () => {
    if (createOption === "Upload") {
      handleUpload();
    } else {
      navigateToNewMonthlyReport();
      props.handleClose();
    }
  };

  const classes = useStyles();

  const handleTemplateDownload = async () => {
    setIsDownloadingTemplate(true);

    try {
      await myStore.getMyInfo();

      if (!myStore.currentCompany && !props.sponsorId) {
        throw Error("Unable to determine companyId");
      }

      const companyId = myStore.currentCompany
        ? myStore.currentCompany.companyId
        : props.sponsorId!;

      // This is a little overkill. All we really need to know is if
      // they have rates set up
      const newData = await api.shared.monthlyReports.getNewMonthlyReportData(
        companyId
      );

      if (newData.data.rates.length === 0) {
        alert(messages.sponsorNoRatesMessage);
      } else {
        await api.shared.monthlyReports.downloadTemplate(companyId);
      }
      setIsDownloadingTemplate(false);
    } catch (e) {
      setIsDownloadingTemplate(false);
    }
  };

  const renderInstructions = () => {
    const downloadTemplateBlock = (
      <>
        <Typography className={classes.uploadInstructions}>
          <span
            onClick={() => window.setTimeout(handleTemplateDownload, 100)}
            className={clsx(classes.link, {
              [classes.disabledLink]: isDownloadingTemplate,
            })}
          >
            Click here
          </span>
          , to download a template. Fill it out, then click the button below to
          select your spreadsheet and click the <strong>Upload</strong> button.
        </Typography>
        <Typography className={classes.uploadInstructions}>
          <strong>Note:</strong>&nbsp;The template now includes a second
          worksheet containing a list of all active members. If a member is
          missing from your report, you can add them by copying and pasting from
          this worksheet.
        </Typography>
      </>
    );

    return downloadTemplateBlock;
  };

  const handleOptionChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setCreateOption(evt.currentTarget.value as any);
  };

  return (
    <ResponsiveModalShell
      disableSaveButton={isUploading}
      handleClose={props.handleClose}
      saveButtonCaption="OK"
      handleSave={handleOkButtonClicked}
      title="New Report"
    >
      <div>
        <Typography>How would you like to start your report?</Typography>
        <RadioGroup onChange={handleOptionChanged} value={createOption}>
          <FormControlLabel
            label="Manually enter the values"
            value="Manual"
            control={<Radio />}
          />
          <FormControlLabel
            label="Upload a spreadsheet"
            value="Upload"
            control={<Radio />}
          />
          <div
            className={clsx(classes.uploadInfoHost, {
              [classes.disabled]: createOption !== "Upload",
            })}
          >
            {renderInstructions()}
            {errorMessage ? (
              <div className={classes.errorHost}>
                <Typography className={classes.error}>
                  Your spreadsheet could not be uploaded because of the
                  following issues:
                </Typography>
                <Typography className={classes.error}>
                  {errorMessage}
                </Typography>
              </div>
            ) : undefined}
            <div>
              <input ref={inputRef} type="file" name="file" />
            </div>
          </div>
        </RadioGroup>
      </div>
    </ResponsiveModalShell>
  );
};

export default CreateMonthlyReportModal;
