import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import red from "@material-ui/core/colors/red";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";
import enumerablesStore from "stores/enumerable";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import Divider from "@material-ui/core/Divider";

import {
  AttachmentAudience,
  IMonthlyStatementAttachment,
} from "services/api/types";

import { Observer } from "mobx-react";

type Props = {
  handleClose: () => void;
  handleAddAttachment: (attachment: IMonthlyStatementAttachment) => void;
};

const AddEditAttachment = (props: Props) => {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [audience, setAudience] = React.useState<AttachmentAudience>("All");
  const [touched, setTouched] = React.useState(false);
  const [selectedMarkets, setSelectedMarkets] = React.useState<string[]>([]);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        position: "relative",
        display: "grid",
        gridTemplateColumns: "1fr",
        gridColumnGap: theme.spacing(2),
        gridRowGap: theme.spacing(3),
      },
      title: {
        display: "inline-block",
      },
      controls: {
        display: "grid",
        gridTemplateColumns: "auto 1fr auto 2fr",
      },
      formLabel: {
        fontSize: ".9rem",
      },
      radiosHost: {
        margin: theme.spacing(0, 2),
        transform: "translateY(-10px)",
      },
      marketsHost: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
      },
      errors: {
        color: red[500],
      },
    })
  );

  React.useEffect(() => {
    enumerablesStore.ensureMarketsFetched();
  }, []);

  const handleFileInputChanged = () => {
    if (touched) {
      validate();
    }
  };

  const validate = () => {
    const localErrors: string[] = [];

    if (!inputRef.current!.files || inputRef.current!.files.length === 0) {
      localErrors.push("Please select a file");
    }

    if (!audience) {
      localErrors.push("Please select who you'd like the attachment to go to");
    }

    if (selectedMarkets.length === 0) {
      localErrors.push("Please select at least one market");
    }

    setErrors(localErrors);
    setTouched(true);

    return localErrors.length === 0;
  };

  const save = () => {
    const valid = validate();

    if (valid) {
      const attachment: IMonthlyStatementAttachment = {
        id: "_" + Math.random().toString(36).substr(2, 9),
        markets: selectedMarkets,
        file: inputRef.current!.files![0],
        audience: audience!,
      };

      props.handleAddAttachment(attachment);
      props.handleClose();
    }
  };

  const handleCheckboxChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.checked) {
      setSelectedMarkets([...selectedMarkets, evt.target.value]);
    } else {
      setSelectedMarkets(selectedMarkets.filter((m) => m !== evt.target.value));
    }
  };

  const renderCheckboxes = () => {
    const markets = enumerablesStore.getEnum("markets");
    const mapped = markets.map((m) => {
      return (
        <Tooltip key={m.code} title={m.description}>
          <FormControlLabel
            classes={{ label: classes.formLabel }}
            value={m.code}
            control={
              <Checkbox
                value={m.code}
                checked={selectedMarkets.indexOf(m.code) >= 0}
                onChange={handleCheckboxChange}
                size="small"
              />
            }
            label={m.code}
          />
        </Tooltip>
      );
    });

    return <div className={classes.marketsHost}>{mapped}</div>;
  };

  React.useEffect(() => {
    if (touched) {
      validate();
    }
    // eslint-disable-next-line
  }, [audience, touched]);

  const handleRadioChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setAudience(evt.target.value as AttachmentAudience);
  };

  const renderRadios = () => {
    return (
      <RadioGroup
        name="company-type"
        value={audience}
        onChange={handleRadioChange}
      >
        <FormControlLabel
          classes={{ label: classes.formLabel }}
          value="ActivitySummary"
          control={<Radio size="small" />}
          label="Activity Summary"
        />
        <FormControlLabel
          classes={{ label: classes.formLabel }}
          value="SponsorStatement"
          control={<Radio size="small" />}
          label="Sponsor Statement"
        />
        <FormControlLabel
          classes={{ label: classes.formLabel }}
          value="All"
          control={<Radio size="small" />}
          label="All"
        />
      </RadioGroup>
    );
  };

  const classes = useStyles();

  const renderErrors = () => {
    if (errors.length > 0) {
      return (
        <div className={classes.errors}>
          {errors.map((e, i) => {
            return <div key={i}>{e}</div>;
          })}
        </div>
      );
    }

    return undefined;
  };

  const selectAllMarkets = () => {
    const markets = enumerablesStore.getEnum("markets");
    const allCodes = markets.map((m) => m.code);
    setSelectedMarkets(allCodes);
  };

  return (
    <Observer
      render={() => {
        return (
          <ResponsiveModalShell
            handleSave={save}
            handleClose={props.handleClose}
            title="Add Attachment"
          >
            <div className={classes.root}>
              {renderErrors()}
              <div>
                <input
                  ref={inputRef}
                  type="file"
                  name="file"
                  onChange={handleFileInputChanged}
                />
              </div>
              <Divider />
              <div className={classes.controls}>
                <strong>Send To:</strong>
                <div className={classes.radiosHost}>{renderRadios()}</div>
                <strong>Markets:</strong>
                <div className={classes.radiosHost}>{renderCheckboxes()}</div>
              </div>
              <Button color="secondary" onClick={selectAllMarkets}>
                Select All Markets
              </Button>
            </div>
          </ResponsiveModalShell>
        );
      }}
    />
  );
};

export default AddEditAttachment;
