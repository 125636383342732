import { action, observable } from "mobx";
import {
  INewEventData,
  IListedAdminEvent,
  IEventDetails,
  IExistingEventData,
  IAddAttachmentCommand,
  IAddEventPayerPaymentCommand,
  EventAttachmentType,
  IListedCompanyInEvent,
  ICompanyEventDetails2,
  isApiProblemDetails,
  IUpdateEventPayerAdjustmentCommand,
  getApiErrors,
  EventFilterType,
  IEventCounts,
  IaccountEventSummary,
  DeleteAttendeeOption,
  IUpdateEventGroupPaymentCommand,
  IAddEventImageCommand,
  ICallSheetResult,
  ICallSheetQuery,
  IAddCompanyEventNote,
  ISetPotentialEventGroupStatusCommand,
  EventGroupStatus,
} from "services/api/types";
import api from "services/api";

// Not quite sure if this is needed yet.
const reportProblems = (e: any) => {
  if (isApiProblemDetails(e)) {
    const errors = getApiErrors(e);
    console.error("errors", errors);
  }
  console.error(e);
};

export class EventsStore {
  @observable
  public isSavingEvent = false;

  @observable
  public isModifyingAttendees = false;

  @observable
  public isModifyingPayments = false;

  @observable
  public isModifyingAdjustments = false;

  @observable
  public isModifyingInvoices = false;

  @observable
  public isLoadingEvents = false;

  @observable
  public isModifyingEventGroups = false;

  @observable
  public isModifyingRooms = false;

  @observable
  public isModifyingNotes = false;

  @observable
  public eventGroupsFilterTerm = "";

  @observable
  public eventGroupsOrderBy: keyof IListedCompanyInEvent = "companyName";

  @observable
  public eventGroupsOrder: "asc" | "desc" = "asc";

  @observable
  public eventGroupsPage = 0;

  @observable
  public eventGroupsPageSize = 200;

  @observable
  public eventCounts: IEventCounts = {
    cancelledGroups: 0,
    eventGroups: 0,
    interested: 0,
    signUpQueue: 0,
    waitingList: 0,
  };

  @observable
  public isLoadingEventDetails = false;

  @observable
  public isLoadingEventGroupDetails = false;

  @observable
  public isLoadingEventGroupSummary = false;

  @observable
  public events: IListedAdminEvent[] | undefined;

  @observable
  public filterType: EventFilterType = "Upcoming";

  @observable
  public eventGroups: IListedCompanyInEvent[] | undefined;

  @observable
  public callSheetPage: ICallSheetResult | undefined;

  @observable
  public eventGroupsEventId: string | undefined;

  @observable
  public companyEventDetails: ICompanyEventDetails2 | undefined;

  @observable
  public accountEventSummary: IaccountEventSummary | undefined | null;

  @observable
  public selectedEventDetails: IEventDetails | undefined;

  @action.bound
  public async setFilterType(filterType: EventFilterType) {
    this.filterType = filterType;
    this.loadEventList();
  }

  @action.bound
  public async saveNewEvent(eventData: INewEventData) {
    this.isSavingEvent = true;

    try {
      const newEvent = await api.admin.events.create(eventData);
      this.filterType = "Upcoming";
      const listedEvents = await api.admin.events.getEventList(this.filterType);
      this.events = listedEvents;
      this.isSavingEvent = false;
      return newEvent;
    } catch (e) {
      this.isSavingEvent = false;
      return null;
    }
  }

  @action.bound
  public async addCompanyEventNote(command: IAddCompanyEventNote) {
    const newNote = await api.admin.events.addCompanyEventNote(command);

    if (this.eventGroups) {
      const group = this.eventGroups.find(
        (e) =>
          e.eventId === command.eventId && e.companyId === command.companyId
      )!;

      const newNotes = [newNote.data, ...group.notes];
      const newGroup = Object.assign({}, group, {
        notes: newNotes,
      });

      const newArray = this.eventGroups.map((peg) =>
        peg.id === newGroup.id ? newGroup : peg
      );

      this.eventGroups = newArray;
    }
  }

  @action.bound
  public async setEventGroupsFilterTerm(newValue: string) {
    this.eventGroupsFilterTerm = newValue;
  }

  @action.bound
  public async setEventGroupsOrderBy(orderBy: keyof IListedCompanyInEvent) {
    this.eventGroupsOrderBy = orderBy;
  }

  @action.bound
  public async setEventGroupsOrder(order: "asc" | "desc") {
    this.eventGroupsOrder = order;
  }

  @action.bound
  public async setEventGroupsPage(page: number) {
    this.eventGroupsPage = page;
  }

  @action.bound
  public async setEventGroupsPageSize(pageSize: number) {
    this.eventGroupsPageSize = pageSize;
  }

  @action.bound
  public async setCancellationFee(args: {
    eventPayerId: string;
    cancellationFee: number;
  }) {
    const summary = await api.admin.events.setCancellationFee(args);
    this.accountEventSummary = summary;
  }

  @action.bound
  public async sendEventGroupConfirmation(
    eventGroupId: string,
    contactId: string
  ) {
    try {
      const newConfirmationEmail =
        await api.admin.events.sendEventGroupConfirmation(
          eventGroupId,
          contactId
        );

      const newGroupDetails = Object.assign(
        {},
        this.companyEventDetails?.groupDetails,
        {
          confirmationEmails: [
            ...this.companyEventDetails!.groupDetails!.confirmationEmails,
            newConfirmationEmail,
          ],
        }
      );

      const newDetails = Object.assign({}, this.companyEventDetails, {
        groupDetails: newGroupDetails,
      });

      this.companyEventDetails = newDetails;
    } catch (e) {
      console.log(e);
    }
  }

  @action.bound
  public async addCallSheetNote(command: IAddCompanyEventNote) {
    const newNote = await api.admin.events.addCompanyEventNote(command);

    this.reloadEventGroupDetails();

    if (this.callSheetPage) {
      const group = this.callSheetPage.data.find(
        (e) =>
          e.eventId === command.eventId && e.companyId === command.companyId
      )!;

      const newNotes = [newNote.data, ...group.notes];
      const newGroup = Object.assign({}, group, {
        notes: newNotes,
      });

      const newArray = this.callSheetPage.data.map((peg) =>
        peg.id === newGroup.id ? newGroup : peg
      );

      const newPage = Object.assign({}, this.callSheetPage, { data: newArray });
      this.callSheetPage = newPage;
    }
  }

  @action.bound
  public async setCallSheetStatus(
    command: ISetPotentialEventGroupStatusCommand
  ) {
    const { companyId, eventId } = command;
    let newOrUpdatedGroup: IListedCompanyInEvent | undefined;
    const companyEvent = this.callSheetPage?.data.find(
      (p) => p.companyId === command.companyId
    );

    try {
      if (companyEvent?.eventGroupStatus === command.newStatus) {
        await api.admin.events.clearPotentialEventGroupStatus(command);
        this.setCompanyStatusToBlank({
          companyId,
          eventId,
        });
      } else {
        const response = await api.admin.events.setPotentialEventGroupStatus(
          command
        );

        newOrUpdatedGroup = response.data;
      }

      if (this.callSheetPage && newOrUpdatedGroup) {
        const newArray = this.callSheetPage.data.map((d) => {
          const isCompany =
            d.companyId === newOrUpdatedGroup!.companyId &&
            d.eventId === newOrUpdatedGroup!.eventId;

          return isCompany ? newOrUpdatedGroup : d;
        });

        const newPage = Object.assign({}, this.callSheetPage, {
          data: newArray,
        });

        this.callSheetPage = newPage;
      }

      this.loadEventList();
      this.loadEventCounts();
    } catch (e) {
      console.log(e);
    }
  }

  @action.bound
  public async setPotentialEventGroupStatus(
    command: ISetPotentialEventGroupStatusCommand
  ) {
    const { companyId, eventId } = command;
    const eventGroup = this.eventGroups?.find(
      (eg) => eg.companyId === companyId
    );

    try {
      if (command.newStatus === eventGroup?.eventGroupStatus) {
        await api.admin.events.clearPotentialEventGroupStatus(command);
        this.updatePotentialEventGroupStatus({
          companyId,
          eventId,
          status: "Blank",
        });
      } else {
        const response = await api.admin.events.setPotentialEventGroupStatus(
          command
        );

        this.reloadEventGroups(response.data);
        this.reloadEventGroup();
      }

      this.loadEventList();
      this.loadEventCounts();
    } catch (e) {
      console.log(e);
    }
  }

  @action.bound
  private reloadEventGroups(newOrUpdated: IListedCompanyInEvent) {
    if (this.eventGroups) {
      const newArray = this.eventGroups.map((d) => {
        const isNewOrUpdatedCompany =
          d.companyId === newOrUpdated.companyId &&
          d.eventId === newOrUpdated.eventId;
        return isNewOrUpdatedCompany ? newOrUpdated : d;
      });

      this.eventGroups = newArray;
    }
  }

  private setCompanyStatusToBlank(args: {
    companyId: string;
    eventId: string;
  }) {
    const callSheetGroup = this.callSheetPage!.data.find(
      (peg) => peg.companyId === args.companyId && peg.eventId === args.eventId
    )!;

    const newGroup = Object.assign({}, callSheetGroup, {
      eventGroupStatus: "Blank",
    });

    const newArray = this.callSheetPage!.data.map((peg) => {
      return peg.id === newGroup.id ? newGroup : peg;
    });

    const newPage = Object.assign({}, this.callSheetPage, { data: newArray });
    this.callSheetPage = newPage;
  }

  private updatePotentialEventGroupStatus(args: {
    companyId: string;
    eventId: string;
    status: EventGroupStatus | undefined;
  }) {
    const potentialEventGroup = this.eventGroups!.find(
      (peg) => peg.companyId === args.companyId && peg.eventId === args.eventId
    )!;

    const newGroup = Object.assign({}, potentialEventGroup, {
      eventGroupStatus: args.status,
    });

    const newArray = this.eventGroups!.map((peg) => {
      return peg.id === newGroup.id ? newGroup : peg;
    });

    this.eventGroups = newArray;
  }

  @action.bound
  public async signUpCompany(eventId: string, companyId: string) {
    try {
      const result = await api.admin.events.signUpCompany(companyId, eventId);

      this.reloadEventGroups(result.data);
      this.loadEventCounts();
      this.loadEventList();

      await this.reloadEventGroup();

      return result.data;
    } catch (e) {
      console.error(e);
      this.isModifyingAttendees = false;
      return e as string;
    }
  }

  @action.bound
  public async updateEventAdjustment(
    id: string,
    eventId: string,
    name: string,
    amountDollars: number
  ) {
    this.isModifyingAdjustments = true;

    try {
      const updatedEventAdjustmentResult =
        await api.admin.events.updateEventAdjustment({
          name,
          eventId,
          amountDollars,
          eventAdjustmentId: id,
        });

      const newEventAdjustments = this.selectedEventDetails!.adjustments.map(
        (du) => {
          if (du.id === updatedEventAdjustmentResult.updatedAdjustment.id)
            return updatedEventAdjustmentResult.updatedAdjustment;
          return du;
        }
      );

      const newEventDetails = Object.assign({}, this.selectedEventDetails!, {
        adjustments: newEventAdjustments,
      });

      this.selectedEventDetails = newEventDetails;
      const updatedBalanceCount =
        updatedEventAdjustmentResult.balancedChangedForCompanies.length;
      if (updatedBalanceCount > 0 && updatedBalanceCount < 20) {
        const names =
          updatedEventAdjustmentResult.balancedChangedForCompanies.reduce(
            (c, acc) => {
              return `${c}\r\n${acc}`;
            },
            ""
          );

        const message = `The upgrade/discount has been updated. The balance has changed for the following event groups:\r\n${names}`;
        alert(message);
      } else if (updatedBalanceCount >= 20) {
        alert(
          `The balance has changed for ${updatedBalanceCount} event groups.`
        );
      }
    } catch (e) {
      reportProblems(e);
    }

    this.isModifyingAdjustments = false;
  }

  @action.bound
  public async addPayment(payment: IAddEventPayerPaymentCommand) {
    this.isModifyingPayments = true;
    try {
      // Don't care about the result since we just end up
      // reloading details
      await api.admin.events.addPayment(payment);

      // Adding a payment can affect attendees (whether or not they need
      // to confirm removal). So just reload everything
      await this.reloadEventGroup();
    } catch (e) {
      console.error(e);
    }
    this.isModifyingPayments = false;
  }

  @action.bound
  public async deletePayment(args: {
    eventPayerId: string;
    paymentId: string;
  }) {
    this.isModifyingPayments = true;

    try {
      await api.admin.events.deletePayment(args);
      await this.reloadEventGroup();
    } catch (e) {
      console.error(e);
    }

    this.isModifyingPayments = false;
  }

  @action.bound
  public async setInvoiceVoidStatus(invoiceId: string, setToVoid: boolean) {
    this.isModifyingInvoices = true;

    try {
      if (setToVoid) {
        await api.admin.events.voidInvoice(invoiceId);
      } else {
        await api.admin.events.unvoidInvoice(invoiceId);
      }

      await this.reloadEventGroup();
    } catch (e) {
      console.error(e);
    }

    this.isModifyingInvoices = false;
  }

  @action.bound
  public async updatePayment(command: IUpdateEventGroupPaymentCommand) {
    this.isModifyingPayments = true;

    try {
      await api.admin.events.updatePayment(command);

      await this.reloadEventGroup();
    } catch (e) {
      console.error(e);
    }

    this.isModifyingPayments = false;
  }

  @action.bound
  public async deleteEventAdjustment(
    eventId: string,
    eventAdjustmentId: string
  ) {
    this.isModifyingAdjustments = true;

    try {
      await api.admin.events.deleteEventAdjustment(eventId, eventAdjustmentId);

      const newEventAdjustments = this.selectedEventDetails!.adjustments.filter(
        (d) => {
          return d.id !== eventAdjustmentId;
        }
      );

      const newDetails = Object.assign({}, this.selectedEventDetails!, {
        adjustments: newEventAdjustments,
      });

      this.selectedEventDetails = newDetails;
    } catch (e) {
      console.error(e);
    }

    this.isModifyingAdjustments = false;
  }

  @action.bound
  public async addEventPayerAdjustment(args: {
    eventPayerId: string;
    eventAdjustmentId: string | undefined;
    quantity: number;
    memo: string;
    manualDollarAdjustment: number | undefined;
  }) {
    try {
      await api.admin.events.addEventPayerAdjustment(args);
      await this.reloadEventGroup();
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound
  public async addEventAdjustment(
    eventId: string,
    name: string,
    amountDollars: number
  ) {
    this.isModifyingAdjustments = true;

    try {
      const newAdjustment = await api.admin.events.addEventAdjustment({
        eventId,
        name,
        amountDollars,
      });

      const newAdjustments = [
        ...this.selectedEventDetails!.adjustments,
        newAdjustment,
      ];
      const newDetails = Object.assign({}, this.selectedEventDetails!, {
        adjustments: newAdjustments,
      });
      this.selectedEventDetails = newDetails;
    } catch (e) {
      reportProblems(e);
    }

    this.isModifyingAdjustments = false;
  }

  @action.bound
  public async deleteEvent(eventId: string) {
    const response = await api.admin.events.deleteEvent(eventId);
    if (this.selectedEventDetails?.id === eventId) {
      this.selectedEventDetails = undefined;
      this.companyEventDetails = undefined;
      this.accountEventSummary = undefined;
      this.events = this.events!.filter((e) => e.id !== eventId);
    }

    return response;
  }

  @action.bound
  public async refundEventGroup(args: {
    eventGroupId: string;
    issueRefund: boolean;
    moveToWaitingList: boolean;
  }) {
    try {
      await api.admin.events.refundEventGroup(args);
      await this.reloadEventGroup();

      this.loadEventCounts();
      this.loadEventList();
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound
  public async restoreEventGroup(eventGroupId: string) {
    try {
      await api.admin.events.restoreEventGroup(eventGroupId);
      this.loadEventGroupDetails(
        this.companyEventDetails!.eventId,
        this.companyEventDetails!.companyInfo!.id
      );
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound
  public async updateRoomsNeeded(eventGroupId: string, roomsNeeded?: number) {
    this.isModifyingRooms = true;

    try {
      await api.admin.events.updateEventGroupRoomsNeeded(
        eventGroupId,
        roomsNeeded
      );
    } catch (e) {
      console.error(e);
    }

    this.isModifyingRooms = false;
  }

  @action.bound
  public async updateNotes(
    companyId: string,
    eventId: string,
    newNote: {
      content: string;
      isImportant: boolean;
    },
    // Any id not present here will be deleted
    existingNoteIds: string[]
  ) {
    this.isModifyingNotes = true;

    try {
      await api.admin.events.updateEventGroupNotes(
        companyId,
        eventId,
        newNote,
        existingNoteIds
      );

      this.loadEventGroupDetails(
        this.companyEventDetails!.eventId,
        this.companyEventDetails!.companyInfo!.id
      );
    } catch (e) {
      console.error(e);
    }

    this.isModifyingNotes = false;
  }

  @action.bound
  public async updateEventPayerAdjustment(
    command: IUpdateEventPayerAdjustmentCommand
  ) {
    this.isModifyingAdjustments = true;

    try {
      await api.admin.events.updateEventPayerAdjustment(command);
      await this.reloadEventGroup();
    } catch (e) {
      console.error(e);
    }

    this.isModifyingAdjustments = false;
  }

  @action.bound
  public async updateEvent(eventData: IExistingEventData) {
    this.isSavingEvent = true;

    try {
      const updatedEvent = await api.admin.events.update(eventData);
      this.selectedEventDetails = updatedEvent;
      const events = await api.admin.events.getEventList(this.filterType);
      this.events = events;
    } catch (e) {
      console.error(e);
    }

    this.isSavingEvent = false;
  }

  @action.bound
  public async updateAttendeeChargeStatus(
    eventAttendeeId: string,
    isNoCharge: boolean
  ) {
    this.isModifyingAttendees = true;

    try {
      await api.admin.events.updateAttendeeChargeStatus(
        eventAttendeeId,
        isNoCharge
      );
      await this.reloadEventGroup();
    } catch (e) {
      console.error(e);
    }

    this.isModifyingAttendees = false;
  }

  @action.bound
  public async loadEventList() {
    this.isLoadingEvents = true;

    try {
      const events = await api.admin.events.getEventList(this.filterType);
      this.events = events;
    } catch (e) {
      console.error(e);
    }

    this.isLoadingEvents = false;
  }

  @action.bound
  public async deleteAttendee(
    eventGroupId: string,
    eventAttendeeId: string,
    option: DeleteAttendeeOption
  ) {
    this.isModifyingAttendees = true;

    try {
      await api.admin.events.deleteEventGroupAttendee(
        eventGroupId,
        eventAttendeeId,
        option
      );

      // Because we display the count of attendees in the list of events
      const listedEvents = await api.admin.events.getEventList(this.filterType);
      this.events = listedEvents;
      await this.reloadEventGroup();
    } catch (e) {
      console.error(e);
    }

    this.isModifyingAttendees = false;
  }

  @action.bound
  public async deleteEventGroup(eventId: string, eventGroupId: string) {
    this.isModifyingEventGroups = true;

    try {
      await api.admin.events.deleteEventGroup(eventId, eventGroupId);

      this.eventGroups = this.eventGroups!.filter(
        (eg) => eg.id !== eventGroupId
      );

      if (this.selectedEventDetails!.id === eventGroupId) {
        this.selectedEventDetails = undefined;
      }
      this.loadEventCounts();
    } catch (e) {
      console.error(e);
    }

    this.isModifyingEventGroups = false;
  }

  @action.bound
  public async loadEventCounts() {
    if (this.selectedEventDetails) {
      const result = await api.admin.events.getEventCounts(
        this.selectedEventDetails.id
      );
      this.eventCounts = result;
    } else {
      console.log("No details");
    }
  }

  @action.bound
  public async loadCallSheetPage(query: ICallSheetQuery) {
    const page = await api.admin.events.getCallSheet(query);
    this.callSheetPage = page;
  }

  @action.bound
  public async loadEventGroups(eventId: string, statuses: EventGroupStatus[]) {
    this.isModifyingEventGroups = true;
    this.eventGroups = undefined;

    try {
      const eventGroups = await api.admin.events.getEventGroups(
        eventId,
        statuses
      );
      this.eventGroups = eventGroups;
      this.eventGroupsEventId = eventId;
    } catch (e) {
      console.error(e);
    }

    this.isModifyingEventGroups = false;
  }

  @action.bound
  public async loadEventPayerDetails(eventId: string, accountId: string) {
    this.isLoadingEventGroupDetails = true;

    try {
      const eventGroupDetails = await api.admin.events.getAccountEventDetails(
        eventId,
        accountId
      );

      this.companyEventDetails = eventGroupDetails;

      if (this.companyEventDetails?.payerDetails) {
        await this.reloadAccountEventSummary();
      } else {
        this.accountEventSummary = null;
      }
    } catch (e) {
      console.error(e);
    }

    this.isLoadingEventGroupDetails = false;
  }

  @action.bound
  public async loadEventGroupDetails(eventId: string, companyId: string) {
    this.isLoadingEventGroupDetails = true;

    try {
      const eventGroupDetails = await api.admin.events.getCompanyEventDetails(
        eventId,
        companyId
      );

      this.companyEventDetails = eventGroupDetails;

      if (this.companyEventDetails?.payerDetails) {
        await this.reloadAccountEventSummary();
      } else {
        this.accountEventSummary = null;
      }
    } catch (e) {
      console.error(e);
    }

    this.isLoadingEventGroupDetails = false;
  }

  @action.bound
  public async ensureEventDetailsLoaded(eventId: string) {
    // Multiple components call this, sometimes in scenarios where
    // it's alreay been loaded
    if (this.selectedEventDetails && this.selectedEventDetails.id === eventId) {
      return;
    }
    this.isLoadingEventDetails = true;

    try {
      const eventDetails = await api.admin.events.getEventDetails(eventId);
      this.selectedEventDetails = eventDetails;
      this.loadEventCounts();
    } catch (e) {
      console.error(e);
    }

    this.isLoadingEventDetails = false;
  }

  @action.bound
  public async setGroupLead(eventGroupId: string, eventAttendeeId: string) {
    this.isModifyingAttendees = true;

    try {
      await api.admin.events.setGroupLead(eventGroupId, eventAttendeeId);

      const { eventId, accountInfo } = this.companyEventDetails!;

      const eventGroupDetails = await api.admin.events.getAccountEventDetails(
        eventId,
        accountInfo.id
      );
      this.companyEventDetails = eventGroupDetails;
    } catch (e) {
      console.error(e);
    }

    this.isModifyingAttendees = false;
  }

  @action.bound
  public async addInvoice(args: {
    eventPayerId: string;
    message: string;
    amount: number;
    dateDue: Date | undefined;
  }) {
    this.isModifyingInvoices = true;

    try {
      const newInvoice = await api.admin.events.addEventPayerInvoice(args);
      await this.reloadEventGroup();
      this.isModifyingInvoices = false;
      return newInvoice;
    } catch (e) {
      console.error(e);
      this.isModifyingInvoices = false;
      return undefined;
    }
  }

  @action.bound
  public async removeAttachment(eventId: string, attachmentId: string) {
    try {
      await api.admin.events.removeAttachment(eventId, attachmentId);

      const newAttachments = this.selectedEventDetails!.attachments.filter(
        (a) => a.id !== attachmentId
      );

      const newEventDetails = Object.assign({}, this.selectedEventDetails, {
        attachments: newAttachments,
      });

      this.selectedEventDetails = newEventDetails;
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound
  public async removeImage(eventId: string, eventImageId: string) {
    try {
      await api.admin.events.removeImage(eventId, eventImageId);

      const newImages = this.selectedEventDetails!.images.filter(
        (a) => a.id !== eventImageId
      );

      const newEventDetails = Object.assign({}, this.selectedEventDetails, {
        images: newImages,
      });

      this.selectedEventDetails = newEventDetails;
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound
  public async addImage(eventId: string, file: File) {
    try {
      const uploadedFile = await api.admin.files.uploadPublicFile(file);

      const command: IAddEventImageCommand = {
        eventId,
        originalFileName: file.name,
        uri: uploadedFile.uri,
      };

      const image = await api.admin.events.addImage(command);
      const newImages = [...this.selectedEventDetails!.images, image];

      const newEventDetails = Object.assign({}, this.selectedEventDetails, {
        images: newImages,
      });

      this.selectedEventDetails = newEventDetails;
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound
  public async addAttachment(
    eventId: string,
    file: File,
    type: EventAttachmentType
  ) {
    try {
      const uploadedFile = await api.admin.files.uploadFile(file);

      const command: IAddAttachmentCommand = {
        eventId,
        originalFileName: file.name,
        uri: uploadedFile.uri,
        type,
      };

      const attachment = await api.admin.events.addAttachment(command);
      const newAttachments = [
        ...this.selectedEventDetails!.attachments,
        attachment,
      ];

      const newEventDetails = Object.assign({}, this.selectedEventDetails, {
        attachments: newAttachments,
      });

      this.selectedEventDetails = newEventDetails;
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound
  public async deleteEventPayerAdjustment(args: {
    eventPayerId: string;
    eventPayerAdjustmentId: string;
  }) {
    this.isModifyingAdjustments = true;

    try {
      await api.admin.events.deleteEventPayerAdjustment(args);

      const newAdjustments =
        this.companyEventDetails!.payerDetails!.adjustments.filter(
          (a) => a.id !== args.eventPayerAdjustmentId
        );

      const newPayerDetails = Object.assign(
        {},
        this.companyEventDetails?.payerDetails,
        {
          adjustments: newAdjustments,
        }
      );

      const newDetails = Object.assign({}, this.companyEventDetails, {
        payerDetails: newPayerDetails,
      });

      this.companyEventDetails = newDetails;
      await this.reloadAccountEventSummary();
    } catch (e) {
      console.error(e);
    }

    this.isModifyingAdjustments = false;
  }

  @action.bound
  private reloadEventGroup = async () => {
    await this.reloadEventGroupDetails();
    await this.reloadAccountEventSummary();
  };

  @action.bound
  private reloadAccountEventSummary = async () => {
    if (!this.companyEventDetails) return;
    const summary = await api.admin.events.getAccountEventSummary({
      accountId: this.companyEventDetails.accountInfo.id,
      eventId: this.companyEventDetails!.eventId,
    });
    this.accountEventSummary = summary;
  };

  @action.bound
  private reloadEventGroupDetails = async () => {
    if (!this.companyEventDetails) return;
    const { eventId, accountInfo } = this.companyEventDetails!;
    const eventGroupDetails = await api.admin.events.getAccountEventDetails(
      eventId,
      accountInfo.id
    );

    this.companyEventDetails = eventGroupDetails;
  };
}

export default new EventsStore();
