import { apiClient } from "services/api/client";

import {} from "services/api/types";

const STATEMENTS_URL = "shared/statements";

export const getStatementDetails = async (statementId: string) => {
  const url = `${STATEMENTS_URL}/${statementId!}`;
  const response = await apiClient.get<string>(url);
  return response.data;
};
