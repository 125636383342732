import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Omit } from "utils/type";
import FormCompanyNote from "modals/components/CompanyForms/Note/Note";
import { ICompanyNotePayload } from "services/api/types";
import companyStore from "stores/shared/company";

type Props = {
  handleClose: () => void;
} & RouteComponentProps;

class ModalAddCompanyNote extends Component<Props> {
  public render = () => (
    <FormCompanyNote
      formType="add"
      handleSubmit={this.handleSubmit}
      handleCancel={this.props.handleClose}
    />
  );

  private handleSubmit = async (
    payload: Omit<ICompanyNotePayload["companyNote"], "companyId">
  ) => {
    await companyStore.createNote({
      companyNote: {
        companyId: companyStore.details ? companyStore.details.id : "",
        ...payload,
      },
    });
    this.props.handleClose();
  };
}

export default withRouter(ModalAddCompanyNote);
