import React from "react";
import enumerableStore from "stores/enumerable";
import { Observer } from "mobx-react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { Option } from "services/api/types";

type Props = {
  onChanged: (value: Option) => void;
  value: Option | undefined;
  hasError: boolean;
  onlyAvailableOptions?: Option[];
  noOptionsText?: string;
  disabled?: boolean;
  renderOption?: (option: Option) => React.ReactNode;
  hideLabel?: boolean;
};

const CategorySelect = (props: Props) => {
  React.useEffect(() => {
    // Might be wasteful because these categories aren't
    // used if onlyAvailableOptions is specified. But I wanted
    // to get rid of onlyAvailableOptions in the dependency array.
    enumerableStore.ensureCategoriesFetched();
  }, []);

  const getCategoryOptions = (): Option[] => {
    const options =
      props.onlyAvailableOptions ??
      enumerableStore.getEnum("categories").map((category) => {
        return {
          value: category.id,
          label: category.name,
        };
      });

    return options;
  };

  return (
    <Observer
      render={() => {
        if (enumerableStore.isLoading("categories")) return <div></div>;
        return (
          <Autocomplete
            disabled={props.disabled}
            options={getCategoryOptions() as any}
            loading={false}
            onChange={(event: any, option: any) => {
              return props.onChanged(option);
            }}
            value={props.value}
            noOptionsText={props.noOptionsText}
            getOptionSelected={(option, value) => option.value === value.value}
            getOptionLabel={(option) => {
              if (typeof option.label === "string") {
                return option.label;
              }

              return (option as any).label.label;
            }}
            renderOption={props.renderOption}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                error={props.hasError}
                label={props.hideLabel ? undefined : "Category"}
                placeholder="Select category"
                style={{ margin: 0 }}
                InputProps={{
                  ...params.InputProps,
                }}
                margin="dense"
              />
            )}
          />
        );
      }}
    />
  );
};

export default CategorySelect;
