import { CompanyType } from "services/api/types";

const appBasePath = "/app";
const adminRootPath = `${appBasePath}/admin`;
const adminCompanyDetailsBasePath = `${adminRootPath}/companies`;

const memberRootPath = `${appBasePath}/members`;
const memberMyCompanyPath = `${memberRootPath}/myCompany`;

const sponsorsRootPath = `${appBasePath}/sponsors`;
const sponsorsMyCompanyPath = `${sponsorsRootPath}/myCompany`;

const getAdminEventCompanyDetailsRouteTemplate = (eventId: string) => {
  return `${adminRootPath}/events/:eventId/companies`;
};

export const paths = {
  app: {
    root: appBasePath,
    adminsAndMembers: {
      catalog: `${appBasePath}/catalog`, // same as member
      mobileCatalog: `${appBasePath}/catalog-home`, // same as member
    },
    admin: {
      root: adminRootPath,
      home: `${adminRootPath}/home`,
      events: `${adminRootPath}/events`,
      users: `${adminRootPath}/company-users`,
      companyDetails: adminCompanyDetailsBasePath,
    },
    members: {
      root: memberRootPath,
      myCompany: memberMyCompanyPath,
    },
    sponsors: {
      root: sponsorsRootPath,
      myCompany: sponsorsMyCompanyPath,
    },
  },
};

export const getCompanyBaseRouteTemplate = (params: {
  companyType: CompanyType;
  isAdminNotImpersonating: boolean;
  eventId?: string;
}) => {
  if (params.isAdminNotImpersonating) {
    if (params.eventId) {
      return getAdminEventCompanyDetailsRouteTemplate(params.eventId);
    } else {
      return paths.app.admin.companyDetails;
    }
  } else if (params.companyType === "Sponsor") {
    return paths.app.sponsors.myCompany;
  } else if (params.companyType === "Member") {
    return paths.app.members.myCompany;
  }

  throw Error("Cannot get base path");
};
