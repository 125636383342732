import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { IHostCompanyInfo } from "services/api/types";

type Props = {
  hostCompanyInfo: IHostCompanyInfo | undefined;
};

const ContactUs = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        color: "white",
        padding: 20,
        backgroundColor: "rgba(27, 97, 177, 0.8)",
        "& h2": {
          fontFamily: "Oswald, sans-serif",
          fontWeight: 400,
          textAlign: "center",
          margin: ".2em 0 1em 0",
          [theme.breakpoints.between("xs", "lg")]: {
            fontSize: "2em",
          },
          [theme.breakpoints.between("lg", "xl")]: {
            fontSize: "2.5em",
          },
        },
      },
      infoGrid: {
        display: "grid",
        [theme.breakpoints.between("xs", "sm")]: {
          gridTemplateColumns: "1fr",
          gridGap: 10,
          textAlign: "center",
          margin: 0,
        },
        [theme.breakpoints.between("sm", "md")]: {
          gridTemplateColumns: "1fr 1fr auto",
          gridGap: 4,
          margin: "0 2em",
          textAlign: "unset",
        },
        [theme.breakpoints.between("md", "xl")]: {
          gridTemplateColumns: "1fr 1fr auto",
          gridGap: 4,
          margin: "0 5em",
          textAlign: "unset",
        },
      },
      mailToLink: {
        color: "white",
        textDecoration: "none",
      },
    })
  );

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2>Contact Us</h2>
      <div className={classes.infoGrid}>
        <div>
          <div>Interested in learning more? </div>
          <div>We'd love to hear from you!</div>
        </div>
        {props.hostCompanyInfo && (
          <>
            <div>
              <div>
                <strong>{props.hostCompanyInfo.companyName}</strong>{" "}
              </div>
              <div>{`${props.hostCompanyInfo.city}, ${props.hostCompanyInfo.state} ${props.hostCompanyInfo.zip}`}</div>
            </div>
            <div>
              <div>{`${props.hostCompanyInfo.phoneNumber}`}</div>
              <a
                className={classes.mailToLink}
                target="_blank"
                href={`mailto:${props.hostCompanyInfo.emailAddress}`}
                rel="noopener noreferrer"
              >
                {props.hostCompanyInfo.emailAddress}
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ContactUs;
