import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

type Props = {
  selectedOption: Option | undefined;
  setSelectedOption: (option: Option | undefined) => void;
  options: Option[];
  canClear: boolean;
  label?: string;
  makeBackgroundWhite?: boolean;
  invalidOptionIds: string[];
  error: boolean;
  noOptionsText?: string;
  disabled?: boolean;
  loading?: boolean;
  renderOption?: (option: Option) => React.ReactNode;
};

export type Option = {
  value: string;
  label: string;
};

const GenericDropdown = (props: Props) => {
  // eslint-disable-next-line
  const [textBoxValue, setTextBoxValue] = React.useState("");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: { minWidth: "10rem" },
      noOptionsClass: {
        pointerEvents: "none",
        width: 500,
      },
      input: {
        backgroundColor: props.makeBackgroundWhite ? "white" : "unset",
      },
    })
  );

  const classes = useStyles();

  return (
    <Autocomplete
      onChange={(event: any, option: any) => {
        props.setSelectedOption(option);
      }}
      noOptionsText={props.noOptionsText ?? "No options"}
      getOptionLabel={(option) => {
        if (typeof option.label === "string") {
          return option.label;
        }

        return (option as any).label.label;
      }}
      classes={{
        noOptions: classes.noOptionsClass,
        inputRoot: classes.input,
      }}
      className={classes.root}
      options={props.options.filter(
        (o) =>
          !props.invalidOptionIds.some((invalidId) => o.value === invalidId)
      )}
      freeSolo={false}
      disabled={props.disabled}
      disableClearable={!props.canClear}
      value={props.selectedOption ?? null}
      loading={props.loading}
      renderOption={props.renderOption}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={props.label ?? ""}
            variant="outlined"
            style={{ minWidth: 100 }}
            onChange={(evt) => {
              setTextBoxValue(evt.target.value);
            }}
            error={props.error}
            margin="dense"
          />
        );
      }}
    />
  );
};

export default GenericDropdown;
