import React from "react";
import TextField from "@material-ui/core/TextField";

type Props = {
  value: string | undefined;
  touched: boolean | undefined;
  error: string | undefined;
  handleChange: (e: React.ChangeEvent<any>) => void;
};

const WebsiteField = (props: Props) => {
  return (
    <TextField
      variant="outlined"
      id="website"
      margin="dense"
      fullWidth
      label="Website"
      value={props.value}
      onChange={props.handleChange}
      error={props.touched && Boolean(props.error)}
      name="website"
      placeholder="Website"
      helperText={props.touched && props.error}
    />
  );
};

export default WebsiteField;
