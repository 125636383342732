import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import withWidth from "@material-ui/core/withWidth";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import enumerableStore from "stores/enumerable";
import companyStore from "stores/shared/company";
import { Observer } from "mobx-react";
import { renderRoutes, getTabsToRender } from "./screenFactory";
import myStore from "stores/my";
import { getCompanyBaseRouteTemplate } from "routes/Paths";
import SubtleLinearProgress from "components/Shell/SubtleLinearProgress";
import {
  Route,
  Switch,
  RouteComponentProps,
  withRouter,
} from "react-router-dom";

const EditorContainer = React.lazy(
  () => import("areas/Shared/MonthlyReports/EditorContainer")
);
const EditContact = React.lazy(
  () => import("../../Contacts/components/EditContact")
);
const ProcessingContainer = React.lazy(
  () => import("areas/Admin/MonthlyReports/ProcessingContainer")
);

type Props = {
  companyId: string;
  width: Breakpoint;
  eventId?: string;
} & RouteComponentProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexGrow: 1,
      // overflow: "hidden",
      height: "100%",
      flexDirection: "row",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
    panel: {
      // overflowY: "scroll",
      height: "100%",
      width: "100%",
      padding: theme.spacing(2, 0, 2, 2),
      [theme.breakpoints.down("xs")]: {
        padding: 0,
      },
    },
    tab: {
      color: theme.palette.common.black,
      fontSize: "10pt",
      padding: theme.spacing(0, 4),
    },
    tabsRoot: {
      // minWidth: "20em",
    },
    selectedTab: {
      background: theme.palette.grey[200],
      color: theme.palette.primary.main,
    },
    tabRoot: {
      minHeight: 38,
      // borderBottom: "2px solid gainsboro",
      "&:hover": {
        background: theme.palette.grey[300],
      },
    },
    tabLabel: {
      paddingRight: theme.spacing(1),
    },
  })
);

const CompanyTabsHost = (props: Props) => {
  const classes = useStyles();

  const navigate = (path: string) => {
    const centerContentPane = document.getElementById("center-content");
    centerContentPane?.scrollTo(0, 0);

    // Not sure if this still works
    companyStore.setLastCompanyPage(path);
    props.history.push(path);
  };

  React.useEffect(() => {
    enumerableStore.ensureRelationshipTypesFetched();
  }, []);

  const renderContactRoutes = (basePath: string) => {
    return [
      <Route
        key="new-contact"
        exact
        path={`${basePath}/:companyId/contacts/create-new`}
        component={EditContact}
      />,
      <Route
        key="existing-contact"
        exact
        path={`${basePath}/:companyId/contacts/:contactId`}
        component={EditContact}
      />,
    ];
  };

  const renderMonthlyReportRoutes = (
    basePath: string,
    isAdminNotImpersonating: boolean
  ) => {
    if (!isAdminNotImpersonating) return undefined;

    return [
      <Route
        exact
        key="existing-report"
        path={`${basePath}/:sponsorId/monthlyReports/:monthlyReportId`}
        render={(p) => {
          return (
            // Shift the whole thing up a bit here
            <div style={{ transform: "translateY(-40px)" }}>
              <ProcessingContainer
                isNestedInCompanyDetails={true}
                hideSidebar={true}
                {...p}
              />
            </div>
          );
        }}
      />,
      <Route
        exact
        key="new-report"
        path={`${basePath}/:sponsorId/newMonthlyReport`}
        render={(p) => {
          return <EditorContainer overflowY="unset" audience="admin" {...p} />;
        }}
      />,
    ];
  };

  const renderTabs = (showVerticalMenu: boolean) => {
    const isAdminNotImpersonating = myStore.isAdminNotImpersonating;

    const currentCompany = companyStore.details
      ? {
          id: companyStore.details.id,
          name: companyStore.details.name,
          computedBalance: companyStore.details.clubPoints,
          identifier: companyStore.details.identifier,
          companyType: companyStore.details.companyType,
        }
      : undefined;

    const companyType = companyStore.details!.companyType;

    const relevantTabs = getTabsToRender({
      companyId: props.companyId,
      eventId: props.eventId,
      isAdminNotImpersonating,
      companyType,
    });

    const tabsForThisCompany = currentCompany === undefined ? [] : relevantTabs;

    const findCurrentTabIndex = (pathname: string) => {
      const currentTab = tabsForThisCompany.find((t) =>
        pathname.endsWith(t.href)
      );

      // If we can't find the tab that's active return false
      // not -1 not null or undefined but false! Weird, huh?
      return currentTab ? tabsForThisCompany.indexOf(currentTab) : false;
    };

    return (
      <Tabs
        orientation={showVerticalMenu ? "vertical" : "horizontal"}
        variant="scrollable"
        scrollButtons="on"
        indicatorColor="primary"
        textColor="primary"
        classes={{ root: classes.tabsRoot }}
        value={findCurrentTabIndex(props.location.pathname)}
        className={classes.tabs}
      >
        {tabsForThisCompany.map((t) => {
          return (
            <Tab
              key={t.href}
              onClick={() => navigate(t.href)}
              className={classes.tab}
              classes={{ selected: classes.selectedTab }}
              label={t.label}
              selected={false}
            />
          );
        })}
      </Tabs>
    );
  };

  return (
    <Observer
      render={() => {
        const isAdminNotImpersonating = myStore.isAdminNotImpersonating;
        const companyType = companyStore.details!.companyType;

        const adminRoles = myStore.getEffectiveAdminRoles();
        const companyRole = myStore.getEffectiveCompanyRole();
        const basePath = getCompanyBaseRouteTemplate({
          companyType,
          isAdminNotImpersonating,
          eventId: props.eventId,
        });

        const showVerticalMenu = props.width === "lg" || props.width === "xl";

        return (
          <div className={classes.root}>
            {showVerticalMenu ? (
              renderTabs(showVerticalMenu)
            ) : (
              <AppBar position="sticky" color="default">
                {renderTabs(showVerticalMenu)}
              </AppBar>
            )}
            <div role="tabpanel" className={classes.panel}>
              <React.Suspense fallback={<SubtleLinearProgress />}>
                <Switch>
                  {renderRoutes({
                    companyId: props.companyId,
                    eventId: props.eventId,
                    usersAdminRoles: adminRoles,
                    usersCompanyRole: companyRole,
                    isAdminNotImpersonating: myStore.isAdminNotImpersonating,
                    companyType: companyStore.details!.companyType,
                    currentAccount: companyStore.currentAccount!,
                  })}
                  {renderContactRoutes(basePath)}
                  {renderMonthlyReportRoutes(
                    basePath,
                    myStore.isAdminNotImpersonating
                  )}
                  <Route
                    path="*"
                    exact={true}
                    render={() => <div>Not found</div>}
                  />
                </Switch>
              </React.Suspense>
            </div>
          </div>
        );
      }}
    />
  );
};

export default withWidth()(withRouter(CompanyTabsHost));
