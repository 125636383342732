import React from "react";
import BcnHome from "./publicPages/BCN/BuildersClubHome";
import BuildPerksHome from "./publicPages/BuildPerks/BuildPerksHome";
import BcnLegal from "./publicPages/BCN/LegalNotice";
import BcnPrivacy from "./publicPages/BCN/Privacy";
import BuildPerksLegal from "./publicPages/BCN/LegalNotice";
import BuildPerksPrivacy from "./publicPages/BCN/Privacy";
import bcnTheme from "./bcnTheme";
import buildPerksTheme from "./buildPerksTheme";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import { Route } from "react-router-dom";
import Root from "./areas/Root";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ApplicationPaths } from "./components/api-authorization/ApiAuthorizationConstants";
import ApiAuthorizationRoutes from "./components/api-authorization/ApiAuthorizationRoutes";
import { paths } from "routes/Paths";
import ErrorBoundary from "./utils/ErrorBoundary";
import * as appConstants from "applications/appConstants";

const renderAppRoutes = () => {
  if (process.env.REACT_APP_BRANDING === appConstants.BuildPerks) {
    return (
      <>
        <Route exact path="/" component={BuildPerksHome} />
        <Route exact path="/Privacy" component={BuildPerksPrivacy} />
        <Route exact path="/LegalNotice" component={BuildPerksLegal} />
      </>
    );
  } else if (
    process.env.REACT_APP_BRANDING === appConstants.BuildersClubNorth
  ) {
    return (
      <>
        <Route exact path="/" component={BcnHome} />
        <Route exact path="/Privacy" component={BcnPrivacy} />
        <Route exact path="/LegalNotice" component={BcnLegal} />
      </>
    );
  }

  throw new Error("Invalid branding");
};

const App = () => {
  return (
    <MuiThemeProvider
      theme={
        process.env.REACT_APP_BRANDING === "BCN" ? bcnTheme : buildPerksTheme
      }
    >
      <ErrorBoundary>
        {renderAppRoutes()}
        <AuthorizeRoute path={paths.app.root} component={Root} />
        <Route
          path={ApplicationPaths.ApiAuthorizationPrefix}
          component={ApiAuthorizationRoutes}
        />
      </ErrorBoundary>
    </MuiThemeProvider>
  );
};

export default App;
