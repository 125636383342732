import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Formik, FormikProps, ErrorMessage } from "formik";
import {
  ISimpleNewContact,
  IPhoneNumber,
  PhoneNumberType,
  IContactEmailAddress,
} from "services/api/types";
import enumerableStore from "stores/enumerable";
import * as yup from "yup";
import { Observer } from "mobx-react";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import withWidth from "@material-ui/core/withWidth";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import InputPhoneNumber from "components/Inputs/InputPhoneNumber";
import PhoneTypeDropdown from "components/Dropdowns/PhoneTypeDropdown";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import EmailTypesDropdown from "components/Dropdowns/EmailTypesDropdown";

type Props = {
  // formType: "add" | "update"; // always add
  companyId: string;
  isSaving: boolean;
  handleCancel: () => void;
  handleSubmit: (contact: ISimpleNewContact) => void;
  width: Breakpoint;
};

const NewContactForm = (props: Props) => {
  React.useEffect(() => {
    enumerableStore.ensurePhoneNumberTypesFetched();
    enumerableStore.ensureRelationTypesFetched();
  }, []);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      contactFormRoot: {},
      heading: {
        margin: theme.spacing(1, 0),
        color: theme.palette.primary.dark,
        fontWeight: "bold",
      },
      warning: {
        color: theme.palette.secondary.dark,
      },
      error: {
        color: theme.palette.error.main,
        fontWeight: "bold",
      },
    })
  );

  const initialValues: ISimpleNewContact = {
    altName: "",
    lastName: "",
    firstName: "",
    companyId: props.companyId,
    email: {
      emailAddress: "",
      main: true,
    },
    phone: {
      extension: "",
      number: "",
    },
    isEmployee: false,
    title: "",
  };

  const renderNames = (formikProps: FormikProps<ISimpleNewContact>) => {
    return (
      <>
        <Grid item xs={12} sm={4}>
          <TextField
            name="firstName"
            autoFocus
            label="First Name"
            margin="dense"
            variant="outlined"
            helperText={
              formikProps.touched.firstName && formikProps.errors?.firstName
            }
            error={Boolean(
              formikProps.touched.firstName && formikProps.errors?.firstName
            )}
            fullWidth
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name="lastName"
            label="Last Name"
            margin="dense"
            variant="outlined"
            helperText={
              formikProps.touched.lastName && formikProps.errors?.lastName
            }
            error={Boolean(
              formikProps.touched.lastName && formikProps.errors?.lastName
            )}
            fullWidth
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            name="altName"
            label="Preferred Name"
            margin="dense"
            variant="outlined"
            fullWidth
            helperText={formikProps.errors?.altName}
            error={Boolean(formikProps.errors?.altName)}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
          />
        </Grid>
      </>
    );
  };

  const renderHeading = (heading: string) => {
    return (
      <Grid item xs={12}>
        <Typography
          className={classes.heading}
          style={{ gridColumn: "1 / 13" }}
        >
          {heading}
        </Typography>
      </Grid>
    );
  };

  const renderTitle = (formikProps: FormikProps<ISimpleNewContact>) => {
    return (
      <>
        <Grid item xs={12} sm={4}>
          <TextField
            name="title"
            label="Title/Type"
            margin="dense"
            variant="outlined"
            helperText={formikProps.errors?.title}
            error={Boolean(formikProps.errors?.title)}
            fullWidth
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* <Field name="isEmployee" type="checkbox" /> */}
          <FormControlLabel
            control={
              <Checkbox
                checked={formikProps.values.isEmployee}
                onChange={(evt) =>
                  formikProps.setFieldValue("isEmployee", evt.target.checked)
                }
                size="small"
              />
            }
            label="Is Employee"
          />
        </Grid>
      </>
    );
  };

  const renderPhoneNumber = (formikProps: FormikProps<ISimpleNewContact>) => {
    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputPhoneNumber
              error={Boolean(formikProps.errors.phone?.number ?? "")}
              name="phone.number"
              type="text"
              label="Number" /// ???
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              value={formikProps.values.phone.number}
              placeholder="Number"
            />
            <ErrorMessage
              className={classes.error}
              component={FormHelperText}
              name={`phone.number`}
            />
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <TextField
            fullWidth
            type="text"
            variant="outlined"
            margin="dense"
            label="Ext"
            placeholder="Ext"
            name={`phone.extension`}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            value={formikProps.values.phone.extension ?? ""}
          />
        </Grid>
        <Grid item xs={5}>
          <FormControl fullWidth>
            <PhoneTypeDropdown
              error={Boolean(formikProps.errors?.phone?.type)}
              handleBlur={formikProps.handleBlur}
              value={formikProps.values.phone?.type ?? null}
              onChange={(value) => {
                formikProps.setFieldValue("phone.type", value);
              }}
            />
            <div>{formikProps.errors.phone?.type}</div>
            <ErrorMessage
              component={FormHelperText}
              className={classes.error}
              name="phone.type"
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  const renderEmailAddress = (formikProps: FormikProps<ISimpleNewContact>) => {
    return (
      <Grid alignItems="center" container spacing={1}>
        <Grid item xs={12} sm={5}>
          <FormControl fullWidth>
            <TextField
              margin="dense"
              variant="outlined"
              error={Boolean(
                formikProps.touched.email &&
                  formikProps.errors.email?.emailAddress
              )}
              name={`email.emailAddress`}
              type="email"
              label="Address"
              helperText={
                formikProps.touched.email &&
                formikProps.errors.email?.emailAddress
              }
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              value={formikProps.values.email.emailAddress ?? ""}
              placeholder="Address"
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={4}>
          <FormControl style={{ width: "100%" }}>
            <EmailTypesDropdown
              error={Boolean(formikProps.errors.email?.type)}
              handleBlur={formikProps.handleBlur}
              onChange={(value) => {
                formikProps.setFieldValue("email.type", value);
              }}
              value={formikProps.values.email.type ?? null}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  const classes = useStyles();
  return (
    <Observer
      render={() => {
        const relationshipTypesLoading =
          enumerableStore.isLoading("relationTypes");

        if (relationshipTypesLoading) {
          return <div />;
        }

        const schema = yup.object().shape({
          firstName: yup.string().required("First Name is required"),
          lastName: yup.string(),
          phone: yup.object().shape<IPhoneNumber>({
            extension: yup.string(),
            number: yup.string(),
            type: yup
              .mixed<PhoneNumberType>()
              .oneOf(["Home", "Work", "Cell", "Direct", "Other"]),
          }),
          email: yup.object().shape<IContactEmailAddress>({
            type: yup.string(),
            emailAddress: yup.string().email("Must be a valid email address"),
            main: yup.bool(),
          }),
        });

        return (
          <Formik
            onSubmit={(values) => {
              props.handleSubmit(values);
            }}
            validationSchema={schema}
            initialValues={initialValues}
            validate={(values) => {
              const errors: any = {};

              if (values.phone.number && !values.phone.type) {
                errors.phone = {
                  type: "Please select a type",
                };
              }

              return errors;
            }}
          >
            {(formikProps) => {
              return (
                <div className={classes.contactFormRoot}>
                  <ResponsiveModalShell
                    handleClose={props.handleCancel}
                    title="Add Contact"
                    disableSaveButton={formikProps.isSubmitting}
                    handleSave={formikProps.submitForm}
                  >
                    <Grid container spacing={2} alignItems="center">
                      {renderNames(formikProps)}
                      {renderTitle(formikProps)}
                      {renderHeading("Phone Number")}
                      {renderPhoneNumber(formikProps)}
                      {renderHeading("Email")}
                      {renderEmailAddress(formikProps)}
                    </Grid>
                  </ResponsiveModalShell>
                </div>
              );
            }}
          </Formik>
        );
      }}
    />
  );
};

export default withWidth()(NewContactForm);
