import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import GenericDropdown from "components/Dropdowns/GenericDropdown";
import companyStore from "stores/shared/company";
import { IContactDetailed, Option } from "services/api/types";
import { Observer } from "mobx-react";
import { IContactDate } from "services/api/types";
import DateFormat from "components/Shell/DateFormat";

export type ContactBirthdayOption = Option & {
  lastAwarded: Date | undefined;
  birthday: IContactDate | undefined;
  altOrFirstName: string;
};

type Props = {
  value: Option | undefined;
  onChanged: (value: Option | undefined) => void;
};

const ContactBirthdayAwardsDropdown = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      smaller: {
        fontSize: "smaller",
      },
    })
  );

  const classes = useStyles();

  React.useEffect(() => {
    companyStore.loadContacts(false);
  }, []);

  const getContactOptions = (
    contacts: IContactDetailed[] | undefined
  ): ContactBirthdayOption[] => {
    if (contacts === undefined) return [];

    return contacts
      .filter((c) => c.isEmployee && c.isActive)
      .map((c) => {
        return {
          label: c.altFullName!,
          value: c.id,
          lastAwarded: c.lastAwardedBirthdayPointsOn,
          birthday: c.birthdayDate,
          altOrFirstName:
            c.altName === undefined || c.altName.length === 0
              ? c.firstName
              : c.altName,
        };
      })
      .sort((a, b) => {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
      });
  };

  const getBirthdayString = (date: IContactDate | undefined) => {
    if (!date) return "---";

    return `${date.month}/${date.day ?? "--"}/${date.year ?? "----"}`;
  };

  const renderOption = (option: Option) => {
    const realOption = option as ContactBirthdayOption;
    return (
      <div>
        <div>
          <strong>{realOption.label}</strong>
        </div>
        <div className={classes.smaller}>
          <strong>Birthday: </strong>
          <span>{getBirthdayString(realOption.birthday)}</span>
        </div>
        <div className={classes.smaller}>
          <strong>Last Awarded Points On: </strong>
          <DateFormat date={realOption.lastAwarded} />
        </div>
      </div>
    );
  };

  return (
    <Observer
      render={() => {
        const contacts = companyStore.contacts;
        return (
          <GenericDropdown
            label="Select Birthday Contact"
            options={getContactOptions(contacts)}
            loading={contacts === undefined}
            selectedOption={props.value}
            setSelectedOption={props.onChanged}
            invalidOptionIds={[]}
            renderOption={renderOption}
            canClear={false}
            error={false}
          />
        );
      }}
    />
  );
};

export default ContactBirthdayAwardsDropdown;
