import { AdminPermissions } from "services/api/types";

type AdminPermissionsInfo = {
  [key in keyof AdminPermissions]: string;
};

export const permissionColors: AdminPermissionsInfo = {
  hasSettingsManagerRole: "sienna",
  hasCatalogOrderManagerRole: "olivedrab",
  hasCompanyUserAccountManagerRole: "orangered",
  hasGeneralAdminRole: "teal",
  hasImpersonatorRole: "midnightblue",
  hasMonthlyReportEditorRole: "maroon",
  hasTransactionCreatorRole: "darkviolet",
  hasEventAdminRole: "crimson",
  hasSysAdminRole: "blue",
};

export const permissionAbbreviations: AdminPermissionsInfo = {
  hasSettingsManagerRole: "settings",
  hasCatalogOrderManagerRole: "catalog",
  hasCompanyUserAccountManagerRole: "company users",
  hasGeneralAdminRole: "general",
  hasImpersonatorRole: "impersonate",
  hasMonthlyReportEditorRole: "monthly reports",
  hasTransactionCreatorRole: "transactions",
  hasEventAdminRole: "event admin",
  hasSysAdminRole: "system admin",
};

export const permissionExplainations: AdminPermissionsInfo = {
  hasSettingsManagerRole: "Can add/edit administrator accounts",
  hasTransactionCreatorRole: "Can create manual transactions",
  hasCatalogOrderManagerRole: "Can accept/cancel catalog orders",
  hasCompanyUserAccountManagerRole: "Can add/remove company user accounts",
  hasGeneralAdminRole: "General admin",
  hasImpersonatorRole: "Can impersonate company users",
  hasMonthlyReportEditorRole: "Can edit and process monthly reports",
  hasEventAdminRole: "Can modify data in the events area",
  hasSysAdminRole: "Can send messages to all logged in users",
};

export const permissionNames: AdminPermissionsInfo = {
  hasCatalogOrderManagerRole: "Catalog Order Manager",
  hasCompanyUserAccountManagerRole: "Company User Account Manager",
  hasGeneralAdminRole: "General Admin",
  hasImpersonatorRole: "Impersonator",
  hasMonthlyReportEditorRole: "Monthly Report Editor",
  hasSettingsManagerRole: "Settings",
  hasTransactionCreatorRole: "Transaction Creator",
  hasEventAdminRole: "Event Admin",
  hasSysAdminRole: "System Admin",
};
