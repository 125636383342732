import { apiClient } from "services/api/client";
import { IAuditRecord } from "services/api/types";
import { getFileName } from "services/api/modules/helpers";

export const getAuditRecords = async (args: {
  startDate: Date;
  endDate: Date;
  hideBcnAdminActivity: boolean;
  companyId?: string;
}) => {
  const url = "administrator/audit";
  const response = await apiClient.get<IAuditRecord[]>(url, { params: args });
  return response.data;
};

export const getAuditRecordExport = async (args: {
  startDate: Date;
  endDate: Date;
  hideBcnAdminActivity: boolean;
  companyId?: string;
}) => {
  const url = "administrator/audit/export";
  const response = await apiClient.get(url, {
    params: args,
    responseType: "blob",
  });
  const fileName = getFileName(response, `Recent Activity.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};
