import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { IContactDetailed } from "services/api/types";
import { useHistory } from "react-router";
import { paths } from "routes/Paths";
import clsx from "clsx";
import PhoneNumber from "components/PhoneNumber";

type Props = {
  contact: IContactDetailed;
  closePanel: () => void;
};

const ContactInfo = (props: Props) => {
  const history = useHistory();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        position: "relative",
        height: "100vh",
      },
      name: {
        fontWeight: "bold",
        fontSize: "large",
      },
      heading: {
        margin: theme.spacing(4, 0, 1, 0),
        display: "inline-block",
      },
      buttonHost: {
        position: "absolute",
        right: "10px",
        bottom: "50px",
        textAlign: "center",
        width: "100%",
      },
      primaryPhone: {
        fontWeight: "bold",
      },
    })
  );

  const renderPhoneNumbers = () => {
    if (props.contact.phoneNumbers.length === 0) {
      return <div>None entered</div>;
    }

    const mainPhone = props.contact.phoneNumbers.find(
      (ph) => ph.type === "Work"
    );

    return (
      <div>
        {props.contact.phoneNumbers.map((p) => {
          return (
            <div
              className={clsx({
                [classes.primaryPhone]: p.id === mainPhone?.id,
              })}
              key={p.id}
            >
              <span>
                <PhoneNumber
                  number={p.number}
                  type={p.type}
                  extension={p.extension}
                />
              </span>
              {p.type === "Work" ? <span>*</span> : undefined}
            </div>
          );
        })}
      </div>
    );
  };

  const handleEditContactClick = () => {
    history.push(
      `${paths.app.admin.companyDetails}/${props.contact.companyId}/contacts/${props.contact.id}`
    );
    props.closePanel();
  };

  const renderEmail = () => {
    if (!props.contact.email) {
      return <div>None entered</div>;
    }

    const href = `mailTo:${props.contact.email}`;
    return (
      <div>
        <a target="_blank" href={href} rel="noopener noreferrer">
          {props.contact.email}
        </a>
      </div>
    );
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div
        className={classes.name}
      >{`${props.contact.firstName} ${props.contact.lastName}`}</div>
      <Typography className={classes.heading} color="primary" variant="caption">
        Email Address
      </Typography>
      {renderEmail()}
      <Typography className={classes.heading} color="primary" variant="caption">
        Phone Numbers
      </Typography>
      {renderPhoneNumbers()}
      <div className={classes.buttonHost}>
        <Button
          onClick={handleEditContactClick}
          variant="contained"
          color="primary"
        >
          Edit Contact
        </Button>
      </div>
    </div>
  );
};

export default ContactInfo;
