import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import api from "services/api";

type Props = {
  handleClose: () => void;
  memberId: string;
  memberName: string;
  memberIdentifier: string;
  pointBalance: number;
  doneCallback: () => void;
};

const CloseMemberAccountModal = (props: Props) => {
  const [notes, setNotes] = React.useState("");
  const [isSaving, setIsSaving] = React.useState(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
    })
  );

  const classes = useStyles();

  const handleSave = async () => {
    setIsSaving(true);

    try {
      await api.admin.members.closeAccount({ memberId: props.memberId, notes });
      props.handleClose();
      props.doneCallback();
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <ResponsiveModalShell
      handleSave={handleSave}
      saveButtonCaption="Close Account"
      disableSaveButton={isSaving}
      handleClose={props.handleClose}
      title="Close Member Account"
    >
      <div className={classes.root}>
        <Typography>
          Clicking OK will make the member inactive and transfer any remaining
          point balance to the marketing fund.
        </Typography>
        <TextField
          variant="outlined"
          label="Note to add to account (optional)"
          margin="dense"
          multiline={true}
          minRows={3}
          disabled={isSaving}
          maxRows={3}
          fullWidth
          value={notes}
          onChange={(evt) => setNotes(evt.target.value)}
        />
      </div>
    </ResponsiveModalShell>
  );
};

export default CloseMemberAccountModal;
