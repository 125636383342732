import { Formik, FormikActions, FormikProps } from "formik";
import React from "react";
import { defaultErrorHandler } from "services/api/errors";
import {
  IBrand,
  ICompanyBrand,
  CreateCompanyBrandCommand,
  Option,
} from "services/api/types";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import enumerableStore from "stores/enumerable";
import BrandsDropdown from "./BrandsDropdown";
import { Observer } from "mobx-react";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";

type FormData = {
  brandId?: string;
  brandName?: string;
};

type Props = {
  companyId: string;
  companyBrands: ICompanyBrand[];
  handleCancel: () => void;
  handleSubmit: (payload: CreateCompanyBrandCommand) => void;
};

const BrandForm = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        minWidth: 300,
      },
    })
  );
  const classes = useStyles();

  React.useEffect(() => {
    enumerableStore.ensureBrandsFetched();
  }, []);

  const handleFormSubmit = async (
    values: FormData,
    actions: FormikActions<FormData>
  ) => {
    const command: CreateCompanyBrandCommand = {
      brandId: values.brandId,
      brandName: values.brandName,
      companyId: props.companyId,
    };

    try {
      await props.handleSubmit(command);
    } catch (err) {
      defaultErrorHandler(err);
    }
    actions.setSubmitting(false);
  };

  const initialValues = { brandId: "" };

  const handleOptionSelected = (
    option: Option,
    formikProps: FormikProps<FormData>
  ) => {
    formikProps.setFieldValue("brandId", option.value);
    formikProps.setFieldValue("brandName", undefined);
  };

  const handleRawTextSelected = (
    rawText: string,
    formikProps: FormikProps<FormData>
  ) => {
    formikProps.setFieldValue("brandId", undefined);
    formikProps.setFieldValue("brandName", rawText);
  };

  const renderBrandDropdown = (
    formikProps: FormikProps<FormData>,
    brands: IBrand[]
  ) => {
    return (
      <BrandsDropdown
        handleOptionSelected={(option) =>
          handleOptionSelected(option, formikProps)
        }
        handleRawTextSelected={(rawText) =>
          handleRawTextSelected(rawText, formikProps)
        }
        value={formikProps.values.brandId}
        error={formikProps.errors.brandId}
        touched={formikProps.touched.brandId}
        brands={brands}
      />
    );
  };

  return (
    <Observer
      render={() => {
        const brands = enumerableStore.getEnum("brands");

        if (enumerableStore.isLoading("brands")) {
          return <div />;
        }

        return (
          <div className={classes.root}>
            <Formik initialValues={initialValues} onSubmit={handleFormSubmit}>
              {(formikProps) => (
                <ResponsiveModalShell
                  handleClose={props.handleCancel}
                  title="Add Brand"
                  disableSaveButton={formikProps.isSubmitting}
                  handleSave={() => formikProps.submitForm()}
                >
                  {renderBrandDropdown(formikProps, brands)}
                </ResponsiveModalShell>
              )}
            </Formik>
          </div>
        );
      }}
    />
  );
};

export default BrandForm;
