import { apiClient } from "services/api/client";
import {
  IListedMonthlyReport,
  IMonthlyReportSummary,
  ReportFilterType,
  IGetMonthlyReportListResult,
  IMonthlyReportPayment,
  INewMonthlyReportPayment,
  IFullMonthlyReport,
} from "services/api/types";

const SPONSOR_MONTHLY_REPORTS_URL = "administrator/sponsors";
const MONTHLY_REPORTS_URL = "administrator/monthlyReports";
const PROCESSED_MONTHLY_REPORTS_URL = "administrator/processedMonthlyReports";

export const getReportsForSponsor = async (sponsorId: string) => {
  const response = await apiClient.get<IListedMonthlyReport[]>(
    `${SPONSOR_MONTHLY_REPORTS_URL}/${sponsorId}/monthlyReports`
  );
  return response.data;
};

export const getSummary = async (monthlyReportId: string) => {
  const url = `${MONTHLY_REPORTS_URL}/${monthlyReportId}`;
  const response = await apiClient.get<IMonthlyReportSummary>(url);
  return response.data;
};

export const getReportsForProcessing = async (
  type: ReportFilterType,
  searchTerm?: string
) => {
  const response = await apiClient.get<IGetMonthlyReportListResult>(
    MONTHLY_REPORTS_URL,
    {
      params: {
        type,
        searchTerm,
      },
    }
  );
  return response.data;
};

export const addPayment = async (payment: INewMonthlyReportPayment) => {
  const url = `${MONTHLY_REPORTS_URL}/${payment.monthlyReportId}/payments`;
  const response = await apiClient.post<IMonthlyReportPayment>(url, payment);
  return response.data;
};

export const updatePayment = async (payment: IMonthlyReportPayment) => {
  const url = `${MONTHLY_REPORTS_URL}/${payment.monthlyReportId}/payments/${payment.id}`;
  const response = await apiClient.put<IMonthlyReportPayment>(url, payment);
  return response.data;
};

export const deletePayment = async (
  monthlyReportId: string,
  monthlyReportPaymentId: string
) => {
  const url = `${MONTHLY_REPORTS_URL}/${monthlyReportId}/payments/${monthlyReportPaymentId}`;
  const response = await apiClient.delete(url);
  return response;
};

export const deleteReport = async (monthlyReportId: string) => {
  const url = `${MONTHLY_REPORTS_URL}/${monthlyReportId}`;
  const response = await apiClient.delete(url);
  return response;
};

export const markReportAsProcessed = async (monthlyReportId: string) => {
  const url = `${PROCESSED_MONTHLY_REPORTS_URL}/${monthlyReportId}`;
  const response = await apiClient.post<IFullMonthlyReport>(url);
  return response;
};

export const markReportAsUnprocessed = async (monthlyReportId: string) => {
  const url = `${PROCESSED_MONTHLY_REPORTS_URL}/${monthlyReportId}`;
  const response = await apiClient.delete(url);
  return response;
};

export const updateAdminFields = async (
  monthlyReportId: string,
  ignoreMinimum: boolean,
  manualAdjustment: string,
  status: string
) => {
  const url = `${MONTHLY_REPORTS_URL}/${monthlyReportId}/updateAdminFields`;
  const response = await apiClient.post(url, {
    monthlyReportId,
    ignoreMinimum,
    manualAdjustment,
    status,
  });

  return response.data;
};
