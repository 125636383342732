import React from "react";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";

type InputPhoneNumberProps = React.InputHTMLAttributes<HTMLInputElement> & {
  error: boolean;
  helperText?: string;
  label: string;
  fullWidth?: boolean;
};

const InputPhoneNumber = (props: InputPhoneNumberProps) => {
  return (
    <InputMask mask="999-999-9999" {...props}>
      {(inputProps: React.InputHTMLAttributes<HTMLInputElement>) => {
        const { color, size, ...rest } = inputProps;

        return (
          <TextField
            label={props.label}
            margin="dense"
            error={props.error}
            helperText={props.helperText}
            variant="outlined"
            {...rest}
          />
        );
      }}
    </InputMask>
  );
};

export default InputPhoneNumber;
