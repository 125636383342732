import { apiClient } from "services/api/client";
import { IHostCompanyInfo } from "../types";

export const login = async (
  userName: string,
  password: string,
  rememberMe: boolean
) => {
  const url = `public/login`;

  const response = await apiClient.post<any>(url, {
    email: userName,
    password,
    rememberMe,
  });

  return response;
};

export const latestNewspaper = async () => {
  const url = "public/latestNewspaper";
  const response = await apiClient.get<string>(url);
  return response.data;
};

export const getHostCompanyInfo = async () => {
  const url = "public/hostCompanyInfo";
  const response = await apiClient.get<IHostCompanyInfo>(url);
  return response.data;
};
