import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Helmet } from "react-helmet";
import PageTitle from "components/Shell/PageTitle";
import Container from "@material-ui/core/Container";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import clsx from "clsx";
import { OverflowYProperty } from "csstype";

type Props = {
  title: string;

  // Think of this as "IsSafeToRender"
  isLoading: boolean;

  // Default = true. Indicate we have some data loaded
  isLoaded?: boolean;

  render: () => JSX.Element;
  disableTopPadding?: boolean; // Sometimes it just looks weird
  hasToolbar?: boolean;
  maxWidth?: Breakpoint;
  disableContainer?: boolean;
  nameForDebugging?: string;
  overflowY?: OverflowYProperty;
};

const Page = (props: Props) => {
  const derrivedIsLoaded = props.isLoaded === undefined ? true : props.isLoaded;

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      pageRoot: {
        paddingBottom: theme.spacing(4),
        height: "100%",
        overflowY: props.overflowY ?? "auto",
        paddingTop: props.hasToolbar ? theme.spacing(5) - 2 : "unset",
      },
      watermark: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: -1000,
        opacity: derrivedIsLoaded ? 0 : 0.3,
        background: "url(/images/watermark-logo.png)",
        // background: "url(/images/new-logo-smaller.png)",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        pointerEvents: "none",
        // transition: theme.transitions.create(["opacity"], {
        //   easing: theme.transitions.easing.easeInOut,
        //   duration: theme.transitions.duration.short,
        // }),
      },
      linearProgress: {
        width: 100,
        marginTop: 20,
      },
      loading: {
        pointerEvents: "none",
        // opacity: 0.5,
        // filter: "blur(1px)",
      },
      content: {
        transition: theme.transitions.create(["opacity"], {
          easing: theme.transitions.easing.easeInOut,
          duration: theme.transitions.duration.standard,
        }),
      },
      hiddenLoader: {
        opacity: 0,
      },
      topPadding: {
        minHeight: theme.spacing(2),
      },
    })
  );

  const classes = useStyles();

  const renderContents = () => {
    return props.disableContainer ? (
      props.render()
    ) : (
      <Container maxWidth={props.maxWidth ?? "lg"}>{props.render()}</Container>
    );
  };

  const renderTopPadding = () => {
    if (props.disableTopPadding) return undefined;

    return <div className={classes.topPadding} />;
  };

  // TODO why do we need an Id here? there can be multiple
  // pages in the DOM at one time.
  return (
    <div
      id="page-root"
      data-page-name={props.nameForDebugging ?? ""}
      className={classes.pageRoot}
    >
      <>
        <Helmet>
          <title>{props.title}</title>
        </Helmet>
        <PageTitle title={props.title} />
      </>
      {renderTopPadding()}
      <div className={classes.watermark} />
      <div
        className={clsx(classes.content, {
          [classes.loading]: props.isLoading,
        })}
      >
        {derrivedIsLoaded && renderContents()}
      </div>
    </div>
  );
};

export default Page;
