import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import CompanyAvatar from "areas/Shared/components/CompanyAvatar";

type Props = {
  companyName: string;
  identifier: string;
  website: string;
  imageUrl?: string;
};

const CompanyInfoHeader = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      companyInfoHeader: {
        display: "grid",
        gridTemplateColumns: "auto 1fr",
        alignItems: "center",
        marginBottom: theme.spacing(3),
        gridGap: theme.spacing(2),
      },
    })
  );

  const classes = useStyles();

  const webSiteUrl =
    props.website && props.website.indexOf("http") < 0
      ? `http://${props.website}`
      : props.website;

  return (
    <div className={classes.companyInfoHeader}>
      <CompanyAvatar
        size="large"
        companyName={props.companyName}
        website={props.website}
        imageUri={props.imageUrl}
      />
      <div>
        <h2
          style={{ marginTop: 0, marginBottom: 0 }}
        >{`${props.companyName} (${props.identifier})`}</h2>
        <a rel="noopener noreferrer" href={webSiteUrl} target="_blank">
          {props.website}
        </a>
      </div>
    </div>
  );
};

export default CompanyInfoHeader;
