import { apiClient } from "services/api/client";
import { getFileName } from "services/api/modules/helpers";
import {
  IExportPredefinedCompanyListRequest,
  IExportPointsAwardedRequest,
  IExportPointsReceivedRequest,
  IExportCompaniesRequest,
} from "services/api/types";

const EXPORTS_URL = "administrator/exports";

export const exportTransactions = async (accountId: string) => {
  const url = `${EXPORTS_URL}/accounts/${accountId}/transactions`;
  const response = await apiClient.post(url, {}, { responseType: "blob" });
  const fileName = getFileName(response, `Account Transaction.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const escrowDepositSummaryExport = async (request: {
  month: number;
  year: number;
}) => {
  const url = `${EXPORTS_URL}/escrowDepositSummary`;
  const response = await apiClient.post(url, request, { responseType: "blob" });
  const fileName = getFileName(response, `EscrowDepositSummary.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const sponsorReferralsExport = async (sponsorId?: string) => {
  const url = `${EXPORTS_URL}/sponsorReferrals`;

  const response = await apiClient.post(
    url,
    {},
    {
      responseType: "blob",
      params: {
        sponsorId,
      },
    }
  );
  const fileName = getFileName(response, `SponsorReferrals.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const sponsorsUsedAndNotUsed = async (
  memberId: string,
  startDate: Date,
  endDate: Date
) => {
  const url = `${EXPORTS_URL}/sponsorsUsedAndNotUsed/${memberId}`;

  const response = await apiClient.post(
    url,
    {},
    {
      responseType: "blob",
      params: {
        startDate,
        endDate,
      },
    }
  );
  const fileName = getFileName(response, `SponsorsUsedAndNotUsed.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const singleCompanyTransactionExport = async (request: {
  startDate: Date;
  endDate: Date;
  companyId: string;
}) => {
  const url = `${EXPORTS_URL}/transactions/${request.companyId}`;
  const response = await apiClient.post(url, request, { responseType: "blob" });
  const fileName = getFileName(response, `TransactionRegister.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const multiCompanyTransactionExport = async (request: {
  startDate: Date;
  endDate: Date;
}) => {
  const url = `${EXPORTS_URL}/transactions`;
  const response = await apiClient.post(url, request, { responseType: "blob" });
  const fileName = getFileName(response, `TransactionRegister.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const contactEmails = async () => {
  const url = `${EXPORTS_URL}/contactEmails`;
  const response = await apiClient.post(url, {}, { responseType: "blob" });
  const fileName = getFileName(response, "ContactEmails.xlsx");

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const accountants = async () => {
  const url = `${EXPORTS_URL}/accountants`;
  const response = await apiClient.post(url, {}, { responseType: "blob" });
  const fileName = getFileName(response, "Accountants.xlsx");

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const exportCompanies = async (request: IExportCompaniesRequest) => {
  const response = await apiClient.post(
    "administrator/companies/export",
    request,
    {
      responseType: "blob",
    }
  );
  const fileName = getFileName(response, `CompanyExport.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const exportPredefinedCompanyList = async (
  request: IExportPredefinedCompanyListRequest
) => {
  const response = await apiClient.post(
    "administrator/companies/exportPredefined",
    request,
    {
      responseType: "blob",
    }
  );
  const fileName = getFileName(response, `CompanyExport.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const exportPointsAwarded = async (
  request: IExportPointsAwardedRequest
) => {
  const url = `${EXPORTS_URL}/pointsAwarded`;
  const response = await apiClient.post(url, request, {
    responseType: "blob",
  });

  const fileName = getFileName(response, `PointsAwarded.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const exportPointsReceived = async (
  request: IExportPointsReceivedRequest
) => {
  const url = `${EXPORTS_URL}/pointsReceived`;
  const response = await apiClient.post(url, request, {
    responseType: "blob",
  });

  const fileName = getFileName(response, `PointsAwarded.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const exportRedemptions = async (request: {
  startDate: Date;
  endDate: Date;
}) => {
  const url = `${EXPORTS_URL}/redemptions`;

  const response = await apiClient.get(url, {
    responseType: "blob",
    params: request,
  });
  const fileName = getFileName(response, `CompanyExport.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};
