import { action, observable, computed, observe } from "mobx";
import api from "services/api";
import {
  IRecentCompanies,
  ILoggedInUserInfo,
  ICompanyLocation,
  ICompanyPermissionViewModel,
  IGrandDestinationVideoInfo,
  ISummaryTransaction,
  UserType,
  IListedCompanyEvent,
  IListedMember,
  IChartData,
} from "services/api/types";
import * as roles from "types/roles";
import companyStore from "stores/shared/company";

const impersonationSessionStorageKey = "impersonating-user-id";
const currentCompanySessionStoragekey = "current-company";

export class MyStore {
  constructor() {
    this.monitorAndSaveToSessionStorage();
  }

  @computed get upcomingEvents() {
    if (!this.currentCompany) return [];
    const { companyType } = this.currentCompany;
    return this.upcomingEventsCache[companyType];
  }

  @computed
  // This property gives precedence to the current company over my info
  public get displayUserType(): UserType | undefined {
    if (this.currentCompany && this.currentCompany.companyType === "Member") {
      return "Member";
    } else if (
      this.currentCompany &&
      this.currentCompany.companyType === "Sponsor"
    ) {
      return "Sponsor";
    } else if (this.myInfo && this.myInfo.roles.length > 0) {
      return "Admin";
    }

    return undefined;
  }

  @computed
  // This probably gives precedence to myInfo over the current company
  public get realUserType(): UserType | undefined {
    if (this.myInfo && this.myInfo.roles.length > 0) {
      return "Admin";
    } else if (
      this.currentCompany &&
      this.currentCompany.companyType === "Member"
    ) {
      return "Member";
    } else if (
      this.currentCompany &&
      this.currentCompany.companyType === "Sponsor"
    ) {
      return "Sponsor";
    }

    return undefined;
  }

  @computed
  public get currentCompanyName() {
    return this.currentCompany ? this.currentCompany.companyName : undefined;
  }

  @computed
  public get currentCompanyPointBalance() {
    return this.currentCompany ? this.currentCompany.pointBalance : undefined;
  }

  @computed
  public get currentCompanyType() {
    return this.currentCompany ? this.currentCompany.companyType : undefined;
  }

  @computed
  public get emailAddress() {
    if (this.impersonatingUserInfo)
      return this.impersonatingUserInfo.emailAddress;
    return this.myInfo?.emailAddress;
  }

  @computed
  public get currentUserInfo() {
    if (this.impersonatingUserInfo) return this.impersonatingUserInfo;
    return this.myInfo;
  }

  @observable
  public preImpersonationPath: string | undefined;

  @observable
  public myInfoLoading = true;

  @observable
  public myInfoLoaded = false;

  @observable
  public recentCompaniesLoading = true;

  @observable
  public recentCompaniesLoaded = false;

  @observable
  public shippingAdressesLoaded = false;

  @observable
  public shippingAddresses: ICompanyLocation[] = [];

  @observable
  public myInfo: ILoggedInUserInfo | undefined;

  @observable
  public impersonatingUserInfo: ILoggedInUserInfo | undefined;

  @observable
  public currentCompany: ICompanyPermissionViewModel | undefined;

  @observable
  private upcomingEventsCache: {
    [key: string]: IListedCompanyEvent[] | undefined;
  } = {};

  @observable
  public newestMembers: IListedMember[] | undefined;

  @observable
  public grandDestinationVideoInfo: IGrandDestinationVideoInfo | undefined;

  @observable
  public pointActivityChartData: { [key: string]: IChartData | undefined } = {};

  @computed
  public get isAdmin() {
    return (
      this.myInfo &&
      this.myInfo.companies.length === 0 &&
      this.myInfo.roles.length > 0
    );
  }

  @computed
  public get isAdminNotImpersonating() {
    return this.isAdmin === true && !this.impersonatingUserInfo;
  }

  @computed
  public get isSponsor() {
    return (
      this.currentCompany &&
      (this.currentCompany.roleId === roles.sponsorAccounting ||
        this.currentCompany.roleId === roles.sponsorSales ||
        this.currentCompany.roleId === roles.sponsorAdmin)
    );
  }

  @computed
  public get isMember() {
    return (
      this.currentCompany &&
      (this.currentCompany.roleId === roles.memberAdmin ||
        this.currentCompany.roleId === roles.memberUser)
    );
  }

  @observable
  public recentCompanies: IRecentCompanies | undefined = undefined;

  @observable
  public transactionsAreLoading = false;

  public transactions: ISummaryTransaction[] | undefined = undefined;

  @action.bound
  public async impersonateUser(
    userId: string,
    currentLocationPathName: string,
    callback: (currentCompany: ICompanyPermissionViewModel) => void
  ) {
    companyStore.unload();
    this.preImpersonationPath = currentLocationPathName;
    const impersonatingUserInfo = await api.admin.users.getUserInfo(userId);
    this.impersonatingUserInfo = impersonatingUserInfo;

    this.currentCompany = impersonatingUserInfo.companies[0];
    callback(impersonatingUserInfo.companies[0]);
  }

  @action.bound
  public updateCurrentUserPhoneNumber(phoneNumber: string) {
    if (this.impersonatingUserInfo) {
      const newMyInfo = Object.assign({}, this.impersonatingUserInfo, {
        phoneNumber,
      });
      this.impersonatingUserInfo = newMyInfo;
    } else {
      const newMyInfo = Object.assign({}, this.myInfo, { phoneNumber });
      this.myInfo = newMyInfo;
    }
  }

  @action.bound
  public getCurrentUserPhoneNumber() {
    if (this.impersonatingUserInfo) {
      return this.impersonatingUserInfo.phoneNumber;
    }

    return this.myInfo?.phoneNumber;
  }

  @action.bound
  public async setCurrentCompany(companyId: string) {
    const company = this.currentUserInfo?.companies.find(
      (c) => c.companyId === companyId
    );

    if (!company) {
      throw Error(`Could not find company with Id of ${companyId}`);
    }

    this.currentCompany = company;
  }

  @action.bound
  public async stopImpersonating() {
    companyStore.unload();
    this.impersonatingUserInfo = undefined;
    this.currentCompany = undefined;
    this.shippingAdressesLoaded = false;
  }

  @action.bound
  public async updatePointBalance(newPointBalance: number) {
    if (this.currentCompany) {
      this.currentCompany = Object.assign({}, this.currentCompany, {
        pointBalance: newPointBalance,
      });
    }

    if (this.myInfo) {
      const newCompanies = (
        this.impersonatingUserInfo ?? this.myInfo
      ).companies.map((c) => {
        if (c.companyId === this.currentCompany?.companyId) {
          return Object.assign({}, c, { pointBalance: newPointBalance });
        } else {
          return c;
        }
      });

      if (this.impersonatingUserInfo) {
        this.impersonatingUserInfo = Object.assign(
          {},
          this.impersonatingUserInfo,
          {
            companies: newCompanies,
          }
        );
      } else {
        this.myInfo = Object.assign({}, this.myInfo, {
          companies: newCompanies,
        });
      }
    }
  }

  @action.bound
  public async getMyInfo(force = false) {
    if (this.myInfoLoaded && !force) return this.myInfo;

    this.myInfoLoading = true;

    try {
      const result = await api.members.my.getMyInfo();
      this.myInfo = result.data;

      this.impersonatingUserInfo = undefined;

      if (this.hasAnyAdminRole(["bcn:admin"])) {
        console.log("");
      }

      // The observation of impersonatingUserInfo doesn't work
      // all the time for some reason...!?!?
      window.sessionStorage.removeItem(impersonationSessionStorageKey);

      if (this.myInfo.companies.length > 0) {
        // Try to get the current company from storage first
        const storageValue = window.sessionStorage.getItem("current-company");
        const previouslySetCompany = storageValue
          ? this.myInfo.companies.find((c) => c.companyId === storageValue)
          : undefined;

        if (previouslySetCompany) {
          this.currentCompany = previouslySetCompany;
        } else {
          this.currentCompany = this.myInfo.companies[0];
        }
      } else {
        this.currentCompany = undefined;
      }
      this.myInfoLoaded = true;
    } catch (e) {
      console.error(e);
    }

    this.myInfoLoading = false;
    return this.myInfo;
  }

  @action.bound
  public async getRecentCompanies() {
    this.recentCompaniesLoading = true;
    this.recentCompaniesLoaded = false;

    try {
      const result = await api.admin.my.getRecentCompanies();
      this.recentCompanies = result.data;
      this.recentCompaniesLoaded = true;
    } catch (e) {
      console.error(e);
    }

    this.recentCompaniesLoading = false;
  }

  @action.bound
  public async getMyShippingAddresses() {
    if (this.shippingAdressesLoaded) return;

    try {
      const result = await api.members.my.getMyShippingAdresses();
      this.shippingAddresses = result.data;
      this.shippingAdressesLoaded = true;
    } catch (e) {
      console.error(e);
    }
  }

  @action.bound
  public async ensureEventsAreLoaded() {
    const { companyType } = this.currentCompany!;
    const cached =
      this.upcomingEvents && this.upcomingEvents[companyType] !== undefined;

    if (!cached) {
      const events = await api.companies.events.getUpcomingEvents();
      this.upcomingEventsCache = Object.assign({}, this.upcomingEvents, {
        [companyType]: events,
      });
    }
  }

  @action.bound
  public async ensureGrandDestinationVideoInfoIsLoaded() {
    if (this.grandDestinationVideoInfo) return;

    const result =
      await api.shared.grandDestinations.getGrandDestinationVideoLink();

    this.grandDestinationVideoInfo = result;
  }

  @action.bound
  public async loadNewestMembers() {
    // Two different endpoints for security reasons. Don't want members
    // to have access to this
    if (this.isAdminNotImpersonating) {
      this.newestMembers = await api.admin.members.getNewest();
    } else if (!this.newestMembers) {
      this.newestMembers = await api.sponsors.members.getNewest();
    }

    return this.newestMembers;
  }

  @action.bound
  public async getMemberPointHistoryChartData(companyId: string) {
    const cached = this.pointActivityChartData[companyId];

    if (cached) return cached;

    const data = await api.admin.company.getMemberPointHistoryChartData(
      companyId
    );

    this.pointActivityChartData = Object.assign(
      {},
      this.pointActivityChartData,
      { [companyId]: data }
    );

    return this.pointActivityChartData[companyId]!;
  }

  @action.bound
  public async getSponsorPointHistoryChartData(companyId: string) {
    const cached = this.pointActivityChartData[companyId];

    if (cached) return cached;

    const data = await api.admin.company.getSponsorPointHistoryChartData(
      companyId
    );

    this.pointActivityChartData = Object.assign(
      {},
      this.pointActivityChartData,
      { [companyId]: data }
    );

    return this.pointActivityChartData[companyId]!;
  }

  public hasAnyAdminRole(roleArray: roles.AdminRoleKey[]): boolean {
    for (const permission of roleArray) {
      if (this.hasAdminRole(permission)) {
        return true;
      }
    }

    return false;
  }

  public getEffectiveAdminRoles = () => {
    // Gets admin roles only if they're not impersonating
    if (!this.myInfo) return [];

    if (this.isAdminNotImpersonating) {
      return this.myInfo.roles as roles.AdminRoleKey[];
    }

    return [];
  };

  public getEffectiveCompanyRole = () => {
    if (!this.currentCompany) return undefined;
    return this.currentCompany.roleId;
  };

  public hasAdminRole(role: roles.AdminRoleKey): boolean {
    if (!this.myInfo) return false;

    const userRoles = this.myInfo.roles;
    const hasRole = userRoles.some((p) => p === role);

    return hasRole;
  }

  public hasAnyCompanyRole(roleArray: roles.CompanyRoleKey[]): boolean {
    for (const permission of roleArray) {
      if (this.hasCompanyRole(permission)) {
        return true;
      }
    }
    return false;
  }

  public hasCompanyRole(role: roles.CompanyRoleKey): boolean {
    if (!this.currentCompany) return false;
    const hasPermission = this.currentCompany.roleId === role;
    return hasPermission;
  }

  private monitorAndSaveToSessionStorage() {
    observe(this, "impersonatingUserInfo", (change) => {
      const userId = change.newValue?.userId;
      if (userId) {
        window.sessionStorage.setItem(impersonationSessionStorageKey, userId);
      } else {
        window.sessionStorage.removeItem(impersonationSessionStorageKey);
      }
    });

    observe(this, "currentCompany", (change) => {
      const companyId = change.newValue?.companyId;
      if (companyId) {
        window.sessionStorage.setItem(
          currentCompanySessionStoragekey,
          companyId
        );
      } else {
        window.sessionStorage.removeItem(currentCompanySessionStoragekey);
      }
    });
  }
}

export default new MyStore();
