import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Observer } from "mobx-react";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import MuiTextField from "@material-ui/core/TextField";
import * as yup from "yup";
import { Formik, Field, Form, FormikActions, FormikProps } from "formik";
import { TextField } from "formik-material-ui";
import { IEventAdjustment, IEventPayerAdjustment } from "services/api/types";
import FormHelperText from "@material-ui/core/FormHelperText";
import api from "services/api";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loader from "components/Shell/Loader";
import eventStore from "stores/admin/events";
import { formatMoney, formatNumber } from "utils/number";
import configStore from "stores/admin/config";
import DollarPointsInput from "components/Inputs/DollarPointsInput";

type Props = {
  handleClose: () => void;
  eventId: string;
  eventPayerId: string;
  eventGroupAdjustment?: IEventPayerAdjustment;
};

export type Option = {
  value: string;
  label: string;
};

type FormData = {
  eventAdjustment: Option | null;
  quantity: number;
  memo: string;
  manualDollarValue?: number;
  manualPointValue?: number;
};

const MANUAL_ADJUSTMENT_ID = "manual";

const AddEditEventPayerAdjustmentModal = (props: Props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [eventAdjustments, setEventAdjustments] = React.useState<
    IEventAdjustment[]
  >([]);
  // const [manualPointValue, setManualPointValue] = React.useState<
  //   number | undefined
  // >();
  // const [manualDollarValue, setManualDollarValue] = React.useState<
  //   number | undefined
  // >();
  // const [manualPointsError, setManualPointsError] = React.useState("");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      formControl: {
        margin: theme.spacing(1),
      },
      form: {
        maxWidth: "600px",
        margin: "0 auto",
        display: "grid",
        gridGap: theme.spacing(1),
        gridTemplateColumns: "repeat(12, 1fr)",
      },
      memoField: {
        gridColumn: "1 / span 12",
      },
      dropdownField: {
        gridColumn: "1 / span 9",
      },
      quantityField: {
        gridColumn: "10 / span 3",
      },
      amountPointsField: {
        gridColumn: "7 / span 6",
      },
      amountDollarsField: {
        gridColumn: "1 / span 6",
      },
      manualAdjustmentsInput: {
        gridColumn: "1 / span 12",
      },
      error: {
        color: theme.palette.error.main,
        marginLeft: theme.spacing(2),
      },
    })
  );

  const { eventId } = props;
  React.useEffect(() => {
    const loadEventAdjustments = async () => {
      setIsLoading(true);
      const eventAdjustments = await api.admin.events.getEventAdjustments(
        eventId
      );
      setEventAdjustments(eventAdjustments);
      setIsLoading(false);
    };

    loadEventAdjustments();
  }, [eventId]);

  const classes = useStyles();

  const schema = yup.object().shape({
    eventAdjustment: yup
      .object()
      .nullable()
      .required("Discount/Upgrade is required"),
    quantity: yup.number().min(1).required("Quanity is required"),
  });

  const submit = async (values: FormData, actions: FormikActions<FormData>) => {
    const eventAdjustmentId =
      values.eventAdjustment!.value === MANUAL_ADJUSTMENT_ID
        ? undefined
        : values.eventAdjustment!.value;

    if (props.eventGroupAdjustment) {
      await eventStore.updateEventPayerAdjustment({
        eventAdjustmentId: eventAdjustmentId,
        eventPayerAdjustmentId: props.eventGroupAdjustment.id,
        quantity: values.quantity,
        memo: values.memo,
        manualDollarAdjustment: values.manualDollarValue,
      });
    } else {
      await eventStore.addEventPayerAdjustment({
        eventAdjustmentId: eventAdjustmentId,
        eventPayerId: props.eventPayerId,
        quantity: values.quantity,
        memo: values.memo,
        manualDollarAdjustment: values.manualDollarValue,
      });
    }

    props.handleClose();
  };

  const renderAmountFields = (formikProps: FormikProps<FormData>) => {
    const isManualAdjustment =
      formikProps.values.eventAdjustment?.value === MANUAL_ADJUSTMENT_ID;

    if (isManualAdjustment) {
      return (
        <div className={classes.manualAdjustmentsInput}>
          <DollarPointsInput
            pointValue={formikProps.values.manualPointValue}
            allowNegative={true}
            dollarValue={formikProps.values.manualDollarValue}
            showDollarsError={Boolean(formikProps.errors.manualDollarValue)}
            showPointsError={Boolean(formikProps.errors.manualPointValue)}
            pointsError={formikProps.errors.manualPointValue}
            dollarsError={formikProps.errors.manualDollarValue}
            dollarsPerPoint={configStore.eventCostPerPoint!}
            onChange={(evt) => {
              formikProps.setFieldValue("manualDollarValue", evt.dollars);
              formikProps.setFieldValue("manualPointValue", evt.points);

              if (evt.points) {
                formikProps.setFieldError("manualPointValue", "");
              }
            }}
          />
        </div>
      );
    }

    const values = getValuesForSelectedAdjustment(formikProps);

    return (
      <div className={classes.manualAdjustmentsInput}>
        <DollarPointsInput
          pointValue={values.points}
          allowNegative={true}
          disabled
          dollarValue={values.dollars}
          showDollarsError={false}
          showPointsError={false}
          pointsError={""}
          dollarsError={""}
          dollarsPerPoint={configStore.eventCostPerPoint!}
          onChange={(evt) => {
            // do nothing
          }}
        />
      </div>
    );
  };

  const getValuesForSelectedAdjustment = (
    formikProps: FormikProps<FormData>
  ) => {
    const selectedAdjustment = eventAdjustments.find(
      (ea) => ea.id === formikProps.values.eventAdjustment?.value
    );

    const dollars =
      selectedAdjustment?.amountDollars ?? formikProps.values.manualDollarValue;

    const totalDollars = dollars
      ? dollars * formikProps.values.quantity
      : undefined;

    const totalPoints = dollars
      ? configStore.convertEventDollarsToPoints(
          dollars * formikProps.values.quantity
        )
      : undefined;

    return {
      totalDollars,
      totalPoints,
      dollars: selectedAdjustment?.amountDollars,
      points: selectedAdjustment
        ? configStore.convertEventDollarsToPoints(
            selectedAdjustment.amountDollars
          )
        : undefined,
    };
  };

  const renderReadOnlyAmountFields = (formikProps: FormikProps<FormData>) => {
    const selectedAdjustmentValues =
      getValuesForSelectedAdjustment(formikProps);

    return (
      <>
        <div className={classes.amountDollarsField}>
          <strong>Total Dollars:</strong>&nbsp;
          {formatMoney(selectedAdjustmentValues.totalDollars)}
        </div>
        <div className={classes.amountPointsField}>
          <strong>Total Points:</strong>&nbsp;
          {formatNumber(selectedAdjustmentValues.totalPoints)}
        </div>
      </>
    );
  };

  const renderQuantityField = (formikProps: FormikProps<FormData>) => {
    return (
      <Field
        className={classes.quantityField}
        variant="outlined"
        margin="dense"
        component={TextField}
        label="Quantity"
        name="quantity"
        disabled={formikProps.isSubmitting}
        type="number"
      />
    );
  };

  const renderMemoField = (formikProps: FormikProps<FormData>) => {
    return (
      <Field
        className={classes.memoField}
        variant="outlined"
        multiline
        rows={3}
        margin="dense"
        component={TextField}
        label="Memo"
        name="memo"
        disabled={formikProps.isSubmitting}
      />
    );
  };

  const renderAdjustmentDropdown = (
    formikProps: FormikProps<FormData>,
    options: Option[]
  ) => {
    const error = formikProps.errors.eventAdjustment;

    const showError =
      Boolean(error) && (formikProps.touched.eventAdjustment || false);

    return (
      <div className={classes.dropdownField}>
        <Autocomplete
          placeholder="Event Discount/Upgrade"
          onBlur={formikProps.handleBlur}
          onChange={(event: any, option: any) => {
            return formikProps.setFieldValue("eventAdjustment", option);
          }}
          options={options}
          value={formikProps.values.eventAdjustment}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              label="Discount/Upgrade"
              variant="outlined"
              name="eventType"
              error={showError}
              margin="dense"
            />
          )}
          getOptionLabel={(option) => {
            if (typeof option.label === "string") {
              return option.label;
            }

            return (option as any).label.label;
          }}
        />
        {showError ? (
          <FormHelperText className={classes.error}>{error}</FormHelperText>
        ) : undefined}
      </div>
    );
  };

  if (isLoading) {
    return <Loader message="Loading" />;
  }

  const sortedAdjustments = eventAdjustments.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  const options = sortedAdjustments.map((du) => {
    return {
      label: `${du.name} -- ${formatMoney(du.amountDollars)}`,
      value: du.id,
    };
  });

  options.unshift({
    label: "-- Manual Adjustment --",
    value: MANUAL_ADJUSTMENT_ID,
  });

  const getInitialEventAdjustmentOption = () => {
    if (props.eventGroupAdjustment) {
      const { eventAdjustmentId } = props.eventGroupAdjustment;
      const option = options.find(
        (o) => o.value === (eventAdjustmentId ?? MANUAL_ADJUSTMENT_ID)
      );
      return option!;
    } else {
      return null;
    }
  };

  return (
    <Observer
      render={() => {
        if (configStore.eventCostPerPoint === undefined) {
          return <div />;
        }

        return (
          <div>
            <Formik
              validationSchema={schema}
              onSubmit={submit}
              initialValues={{
                eventAdjustment: getInitialEventAdjustmentOption(),
                quantity: props.eventGroupAdjustment?.quantity || 1,
                memo: props.eventGroupAdjustment?.memo || "",
                manualDollarValue:
                  props.eventGroupAdjustment?.totalAmountDollars,
                manualPointValue: configStore.convertEventDollarsToPoints(
                  props.eventGroupAdjustment?.totalAmountDollars
                ),
              }}
              render={(formikProps) => {
                return (
                  <ResponsiveModalShell
                    handleSave={formikProps.handleSubmit}
                    disableSaveButton={formikProps.isSubmitting}
                    handleClose={props.handleClose}
                    title="Add Discount/Upgrade"
                  >
                    <Form className={classes.form}>
                      {renderAdjustmentDropdown(formikProps, options)}
                      {renderQuantityField(formikProps)}
                      {renderAmountFields(formikProps)}
                      {renderReadOnlyAmountFields(formikProps)}
                      {renderMemoField(formikProps)}
                    </Form>
                  </ResponsiveModalShell>
                );
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default AddEditEventPayerAdjustmentModal;
