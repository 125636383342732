import axios from "axios";
import authService from "components/api-authorization/AuthorizeService";
import uiStore from "stores/ui";

const doNotShowJavascriptAlertPrefix = "NOALERT:";

export const apiClient = axios.create({
  baseURL: "api",
});

export const removeAuthHeader = () => {
  delete apiClient.defaults.headers.common.Authorization;
};

apiClient.interceptors.request.use(async (config) => {
  uiStore.incrementPendingAjaxCalls();
  const companyId = window.sessionStorage.getItem("current-company");
  const impersonatingUserId = window.sessionStorage.getItem(
    "impersonating-user-id"
  );

  if (!config.headers) {
    throw Error("headers is undefined.");
  }

  if (companyId) {
    config.headers["current-company-id"] = companyId;
  }

  if (impersonatingUserId) {
    config.headers["impersonating-user-id"] = impersonatingUserId;
  }

  if (!config.headers.Authorization) {
    const token = await authService.getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }

  return config;
});

let attempts = 0;

apiClient.interceptors.response.use(
  (res) => {
    uiStore.decrementPendingAjaxCalls();
    return res;
  },
  (error) => {
    uiStore.decrementPendingAjaxCalls();
    console.error(error);

    if (error?.request?.status === 401) {
      // TODO - Get rid of... does no good :(
      attempts++;
      if (attempts < 2) {
        authService.signIn();
      }
    } else if (
      error.response &&
      error.response.data &&
      typeof error.response.data === "string"
    ) {
      if (
        (error.response.data as string).startsWith(
          doNotShowJavascriptAlertPrefix
        )
      ) {
        const errorMessage = error.response.data.replace(
          doNotShowJavascriptAlertPrefix,
          ""
        );
        return Promise.reject(errorMessage);
      }
      console.log(error.response.data);
      const isHtml = (error.response.data as string).indexOf("<div") >= 0;
      if (!isHtml) {
        alert(error.response.data);
      } else {
        alert("Something went wrong.");
      }
    } else {
      console.error(error);
    }
    return Promise.reject(error.response);
  }
);
