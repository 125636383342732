import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SimpleContactForm from "modals/components/CompanyForms/Contact/SimpleContactForm";
import { ISimpleNewContact, IContactDetailed } from "services/api/types";
import companyStore from "stores/shared/company";

type Props = {
  companyId: string;
  savedCallback: (conatct: IContactDetailed) => void;
  handleClose: () => void;
} & RouteComponentProps;

class ModalAddCompanyContact extends Component<Props> {
  public render = () => {
    return (
      <SimpleContactForm
        isSaving={companyStore.isSavingContact}
        companyId={this.props.companyId}
        handleSubmit={this.handleSubmit}
        handleCancel={this.props.handleClose}
      />
    );
  };

  private handleSubmit = async (contact: ISimpleNewContact) => {
    try {
      contact.companyId = this.props.companyId;
      const newContact = await companyStore.createSimpleCompanyContact(contact);
      this.props.savedCallback(newContact);
    } catch (e) {
      console.error(e);
    } finally {
      this.props.handleClose();
    }
  };
}

export default withRouter(ModalAddCompanyContact);
