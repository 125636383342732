import * as appConstants from "applications/appConstants";

export const getPointsTermProper = () => {
  if (process.env.REACT_APP_BRANDING === appConstants.BuildPerks) {
    return "Perk Points";
  } else {
    return "Club Points";
  }
};

export const getIdTermProper = () => {
  if (process.env.REACT_APP_BRANDING === appConstants.BuildPerks) {
    return "Perk Id";
  } else {
    return "Club Id";
  }
};
