import { apiClient } from "services/api/client";
import { IIdentityResult } from "services/api/types";

const MY_URL = "core/my";

export const changePassword = async (
  currentPassword: string,
  newPassword: string,
  userId?: string
) => {
  const url = `${MY_URL}/changePassword`;
  const response = await apiClient.post<IIdentityResult>(url, {
    currentPassword,
    newPassword,
    userId
  });

  return response.data;
};
