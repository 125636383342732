import React from "react";
import TextField from "@material-ui/core/TextField";
import { MaskedNumberInput } from "areas/Shared/components/maskedInputs/MaskedNumberInput";

type Props = {
  value: string | undefined;
  touched: boolean | undefined;
  error: string | undefined;
  handleChange: (e: React.ChangeEvent<any>) => void;
};

const MonthlyMinimumField = (props: Props) => {
  return (
    <TextField
      variant="outlined"
      id="minimumMonthlyPurchase"
      margin="dense"
      label="Monthly Minimum"
      fullWidth
      value={props.value}
      onChange={props.handleChange}
      error={props.touched && Boolean(props.error)}
      name="minimumMonthlyPurchase"
      placeholder="Monthly Minimum"
      // type="number"
      inputProps={{
        min: "0",
        step: "1000",
      }}
      InputProps={{
        inputComponent: MaskedNumberInput as any,
        inputProps: {
          allowNegative: false,
          prefix: "$",
          decimalScale: 0,
        },
      }}
      helperText={props.touched && props.error}
    />
  );
};

export default MonthlyMinimumField;
