import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SupplierDetailsForm from "modals/components/CompanyForms/Details/AddSupplierForm";
import { CompanyType, ICreateCompanyCommand } from "services/api/types";
import companyStore from "stores/shared/company";
import { paths } from "routes/Paths";

type OriginalProps = {
  handleClose: () => void;
  companyType: CompanyType;
};

type Props = OriginalProps & RouteComponentProps;
class ModalAddCompany extends Component<Props> {
  public render() {
    return (
      <SupplierDetailsForm
        newCompanyType={this.props.companyType}
        handleCancel={this.props.handleClose}
        handleCreate={this.handleSubmit}
      />
    );
  }

  private handleSubmit = async (command: ICreateCompanyCommand) => {
    const companyId = await companyStore.createCompany(command);
    this.props.history.push(
      `${paths.app.admin.companyDetails}/${companyId}/overview`
    );
    this.props.handleClose();
  };
}

export default withRouter(ModalAddCompany);
