import React from "react";
import FormControl from "@material-ui/core/FormControl";
import { ICategory } from "services/api/types";
import CategoryDropdown from "modals/components/CompanyForms/Category/CategoryDropdown";

type Props = {
  value: string | undefined;
  touched: boolean | undefined;
  error: string | undefined;
  handleChange: (e: React.ChangeEvent<any>) => void;
  categories: ICategory[];
};

const PrimaryCategoryField = (props: Props) => {
  return (
    <FormControl fullWidth>
      <CategoryDropdown
        name="supplierPrimaryCategoryId"
        label="Primary Category"
        value={props.value}
        error={props.error}
        touched={props.touched}
        handleChange={props.handleChange}
        categories={props.categories}
      />
    </FormControl>
  );
};

export default PrimaryCategoryField;
