import React from "react";
import { RouteComponentProps } from "react-router-dom";
import myStore from "stores/my";
import { Observer } from "mobx-react";
import Layout from "../components/Shell/Layout";

type Props = RouteComponentProps & {
  invoiceId?: string;
};

const Root = (props: Props) => {
  React.useEffect(() => {
    myStore.getMyInfo();
  }, []);

  return (
    <Observer
      render={() => {
        if (myStore.myInfoLoading || !myStore.myInfo) {
          return <div />;
        }

        return <Layout />;
      }}
    />
  );
};

export default Root;
