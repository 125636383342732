import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import { Formik, Field, Form, FormikActions } from "formik";
import { TextField } from "formik-material-ui";
import { changePassword } from "services/api/modules/shared/my";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";

type Props = {
  handleClose: () => void;
  // asdklfkj: () => void;
};

const ChangePasswordModal = (props: Props) => {
  const [errors, setErrors] = React.useState<string[]>([]);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "flex",
        flexDirection: "column",
      },
      errors: {
        color: theme.palette.error.main,
        marginBottom: theme.spacing(2),
      },
      success: {
        color: theme.palette.success.main,
        marginBottom: theme.spacing(2),
      },
    })
  );

  const schema = yup.object().shape({
    currentPassword: yup.string().required("Your current password is required"),
    newPassword: yup.string().required("Please enter a new password"),
    newPasswordConfirmation: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "Passwords do not match")
      .required("Password confirm is required"),
  });

  type FormData = {
    currentPassword: string;
    newPassword: string;
    newPasswordConfirmation: string;
  };

  const submit = async (values: FormData, actions: FormikActions<FormData>) => {
    setErrors([]);
    const result = await changePassword(
      values.currentPassword,
      values.newPassword
    );

    if (result.succeeded) {
      setShowSuccessMessage(true);
      window.setTimeout(() => {
        // props.handleClose();
      }, 2500);
    } else {
      actions.setSubmitting(false);
      setErrors(result.errors.map((e) => e.description));
    }
  };

  const renderSuccess = () => {
    if (!showSuccessMessage) return undefined;

    return (
      <div className={classes.success}>Your password has been changed!</div>
    );
  };

  const renderErrors = () => {
    if (errors.length === 0) return undefined;

    return (
      <div className={classes.errors}>
        {errors.map((error, index) => {
          return (
            <div style={{ color: "red" }} key={index}>
              {error}
            </div>
          );
        })}
      </div>
    );
  };

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Formik
        initialValues={{
          currentPassword: "",
          newPassword: "",
          newPasswordConfirmation: "",
        }}
        validationSchema={schema}
        onSubmit={submit}
        render={(formikProps) => {
          return (
            <Form>
              <ResponsiveModalShell
                handleClose={props.handleClose}
                title="Change Password"
                disableSaveButton={formikProps.isSubmitting}
                handleSave={() => formikProps.submitForm()}
              >
                {renderErrors()}
                {renderSuccess()}
                <Field
                  name="currentPassword"
                  variant="outlined"
                  autoComplete="new-password"
                  disabled={formikProps.isSubmitting}
                  label="Current Password"
                  fullWidth={true}
                  margin="dense"
                  inputProps={{
                    type: "password",
                  }}
                  component={TextField}
                />
                <Field
                  name="newPassword"
                  variant="outlined"
                  disabled={formikProps.isSubmitting}
                  label="New Password"
                  autoComplete="new-password"
                  fullWidth={true}
                  margin="dense"
                  inputProps={{
                    type: "password",
                  }}
                  component={TextField}
                />
                <Field
                  inputProps={{
                    type: "password",
                  }}
                  name="newPasswordConfirmation"
                  label="Confirm New Password"
                  disabled={formikProps.isSubmitting}
                  autoComplete="new-password"
                  fullWidth={true}
                  margin="dense"
                  variant="outlined"
                  component={TextField}
                />
              </ResponsiveModalShell>
            </Form>
          );
        }}
      ></Formik>
    </div>
  );
};

export default ChangePasswordModal;
