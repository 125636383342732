import React from "react";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import authService from "components/api-authorization/AuthorizeService";

type Props = {
  groupName: "Administrators" | "CompanyUsers";
  children: React.ReactNode;
};

type SignalRContextDef = {
  connection: HubConnection;
  // Added to use in useEffect depenedancy array
  isConnected: boolean;
};

export const SignalRContext = React.createContext<SignalRContextDef | null>(
  null
);

const SignalRConnection = (props: Props) => {
  const [connection, setConnection] = React.useState<HubConnection>();
  const [isConnected, setIsConnected] = React.useState(false);

  React.useEffect(() => {
    const connectSignalR = async () => {
      const token = await authService.getAccessToken();
      if (!token) {
        return;
      }
      const hubConnectionOptions = {
        accessTokenFactory: () => {
          return token;
        },
      };

      const hubConnection = new HubConnectionBuilder()
        .withUrl("/hubs/main", hubConnectionOptions)
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();

      setConnection(hubConnection);
    };

    connectSignalR();
  }, []);

  React.useEffect(() => {
    if (!connection) return;

    const stopConnection = async () => {
      console.log("**** Stopping SignalR connection");
      await connection.stop();
      console.log("**** SignalR connection stopped");
    };

    connection
      .start()
      .then(() => {
        setIsConnected(true);
        connection.invoke("JoinGroup", props.groupName);
      })
      .catch((error) => alert(error));

    connection.on("ShowAlert", (message) => alert(message));
    // vv Changed
    return () => {
      stopConnection();
    };
  }, [connection, props.groupName]);

  if (!connection) {
    return <div></div>;
  }

  return (
    <SignalRContext.Provider value={{ connection, isConnected }}>
      {props.children}
    </SignalRContext.Provider>
  );
};

export default SignalRConnection;
