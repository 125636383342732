import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

type Props = {
  title: string;
  children: React.ReactNode;
};

const MobileGroupHeader = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      mobileGroupHeaderRoot: {
        marginBottom: theme.spacing(2),
      },
      title: {
        // margin: theme.spacing(4, 0, 2, 0),
        display: "block",
        padding: "3px 6px",
      },
      content: {
        paddingLeft: theme.spacing(1),
      },
    })
  );

  const classes = useStyles();

  return (
    <div className={classes.mobileGroupHeaderRoot}>
      <Typography
        classes={{
          root: classes.title,
        }}
        variant="caption"
        color="primary"
      >
        {props.title}
      </Typography>
      <div className={classes.content}>{props.children}</div>
    </div>
  );
};

export default MobileGroupHeader;
