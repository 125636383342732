import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Observer } from "mobx-react";
import enumerableStore from "stores/enumerable";
import CompanyTabsHost from "./components/CompanyTabsHost";
import CompanyHeader from "./components/CompanyHeader";
import Page from "components/Shell/Page";

import companyStore from "stores/shared/company";
import myStore from "stores/my";
import { IAccountInfo } from "services/api/types";

type Props = {
  shiftUp?: boolean;
} & RouteComponentProps<{ companyId?: string; eventId?: string }>;

const CompanyDetails = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        transform: props.shiftUp ? "translateY(-43px)" : "unset",
      },
      detailsHost: {
        display: "grid",
        gridTemplateRows: "auto 1fr",
        height: "100%",
        [theme.breakpoints.down("md")]: {
          gridRowGap: "1em",
        },
      },
      header: {
        gridRow: 1,
      },
      tabCol: {
        gridRow: 2,
        height: "100%",
        [theme.breakpoints.down("md")]: {
          overflowX: "hidden",
        },
      },
    })
  );

  const classes = useStyles();

  useEffect(() => {
    const companyId =
      props.match.params.companyId ?? myStore.currentCompany!.companyId;

    companyStore.setCompanyId(companyId);
  }, [props.match.params.companyId]);

  useEffect(() => {
    enumerableStore.ensureMarketsFetched();
    enumerableStore.ensureCategoriesFetched();
  }, []);

  const handleLogoUploaded = async (url: string) => {
    await companyStore.updateLogo(url);
  };

  const handleImageRemoved = async () => {
    await companyStore.updateLogo("");
  };

  const handleAccountChange = async (account: IAccountInfo) => {
    await companyStore.setCurrentAccount(account);
  };

  return (
    <Observer
      render={() => {
        const alertCount =
          companyStore.details && myStore.isAdminNotImpersonating
            ? companyStore.details.notes.filter((n) => n.isImportant).length
            : undefined;

        const company = companyStore.details;
        const currentAccount = companyStore.currentAccount;

        // HACK: Leave here. Hack to make sure we're observing club points
        // without it the points don't update in the header.
        const x = companyStore.details?.clubPoints ?? 0;
        console.log(x);

        return (
          <Page
            maxWidth="xl"
            nameForDebugging="Company Details"
            isLoading={companyStore.details === undefined}
            isLoaded={companyStore.details !== undefined}
            title={companyStore.details?.name ?? "Company Details"}
            render={() => {
              return companyStore.details && currentAccount ? (
                <div className={classes.root}>
                  <div className={classes.detailsHost}>
                    {props.match.params.eventId === undefined && (
                      <div className={classes.header}>
                        <CompanyHeader
                          currentAccountId={currentAccount.id}
                          onChangeAccount={handleAccountChange}
                          alertCount={alertCount}
                          company={company!}
                          onImageUploaded={handleLogoUploaded}
                          onRemoveImage={handleImageRemoved}
                        />
                      </div>
                    )}
                    <div className={classes.tabCol}>
                      <CompanyTabsHost
                        eventId={props.match.params.eventId}
                        companyId={companyStore.details!.id}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div />
              );
            }}
          ></Page>
        );
      }}
    />
  );
};

export default CompanyDetails;
