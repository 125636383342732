import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import { formatNumber, formatMoney } from "utils/number";
import { Observer } from "mobx-react";
import {
  PaymentType,
  IMonthlyReportPayment,
  INewMonthlyReportPayment,
} from "services/api/types";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CheckPaymentForm from "../Payments/CheckPaymentForm";
import PointsPaymentForm from "../Payments/PointsPaymentForm";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";

import { Form } from "formik";

type Props = {
  handleClose: () => void;
  paymentId?: string;
  companyInfo: {
    name: string;
    identifier: string;
    computedBalance: number;
  };
  payment?: IMonthlyReportPayment;
  mode: "Add" | "Edit";
  addPayment: (payment: INewMonthlyReportPayment) => void;
  updatePayment: (payment: IMonthlyReportPayment) => void;
  costPerPoint: number;
  balanceDueDollars?: number;
  monthlyReportId: string;
};

const AddEditMonthlyReportPaymentModal = (props: Props) => {
  const [paymentType, setPaymentType] = React.useState<PaymentType>(
    props.payment?.paymentType ?? "Check"
  );

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      subForm: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridGap: theme.spacing(2),
        alignItems: "center",
      },
      fullWidth: {
        gridColumn: "1 / 5",
      },
      formLabel: {
        fontSize: ".9rem",
      },
      radios: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(2),
        cursor: props.payment ? "not-allowed" : "unset",
      },
      paymentTypeLabel: {
        marginRight: theme.spacing(2),
      },
      subtitle: {
        color: theme.palette.primary.main,
      },
    })
  );

  const saveCheckPayment = async (
    amount: number,
    checkNumber: string,
    date: Date
  ) => {
    props.handleClose();
    const { monthlyReportId } = props;
    if (!props.payment) {
      props.addPayment({
        amount,
        checkNumber,
        date: date!,
        paymentType,
        monthlyReportId,
      });
    } else {
      props.updatePayment({
        amount,
        checkNumber,
        date: date!,
        paymentType,
        monthlyReportId,
        id: props.payment!.id,
      });
    }
  };

  const savePointPayment = async (
    points: number,
    dollarAmount: number,
    date: Date
  ) => {
    props.handleClose();
    const { monthlyReportId } = props;

    if (!props.payment) {
      props.addPayment({
        amount: dollarAmount,
        points,
        checkNumber: "",
        date: date!,
        monthlyReportId,
        paymentType,
      });
    } else {
      props.updatePayment({
        amount: dollarAmount,
        points,
        checkNumber: "",
        date: date!,
        monthlyReportId,
        paymentType,
        id: props.payment!.id,
      });
    }
  };

  const handlePaymentTypeChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setPaymentType(event.target.value as any);
  };

  const renderCheckForm = () => {
    return (
      paymentType === "Check" && (
        <CheckPaymentForm
          payment={props.payment}
          onSave={saveCheckPayment}
          onCancel={props.handleClose}
        />
      )
    );
  };

  const renderPointsForm = () => {
    const showForm = paymentType === "Points";

    return (
      showForm && (
        <PointsPaymentForm
          costPerPoint={props.costPerPoint}
          pointBalance={props.companyInfo.computedBalance}
          payment={props.payment}
          onSave={savePointPayment}
          onCancel={props.handleClose}
          mode={props.mode}
        />
      )
    );
  };

  const renderTypeRadios = () => {
    return (
      <div className={classes.radios}>
        <FormLabel className={classes.paymentTypeLabel}>
          Payment Type:
        </FormLabel>
        <FormControlLabel
          classes={{ label: classes.formLabel }}
          value="Check"
          control={
            <Radio disabled={props.payment !== undefined} size="small" />
          }
          label="Check"
        />
        <FormControlLabel
          classes={{ label: classes.formLabel }}
          value="Points"
          control={
            <Radio disabled={props.payment !== undefined} size="small" />
          }
          label="Points"
        />
      </div>
    );
  };

  const classes = useStyles();

  return (
    <Observer
      render={() => {
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ResponsiveModalShell
              hideDoneButton={true}
              handleClose={props.handleClose}
              title={
                props.payment
                  ? "Edit Monthly Report Payment"
                  : "Add Monthly Report Payment"
              }
            >
              <Form>
                <div
                  className={classes.subtitle}
                >{`from ${props.companyInfo.name} (${props.companyInfo.identifier})`}</div>
                <div
                  className={classes.subtitle}
                >{`Point Balance: ${formatNumber(
                  props.companyInfo.computedBalance
                )}`}</div>
                {props.balanceDueDollars !== undefined ? (
                  <div
                    className={classes.subtitle}
                  >{`Balance Due: ${formatMoney(
                    props.balanceDueDollars
                  )}`}</div>
                ) : undefined}
                <RadioGroup
                  row={true}
                  onChange={handlePaymentTypeChange}
                  value={paymentType}
                  name="payment-type"
                >
                  {renderTypeRadios()}
                </RadioGroup>
                {renderCheckForm()}
                {renderPointsForm()}
              </Form>
            </ResponsiveModalShell>
          </MuiPickersUtilsProvider>
        );
      }}
    />
  );
};

export default AddEditMonthlyReportPaymentModal;
