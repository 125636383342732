import React from "react";
import ResponsiveDrawer from "components/Shell/ResponsiveDrawer";
import MemberSidebar from "./MemberSidebar";
import MemberToolbarItems from "./MemberToolbarItems";
import SignalRConnection from "areas/Shared/components/SignalRConnection";
import appTextStore from "stores/shared/appText";

type Props = {
  children: React.ReactNode;
};

const MemberDrawer = (props: Props) => {
  React.useEffect(() => {
    appTextStore.ensureAllAppTextLoaded();
  }, []);

  return (
    <SignalRConnection groupName="CompanyUsers">
      <ResponsiveDrawer
        renderMobileToolbarItems={() => <MemberToolbarItems />}
        renderDesktopToolbarItems={() => <MemberToolbarItems />}
        renderSidebarContent={() => <MemberSidebar />}
      >
        {props.children}
      </ResponsiveDrawer>
    </SignalRConnection>
  );
};

export default MemberDrawer;
