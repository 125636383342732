import { apiClient } from "services/api/client";
import {
  ICompanyUser,
  ISendRemindersResult,
  IAdminUser,
  ILoggedInUserInfo,
  UserListType,
} from "services/api/types";

const USERS_URL = "administrator/users";
const ADMIN_USERS_URL = "administrator/administrators";

export const getUserList = async (listType: UserListType) => {
  const response = await apiClient.get<ICompanyUser[]>(`${USERS_URL}/list`, {
    params: {
      listType,
    },
  });
  return response.data;
};

export const searchUsres = async (
  searchTerm: string,
  includeDisabled: boolean
) => {
  const response = await apiClient.get<ICompanyUser[]>(`${USERS_URL}/search`, {
    params: {
      searchTerm,
      includeDisabled,
    },
  });

  return response.data;
};

export const getAdministrators = async (showEnabledOnly: boolean) => {
  const response = await apiClient.get<IAdminUser[]>(ADMIN_USERS_URL, {
    params: {
      showEnabledOnly,
    },
  });
  return response;
};

export const disableAdminUserAccount = async (userId: string) => {
  const url = `administrator/disabledAdminAccounts/${userId}`;
  const response = await apiClient.post<IAdminUser>(url);
  return response.data;
};

export const deleteAdminUserAccount = async (userId: string) => {
  const url = `${ADMIN_USERS_URL}/${userId}`;
  const response = await apiClient.delete(url);
  return response;
};

export const createAdminUserAccount = async (user: IAdminUser) => {
  const response = await apiClient.post<IAdminUser>(ADMIN_USERS_URL, user);
  return response;
};

export const updateAdminUserAccount = async (user: IAdminUser) => {
  const url = `${ADMIN_USERS_URL}/${user.id}`;
  const response = await apiClient.put<IAdminUser>(url, user);
  return response;
};

export const enableAdminUserAccount = async (userId: string) => {
  const url = `administrator/enabledAdminAccounts/${userId}`;
  const response = await apiClient.post<IAdminUser>(url);
  return response.data;
};

export const sendReminders = async () => {
  const url = `${USERS_URL}/sendReminders`;
  const response = await apiClient.post<ISendRemindersResult>(url);
  return response;
};

export const sendReminderByCreatingDate = async (args: {
  startDate: Date;
  endDate: Date;
}) => {
  const url = `${USERS_URL}/sendRemindersByDate`;
  const response = await apiClient.post(url, args);
  return response;
};

export const getUserInfo = async (userId: string) => {
  const url = `${USERS_URL}/${userId}/info`;
  const response = await apiClient.get<ILoggedInUserInfo>(url);
  return response.data;
};
