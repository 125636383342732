import { action, observable } from "mobx";
import api from "services/api";
import { IGlobalSearchResponse } from "services/api/types";
import { debounceDecorator } from "utils/helpers";

export class SearchStore {
  @observable
  public loading = false;

  @observable
  public results: IGlobalSearchResponse = {
    contacts: [],
    suppliers: [],
    members: [],
  };

  @observable
  public activeQuery = "";

  @observable
  public nextQuery = "";

  @observable
  public activeOnly = true;

  @action.bound
  public setActiveOnly(value: boolean) {
    this.activeOnly = value;
    this.runQuery(true);
  }

  @action.bound
  public setQuery(query: string): void {
    this.nextQuery = query;

    if (query.length === 0) {
      this.loading = false;
      this.activeQuery = "";
      this.results = {
        contacts: [],
        suppliers: [],
        members: [],
      };
    } else {
      this.runQuery();
    }
  }

  @action.bound
  @debounceDecorator(1000, false)
  public async runQuery(force = false) {
    const query = this.nextQuery;
    if (query === this.activeQuery && !force) return;
    if (query.length > 0) {
      this.loading = true;
      this.activeQuery = query;
      const response = await api.admin.search.globalSearch({
        query,
        activeOnly: this.activeOnly,
      });

      if (this.activeQuery === query) {
        this.loading = false;

        this.results = response.data;
      }
    } else {
      this.loading = false;
      this.activeQuery = "";
      this.results = {
        contacts: [],
        suppliers: [],
        members: [],
      };
    }
  }
}

export default new SearchStore();
