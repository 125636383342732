import { apiClient } from "services/api/client";
import { ILoggedInUserInfo, ICompanyLocation } from "services/api/types";

const MY_URL = "core/my";

export const getMyInfo = async () => {
  const url = `${MY_URL}/info`;
  const response = await apiClient.get<ILoggedInUserInfo>(url);
  return response;
};

export const getMyShippingAdresses = async () => {
  const url = `${MY_URL}/shippingAddresses`;
  return await apiClient.get<ICompanyLocation[]>(url);
};
