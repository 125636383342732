import React from "react";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import EventForm from "../components/EventForm";
import {
  INewEventData,
  IEventDetails,
  IExistingEventData,
} from "services/api/types";
import eventsStore from "stores/admin/events";
import enumStore from "stores/enumerable";
import { Observer } from "mobx-react";

type Props = {
  mode: "Add" | "Edit";
  handleClose: () => void;
  event?: IEventDetails;
  onEventCreated?: (eventId: string) => void;
  eventCostPerPoint: number;
};

const AddEditEventModal = (props: Props) => {
  React.useEffect(() => {
    enumStore.ensureEventTypesFetched();
  }, []);

  const handleSaveNewEvent = async (event: INewEventData) => {
    const newEvent = await eventsStore.saveNewEvent(event);
    props.handleClose();

    if (newEvent && props.onEventCreated) {
      props.onEventCreated(newEvent.id);
    }
  };

  const handleSaveExistingEvent = async (event: IExistingEventData) => {
    await eventsStore.updateEvent(event);
    props.handleClose();
  };

  return (
    <Observer
      render={() => {
        const eventTypes = enumStore.getEnum("eventTypes");
        if (eventTypes.length === 0) return <div />;

        return (
          <ResponsiveModalShell
            handleClose={props.handleClose}
            hideDoneButton={true}
            title={props.mode === "Add" ? "Create Event" : "Edit Event"}
          >
            <EventForm
              event={props.event}
              eventCostPerPoint={props.eventCostPerPoint}
              eventTypes={enumStore.getEnum("eventTypes")}
              handleClose={props.handleClose}
              saveNewEvent={handleSaveNewEvent}
              saveExistingEvent={handleSaveExistingEvent}
            />
          </ResponsiveModalShell>
        );
      }}
    />
  );
};

export default AddEditEventModal;
