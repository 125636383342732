import React from "react";
import MobileGroupHeader from "components/Shell/MobileGroupHeader";
import { IBrand } from "services/api/types";
import Typography from "@material-ui/core/Typography";
import NoDataMessage from "components/Shell/NoDataMessage";

type Props = {
  brands: IBrand[];
};

const BrandsInfoBlock = (props: Props) => {
  return (
    <MobileGroupHeader title="Brands">
      {props.brands.map((b) => (
        <Typography variant="body2" key={b.id}>
          {b.name}
        </Typography>
      ))}
      {props.brands.length === 0 && <NoDataMessage message="No brands found" />}
    </MobileGroupHeader>
  );
};

export default BrandsInfoBlock;
