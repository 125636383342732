import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import moment from "moment";

type Props = {
  date: Date | string | undefined;
  hideTime?: boolean;
};

const DateFormat = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
    })
  );

  const classes = useStyles();
  const format = props.hideTime ? "MM/DD/YY" : "MM/DD/YY h:mm a";

  return (
    <span className={classes.root}>
      {props.date ? moment(props.date).format(format) : "---"}
    </span>
  );
};

export default DateFormat;
