import { Form, Formik, FormikActions } from "formik";
import React from "react";
import { Omit } from "utils/type";
import { defaultErrorHandler } from "services/api/errors";
import { ICompanyNotePayload, INote } from "services/api/types";
import * as yup from "yup";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import ContentField from "./ContentField";
import IsImportantCheckbox from "./IsImportantCheckbox";

type FormValues = {
  content: string;
  isImportant: boolean;
};

type Props = {
  companyNote?: INote;
  handleCancel: () => void;
  handleSubmit: (
    payload: Omit<ICompanyNotePayload["companyNote"], "companyId">
  ) => void;
  formType: "add" | "update";
};

const FormCompanyNote = (props: Props) => {
  const handleFormSubmit = async (
    values: FormValues,
    actions: FormikActions<FormValues>
  ) => {
    const notePayload = {
      content: values.content,
      isImportant: values.isImportant,
    };
    try {
      await props.handleSubmit(notePayload);
    } catch (err) {
      defaultErrorHandler(err);
    }
    actions.setSubmitting(false);
  };

  const schema = yup.object().shape({
    content: yup.string().required("Content is required"),
    isImportant: yup.boolean().required(),
  });

  const initialValues = {
    content: props.companyNote?.content ?? "",
    isImportant: props.companyNote?.isImportant ?? false,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleFormSubmit}
    >
      {(formikProps) => (
        <Form>
          <ResponsiveModalShell
            handleClose={props.handleCancel}
            title={props.formType === "add" ? "Add Note" : "Update Note"}
            disableSaveButton={formikProps.isSubmitting}
            handleSave={() => formikProps.submitForm()}
          >
            <ContentField
              error={formikProps.errors.content}
              touched={formikProps.touched.content}
              value={formikProps.values.content}
              handleChange={formikProps.handleChange}
            />
            <IsImportantCheckbox
              value={formikProps.values.isImportant}
              handleChange={formikProps.handleChange}
            />
          </ResponsiveModalShell>
        </Form>
      )}
    </Formik>
  );
};

export default FormCompanyNote;
