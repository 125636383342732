import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { CompanyTypeForExports } from "services/api/types";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import api from "services/api";

type Props = {
  handleClose: () => void;
  defaultValues?: {
    nonVipMembersChecked?: boolean;
    vipMmbersChecked?: boolean;
    sponsorsChecked?: boolean;
    competitorsChecked?: boolean;
    prospectsChecked?: boolean;
    activeChecked?: boolean;
    inactiveChecked?: boolean;
  };
};

const CompanyExportOptionsModal = (props: Props) => {
  const [nonVipMembersChecked, setNonVipMembersChecked] = React.useState(
    props.defaultValues?.nonVipMembersChecked ?? false
  );
  const [vipMmbersChecked, setVipMmbersChecked] = React.useState(
    props.defaultValues?.vipMmbersChecked ?? false
  );
  const [sponsorsChecked, setSponsorsChecked] = React.useState(
    props.defaultValues?.sponsorsChecked ?? false
  );
  const [competitorsChecked, setCompetitorsChecked] = React.useState(
    props.defaultValues?.competitorsChecked ?? false
  );
  const [prospectsChecked, setProspectsChecked] = React.useState(
    props.defaultValues?.prospectsChecked ?? false
  );
  const [activeChecked, setActiveChecked] = React.useState(true);
  const [inactiveChecked, setInactiveChecked] = React.useState(
    props.defaultValues?.inactiveChecked ?? false
  );
  const [separateWorksheets, setSeparateWorksheets] = React.useState(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      checkboxHost: {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        width: 350,
      },
    })
  );

  const classes = useStyles();

  const handleSave = async () => {
    const companyTypes: CompanyTypeForExports[] = [];

    for (const type of getCompanyTypes()) {
      companyTypes.push(type);
    }

    const includingAllStatuses = inactiveChecked && activeChecked;

    await api.admin.reports.exportCompanies({
      companyTypes: companyTypes,
      inactivesInSeparateWorksheet: includingAllStatuses
        ? separateWorksheets
        : null,
      includeActive: activeChecked,
      includeInactive: inactiveChecked,
    });
  };

  function* getCompanyTypes(): Generator<CompanyTypeForExports> {
    if (vipMmbersChecked) {
      yield "VipMember";
    }

    if (nonVipMembersChecked) {
      yield "NonVipMember";
    }

    if (sponsorsChecked) {
      yield "Sponsor";
    }

    if (prospectsChecked) {
      yield "Prospect";
    }

    if (competitorsChecked) {
      yield "Competitor";
    }
  }

  const renderCheckbox = (
    label: string,
    checked: boolean,
    disabled: boolean,
    setChecked: (value: boolean) => void
  ) => {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            disabled={disabled}
            onChange={(e) => {
              setChecked(e.target.checked);
            }}
          />
        }
        label={<div>{label}</div>}
      />
    );
  };

  const renderStatuses = () => {
    return (
      <div className={classes.checkboxHost}>
        {renderCheckbox("Active", activeChecked, false, setActiveChecked)}
        {renderCheckbox("Inactive", inactiveChecked, false, setInactiveChecked)}
      </div>
    );
  };

  const renderExcelOptions = () => {
    return (
      <div className={classes.checkboxHost}>
        {renderCheckbox(
          "Put Inactives in their own Worksheet",
          separateWorksheets,
          !(activeChecked && inactiveChecked),
          setSeparateWorksheets
        )}
      </div>
    );
  };

  const renderCompanyTypes = () => {
    return (
      <div className={classes.checkboxHost}>
        {renderCheckbox(
          "Members (Non-VIP)",
          nonVipMembersChecked,
          false,
          setNonVipMembersChecked
        )}
        {renderCheckbox(
          "Members (VIP)",
          vipMmbersChecked,
          false,
          setVipMmbersChecked
        )}
        {renderCheckbox("Sponsors", sponsorsChecked, false, setSponsorsChecked)}
        {renderCheckbox(
          "Prospects",
          prospectsChecked,
          false,
          setProspectsChecked
        )}
        {renderCheckbox(
          "Competitors",
          competitorsChecked,
          false,
          setCompetitorsChecked
        )}
      </div>
    );
  };

  const canSave =
    (vipMmbersChecked ||
      nonVipMembersChecked ||
      sponsorsChecked ||
      competitorsChecked ||
      prospectsChecked) &&
    (inactiveChecked || activeChecked);

  return (
    <ResponsiveModalShell
      handleSave={handleSave}
      saveButtonCaption="Export"
      disableSaveButton={!canSave}
      title="Company Export Options"
      handleClose={props.handleClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography color="primary" component="div" variant="caption">
            Company Types
          </Typography>
          {renderCompanyTypes()}
        </Grid>
        <Grid item xs={6}>
          <Typography color="primary" component="div" variant="caption">
            Status
          </Typography>
          {renderStatuses()}
          <br />
          <br />
          <Typography color="primary" component="div" variant="caption">
            Excel Options
          </Typography>
          {renderExcelOptions()}
        </Grid>
      </Grid>
    </ResponsiveModalShell>
  );
};

export default CompanyExportOptionsModal;
