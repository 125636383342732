import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";

const Privacy = () => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        "& h1": {
          fontFamily: "Oswald, sans-serif",
          fontWeight: 400,
          color: theme.typography.h1.color,
        },
        "& h2": {
          fontFamily: "Oswald, sans-serif",
          fontWeight: 400,
          color: theme.typography.h2.color,
        },
      },
      container: {
        padding: theme.spacing(0, 4),
      },
    })
  );

  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <h1>Privacy Policy</h1>
        <p>
          Builders Club North has a strict policy of guarding its clients'
          privacy. We have created this privacy statement in order to
          demonstrate our firm commitment to privacy. The following discloses
          the information gathering and dissemination practices for the Builders
          Club North website.
        </p>
        <h2>Cookies</h2>
        <p>
          To better our users, the Builders Club North website uses cookies to
          deliver content specific to the users' interests. In addition, some of
          our online services use cookies to maintain the consistency of the
          items in the shopping cart as the users navigate our online Point
          redemption engines.
        </p>
        <h2>Personal Information</h2>
        <p>
          Builders Club North only collects relevant information to verify
          users' identity and to better serve Members. Builders Club North does
          not share your information with third parties.
        </p>
        <h2>External Links</h2>
        <p>
          This site contains links to other sites. Builders Club North is not
          responsible for the privacy practices or the content of such Web
          sites.
        </p>
        <h2>Security</h2>
        <p>
          This site has strong security measures (SSL) in place to protect the
          loss, misuse, and alteration of the information under our control. The
          only logged information is accessible only to administrators with
          logins to our internal servers.
        </p>
        <h2>Contacting the Web Site</h2>
        <p>
          If you have any questions about this privacy statement, the practices
          of this site, or any technical aspect of this site, you can contact:
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`mailto:webmaster@buildersclubnorth.com`}
          >
            webmaster@buildersclubnorth.com
          </a>
        </p>
        <div style={{ textAlign: "center" }}>
          <Button
            size="large"
            color="primary"
            variant="contained"
            onClick={() => history.push("/")}
          >
            Home
          </Button>
        </div>
      </Container>
    </div>
  );
};

export default Privacy;
