import React from "react";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

type Props = {
  touched: boolean | undefined;
  error: string | undefined;
  handleChange: (e: React.ChangeEvent<any>) => void;
  value: string | undefined;
  addressTypes: string[];
};

const AddressTypeField = (props: Props) => {
  return (
    <FormControl fullWidth>
      <TextField
        select
        variant="outlined"
        id="addressType"
        margin="dense"
        label="Address Type"
        error={props.touched && Boolean(props.error)}
        name="addressType"
        onChange={props.handleChange}
        value={props.value}
        helperText={props.touched && props.error}
      >
        {props.addressTypes.map((tnt) => {
          return (
            <MenuItem key={tnt} value={tnt}>
              {tnt}
            </MenuItem>
          );
        })}
      </TextField>
    </FormControl>
  );
};

export default AddressTypeField;
