import React from "react";
import ReactDOM from "react-dom";

type Props = {
  children: React.ReactNode;
};

const DialogActionsPortal = (props: Props) => {
  const actionsPortal = document.getElementById("mobile-dialog-actions-portal");

  return ReactDOM.createPortal(<>{props.children}</>, actionsPortal!);
};

export default DialogActionsPortal;
