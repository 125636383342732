import { apiClient } from "services/api/client";
import { IRecentCompanies } from "services/api/types";

const MY_URL = "core/my";

export const getRecentCompanies = async () => {
  const url = `${MY_URL}/recentCompanies`;
  const response = await apiClient.post<IRecentCompanies>(url);
  return response;
};
