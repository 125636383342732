import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import api from "services/api";
import MemberInfo from "areas/Shared/components/MemberInfo";
import { IPurchaserInfo } from "services/api/types";

type Props = { companyId?: string };

const MemberInfoWrapper = (props: Props) => {
  const [memberInfo, setMemberInfo] = React.useState<
    IPurchaserInfo | undefined
  >();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
    })
  );

  React.useEffect(() => {
    const loadInfo = async (companyId: string) => {
      const result = await api.sponsors.purchasers.getInfo(companyId);
      setMemberInfo(result);
    };

    if (props.companyId) {
      loadInfo(props.companyId);
    } else {
      setMemberInfo(undefined);
    }
  }, [props.companyId]);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      {memberInfo && <MemberInfo disableLinks={true} memberInfo={memberInfo} />}
    </div>
  );
};

export default MemberInfoWrapper;
