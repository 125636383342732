import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { INewspaper } from "services/api/types";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { getMonthName } from "utils/date";
import api from "services/api";
import ReactMarkdown from "react-markdown";

type Props = {
  text: string;
};

const AboutUs = (props: Props) => {
  const [newspapers, setNewspapers] = React.useState<
    INewspaper[] | undefined
  >();
  const [latestNewspaperUrl, setLatestNewspaperUrl] = React.useState<
    string | undefined
  >();
  const [newspaperId, setNewspaperId] = React.useState<string>("-1");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        padding: "20px",
        background: "rgba(255, 255, 255, 0.95)",
        "& h2": {
          fontFamily: "Oswald, sans-serif",
          fontWeight: 400,
          color: "#1B61B1",
          textAlign: "center",
          margin: ".2em 0",
          [theme.breakpoints.between("xs", "lg")]: {
            fontSize: "2em",
          },
          [theme.breakpoints.between("lg", "xl")]: {
            fontSize: "2.5em",
          },
        },
      },
      body: {
        color: "#38397D",
      },
      header: {
        padding: "0 5%",
        display: "grid",
        [theme.breakpoints.between("xs", "sm")]: {
          gridTemplateColumns: "1fr",
          textAlign: "center",
          gridGap: 30,
        },
        [theme.breakpoints.between("sm", "xl")]: {
          gridTemplateColumns: "1fr auto",
          textAlign: "unset",
          gridGap: 0,
        },
      },
      newspaperImg: {
        width: "100px",
        display: "block",
        margin: "0 10px",
      },
      rightStuff: {
        // transform: "translateY(-25px)",
        display: "flex",
        maxWidth: 300,
        flexWrap: "wrap",
        [theme.breakpoints.between("xs", "sm")]: {
          paddingLeft: 0,
        },
        [theme.breakpoints.between("sm", "xl")]: {
          paddingLeft: 20,
        },
      },
      anchor: {
        textDecoration: "none",
        fontSize: "large",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
      },
      disabled: {
        opacity: 0,
        pointerEvents: "none",
      },
      formControl: {
        margin: theme.spacing(1),
        minWidth: 140,
        textAlign: "left",
      },
      newspaperDropdown: {
        minWidth: 220,
      },
      pastIssueHost: {
        marginTop: "2em",
        display: "flex",
        alignItems: "center",
      },
      currentIssueHost: {},
    })
  );

  React.useEffect(() => {
    const load = async () => {
      const result = await api.shared.newspapers.getAllNewspapers();
      const latest = result.newspapers.find(
        (np) => np.id === result.currentNewspaperId
      );
      setLatestNewspaperUrl(latest!.url);
      setNewspapers(result.newspapers);
    };

    load();
  }, []);

  const getMenuItemText = (newspaper: INewspaper) => {
    const { endMonth, endYear, startMonth, startYear } = newspaper;

    if (endMonth === startMonth && endYear === startYear) {
      return `${getMonthName(startMonth)} ${startYear}`;
    }

    if (startYear === endYear) {
      return `${getMonthName(startMonth)}/${getMonthName(endMonth)} ${endYear}`;
    }

    return `${getMonthName(startMonth)} ${startYear}/${getMonthName(
      endMonth
    )} ${endYear}`;
  };

  const renderNewspapersDropdown = () => {
    if (newspapers === undefined) return undefined;
    return (
      <FormControl
        margin="dense"
        variant="outlined"
        className={classes.formControl}
      >
        <InputLabel id="issue-label">Past Issues</InputLabel>
        <Select
          labelId="issue-label"
          id="issue-select"
          className={classes.newspaperDropdown}
          value={newspaperId}
          onChange={(evt) => setNewspaperId(evt.target.value as string)}
          label="Past Issues"
        >
          <MenuItem value="-1">
            <em>Select Issue</em>
          </MenuItem>
          {newspapers.map((n) => {
            return (
              <MenuItem key={n.id} value={n.id}>
                {getMenuItemText(n)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  const classes = useStyles();

  const renderOpenButton = () => {
    return (
      <FormControl>
        <Button
          disabled={newspaperId === "-1"}
          size="small"
          onClick={() => {
            const newspaper = newspapers?.find((n) => n.id === newspaperId);
            if (newspaper) {
              window.open(newspaper.url, "_blank");
            }
          }}
          variant="contained"
        >
          Open
        </Button>
      </FormControl>
    );
  };

  return (
    <div className={classes.root}>
      <h2>About Us</h2>
      <div className={classes.header}>
        <ReactMarkdown className={classes.body}>{props.text}</ReactMarkdown>
        <div className={classes.rightStuff}>
          {latestNewspaperUrl ? (
            <>
              <div className={classes.currentIssueHost}>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.anchor}
                  href={latestNewspaperUrl}
                >
                  <img
                    className={classes.newspaperImg}
                    src="/images/BC Newspaper.png"
                    alt="Builders Club Newspaper"
                  />
                  Click Here For Latest Newspaper
                </a>
              </div>
              <div className={classes.pastIssueHost}>
                {renderNewspapersDropdown()}
                {renderOpenButton()}
              </div>
            </>
          ) : undefined}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
