import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { extractRootDomain, getFavIconPath } from "utils/urls";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import uiStore from "stores/ui";
import myStore from "stores/my";
import { CompanyType } from "services/api/types";

type ReadOnlyProps = {
  website: string | undefined;
  companyName: string;
  size: "large" | "small";
  imageUri?: string;
};

type ChangeProps = ReadOnlyProps & {
  onImageUploaded: (url: string) => Promise<void>;
  onRemoveImage: () => void;
  companyType: CompanyType;
  companyId: string;
};

const isChangeProps = (
  props: ReadOnlyProps | ChangeProps
): props is ChangeProps => {
  return (props as ChangeProps).onImageUploaded !== undefined;
};

const CompanyAvatar = (props: ReadOnlyProps | ChangeProps) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      avatar: {
        height: props.size === "large" ? 80 : 40,
        width: props.size === "large" ? 80 : 40,
      },
      uploadedLogo: {
        maxHeight: 90,
        maxWidth: 120,
      },
    })
  );

  const classes = useStyles();

  const rootDomain = props.website
    ? extractRootDomain(props.website)
    : undefined;

  const handleChangeImage = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(null);
    uiStore.showModal({
      type: "AddUpdateContactPhotoModal",
      props: {
        handleFileAdded: async (url) => {
          if (isChangeProps(props)) {
            await props.onImageUploaded(url);
            if (props.companyType === "Sponsor") {
              await uiStore.loadSponsorInfo(props.companyId);
            } else if (props.companyType === "Member") {
              await uiStore.loadPurchaserInfo(props.companyId);
            }
          }
        },
      },
    });
  };

  const handleRemoveImage = () => {
    isChangeProps(props) && props.onRemoveImage();
    setAnchorEl(null);
  };

  const renderMenu = () => {
    return (
      <Menu
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 0, horizontal: 75 }}
        anchorEl={anchorEl}
      >
        {isChangeProps(props) && !props.imageUri && (
          <MenuItem onClick={handleChangeImage}>Add Image</MenuItem>
        )}
        {isChangeProps(props) && props.imageUri && (
          <MenuItem onClick={handleChangeImage}>Change Image</MenuItem>
        )}
        {isChangeProps(props) && props.imageUri && (
          <MenuItem onClick={handleRemoveImage}>Remove Image</MenuItem>
        )}
      </Menu>
    );
  };

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(e.target as any);
  };

  const renderImage = () => {
    if (props.imageUri) {
      return (
        <img
          className={classes.uploadedLogo}
          src={props.imageUri}
          alt={props.companyName}
          onClick={onClick}
        />
      );
    } else if (rootDomain) {
      return (
        <img
          className={classes.avatar}
          src={getFavIconPath(rootDomain)}
          alt={props.companyName}
          onClick={onClick}
        />
      );
    } else {
      return (
        <Avatar
          src="/images/no-profile.png"
          alt={props.companyName}
          className={classes.avatar}
          onClick={onClick}
        ></Avatar>
      );
    }
  };

  return (
    <>
      {renderImage()}
      {isChangeProps(props) && myStore.isAdminNotImpersonating && renderMenu()}
    </>
  );
};

export default CompanyAvatar;
