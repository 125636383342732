import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ISummaryTransaction, CompanyType } from "services/api/types";
import red from "@material-ui/core/colors/red";
import green from "@material-ui/core/colors/green";
import DateFormat from "components/Shell/DateFormat";
import { formatNumber } from "utils/number";
import { Link } from "react-router-dom";
import { paths } from "routes/Paths";
import MyGenericTable from "components/DataTables/MyGenericTable";
import TransactionListItem from "./TransactionListItem";
import {
  Column,
  Action,
  PagingProps,
} from "components/DataTables/MyGenericTableTypes";

type Props = {
  transactions: ISummaryTransaction[];
  accountId: string;
  mode: "transaction-admin" | "general-admin" | "sponsor" | "member";
  companyType: CompanyType;
  editTransaction: (transaction: ISummaryTransaction) => void;
  deleteTransaction: (transaction: ISummaryTransaction) => void;
  reverseTransaction: (transaction: ISummaryTransaction) => void;
  renderLeftToolbarComponent: () => JSX.Element;
  customPills: JSX.Element[];
  isLoading: boolean;
  isLoaded: boolean;
  onMobileAddButtonClicked?: () => void;
  totalPages: number;
} & PagingProps<ISummaryTransaction>;

const TransactionsTable = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      positive: {
        fontWeight: "bold",
        color: green[500],
        textAlign: "right",
      },
      negative: {
        fontWeight: "bold",
        color: red[500],
        textAlign: "right",
      },
      transactionListItem: {
        fontSize: 12,
        display: "flex",
        flexDirection: "column",
        "& > div": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        },
      },
      mobileListItem: {
        padding: theme.spacing(1),
        width: "100%",
      },
    })
  );

  const classes = useStyles();

  const getMonthlyReportPath = (companyId: string, monthlyReportId: string) => {
    if (props.mode === "transaction-admin" || props.mode === "general-admin") {
      return `${paths.app.admin.companyDetails}/${companyId}/monthlyReports/${monthlyReportId}`;
    } else {
      return `${paths.app.sponsors.root}/monthlyReports/${monthlyReportId}`;
    }
  };

  const transactionAdminOnlyActions: Action<ISummaryTransaction>[] = [
    {
      execute: props.editTransaction,
      label: "Edit",
      visibilityPredicate: (row) => row.isEditable,
    },
    {
      execute: props.deleteTransaction,
      label: "Delete",
      visibilityPredicate: (row) => row.isEditable,
    },
    {
      execute: props.reverseTransaction,
      label: "Refund",
      visibilityPredicate: (row) =>
        row.isRefundable &&
        Boolean(row.fromCompanyId) &&
        Boolean(!row.toCompanyId),
    },
    {
      execute: props.reverseTransaction,
      label: "Reverse",
      visibilityPredicate: (row) =>
        row.isRefundable &&
        Boolean(row.toCompanyId) &&
        Boolean(!row.fromCompanyId),
    },
  ];

  const actions =
    props.mode === "transaction-admin" ? transactionAdminOnlyActions : [];

  const columns: Column<ISummaryTransaction>[] = [
    {
      label: "Type",
      propertyName: "transactionTypeName",
    },
    {
      label: "Order #",
      propertyName: "externalOrderId",
      disableSorting: true,
      render: (row) => {
        return (
          <Link to={`${paths.app.members.root}/orders/${row.externalOrderId}`}>
            {row.externalOrderId}
          </Link>
        );
      },
    },
    {
      label: "Date",
      propertyName: "transactionDate",
      render: (row) => {
        return <DateFormat date={row.transactionDate} />;
      },
      styleWidth: "175px",
    },
    {
      label: "Points",
      propertyName: "points",
      numeric: true,
      render: (row) => {
        const isCredit = row.toAccountId === props.accountId && row.points > 0;
        return isCredit ? (
          <span className={classes.positive}>
            {formatNumber(row.points * row.quantity)}
          </span>
        ) : (
          <span className={classes.negative}>
            ({formatNumber(Math.abs(row.points) * row.quantity)})
          </span>
        );
      },
    },
    {
      label: "Notes",
      propertyName: "notes",
      disableSorting: true,
      render: (row) => {
        if (row.monthlyReportId && row.transactionTypeName === "Awarded") {
          return (
            <Link
              to={getMonthlyReportPath(row.fromCompanyId!, row.monthlyReportId)}
            >
              {row.notes}
            </Link>
          );
        } else {
          return <div>{row.notes}</div>;
        }
      },
    },
    {
      label: "From",
      propertyName: "fromCompanyName",
      disableSorting: true,
      render: (row) => {
        return <div>{row.fromCompanyName ?? "---"}</div>;
      },
    },
    {
      label: "To",
      propertyName: "toCompanyName",
      disableSorting: true,
      render: (row) => {
        return <div>{row.toCompanyName ?? "---"}</div>;
      },
    },
  ];

  const isFirstLoad = props.isLoading && !props.isLoaded;
  const noDataMessage = isFirstLoad
    ? "Loading transactions..."
    : "No transactions found";

  return (
    <MyGenericTable
      {...props}
      columns={columns}
      actions={actions}
      hideBottomAppBar={false}
      dimRow={(row) => !row.isEditable}
      defaultSortColumn="transactionDate"
      toolbarPortalId="transactions-table-toolbar-portal"
      defaultSortOrder="desc"
      renderListItemContent={(row) => (
        <TransactionListItem accountId={props.accountId} transaction={row} />
      )}
      renderLeftToolbarComponent={props.renderLeftToolbarComponent}
      showMenu={props.mode === "transaction-admin"}
      filterPlaceholder="Filter transactions"
      noDataMessage={noDataMessage}
      onMobileAddButtonClicked={props.onMobileAddButtonClicked}
      isLoading={props.isLoading}
    />
  );
};

export default TransactionsTable;
