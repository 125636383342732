import { apiClient } from "services/api/client";
import {
  ISupplierCategory,
  ICreateSupplierProfileCommand,
  INewProfileViewModel,
} from "services/api/types";

export const getSupplierCategories = async (supplierId: string) => {
  const url = `members/suppliers/${supplierId}/categories`;
  const response = await apiClient.get<ISupplierCategory[]>(url);
  return response.data;
};

export const createSupplierProfile = async (
  command: ICreateSupplierProfileCommand
) => {
  const url = `members/supplierProfiles`;
  const response = await apiClient.post(url, command);
  return response;
};

export const getNewFormData = async () => {
  const url = `members/supplierProfiles/newFormData`;
  const response = await apiClient.get<INewProfileViewModel>(url);
  return response.data;
};

export const canCreate = async () => {
  const url = `members/supplierProfiles/canCreate`;
  const response = await apiClient.get<{ canCreate: boolean }>(url);
  return response.data;
};
