import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ProductForm from "modals/components/CompanyForms/Product/ProductForm";
import { CreateCompanyProductCommand } from "services/api/types";
import companyStore from "stores/shared/company";
import enumStore from "stores/enumerable";

type OriginalProps = {
  handleClose: () => void;
};

type Props = OriginalProps & RouteComponentProps;

class ModalAddCompanyProduct extends Component<Props> {
  public render = () => (
    <ProductForm
      companyProducts={
        companyStore.details ? companyStore.details.products : []
      }
      handleSubmit={this.handleSubmit}
      handleCancel={this.props.handleClose}
      companyId={companyStore.details!.id}
    />
  );

  private handleSubmit = async (command: CreateCompanyProductCommand) => {
    await companyStore.createProduct(command);

    if (command.productName) {
      enumStore.ensureProductsFetched(true);
    }

    this.props.handleClose();
  };
}

export default withRouter(ModalAddCompanyProduct);
