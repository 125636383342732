import React from "react";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";

type Props = {
  eventName: string;
  companyName: string;
  attendeeNames: string[];
  handleClose: () => void;
};

const ViewCompanyEventAttendees = (props: Props) => {
  return (
    <ResponsiveModalShell
      handleClose={props.handleClose}
      title={`${props.companyName} attendees for ${props.eventName}`}
    >
      {props.attendeeNames.map((a, i) => {
        return <div key={i}>{a}</div>;
      })}
      {props.attendeeNames.length === 0 && <div>(no attendees found)</div>}
    </ResponsiveModalShell>
  );
};

export default ViewCompanyEventAttendees;
