import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Button from "@material-ui/core/Button";
import { ICompanyPermissionViewModel } from "services/api/types";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";

type Props = {
  companyPermissions: ICompanyPermissionViewModel[];
  currentCompanyId: string;
  onChange: (company: ICompanyPermissionViewModel) => void;
};

const CompanySelector = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      button: {
        marginLeft: theme.spacing(4),
        color: "#943A3A",
      },
    })
  );

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (company: ICompanyPermissionViewModel) => {
    handleClose();
    props.onChange(company);
  };

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.companyPermissions.map((p) => {
          return (
            <MenuItem
              onClick={() => handleItemClick(p)}
              key={p.companyId}
              value={p.companyId}
            >
              {p.companyName}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  return (
    <div>
      {renderMenu()}
      <Button
        size="small"
        variant="contained"
        className={classes.button}
        onClick={handleClick}
      >
        Change Company
      </Button>
    </div>
  );
};

export default CompanySelector;
