import { apiClient } from "services/api/client";
import { Operation } from "fast-json-patch";

import {
  ICompany,
  ICompanyLocationPayload,
  ILocationDetailed,
  ILocation,
  IContactDetailed,
  IGetStatementsResult,
} from "services/api/types";

const COMPANIES_URL = "shared/companies";
const ACCOUNTS_URL = "shared/accounts";

export const getDetails = async (payload: { id: string }) => {
  const response = await apiClient.get<{ company: ICompany }>(
    `${COMPANIES_URL}/${payload.id}`
  );
  return response;
};

export const getStatementList = async (accountId: string) => {
  const url = `${ACCOUNTS_URL}/${accountId}/statements`;
  const response = await apiClient.get<IGetStatementsResult>(url);
  return response.data;
};

export const locationCreate = async (payload: ICompanyLocationPayload) => {
  const response = await apiClient.post<{ companyLocation: ILocationDetailed }>(
    `${COMPANIES_URL}/${payload.companyLocation.companyId}/locations`,
    payload
  );
  return response;
};

export const setLocationAsPrimary = async (
  companyId: string,
  companyLocationId: string
) => {
  const url = `${COMPANIES_URL}/${companyId}/primaryLocation/${companyLocationId}`;
  const response = await apiClient.post<ILocation>(url);
  return response.data;
};

export const locationUpdate = async (
  companyId: string,
  companyLocationId: string,
  operations: Operation[]
) => {
  const response = await apiClient.patch<{
    companyLocation: ILocationDetailed;
  }>(
    `${COMPANIES_URL}/${companyId}/locations/${companyLocationId}`,
    operations
  );
  return response;
};

export const updateLocationStatus = async (payload: {
  companyLocationId: string;
  companyId: string;
  isActive: boolean;
}) => {
  const response = await apiClient.post<ILocationDetailed>(
    `${COMPANIES_URL}/${payload.companyId}/locations/${payload.companyLocationId}/updateStatus`,
    payload
  );
  return response.data;
};

export const updateCompanyContact = async (contact: IContactDetailed) => {
  const response = await apiClient.put<IContactDetailed>(
    `${COMPANIES_URL}/${contact.companyId}/contacts/${contact.id}`,
    { contact }
  );

  return response.data;
};

export const updateContactStatus = async (
  companyId: string,
  contactId: string,
  isActive: boolean
) => {
  const response = await apiClient.post<IContactDetailed>(
    `${COMPANIES_URL}/${companyId}/contacts/${contactId}/updateStatus`,
    {
      contactId,
      isActive,
    }
  );

  return response.data;
};

export const getCompanyContact = async (payload: {
  id: string;
  companyId: string;
}) => {
  const response = await apiClient.get<{ companyContact: IContactDetailed }>(
    `${COMPANIES_URL}/${payload.companyId}/contacts/${payload.id}`
  );
  return response;
};

export const getAllCompanyContacts = async (companyId: string) => {
  const response = await apiClient.get<IContactDetailed[]>(
    `${COMPANIES_URL}/${companyId}/contacts`
  );

  return response;
};

export const createCompanyContact = async (newContact: IContactDetailed) => {
  const response = await apiClient.post<{ companyContact: IContactDetailed }>(
    `${COMPANIES_URL}/${newContact.companyId}/contacts`,
    newContact
  );
  return response;
};

export const deleteCompanyContact = async (payload: {
  id: string;
  companyId: string;
}) => {
  const response = await apiClient.delete(
    `${COMPANIES_URL}/${payload.companyId}/contacts/${payload.id}`
  );
  return response;
};
