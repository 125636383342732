import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

type Props = {
  text: string;
  superHeader?: string;
  superHeaderClassName?: string;
  subHeader?: string;
  renderAvitar?: () => JSX.Element;
  renderAfterComponent?: () => JSX.Element;
};

const PageHeader = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "flex",
        alignItems: "center",
        marginBottom: theme.spacing(2),
      },
      main: {
        margin: 0,
        fontSize: "1.5rem",
        fontWeight: "bold",
        [theme.breakpoints.down("xs")]: {
          fontSize: "1.3rem",
        },
      },
      mainHost: {
        position: "relative",
      },
      superHeader: {
        margin: 0,
        // position: "absolute",
        // top: "-20px",
        // left: 0,
        // margin: 0,
      },
      avatarHost: {
        marginRight: theme.spacing(1),
        transform: "translateY(16px)",
      },
      afterComponents: {
        marginLeft: theme.spacing(1),
      },
      subHeader: {
        color: "grey",
        margin: 0,
      },
    })
  );

  const classes = useStyles();
  return (
    <div className={classes.root}>
      {props.renderAvitar && (
        <div className={classes.avatarHost}>{props.renderAvitar()}</div>
      )}
      <div className={classes.mainHost}>
        {props.superHeader && (
          <Typography
            className={clsx(classes.superHeader, {
              [props.superHeaderClassName!]: props.superHeaderClassName,
            })}
          >
            {props.superHeader}
          </Typography>
        )}
        <Typography className={classes.main} variant="h4" color="primary">
          {props.text}
        </Typography>
        {props.subHeader && (
          <Typography className={classes.subHeader}>
            {props.subHeader}
          </Typography>
        )}
      </div>
      {props.renderAfterComponent && (
        <div className={classes.afterComponents}>
          {props.renderAfterComponent()}
        </div>
      )}
    </div>
  );
};

export default PageHeader;
