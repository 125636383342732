import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { CompanyOption } from "types/options";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDebounce } from "utils/hooks";
import api from "services/api";
import { CompanyType, ICompanySearchResults } from "services/api/types";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { companyColors } from "areas/Shared/companyColors";
import clsx from "clsx";

type Props = {
  className?: string;
  errorClassName: string;
  errorMessage: string;
  value: CompanyOption | null;
  onChange: (newValue: CompanyOption) => void;
  onBlur?: (evt: React.FocusEvent) => void;
  label: string;
  optionsFilter?: (options: CompanyOption[]) => CompanyOption[];
  noRecordsText?: string;
  searchCompanyTypes?: React.MutableRefObject<CompanyType[]>;
  onlyAvailableOptions?: CompanyOption[];
  currentUserType: "Admin" | "Sponsor" | "Member";
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    companyTypes: {
      display: "inline-block",
      marginLeft: theme.spacing(1),
      fontSize: "smaller",
      color: "white",
      padding: theme.spacing(0, 1),
      borderRadius: "10px",
    },
    sponsor: {
      backgroundColor: companyColors.sponsor,
    },
    competitor: {
      backgroundColor: companyColors.competitor,
    },
    prospect: {
      backgroundColor: companyColors.prospect,
    },
    member: {
      backgroundColor: companyColors.member,
    },
  })
);

const CompanySelect = (props: Props) => {
  const [options, setOptions] = React.useState<CompanyOption[]>(
    props.onlyAvailableOptions ?? []
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [textBoxValue, setTextBoxValue] = React.useState("");

  const debouncedSearchTerm = useDebounce(textBoxValue, 500);
  const { searchCompanyTypes, optionsFilter } = props;

  const classes = useStyles();

  React.useEffect(() => {
    if (debouncedSearchTerm && !props.onlyAvailableOptions) {
      const searchCompanies = async (searchTerm: string) => {
        const companyTypes = props.searchCompanyTypes?.current ?? [];

        const isSponsorSearchingMembers =
          props.currentUserType === "Sponsor" &&
          companyTypes.includes("Member");

        const isMemberSearchingSponsors =
          props.currentUserType === "Member" &&
          companyTypes.includes("Sponsor");

        setIsLoading(true);
        let searchResult: ICompanySearchResults | undefined;
        if (props.currentUserType === "Admin") {
          searchResult = await api.admin.search.searchCompanies(
            searchTerm,
            true,
            ...companyTypes
          );
        } else if (isSponsorSearchingMembers) {
          searchResult = await api.sponsors.purchasers.search(searchTerm);
        } else if (isMemberSearchingSponsors) {
          searchResult = await api.shared.sponsors.searchSponsors(searchTerm);
        }

        if (!searchResult) {
          setIsLoading(false);
          return;
        }

        if (searchResult.searchTerm === debouncedSearchTerm) {
          let options: CompanyOption[] = searchResult.companies.map((r) => {
            return {
              value: r.id,
              label: `${r.name} (${r.identifier})`,
              computedBalance: r.computedBalance,
              companyType: r.companyType,
            };
          });

          if (optionsFilter) {
            options = optionsFilter(options);
          }

          window.setTimeout(() => {
            setOptions(options);
            setIsLoading(false);
          }, 100);

          setOptions(options);
          setIsLoading(false);
        }
      };

      searchCompanies(debouncedSearchTerm);
    }
  }, [
    debouncedSearchTerm,
    searchCompanyTypes,
    optionsFilter,
    props.onlyAvailableOptions,
    props.currentUserType,
    props.searchCompanyTypes,
  ]);

  const handleInputChange = (evt: React.ChangeEvent<any>) => {
    // Setting the inital value of the dropdown triggers this event
    // with a null evt
    if (!evt) return;

    if (!props.onlyAvailableOptions) {
      setOptions([]);
    }

    const searchTerm = (evt.currentTarget as any).value || "";
    setTextBoxValue(searchTerm);
  };

  const noRecordsText = props.noRecordsText ?? "No companies found";

  return (
    <FormControl fullWidth variant="filled" className={props.className ?? ""}>
      <Autocomplete
        value={props.value}
        noOptionsText={textBoxValue ? noRecordsText : "Type to search"}
        onInputChange={handleInputChange}
        options={options}
        loading={isLoading}
        onBlur={props.onBlur}
        loadingText="Searching companies..."
        getOptionSelected={(option, value) => {
          return option === value;
        }}
        renderOption={(opt) => {
          return opt.companyType ? (
            <div>
              <span>{opt.label}</span>
              <span
                className={clsx(classes.companyTypes, {
                  [classes.competitor]:
                    (opt.companyType as CompanyType) === "Competitor",
                  [classes.sponsor]:
                    (opt.companyType as CompanyType) === "Sponsor",
                  [classes.member]:
                    (opt.companyType as CompanyType) === "Member",
                  [classes.prospect]:
                    (opt.companyType as CompanyType) === "Prospect",
                })}
              >
                {opt.companyType}
              </span>
            </div>
          ) : (
            <div>{opt.label}</div>
          );
        }}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (typeof option.label === "string") {
            return option.label;
          }

          return (option as any).label.label;
        }}
        onChange={(event: any, option: any) => {
          if (!props.onlyAvailableOptions) {
            setOptions([]);
          }
          return props.onChange(option);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            error={Boolean(props.errorMessage)}
            label={props.label}
            style={{ margin: 0 }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            margin="dense"
          />
        )}
      />
      <FormHelperText variant="standard" className={props.errorClassName}>
        {props.errorMessage}
      </FormHelperText>
    </FormControl>
  );
};

export default CompanySelect;
