import { apiClient } from "services/api/client";
import {
  IBrand,
  ICategory,
  IMarket,
  IProduct,
  IEventType,
  IUnit,
  ITransactionType,
  IRelationType,
  IRelationshipType,
} from "services/api/types";

const listsUrl = "core/lists";

export const eventTypes = async () => {
  const url = `${listsUrl}/eventTypes`;
  const response = await apiClient.get<{ eventTypes: IEventType[] }>(url);
  return response;
};

export const marketsShow = async () => {
  const url = `${listsUrl}/markets`;
  const response = await apiClient.get<{ markets: IMarket[] }>(url);
  return response;
};

export const categoriesShow = async () => {
  const url = `${listsUrl}/categories`;
  const response = await apiClient.get<{ categories: ICategory[] }>(url);
  return response;
};

export const brandsShow = async () => {
  const url = `${listsUrl}/brands`;
  const response = await apiClient.get<{ brands: IBrand[] }>(url);
  return response;
};

export const productsShow = async () => {
  const url = `${listsUrl}/products`;
  const response = await apiClient.get<{ products: IProduct[] }>(url);
  return response;
};

export const taxNumberTypesShow = async () => {
  const url = `${listsUrl}/taxNumberTypes`;
  const response = await apiClient.get<{ taxNumberTypes: string[] }>(url);
  return response;
};

export const addressTypesShow = async () => {
  const url = `${listsUrl}/addressTypes`;
  const response = await apiClient.get<{ addressTypes: string[] }>(url);
  return response;
};

export const emailTypesShow = async () => {
  const url = `${listsUrl}/emailTypes`;
  const response = await apiClient.get<{ emailTypes: string[] }>(url);
  return response;
};

export const phoneNumberTypesShow = async () => {
  const url = `${listsUrl}/phoneNumberTypes`;
  const response = await apiClient.get<{ phoneNumberTypes: string[] }>(url);
  return response;
};

export const relationTypes = async () => {
  const url = `${listsUrl}/relationTypes`;
  const response = await apiClient.get<{
    relationTypes: IRelationType[];
  }>(url);
  return response;
};

export const relationshipTypes = async () => {
  const url = `${listsUrl}/relationshipTypes`;
  const response = await apiClient.get<{
    relationshipTypes: IRelationshipType[];
  }>(url);
  return response;
};

export const transactionTypes = async () => {
  const url = `${listsUrl}/transactionTypes`;
  const response = await apiClient.get<{
    transactionTypes: ITransactionType[];
  }>(url);
  return response;
};

export const units = async () => {
  const url = `${listsUrl}/units`;
  const response = await apiClient.get<{ units: IUnit[] }>(url);
  return response;
};
