import { action, observable } from "mobx";
import api from "services/api";

export class NeedAttentionCountsStore {
  @observable
  public newSupplierProfileCount = 0;

  @observable
  public newMemberReferralCount = 0;

  @action.bound
  public async refreshCounts(): Promise<void> {
    const memberReferals =
      await api.admin.members.getMemberReferralsNeedAttentionCount();
    const supplierProfiles =
      await api.admin.members.getSupplierProfilesNeedAttentionCount();

    this.newSupplierProfileCount = supplierProfiles;
    this.newMemberReferralCount = memberReferals;
  }
}

export default new NeedAttentionCountsStore();
