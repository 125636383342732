import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import SupplierDetailsForm from "modals/components/CompanyForms/Details/EditSupplierForm";
import { ICompany, IUpdateCompanyCommand } from "services/api/types";
import companyStore from "stores/shared/company";

type OriginalProps = {
  company: ICompany;
  onSuccess?: () => void;
  handleClose: () => void;
};

type Props = OriginalProps & RouteComponentProps;
class ModalUpdateCompany extends Component<Props> {
  public render() {
    return (
      <SupplierDetailsForm
        company={this.props.company}
        handleCancel={this.props.handleClose}
        handleUpdate={this.handleSubmit}
      />
    );
  }

  private handleSubmit = async (command: IUpdateCompanyCommand) => {
    await companyStore.updateCompany(command);

    if (this.props.onSuccess) this.props.onSuccess();
    this.props.handleClose();
  };
}

export default withRouter(ModalUpdateCompany);
