import { apiClient } from "services/api/client";
import {
  INewspaper,
  INewspaperResult,
  INewspaperConfig,
  INewspaperSetCounts,
} from "services/api/types";
import { getFileName } from "services/api/modules/helpers";

const NEWSPAPERS_URL = "administrator/newspapers";
const NEWSPAPERS_CONFIG_URL = "administrator/newspaperConfig";

export const exportList = async () => {
  const url = `${NEWSPAPERS_URL}/export`;

  const response = await apiClient.post(url, {}, { responseType: "blob" });
  const fileName = getFileName(response, `Newspaper Recipients.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const exportDoNotGetList = async () => {
  const url = `${NEWSPAPERS_URL}/exportDoNotGet`;

  const response = await apiClient.post(url, {}, { responseType: "blob" });
  const fileName = getFileName(response, `Do Not Get Newspaper.xlsx`);

  const objectUrl = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = objectUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const getConfig = async () => {
  const response = await apiClient.get<INewspaperConfig>(NEWSPAPERS_CONFIG_URL);
  return response;
};

export const updateConfig = async (config: INewspaperConfig) => {
  const response = await apiClient.put<INewspaperConfig>(
    NEWSPAPERS_CONFIG_URL,
    config
  );
  return response;
};

export const requestLabels = async () => {
  const url = `${NEWSPAPERS_URL}/requestLabels`;
  const response = await apiClient.post(url);
  return response;
};

export const getCounts = async () => {
  const url = `${NEWSPAPERS_URL}/counts`;
  const response = await apiClient.get<INewspaperSetCounts[]>(url);
  return response;
};

export const updateNewspaper = async (newspaper: INewspaper) => {
  const url = `${NEWSPAPERS_URL}/${newspaper.id}`;
  const response = await apiClient.put<INewspaperResult>(url, newspaper);
  return response;
};

export const createNewspaper = async (newNewspaper: {
  startYear: number;
  startMonth: number;
  endYear: number;
  endMonth: number;
  url: string;
}) => {
  const response = await apiClient.post<INewspaperResult>(
    NEWSPAPERS_URL,
    newNewspaper
  );

  return response;
};

export const deleteNewspaper = async (id: string) => {
  const url = `${NEWSPAPERS_URL}/${id}`;
  const response = await apiClient.delete(url);
  return response;
};
