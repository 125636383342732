import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Field } from "formik";
import FormControl from "@material-ui/core/FormControl";
import MuiTextField from "@material-ui/core/TextField";
import { TextField } from "formik-material-ui";
import FormHelperText from "@material-ui/core/FormHelperText";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import InputPhoneNumber from "components/Inputs/InputPhoneNumber";

export type UserNameState =
  | "Locked"
  | "Available"
  | "Taken"
  | "Unknown"
  | "Checking"
  | "NotValid";

type Props = {
  disabled: boolean;
  mode: "Administrator" | "CompanyUser";
  userName: string;
  userNameState: UserNameState;
  onUserNameChanged: (value: string) => void;
  userNameErrorInfo: { error?: string; showError: boolean };
  phoneNumber: string;
  onPhoneNumberChanged: (value: string) => void;
};

const BasicInfo = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      error: {
        color: "red",
      },
      userNameState: {
        display: "flex",
        alignItems: "center",
        gridColumn: "2 / 4",
      },
      notValidOrTaken: {
        color: "red",
      },
      available: {
        color: "green",
      },
      checking: {
        color: "darkyellow",
      },
      accountInfoHeader: {
        marginBottom: theme.spacing(2),
      },
      formControl: {
        margin: theme.spacing(1),
      },
      signatureFieldsNote: {
        color: "gray",
        transform: "translateY(20px)",
      },
    })
  );

  const classes = useStyles();

  const { userNameErrorInfo } = props;

  const getUserNameStateMessage = () => {
    switch (props.userNameState) {
      case "Available":
        return "This user name is available!";
      case "Checking":
        return "Checking to see if this user name is available";
      case "Unknown":
        return "";
      case "Locked":
        return "";
      case "NotValid":
        return "This isn't a valid user name";
      case "Taken":
        return "This user name is already taken. Please choose another.";
    }
  };

  const renderUserNameState = () => {
    return (
      <div
        className={clsx(classes.userNameState, {
          [classes.available]: props.userNameState === "Available",
          [classes.notValidOrTaken]:
            props.userNameState === "NotValid" ||
            props.userNameState === "Taken",
          [classes.checking]: props.userNameState === "Checking",
        })}
      >
        {getUserNameStateMessage()}
      </div>
    );
  };

  return (
    <>
      <Typography
        className={classes.accountInfoHeader}
        component="p"
        color="primary"
        variant="caption"
      >
        Account Information
      </Typography>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={6} sm={3}>
          <Field
            name="firstName"
            label="First Name"
            margin="dense"
            fullWidth={true}
            variant="outlined"
            component={TextField}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <Field
            name="lastName"
            label="Last Name"
            fullWidth={true}
            margin="dense"
            variant="outlined"
            component={TextField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            name="email"
            label="Email Address"
            fullWidth={true}
            margin="dense"
            variant="outlined"
            component={TextField}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth={true}>
            <MuiTextField
              label="User Name"
              margin="dense"
              variant="outlined"
              disabled={props.disabled}
              error={userNameErrorInfo.showError}
              onChange={(evt) => {
                props.onUserNameChanged(evt.target.value);
              }}
              name="userName"
              value={props.userName}
            />
            {userNameErrorInfo.showError ? (
              <FormHelperText className={classes.error}>
                &nbsp;&nbsp;{userNameErrorInfo.error}
              </FormHelperText>
            ) : undefined}
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          {renderUserNameState()}
        </Grid>
        {props.mode === "Administrator" && (
          <>
            <Grid item xs={12}>
              <Typography
                component="div"
                className={classes.signatureFieldsNote}
              >
                The fields below will be used in email signatures if this user
                is set as the "from" user for an email.
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputPhoneNumber
                label="Phone Number"
                onChange={(evt) => props.onPhoneNumberChanged(evt.target.value)}
                value={props.phoneNumber}
                type="tel"
                error={false}
                fullWidth
                required={true}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field
                name="jobTitle"
                label="Job Title"
                fullWidth
                margin="dense"
                variant="outlined"
                component={TextField}
              />
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

export default BasicInfo;
