import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { TextField } from "formik-material-ui";
import { Formik, Field } from "formik";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Button from "@material-ui/core/Button";
import { IExistingGenericPayment } from "services/api/types";
import { MaskedNumberInput } from "areas/Shared/components/maskedInputs/MaskedNumberInput";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
    },
    formControl: {
      margin: theme.spacing(1),
    },
    buttons: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
      marginTop: theme.spacing(4),
    },
  })
);

type CheckPaymentFormProps = {
  onCancel: () => void;
  onSave: (amount: number, checkNumber: string, date: Date) => void;
  payment?: IExistingGenericPayment;
  customAmountValidator?: (dollarAmount: number) => {
    passed: boolean;
    errorMessage?: string;
  };
};

export default function CheckPaymentForm(
  componentProps: CheckPaymentFormProps
) {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        amount: componentProps.payment
          ? componentProps.payment.amount.toString()
          : "",
        checkNumber: componentProps.payment
          ? componentProps.payment.checkNumber
          : "",
        date: componentProps.payment ? componentProps.payment.date : new Date(),
      }}
      validate={(values) => {
        const errors: { [key: string]: string } = {};

        if (values.amount.length === 0 || values.amount === "0") {
          errors.amount = "Required";
        }

        const parseResult = parseFloat(values.amount);
        if (isNaN(parseResult)) {
          errors.amount = "Must be a number";
        }

        if (componentProps.customAmountValidator) {
          const validationResult =
            componentProps.customAmountValidator(parseResult);

          if (!validationResult.passed) {
            errors.amount = validationResult.errorMessage!;
          }
        }

        if (values.checkNumber.length === 0) {
          errors.checkNumber = "Required";
        }

        return errors;
      }}
      onSubmit={(values) => {
        const parsedAmount = parseFloat(values.amount);
        componentProps.onSave(parsedAmount, values.checkNumber, values.date);
      }}
      render={(props) => {
        return (
          <>
            <div className={classes.container}>
              <Field
                margin="dense"
                inputVariant="outlined"
                format="MM/dd/yy"
                color="secondary"
                value={props.values.date}
                component={KeyboardDatePicker}
                className={classes.formControl}
                onChange={(newValue) => {
                  props.setFieldValue("date", newValue);
                }}
                name="date"
                label="Date"
              />
              <Field
                component={TextField}
                variant="outlined"
                margin="dense"
                className={classes.formControl}
                name="checkNumber"
                label="Check #"
              />
              <Field
                variant="outlined"
                margin="dense"
                component={TextField}
                className={classes.formControl}
                label="Amount"
                name="amount"
                id="amount"
                // Note: has to be uppercase
                InputProps={{
                  inputComponent: MaskedNumberInput as any,
                  inputProps: {
                    allowNegative: false,
                    prefix: "$",
                    decimalScale: 2,
                  },
                }}
              />
            </div>
            <div className={classes.buttons}>
              <Button
                color="secondary"
                onClick={() => {
                  props.submitForm();
                }}
              >
                Save
              </Button>
              <Button onClick={componentProps.onCancel} color="secondary">
                Cancel
              </Button>
            </div>
          </>
        );
      }}
    />
  );
}
