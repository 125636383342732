import React from "react";
import ResponsiveDrawer from "components/Shell/ResponsiveDrawer";
import AdminSidebar from "areas/Admin/AdminSidebar";
import AdminToolbarItems from "areas/Admin/AdminToolbarItems";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import SignalRConnection from "../Shared/components/SignalRConnection";
import appTextStore from "stores/shared/appText";

type Props = {
  children: React.ReactNode;
};

const AdminDrawer = (props: Props) => {
  React.useEffect(() => {
    appTextStore.ensureAllAppTextLoaded();
  }, []);

  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        // position: "relative",
      },
    })
  );

  const classes = useStyles();

  return (
    <SignalRConnection groupName="Administrators">
      <div className={classes.root}>
        <ResponsiveDrawer
          renderMobileToolbarItems={() => <AdminToolbarItems />}
          renderDesktopToolbarItems={() => <AdminToolbarItems />}
          renderSidebarContent={() => <AdminSidebar />}
        >
          {props.children}
        </ResponsiveDrawer>
      </div>
    </SignalRConnection>
  );
};

export default AdminDrawer;
