import React from "react";
import { getCompanyBaseRouteTemplate } from "routes/Paths";
import { AdminRoleKey, CompanyRoleKey } from "types/roles";
import { CompanyType, IAccountInfo } from "services/api/types";
import myStore from "stores/my";
import {
  companyScreenDefs,
  CompanyScreenDef,
  ValidTabCountProperties,
} from "./tabDefinitions";
import { Route } from "react-router-dom";

export type TabDef = {
  label: string;
  href: string;
  badgeCountProperty?: ValidTabCountProperties;
};

export const getTabsToRender = (params: {
  companyId: string;
  eventId?: string;
  companyType: CompanyType;
  isAdminNotImpersonating: boolean;
}): TabDef[] => {
  const relevantScreens = getRelevantScreens(params);
  const { companyType, isAdminNotImpersonating: isAdmin } = params;
  const baseRoute = getCompanyBaseRouteTemplate({
    companyType,
    isAdminNotImpersonating: isAdmin,
    eventId: params.eventId,
  });

  return relevantScreens
    .filter((tab) => !tab.hideTab)
    .map((tab) => {
      const useEventRoute = Boolean(params.eventId);
      const fullRouteTemplate = getFullRouteTemplate(
        isAdmin,
        useEventRoute,
        baseRoute,
        tab
      );

      const fullRoute = fullRouteTemplate
        .replace(":companyId", params.companyId)
        .replace(":eventId", params.eventId ?? "eventid-not-found");

      return {
        label: tab.label,
        href: fullRoute,
        badgeCountProperty: tab.badgeCountProperty,
      };
    });
};

export const renderRoutes = (params: {
  companyId: string;
  currentAccount: IAccountInfo;
  eventId?: string;
  companyType: CompanyType;
  isAdminNotImpersonating: boolean;
  usersAdminRoles: AdminRoleKey[];
  usersCompanyRole: CompanyRoleKey | undefined;
}) => {
  const {
    companyType,
    usersAdminRoles,
    usersCompanyRole,
    companyId,
    isAdminNotImpersonating,
    currentAccount,
  } = params;

  const relevantTabs = getRelevantScreens({
    companyId,
    companyType,
    isAdminNotImpersonating,
  });

  const baseRoute = getCompanyBaseRouteTemplate({
    companyType,
    isAdminNotImpersonating,
    eventId: params.eventId,
  });

  return relevantTabs.map((tab) => {
    const useEventRoute = Boolean(params.eventId);
    const fullPath = getFullRouteTemplate(
      isAdminNotImpersonating,
      useEventRoute,
      baseRoute,
      tab
    );

    return (
      <Route
        key={tab.routeSuffix}
        path={fullPath}
        exact
        render={(props) =>
          tab.renderComponent({
            companyType,
            routeProps: props,
            isAdminNotImpersonating,
            usersAdminRoles,
            usersCompanyRole,
            currentAccount,
          })
        }
      />
    );
  });
};

const getFullRouteTemplate = (
  isAdmin: boolean,
  useEventRoute: boolean,
  baseRoute: string,
  tabDef: CompanyScreenDef
) => {
  if (isAdmin) {
    return `${baseRoute}/:companyId/${tabDef.routeSuffix}`;
  } else {
    return `${baseRoute}/${tabDef.routeSuffix}`;
  }
};

const getRelevantScreens = (params: {
  companyId: string;
  companyType: CompanyType;
  isAdminNotImpersonating: boolean;
}) => {
  const relevantTabs = companyScreenDefs.filter((f) => {
    const isAdminScreen =
      params.isAdminNotImpersonating &&
      f.adminCompanyTypes.indexOf(params.companyType) >= 0;
    const isNonAdminScreen =
      !params.isAdminNotImpersonating &&
      f.nonAdminCompanyTypes.indexOf(params.companyType) >= 0;
    const adminHasPermission =
      f.adminPermission.length === 0 ||
      myStore.hasAnyAdminRole(f.adminPermission);
    const companyHasPermission =
      f.companyPermissions.length === 0 ||
      myStore.hasAnyCompanyRole(f.companyPermissions);

    return (
      (isAdminScreen && adminHasPermission) ||
      (isNonAdminScreen && companyHasPermission)
    );
  });

  return relevantTabs;
};

export default getRelevantScreens;
