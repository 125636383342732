import * as company from "./modules/admins/company";
import * as accounts from "./modules/admins/accounts";
import * as enumerables from "./modules/enumerables";
import * as search from "./modules/admins/search";
import * as sponsors from "./modules/admins/sponsors";
import * as catalog from "./modules/members/catalog";
import * as transactions from "./modules/admins/transactions";
import * as stats from "./modules/admins/stats";
import * as adminOrders from "./modules/admins/orders";
import * as adminReports from "./modules/admins/reports";
import * as adminUsers from "./modules/admins/users";
import * as adminEmail from "./modules/admins/email";
import * as adminLists from "./modules/admins/lists";
import * as adminMonthlyReports from "./modules/admins/monthlyReports";
import * as postings from "./modules/admins/postings";
import * as events from "./modules/admins/events";
import * as members from "./modules/admins/members";
import * as newspapers from "./modules/admins/newspapers";
import * as replinkInfo from "./modules/admins/replinkInfo";
import * as hostCompanyInfo from "./modules/admins/hostCompanyInfo";
import * as sharedCatalogOrders from "./modules/shared/orders";
import * as adminMy from "./modules/admins/my";
import * as files from "./modules/admins/files";
import * as sharedEvents from "./modules/shared/events";
import * as companyEvents from "./modules/companies/events";
import * as config from "./modules/admins/config";
import * as adminGiftCards from "./modules/admins/giftCards";
import * as adminCatalogProducts from "./modules/admins/catalogProducts";
import * as birthdayPoints from "./modules/admins/birthdayPoints";
import * as adminStatements from "./modules/admins/statements";
import * as audit from "./modules/admins/audit";
import * as trackedLeads from "./modules/admins/trackedLeads";
import * as memberSponsorLinks from "./modules/admins/memberSponsorLinks";
import * as adminAppText from "./modules/admins/appText";
import * as mergeConflicts from "./modules/admins/mergeConflicts";

import * as orders from "./modules/members/orders";
import * as highlightedSponsors from "./modules/members/highlightedSponsors";
import * as membersMy from "./modules/members/my";
import * as giftCards from "./modules/members/giftCards";
import * as suppliers from "./modules/members/suppliers";
import * as adminGrandDestinations from "./modules/admins/grandDestinations";
import * as sharedGrandDestinations from "./modules/shared/grandDestinations";

import * as sponsorCategories from "./modules/sponsors/memberDirectory";
import * as sponsorMembers from "./modules/sponsors/members";
import * as sponsorPurchasers from "./modules/sponsors/purchasers";
import * as sponsorMonthlyReports from "./modules/sponsors/monthlyReports";

import * as monthlyReports from "./modules/shared/monthlyReports";
import * as sharedCompany from "./modules/shared/company";
import * as sharedTransactions from "./modules/shared/transactions";
import * as sharedStatements from "./modules/shared/statements";
import * as sponsorsShared from "./modules/shared/sponsors";
import * as sharedUsers from "./modules/shared/users";
import * as sharedMembers from "./modules/shared/members";
import * as sharedPurchasers from "./modules/shared/purchasers";
import * as sharedSuppliers from "./modules/shared/suppliers";
import * as sponsorPointsHistory from "./modules/sponsors/sponsorPointsHistory";
import * as membersPointsHistory from "./modules/members/membersPointsHistory";
import * as sharedNewspapers from "./modules/shared/newspapers";
import * as appText from "./modules/shared/appText";
import * as dateRanges from "./modules/shared/dateRanges";

import * as publicModule from "./modules/public";

const allModules = {
  enumerables,
  admin: {
    company,
    accounts,
    sponsors,
    transactions,
    my: adminMy,
    stats,
    search,
    orders: adminOrders,
    monthlyReports: adminMonthlyReports,
    postings,
    events,
    files,
    users: adminUsers,
    members,
    newspapers,
    reports: adminReports,
    email: adminEmail,
    config,
    giftCards: adminGiftCards,
    audit,
    lists: adminLists,
    trackedLeads,
    memberSponsorLinks,
    appText: adminAppText,
    grandDestinations: adminGrandDestinations,
    replinkInfo,
    hostCompanyInfo,
    birthdayPoints,
    catalogProducts: adminCatalogProducts,
    mergeConflicts,
    statements: adminStatements,
  },
  members: {
    orders,
    my: membersMy,
    catalog,
    giftCards,
    suppliers,
    pointsHistory: membersPointsHistory,
    highlightedSponsors,
  },
  sponsors: {
    monthlyReports: sponsorMonthlyReports,
    categories: sponsorCategories,
    members: sponsorMembers,
    purchasers: sponsorPurchasers,
    pointsHistory: sponsorPointsHistory,
  },
  companies: {
    events: companyEvents,
  },
  shared: {
    monthlyReports,
    transactions: sharedTransactions,
    sponsors: sponsorsShared,
    company: sharedCompany,
    users: sharedUsers,
    orders: sharedCatalogOrders,
    statements: sharedStatements,
    events: sharedEvents,
    suppliers: sharedSuppliers,
    members: sharedMembers,
    purchasers: sharedPurchasers,
    newspapers: sharedNewspapers,
    grandDestinations: sharedGrandDestinations,
    appText,
    dateRanges,
  },
  public: publicModule,
};

export default allModules;
