import api from "services/api";
import { action, observable } from "mobx";

export class ConfigStore {
  @observable
  public sponsorCostPerPoint: number | undefined = undefined;

  @observable
  public eventCostPerPoint: number | undefined = undefined;

  @action.bound
  public async loadConfig() {
    if (this.sponsorCostPerPoint) return;
    const sponsorsResult = await api.admin.sponsors.getCostPerPoint();
    const eventsResult = await api.admin.events.getCostPerPoint();
    this.sponsorCostPerPoint = sponsorsResult;
    this.eventCostPerPoint = eventsResult;
  }

  // convert dollars to points
  public convertEventDollarsToPoints(dollars: number | undefined) {
    if (dollars === undefined) return undefined;
    if (!this.eventCostPerPoint) return undefined;
    return dollars / this.eventCostPerPoint;
  }
}

export default new ConfigStore();
