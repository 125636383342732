import { apiClient } from "services/api/client";
import {
  EmailType,
  IEmailDefinitionDetails,
  IListedEmailDefinition,
  ISentExternalEmailModel,
  IUpdateEmailDefinitionCommand,
} from "../../types";

const DEFINITIONS_URL = "administrator/emailDefinitions";
const EMAILS_URL = "administrator/emails";

export const testEmail = async (emailType: EmailType) => {
  // Will always send to the current user's email address
  const url = `${EMAILS_URL}/test`;
  const response = await apiClient.post(url, {
    emailType,
  });

  return response;
};

export const getEmailDefinitions = async () => {
  const response = await apiClient.get<IListedEmailDefinition[]>(
    DEFINITIONS_URL
  );

  return response.data;
};

export const getEmailDefinitionDetails = async (emailDefinitionId: string) => {
  const url = `${DEFINITIONS_URL}/${emailDefinitionId}`;
  const response = await apiClient.get<IEmailDefinitionDetails>(url);
  return response.data;
};

export const updateEmailDefinition = async (
  command: IUpdateEmailDefinitionCommand
) => {
  const url = `${DEFINITIONS_URL}/${command.id}`;
  const response = await apiClient.put<IEmailDefinitionDetails>(url, command);
  return response.data;
};

export const getRecentlySentEmails = async () => {
  const url = `${EMAILS_URL}/recentlySent`;
  const response = await apiClient.get<ISentExternalEmailModel[]>(url);
  return response.data;
};
