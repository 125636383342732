import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import GlobalModal from "modals/containers/GlobalModal/GlobalModal";
import myStore from "stores/my";
import GenericDrawer from "./GenericDrawer";
import FlyoutPanel from "areas/Admin/FlyoutPanel/FlyoutPanel";
import { Observer } from "mobx-react";
import uiStore from "stores/ui";
import LinearProgress from "@material-ui/core/LinearProgress";
import withWidth from "@material-ui/core/withWidth";

const drawerWidth = 240;

const useStyles = makeStyles(() => {
  return {
    layoutRoot: {
      display: "flex",
      height: "100vh",
    },

    globalLoader: {
      width: "100%",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 10000,
    },
  };
});

const Layout = () => {
  const classes = useStyles();
  const [drawerIsOpen, setDrawerIsOpen] = React.useState(true);

  function handleDrawerClose() {
    setDrawerIsOpen(false);
  }

  const openGlobalSearch = React.useCallback(() => {
    const searchBox = document.getElementById("global-search-box");
    if (searchBox) {
      searchBox.focus();
      if ((searchBox as any).select) {
        (searchBox as any).select();
      }
    }
  }, []);

  const goToNextPage = React.useCallback(() => {
    const something = document.querySelectorAll("[aria-label^='Next page']");

    if (something.length > 0) {
      const button = something.item(0) as HTMLButtonElement;
      if (button) {
        button.click && button.click();
      }
    }
  }, []);

  const goToPreviousPage = React.useCallback(() => {
    const something = document.querySelectorAll(
      "[aria-label^='Previous page']"
    );
    something.forEach((s) => {
      const button = s as HTMLButtonElement;
      if (button) {
        button.click && button.click();
      }
    });
  }, []);

  React.useEffect(() => {
    const onKeyDown = (evt: KeyboardEvent) => {
      if (evt.ctrlKey && evt.key === " ") {
        openGlobalSearch();
      } else if (evt.ctrlKey && evt.key === "ArrowRight") {
        goToNextPage();
      } else if (evt.ctrlKey && evt.key === "ArrowLeft") {
        goToPreviousPage();
      }
    };

    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [openGlobalSearch, goToNextPage, goToPreviousPage]);

  return (
    <Observer
      render={() => {
        const userType = myStore.displayUserType;
        const showLoader = uiStore.pendingAjaxCalls > 0;
        return (
          <div className={classes.layoutRoot}>
            <CssBaseline />
            <GenericDrawer
              drawerWidth={drawerWidth}
              closeDrawer={handleDrawerClose}
              userType={userType}
              isDrawerOpen={drawerIsOpen}
            />
            <FlyoutPanel />
            <GlobalModal />
            {showLoader ? (
              <LinearProgress
                className={classes.globalLoader}
                color="secondary"
              />
            ) : null}
          </div>
        );
      }}
    />
  );
};

export default withWidth()(Layout);
