import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import Typography from "@material-ui/core/Typography";
import CompanyDropdown from "components/Dropdowns/CompanyDropdown";
import { CompanyType } from "services/api/types";
import TextField from "@material-ui/core/TextField";
import { ITinyCompany } from "services/api/types";
import Chip from "@material-ui/core/Chip";
import api from "services/api";
import { CompanyOption } from "types/options";
import { useHistory } from "react-router";
import { paths } from "routes/Paths";

type Props = {
  potentialDuplicateId?: string;
  company1: ITinyCompany;
  company1Inactive: boolean;
  company2?: ITinyCompany;
  company2Inactive?: boolean;
  companyType: CompanyType;
  doneCallback: (keptCompanyId: string, potentialDuplicateId?: string) => void;
  handleClose: () => void;
};

const MergeCompanyModal = (props: Props) => {
  const [isMerging, setIsMerging] = React.useState(false);
  const [areYouSureText, setAreYouSureText] = React.useState("");
  const [selectedCompanyOption, setSelectedCompanyOption] = React.useState<
    CompanyOption | undefined
  >(undefined);

  const history = useHistory();

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: theme.spacing(4),
      },
      warningText: {
        fontWeight: "bold",
        marginTop: theme.spacing(4),
      },
      mergeTypeRadios: {
        margin: theme.spacing(2, 0),
      },
      inactiveChip: {
        backgroundColor: "maroon",
        color: "white",
        // display: "inline-block",
        marginLeft: theme.spacing(2),
      },
    })
  );

  const classes = useStyles();

  const handleOKClick = async () => {
    const company1Id = props.company1.id;
    const company2Id = props.company2?.id ?? selectedCompanyOption?.value;

    if (!company2Id) {
      alert("Could not find other company");
      return;
    }

    setIsMerging(true);

    try {
      const mergeResult = await api.admin.company.mergeCompanies({
        keepCompanyId: company1Id,
        deleteCompanyId: company2Id,
      });

      if (mergeResult.data.conflictCount > 0) {
        history.push(
          `${paths.app.admin.root}/merge-conflicts/${mergeResult.data.dataMergeId}`
        );
      } else {
        props.doneCallback(
          mergeResult.data.remainingCompanyId,
          props.potentialDuplicateId
        );
      }

      props.handleClose();
    } finally {
      setIsMerging(false);
    }
  };

  const companyTypeRef = React.useRef<CompanyType[]>([props.companyType]);
  const areYouSureWord = "MERGE";

  const renderInactiveChip = () => {
    return (
      <Chip className={classes.inactiveChip} size="small" label="Inactive" />
    );
  };

  const saveShouldBeDisabled =
    isMerging ||
    areYouSureText !== areYouSureWord ||
    (props.company2 === undefined && selectedCompanyOption === undefined);

  return (
    <ResponsiveModalShell
      handleSave={handleOKClick}
      handleClose={props.handleClose}
      saveButtonCaption={isMerging ? "Merging..." : "Merge"}
      disableSaveButton={saveShouldBeDisabled}
      title="Merge Companies"
    >
      <div className={classes.root}>
        <div style={{ borderRight: "1px solid gray", paddingRight: "1em" }}>
          <Typography color="primary" variant="caption">
            Companies
          </Typography>
          <ul>
            <li>
              <Typography component="span">{`${props.company1.name} (${props.company1.identifier})`}</Typography>
              {props.company1Inactive && renderInactiveChip()}
            </li>
            {props.company2 && (
              <li style={{ marginTop: "1em" }}>
                <Typography component="span">{`${props.company2.name} (${props.company2.identifier})`}</Typography>
                {props.company2Inactive && renderInactiveChip()}
              </li>
            )}
            {!props.company2 && (
              <li>
                <div
                  style={{ marginTop: "2em", transform: "translateY(-10px)" }}
                >
                  <CompanyDropdown
                    searchInactives={true}
                    userType="Admin"
                    hiddenOptionIds={[props.company1.id]}
                    minWidth="200px"
                    // label={`Select ${props.companyType} to merge with`}
                    companyTypes={companyTypeRef}
                    selectedOption={selectedCompanyOption}
                    onSelectedOptionChanged={setSelectedCompanyOption}
                  />
                </div>
              </li>
            )}
          </ul>
        </div>
        <div>
          <Typography
            className={classes.warningText}
          >{`Warning! This action cannot be undone. Type ${areYouSureWord} in the box below to continue`}</Typography>
          <TextField
            margin="dense"
            variant="outlined"
            value={areYouSureText}
            onChange={(evt) => setAreYouSureText(evt.target.value)}
          />
          <Typography>
            NOTE: Merging companies with event history can take a long time (up
            to 10 minutes). Please leave this window open until the merge has
            completed.
          </Typography>
        </div>
      </div>
    </ResponsiveModalShell>
  );
};

export default MergeCompanyModal;
