import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  Option,
  INewMemberConfirmationAttachment,
  ISmallContact,
  CompanyType,
} from "services/api/types";
import api from "services/api";
import { Observer } from "mobx-react";
import companyStore from "stores/shared/company";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import CompanyDropdown from "components/Dropdowns/CompanyDropdown";
import clsx from "clsx";
import NewMemberAttachmentsList from "../components/NewMemberAttachmentsList";
import ContactCheckedListBox from "components/ContactCheckedListBox";

type Props = {
  handleClose: () => void;
  sentCallback: () => void;
  referredBy?: {
    name: string;
    identifier: string;
    id: string;
  };
};

const SendNewMemberConfirmationEmailModal = (props: Props) => {
  const referredByOption: Option | undefined = props.referredBy
    ? {
        label: `${props.referredBy.name} (${props.referredBy.identifier})`,
        value: props.referredBy.id,
      }
    : undefined;

  const [checkedAttachmentIds, setCheckedAttachmentIds] = React.useState<
    string[]
  >([]);
  const [checkedContactIds, setCheckedContactIds] = React.useState<string[]>(
    []
  );
  const [attachments, setAttachments] = React.useState<
    INewMemberConfirmationAttachment[]
  >([]);
  const [done, setDone] = React.useState(false);

  const [selectedReferrerOption, setSelectedReferrerOption] = React.useState<
    Option | undefined
  >(referredByOption);
  const [createdUserAccounts, setCreatedUserAccounts] = React.useState<
    ISmallContact[]
  >([]);
  const [numberOfEmailsSent, setNumberOfEmailsSent] = React.useState<
    number | undefined
  >();

  const [isSending, setIsSending] = React.useState(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "auto 1fr",
        gridGap: theme.spacing(2),
        transition: theme.transitions.create(["opacity"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      iconButton: {
        marginLeft: theme.spacing(1),
        color: theme.palette.primary.main,
      },
      disabled: {
        opacity: 0.5,
        pointerEvents: "none",
      },
      alert: {
        marginTop: theme.spacing(2),
      },
    })
  );

  React.useEffect(() => {
    const load = async () => {
      await companyStore.loadContacts(true);
      const attachments =
        await api.admin.members.getAllNewMemberWelcomeAttachments();
      setAttachments(attachments);
    };

    load();
  }, []);

  const handleAttachmentAdded = async (
    newAttachment: INewMemberConfirmationAttachment
  ) => {
    setAttachments([newAttachment, ...attachments]);
    setCheckedAttachmentIds([newAttachment.id, ...checkedAttachmentIds]);
  };

  const classes = useStyles();

  const handleAttachmentCheckboxChanged = (
    evt: React.ChangeEvent<HTMLInputElement>
  ) => {
    const attachmentId = evt.currentTarget.value;
    if (evt.currentTarget.checked) {
      setCheckedAttachmentIds([attachmentId, ...checkedAttachmentIds]);
    } else {
      setCheckedAttachmentIds(
        checkedAttachmentIds.filter((id) => id !== attachmentId)
      );
    }
  };

  const handleSend = async () => {
    if (checkedContactIds.length < 1) {
      alert("Please select at least one recipient");
      return;
    }

    if (checkedAttachmentIds.length < 1) {
      alert("Please select at least one attachment");
      return;
    }

    try {
      setIsSending(true);
      const response = await api.admin.members.sendNewMemberWelcome({
        attachmentIds: checkedAttachmentIds,
        contactIds: checkedContactIds,
        sponsorId: selectedReferrerOption?.value,
      });

      setCreatedUserAccounts(response.data.userAccountCreatedFor);
      setNumberOfEmailsSent(response.data.emailsSent);

      props.sentCallback();
      setDone(true);

      // Don't close automatically because they should look at the results.
      // props.handleClose();
    } finally {
      setIsSending(false);
    }
  };

  const handleContactCheckboxChanged = (
    contactId: string,
    isChecked: boolean
  ) => {
    if (isChecked) {
      setCheckedContactIds([contactId, ...checkedContactIds]);
    } else {
      setCheckedContactIds(checkedContactIds.filter((id) => id !== contactId));
    }
  };

  const renderSummary = () => {
    if (numberOfEmailsSent === undefined) return;

    return (
      <Alert className={classes.alert} color="success">
        <Typography color="primary">{`${numberOfEmailsSent} welcome ${
          numberOfEmailsSent > 1 ? "emails were" : "email was"
        } sent`}</Typography>
        <Typography color="primary">
          (Welcome emails will include a set password link if a user account was
          created or if the existing user account has not set their password)
        </Typography>
        {createdUserAccounts.length > 0 && (
          <div>
            <Typography component="div">
              The following user accounts were created automatically:
            </Typography>
            {createdUserAccounts.map((u) => {
              return (
                <Typography component="div">{`${u.firstName} ${u.lastName}`}</Typography>
              );
            })}
          </div>
        )}
        {createdUserAccounts.length === 0 && (
          <Typography color="error" component="div">
            No user accounts were created (most likely because one already
            exists).
          </Typography>
        )}
      </Alert>
    );
  };

  const sponsorCompanyTypeRef = React.useRef<CompanyType[]>(["Sponsor"]);

  return (
    <Observer
      render={() => {
        if (!companyStore.contacts) return <div />;
        const contactsWithEmail = companyStore.contacts.filter(
          (c) => c.emailAddresses.length > 0
        );

        return (
          <ResponsiveModalShell
            saveButtonCaption={isSending ? "Sending..." : "Send"}
            title="Send New Member Welcome Email"
            handleSave={done ? undefined : handleSend}
            disableSaveButton={isSending}
            handleClose={props.handleClose}
          >
            <div>
              <div
                className={clsx(classes.root, {
                  [classes.disabled]: isSending,
                })}
              >
                <div>
                  <ContactCheckedListBox
                    title="Recipients"
                    checkedContactIds={checkedContactIds}
                    handleContactCheckedChanged={handleContactCheckboxChanged}
                    contacts={contactsWithEmail}
                  />
                </div>
                <div style={{ gridColumn: "1 / 2" }}>
                  <Typography
                    color="primary"
                    component="div"
                    variant="caption"
                    style={{ marginBottom: 8 }}
                  >
                    Referring Sponsor
                  </Typography>
                  <CompanyDropdown
                    userType="Admin"
                    minWidth="200px"
                    label="Select Referring Sponsor"
                    companyTypes={sponsorCompanyTypeRef}
                    selectedOption={selectedReferrerOption}
                    onSelectedOptionChanged={setSelectedReferrerOption}
                  />
                </div>
                <div style={{ gridRow: "1 / 3", gridColumn: "2 / 3" }}>
                  <Typography color="primary" component="div" variant="caption">
                    Attachments{" "}
                    {checkedAttachmentIds.length > 0 &&
                      ` (${checkedAttachmentIds.length} selected)`}
                  </Typography>
                  <NewMemberAttachmentsList
                    onAttachmentAdded={handleAttachmentAdded}
                    attachments={attachments}
                    checkedAttachmentIds={checkedAttachmentIds}
                    onAttachmentCheckboxChanged={
                      handleAttachmentCheckboxChanged
                    }
                  />
                </div>
              </div>
              {renderSummary()}
            </div>
          </ResponsiveModalShell>
        );
      }}
    />
  );
};

export default SendNewMemberConfirmationEmailModal;
