import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import {
  CompanyType,
  ITrackedLead,
  Option,
  ITinyCompany,
} from "services/api/types";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import CompanyDropdown from "components/Dropdowns/CompanyDropdown";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import SponsorChecklist from "./SponsorChecklist";
import Grid from "@material-ui/core/Grid";
import SponsorFilters, { SponsorFilterType } from "./SponsorFilters";
import { useDebounce } from "utils/hooks";
import api from "services/api";

type Props = {
  handleClose: () => void;
  savedCallback: (newLeads: ITrackedLead[]) => void;
  member?: Option;
};

const AddTrackedLeadModal = (props: Props) => {
  const [existingLeads, setExistingLeads] = React.useState<ITrackedLead[]>([]);
  const [sponsors, setSponsors] = React.useState<ITinyCompany[]>([]);
  const [member, setMember] = React.useState<Option | undefined>(props.member);

  const [isLoadingMemberInfo, setIsLoadingMemberInfo] = React.useState(false);
  const [isLoadingSponsors, setIsLoadingSponsors] = React.useState(false);

  const [linkedSponsorIds, setLinkedSponsorIds] = React.useState<string[]>([]);
  const [earningCategoryIds, setEarningCategoryIds] = React.useState<string[]>(
    []
  );

  const [searchText, setSearchText] = React.useState("");
  const [checkedSponsorIds, setCheckedSponsorIds] = React.useState<string[]>(
    []
  );

  const [checkedSponsors, setCheckedSponsors] = React.useState<ITinyCompany[]>(
    []
  );

  const [filterType, setFilterType] =
    React.useState<SponsorFilterType>("ByName");
  const [category, setCategory] = React.useState<Option | undefined>();
  const [notes, setNotes] = React.useState("");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      listBoxHost: {
        height: 300,
        minHeight: 300,
        maxHeight: 300,
      },
      earningPointsIn: {
        color: "gray",
        opacity: 0.7,
      },
      summary: {
        height: 65,
        minHeight: 65,
        maxHeight: 65,
        overflowY: "auto",
      },
    })
  );

  const classes = useStyles();

  const debouncedSearchText = useDebounce(searchText, 500);

  React.useEffect(() => {
    const search = async (companyName: string) => {
      setFilterType("ByName");
      setIsLoadingSponsors(true);
      if (debouncedSearchText) {
        const result = await api.admin.search.searchCompanies(
          companyName,
          true,
          "Sponsor"
        );

        if (debouncedSearchText === result.searchTerm) {
          setSponsors(result.companies);
          setIsLoadingSponsors(false);
        }
      }
    };

    search(debouncedSearchText);
  }, [debouncedSearchText]);

  React.useEffect(() => {
    const findSponsorsByCategory = async (categoryId: string) => {
      setIsLoadingSponsors(true);
      setFilterType("ByCategory");
      const result = await api.admin.sponsors.findByCategory(categoryId);
      setSponsors(result);
      setIsLoadingSponsors(false);
    };

    if (category) {
      findSponsorsByCategory(category.value);
    }
  }, [category]);

  React.useEffect(() => {
    const loadInfo = async (memberId: string) => {
      setIsLoadingMemberInfo(true);
      const categoriesEarningPointsIn =
        await api.admin.members.getCategoryIdsEarningPointsIn(memberId);
      const existingLinks = await api.shared.purchasers.getSuppliers(memberId);
      const existingMemberLeads = await api.admin.members.getTrackedLeads(
        memberId
      );

      setEarningCategoryIds(categoriesEarningPointsIn);
      setExistingLeads(existingMemberLeads);
      setLinkedSponsorIds(existingLinks.suppliers.map((s) => s.supplierId));
      setIsLoadingMemberInfo(false);
    };

    if (member) {
      loadInfo(member.value);
    }
  }, [member]);

  const memberCompanyTypeRef = React.useRef<CompanyType[]>(["Member"]);

  const handleSave = async () => {
    const result = await api.admin.trackedLeads.createTrackedLeads({
      memberId: member!.value,
      sponsorIds: checkedSponsorIds,
      notes,
    });

    props.savedCallback(result);
    props.handleClose();
  };

  const handleCheckedSponsorChanged = (
    sponsor: ITinyCompany,
    isChecked: boolean
  ) => {
    if (isChecked) {
      setCheckedSponsorIds([...checkedSponsorIds, sponsor.id]);
      setCheckedSponsors([...checkedSponsors, sponsor]);
    } else {
      setCheckedSponsorIds(checkedSponsorIds.filter((id) => id !== sponsor.id));
      setCheckedSponsors(checkedSponsors.filter((s) => s.id !== sponsor.id));
    }
  };

  const renderCategoryOption = (option: Option): React.ReactNode => {
    const earningPointsIn = earningCategoryIds.includes(option.value);

    if (earningPointsIn) {
      return (
        <div
          className={classes.earningPointsIn}
        >{`${option.label} (Earning points)`}</div>
      );
    }

    return <div>{option.label}</div>;
  };

  const renderFilters = () => {
    if (isLoadingMemberInfo) {
      return (
        <Typography>Loading Member Information. Please wait...</Typography>
      );
    } else if (member) {
      return (
        <SponsorFilters
          disabled={isLoadingMemberInfo}
          selectedCategory={category}
          onSelectedCategoryChanged={setCategory}
          searchText={searchText}
          onSearchTextCHanged={setSearchText}
          onFilterTypeChanged={setFilterType}
          selectedFilterType={filterType}
          categoryIdsEarningPointsIn={earningCategoryIds}
          renderOption={renderCategoryOption}
        />
      );
    } else {
      return (
        <Typography>
          Please select a member. Sponsor selection will be enabled once you
          select a member.
        </Typography>
      );
    }
  };

  const existingLeadSponsorIds = existingLeads.map((m) => m.sponsorId);

  return (
    <ResponsiveModalShell
      handleSave={handleSave}
      title="Add Lead(s)"
      disableSaveButton={checkedSponsorIds.length === 0 || member === undefined}
      handleClose={props.handleClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CompanyDropdown
            disabled={Boolean(props.member) || checkedSponsorIds.length > 0}
            userType="Admin"
            minWidth="200px"
            companyTypes={memberCompanyTypeRef}
            label="Select Member"
            onSelectedOptionChanged={(opt) => setMember(opt)}
            selectedOption={member}
          />
        </Grid>
        <Grid item xs={6}>
          {renderFilters()}
        </Grid>
        <Grid item xs={6}>
          <div className={classes.listBoxHost}>
            {member && (
              <SponsorChecklist
                disabled={isLoadingSponsors || isLoadingMemberInfo}
                sponsors={sponsors}
                linkedSponsorIds={linkedSponsorIds}
                checkedSponsorIds={checkedSponsorIds}
                existingLeadSponsorIds={existingLeadSponsorIds}
                onSponsorCheckedChange={handleCheckedSponsorChanged}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <TextField
            multiline={true}
            minRows={4}
            label="Notes"
            fullWidth
            placeholder="Enter note here"
            variant="outlined"
            value={notes}
            onChange={(evt) => {
              setNotes(evt.target.value);
            }}
            margin="dense"
          />
        </Grid>
        {member && (
          <Grid item xs={12} className={classes.summary}>
            <Typography component="div" variant="caption" color="primary">
              {`Leads will be created for ${member?.label} with following sponsors:`}
            </Typography>
            {checkedSponsors.map((s, index) => {
              const name =
                index === checkedSponsors.length - 1 ? s.name : `${s.name}, `;
              return <span key={s.id}>{name}</span>;
            })}
          </Grid>
        )}
      </Grid>
    </ResponsiveModalShell>
  );
};

export default AddTrackedLeadModal;
