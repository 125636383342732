import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { PartyType } from "services/api/types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

type Props = {
  value: PartyType | undefined;
  onChange: (value: PartyType) => void;
  label: string;
};

const PartyTypeDropdown = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
    })
  );

  const classes = useStyles();

  const handlePartyTypeChanged = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    props.onChange(event.target.value as PartyType);
  };

  const selectName = `${props.label}-select`;

  return (
    <div className={classes.root}>
      <FormControl fullWidth variant="outlined" margin="dense">
        <InputLabel htmlFor={selectName}>{props.label}</InputLabel>
        <Select
          inputProps={{
            name: selectName,
          }}
          labelWidth={70}
          value={props.value}
          onChange={handlePartyTypeChanged}
        >
          <MenuItem value="Company">Company</MenuItem>
          <MenuItem value="MarketingFund">Marketing Fund</MenuItem>
          <MenuItem value="None">N/A</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default PartyTypeDropdown;
