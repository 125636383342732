import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import Typography from "@material-ui/core/Typography";
import * as appConstants from "applications/appConstants";

type Props = {
  handleClose: () => void;
};

const CancellationPolicyModal = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      must: {
        textDecoration: "underline",
      },
    })
  );

  const classes = useStyles();
  const companyName =
    process.env.REACT_APP_BRANDING === appConstants.BuildersClubNorth
      ? "Builders Club North, Inc"
      : "BuildPerks, Inc.";

  return (
    <ResponsiveModalShell
      handleClose={props.handleClose}
      hideDoneButton={false}
      title="CANCELLATION POLICIES"
    >
      <Typography variant="h6">Local Events</Typography>
      <Typography variant="body2">
        <ul>
          <li>
            Cancellations received 35 days prior to the event indicated above
            will receive a 100% refund.
          </li>
          <li>
            Cancellations received from 34 days up to 15 days prior to event
            will be penalized 50% of the cost of the event.
          </li>
          <li>
            Cancellations received less than 15 days prior to the event will be
            penalized 100% of the cost of the event.
          </li>
          <li>
            All cancellations <span className={classes.must}>MUST</span> be
            communicated in writing.
          </li>
        </ul>
      </Typography>
      <Typography variant="h6">Grand Destinations</Typography>
      <Typography variant="body2">
        <ul>
          <li>
            Each Grand Destination is unique – therefore a variety of factors
            can impact refund availability.
          </li>
          <li>
            Please contact {companyName} regarding cancellation policies for
            your specific Grand Destination.
            {process.env.REACT_APP_BRANDING ===
              appConstants.BuildersClubNorth && (
              <ul>
                <li>
                  Kristin Gardas – 612/359-1101 – kristing@buildersclubnorth.com
                </li>
              </ul>
            )}
            {process.env.REACT_APP_BRANDING === appConstants.BuildPerks && (
              <ul>
                <li>Connie Burney – 678/776-1459 – connie@buildperks.com</li>
              </ul>
            )}
          </li>
          <li>
            All cancellations <span className={classes.must}>MUST</span> be
            communicated in writing.
          </li>
        </ul>
      </Typography>
      <Typography variant="body2">
        As a participant in this {companyName} event, I hereby acknowledge and
        agree to assume all risks and further release, waive, discharge and hold
        harmless {companyName} and their affiliates, officers, employees,
        agents, successors and assigns from all liability to myself, spouse,
        children, parents, legal representatives, heirs and assigns, for any and
        all known and unknown personal injuries, including death, and property
        damage which I (we) may sustain while on the trip including all risks
        connected therewith whether caused by the negligence of {companyName} or
        not.
      </Typography>
    </ResponsiveModalShell>
  );
};

export default CancellationPolicyModal;
