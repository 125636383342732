import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { INewTransactionType, ITransactionType } from "services/api/types";
import PartyTypeDropdown from "../components/PartyTypeDropdown";
import { PartyType } from "services/api/types";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

type Props = {
  transactionType?: ITransactionType;
  handleClose: () => void;
  saveNewTransactionType: (tt: INewTransactionType) => Promise<void>;
  updateExistingTransactionType: (tt: ITransactionType) => Promise<void>;
};

const AddEditTransactionTypeModal = (props: Props) => {
  const [description, setDescription] = React.useState<string>(
    props.transactionType?.description ?? ""
  );

  const [defaultPoints, setDefaultPoints] = React.useState<string | undefined>(
    props.transactionType?.defaultPoints?.toString()
  );

  const [defaultNote, setDefaultNote] = React.useState<string>(
    props.transactionType?.defaultNote ?? ""
  );

  const [from, setFrom] = React.useState<PartyType | undefined>(
    props.transactionType?.fromPartyType
  );

  const [to, setTo] = React.useState<PartyType | undefined>(
    props.transactionType?.toPartyType
  );

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
    })
  );

  const classes = useStyles();

  const validate = () => {
    if (!from) {
      alert("Please select a 'From Account Type'");
      return false;
    }

    if (!to) {
      alert("Please select a 'To Account Type'");
      return false;
    }

    return true;
  };

  const handleSave = async () => {
    const valid = validate();
    if (!valid) return;

    if (props.transactionType) {
      await props.updateExistingTransactionType({
        description: description,
        defaultNote: defaultNote,
        defaultPoints: defaultPoints ? parseInt(defaultPoints, 10) : undefined,
        fromPartyType: from!,
        id: props.transactionType.id,
        isEvent: false,
        isManual: true,
        toPartyType: to!,
      });

      props.handleClose();
    } else {
      await props.saveNewTransactionType({
        description: description,
        defaultNote: defaultNote,
        defaultPoints: defaultPoints ? parseInt(defaultPoints, 10) : undefined,
        fromPartyType: from!,
        isEvent: false,
        isManual: true,
        toPartyType: to!,
      });
      props.handleClose();
    }
  };

  return (
    <ResponsiveModalShell
      width="xl"
      handleClose={props.handleClose}
      saveButtonCaption={props.transactionType ? "Update" : "Add"}
      handleSave={handleSave}
      title={
        props.transactionType ? "Edit Transaction Type" : "Add Transaction Type"
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            label="Description"
            value={description}
            onChange={(evt) => setDescription(evt.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            label="Default Note"
            value={defaultNote}
            onChange={(evt) => setDefaultNote(evt.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            type="number"
            fullWidth
            margin="dense"
            variant="outlined"
            label="Default Points"
            value={defaultPoints}
            onChange={(evt) => setDefaultPoints(evt.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <PartyTypeDropdown
            label="From Account Type"
            value={from}
            onChange={(value) => setFrom(value)}
          />
        </Grid>
        <Grid item xs={6}>
          <PartyTypeDropdown
            label="To Account Type"
            value={to}
            onChange={(value) => setTo(value)}
          />
        </Grid>
      </Grid>
    </ResponsiveModalShell>
  );
};

export default AddEditTransactionTypeModal;
