import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import HostCompanyInfoForm from "../Config/HostCompanyInfoForm";
import { IHostCompanyInfo } from "services/api/types";
import api from "services/api";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import * as yup from "yup";
import { Formik } from "formik";
import uiStore from "stores/ui";
import { Observer } from "mobx-react";
import Alert from "@material-ui/lab/Alert";

type Props = {
  handleClose: () => void;
};

const HostCompanyInfoModal = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
    })
  );

  const classes = useStyles();

  const schema = yup.object().shape({
    companyName: yup.string().required("Required"),
    address1: yup.string().required("Required"),
    zip: yup.string().required("Required"),
    city: yup.string().required("Required"),
    state: yup.string().required("Required"),
    phoneNumber: yup.string().required("Required"),
    emailAddress: yup.string().email().required("Required"),
  });

  React.useEffect(() => {
    uiStore.ensureHostCompanyInfoIsLoaded();
  }, []);

  const handleSubmit = async (data: IHostCompanyInfo) => {
    await api.admin.hostCompanyInfo.updateHostCompanyInfo(data);
    uiStore.refreshHostCompanyInfo();
    props.handleClose();
  };

  return (
    <Observer
      render={() => {
        if (!uiStore.hostCompanyInfo) return <div />;

        return (
          <Formik
            initialValues={uiStore.hostCompanyInfo}
            validationSchema={schema}
            onSubmit={handleSubmit}
            render={(formikProps) => {
              return (
                <ResponsiveModalShell
                  handleSave={() => formikProps.submitForm()}
                  saveButtonCaption="Save"
                  handleClose={props.handleClose}
                  title="Update Host Company Info"
                >
                  <div className={classes.root}>
                    <Alert severity="info">
                      Fields here may be used on the home page, event group
                      invoices and email signatures.
                    </Alert>
                    <br />
                    {uiStore.hostCompanyInfo && <HostCompanyInfoForm />}
                  </div>
                </ResponsiveModalShell>
              );
            }}
          />
        );
      }}
    />
  );
};

export default HostCompanyInfoModal;
