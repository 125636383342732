export const formatDate = (date: string | Date) => {
  if (typeof date === "string") {
    return new Date(date).toISOString().split("T")[0];
  } else {
    return date.toISOString().split("T")[0];
  }
};

export const monthLookup = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "June",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

export const monthAbbrevLookup = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export const getMonthName = (monthNumber: number) => {
  const hasProperty = Object.prototype.hasOwnProperty.call(
    monthLookup,
    monthNumber
  );
  if (hasProperty) {
    return monthLookup[monthNumber];
  }

  return "Unknown";
};
