import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import purple from "@material-ui/core/colors/purple";
import teal from "@material-ui/core/colors/teal";

export const companyColors = {
    sponsor: teal[600],
    member: blue[600],
    prospect: purple[500],
    competitor: red.A700
}