import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ISmallContact } from "services/api/types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

type Props = {
  companyContacts: ISmallContact[];
  value: string;
  touched: boolean | undefined;
  error: string | undefined;
  handleChange: (value: string) => void;
};

export type Option = {
  value: string;
  label: string;
};

const PrimaryContactField = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
    })
  );

  const classes = useStyles();
  const options = props.companyContacts.map((cc) => {
    return {
      value: cc.id,
      label: `${cc.firstName} ${cc.lastName}`,
    };
  });

  const selectedOption = options.find((o) => o.value === props.value);

  return (
    <FormControl fullWidth>
      <Autocomplete
        onChange={(event: any, option: any) => {
          props.handleChange(option.value);
        }}
        getOptionLabel={(option) => {
          if (typeof option.label === "string") {
            return option.label;
          }

          return (option as any).label.label;
        }}
        className={classes.root}
        options={options}
        freeSolo={false}
        disableClearable={true}
        value={selectedOption}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label="Primary Contact"
              variant="outlined"
              name="primaryContactId"
              style={{ minWidth: 100 }}
              error={Boolean(props.error)}
              margin="dense"
            />
          );
        }}
      />
      <FormHelperText style={{ color: "red" }} variant="standard">
        {props.error}
      </FormHelperText>
    </FormControl>
  );
};

export default PrimaryContactField;
