import React from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { useHistory } from "react-router";
import { paths } from "./Paths";
import SubtleLinearProgress from "components/Shell/SubtleLinearProgress";

const EditOrderContainer = React.lazy(
  () => import("areas/Admin/Orders/EditOrderContainer")
);

const GenericDataQualityTable = React.lazy(
  () => import("areas/Admin/Maintenance/GenericDataQualityTable")
);

const GiftCardsContainer = React.lazy(
  () => import("areas/Members/GiftCards/GiftCardsContainer")
);

const EditAppTextContainer = React.lazy(
  () => import("areas/Admin/Config/EditAppTextContainer")
);

const TrackedLeadsContainer = React.lazy(
  () => import("areas/Admin/TrackedLeads/TrackedLeadsContainer")
);

const EmailHome = React.lazy(() => import("areas/Admin/Email/EmailHome"));
const NewspapersHome = React.lazy(
  () => import("areas/Admin/Newspapers/NewspapersHome")
);

const OrdersContainer = React.lazy(
  () => import("areas/Admin/Orders/OrdersContainer")
);
const UserListContainer = React.lazy(
  () => import("areas/Admin/Users/GeneralUserListContainer")
);
const AdminsListContainer = React.lazy(
  () => import("areas/Admin/Users/AdministratorsContainer")
);
const PdfPreviewer = React.lazy(
  () => import("areas/Admin/DevTools/PdfPreviewer")
);
const CatalogContainer = React.lazy(
  () => import("areas/Members/Catalog/CatalogContainer")
);
const CatalogHomeContainer = React.lazy(
  () => import("areas/Members/Catalog/CatalogHome")
);
const ReportsDashboard = React.lazy(
  () => import("areas/Admin/Reports/ReportsDashboard")
);
const MaintenanceIndex = React.lazy(() => import("areas/Admin/Maintenance"));
const MarketingFundContainer = React.lazy(
  () => import("areas/Admin/Transactions/MarketingFundAccountContainer")
);
const EmailTester = React.lazy(
  () => import("areas/Admin/Maintenance/EmailTester")
);
const ConfigValues = React.lazy(
  () => import("areas/Admin/Maintenance/ConfigValues")
);
const PostingContainer = React.lazy(
  () => import("areas/Admin/Postings/CreatePostingContainer")
);
const ListPostingsContainer = React.lazy(
  () => import("areas/Admin/Postings/ListedPostingsContainer")
);
const MonthlyReportProcessorContainer = React.lazy(
  () => import("areas/Admin/MonthlyReports/ProcessingContainer")
);
const CompanyDetails = React.lazy(
  () => import("areas/Shared/CompanyDetails/CompanyDetailsContainer")
);
const EventsContainer = React.lazy(
  () => import("areas/Admin/Events/EventsContainer")
);
const ContactUs = React.lazy(() => import("areas/Shared/ContactUs"));
const CreateMember = React.lazy(
  () => import("areas/Admin/Members/components/AddMember")
);
const ViewMemberReferral = React.lazy(
  () => import("areas/Admin/MemberReferrals/ViewMemberReferral")
);
const MemberReferralsContainer = React.lazy(
  () => import("areas/Admin/MemberReferrals/MemberReferralsContainer")
);
const OrderDetailsContainer = React.lazy(
  () => import("areas/Shared/Orders/components/OrderDetailsContainer")
);
const ListContainer = React.lazy(
  () => import("areas/Admin/Maintenance/Lists/ListContainer")
);
const SupplierProfileContainer = React.lazy(
  () => import("areas/Members/SupplierProfile/SupplierProfileContainer")
);

const AdminSupplierProfilesContainer = React.lazy(
  () => import("areas/Admin/SupplierProfiles/SupplierProfilesContainer")
);

const Dashboard = React.lazy(() => import("areas/Admin/Dashboard/Dashboard"));

const PotentialDuplicateCompaniesContainer = React.lazy(
  () =>
    import(
      "areas/Admin/PotentialDuplicates/PotentialDuplicatesCompaniesContainer"
    )
);

const PotentialDuplicateContactsContainer = React.lazy(
  () =>
    import(
      "areas/Admin/PotentialDuplicates/PotentialDuplicateContactsContainer"
    )
);

const MergeConflictsContainer = React.lazy(
  () => import("areas/Admin/MergeConflicts/MergeConflictsContainer")
);

const TransactionTypesContainer = React.lazy(
  () => import("areas/Admin/Maintenance/Lists/TransactionTypesContainer")
);

const AdminRoutes = () => {
  const history = useHistory();

  return (
    <React.Suspense fallback={<SubtleLinearProgress />}>
      <Router history={history}>
        <Switch>
          <Route
            exact
            path={paths.app.root}
            render={() => <Redirect to={`${paths.app.admin.root}/home`} />}
          />
          <Route path={`${paths.app.admin.root}/home`} component={Dashboard} />
          {/*
         Note: Admins, Sponsors and Members normally don't share routes.
         The one below, however appears in an email and, while there are other
         options, it was just easy to add the route here exactly as it is
         in MemberRoutes.tsx
         */}
          <Route
            exact
            path={`${paths.app.members.root}/orders/:friendlyOrderId`}
            component={OrderDetailsContainer}
          />
          <Route
            path={`${paths.app.members.root}/gift-card-orders/:giftCardOrderId`}
            component={GiftCardsContainer}
          />
          <Route
            path={`${paths.app.admin.root}/orders`}
            component={OrdersContainer}
          />
          <Route
            path={`${paths.app.admin.root}/supplier-profiles/:supplierProfileId`}
            render={(props) => (
              <SupplierProfileContainer {...props} currentUserType="Admin" />
            )}
          />
          <Route
            path={`${paths.app.admin.root}/dataQuality/:type`}
            render={(props) => <GenericDataQualityTable {...props} />}
          />
          <Route
            path={`${paths.app.admin.root}/company-users`}
            render={(props) => <UserListContainer />}
          />
          <Route
            path={`${paths.app.admin.root}/edit-app-text`}
            render={(props) => <EditAppTextContainer />}
          />
          <Route
            path={`${paths.app.admin.root}/administrators`}
            component={AdminsListContainer}
          />
          <Route
            path={`${paths.app.admin.root}/pdfs`}
            component={PdfPreviewer}
          />
          <Route
            path={`${paths.app.admin.root}/companies/potential-duplicates`}
            component={PotentialDuplicateCompaniesContainer}
          />
          <Route
            path={`${paths.app.admin.root}/contacts/potential-duplicates`}
            component={PotentialDuplicateContactsContainer}
          />
          <Route
            path={paths.app.adminsAndMembers.catalog}
            component={CatalogContainer}
          />
          <Route
            path={paths.app.adminsAndMembers.mobileCatalog}
            component={CatalogHomeContainer}
          />
          <Route
            path={`${paths.app.admin.root}/reports`}
            component={ReportsDashboard}
          />
          <Route
            exact
            path={`${paths.app.admin.root}/maintenance`}
            component={MaintenanceIndex}
          />
          <Route
            exact
            path={`${paths.app.admin.root}/leads`}
            component={TrackedLeadsContainer}
          />
          <Route
            exact
            path={`${paths.app.admin.root}/marketing-fund`}
            component={MarketingFundContainer}
          />
          <Route
            path={`${paths.app.admin.root}/maintenance/email/test`}
            component={EmailTester}
          />
          <Route
            path={`${paths.app.admin.root}/maintenance/email`}
            component={EmailHome}
          />
          <Route
            path={`${paths.app.admin.root}/maintenance/config`}
            component={ConfigValues}
          />
          <Route
            path={`${paths.app.admin.root}/newspapers`}
            render={(p) => <NewspapersHome />}
          />
          <Route
            path={`${paths.app.admin.root}/merge-conflicts/:dataMergeId`}
            component={MergeConflictsContainer}
          />
          <Route
            path={`${paths.app.admin.root}/createPosting`}
            component={PostingContainer}
          />
          <Route
            exact
            path={`${paths.app.admin.root}/postings`}
            component={ListPostingsContainer}
          />
          <Route
            exact
            path={`${paths.app.admin.root}/lists/transactionTypes`}
            component={TransactionTypesContainer}
          />
          <Route
            exact
            path={`${paths.app.admin.root}/lists/:listName`}
            component={ListContainer}
          />
          <Route
            exact
            path={`${paths.app.admin.root}/processReports/:monthlyReportId?/:sponsorId?`}
            render={(props) => {
              return (
                <MonthlyReportProcessorContainer
                  {...props}
                  isNestedInCompanyDetails={false}
                />
              );
            }}
          />
          <Route
            path={`${paths.app.admin.companyDetails}/:companyId`}
            component={CompanyDetails}
          />
          <Route
            path={`${paths.app.admin.root}/events/:eventId?`}
            component={EventsContainer}
          />
          <Route
            exact
            path={`${paths.app.admin.root}/contact`}
            component={ContactUs}
          />
          <Route
            exact
            path={`${paths.app.admin.root}/create-member/:referralId?`}
            component={CreateMember}
          />
          <Route
            exact
            path={`${paths.app.admin.root}/member-referral/:referralId`}
            component={ViewMemberReferral}
          />
          <Route
            exact
            path={`${paths.app.admin.root}/member-referrals`}
            component={MemberReferralsContainer}
          />
          <Route
            exact
            path={`${paths.app.admin.root}/supplier-profiles`}
            component={AdminSupplierProfilesContainer}
          />
          <Route
            exact
            path={`${paths.app.admin.root}/catalog-orders/:purchaseOrderNumber`}
            component={EditOrderContainer}
          />
          <Route exact={true} path="*" render={() => <div>Not Found</div>} />
        </Switch>
      </Router>
    </React.Suspense>
  );
};

export default AdminRoutes;
