import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BrandForm from "modals/components/CompanyForms/Brand/BrandForm";
import { CreateCompanyBrandCommand } from "services/api/types";
import companyStore from "stores/shared/company";
import enumStore from "stores/enumerable";

type OriginalProps = {
  handleClose: () => void;
};

type Props = OriginalProps & RouteComponentProps;

class ModalAddCompanyBrand extends Component<Props> {
  public render = () => (
    <BrandForm
      companyId={companyStore.details!.id}
      companyBrands={companyStore.details ? companyStore.details.brands : []}
      handleSubmit={this.handleSubmit}
      handleCancel={this.props.handleClose}
    />
  );

  private handleSubmit = async (payload: CreateCompanyBrandCommand) => {
    await companyStore.createBrand(payload);

    if (payload.brandName) {
      // A brand was created so fetch them all again
      await enumStore.ensureBrandsFetched(true);
    }

    this.props.handleClose();
  };
}

export default withRouter(ModalAddCompanyBrand);
