import React from "react";
import {
  ICompanyUser,
  ICreateUserCommand,
  IUpdateUserCommand,
  IAdminUser,
  CompanyType,
  AdminPermissions as AdminPermissionsType,
} from "services/api/types";
import * as yup from "yup";
import { Formik, FormikActions, FormikProps } from "formik";
import usersStore from "stores/admin/users";
import { Observer } from "mobx-react";
import api from "services/api";
import { useDebounce } from "utils/hooks";
import { companyRoleOptions } from "types/roles";
import { ICompanyPermissionViewModel } from "services/api/types";
import BasicInfo, { UserNameState } from "./BasicInfo";
import AdminPermissions from "./AdminPermissions";
import CompanyPermissions, {
  ICompanyPermission,
  RoleOption,
} from "./CompanyPermissions";
import { CompanyOption } from "types/options";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";

type Props = {
  handleClose: () => void;
  user?: ICompanyUser | IAdminUser;
  userIsBcnAdmin: boolean;
  mode: "Administrator" | "CompanyUser";
  currentUserCompanies: ICompanyPermissionViewModel[];
  adminCreatedCallback?: (admin: IAdminUser) => void;
  adminUpdatedCallback?: (admin: IAdminUser) => void;
  companyUserCreatedCallback?: (admin: ICompanyUser) => void;
  companyUserUpdatedCallback?: (admin: ICompanyUser) => void;
  currentCompany?: {
    id: string;
    name: string;
    computedBalance: number;
    identifier: string;
    companyType: CompanyType;
  };
  currentContact?: {
    firstName: string;
    lastName: string;
    email: string;
  };
};

const AddEditUserModal = (props: Props) => {
  if (!props.userIsBcnAdmin && props.mode === "Administrator") {
    throw Error("Invalid prop values.");
  }

  if (
    props.mode === "Administrator" &&
    !props.user &&
    !props.adminCreatedCallback
  ) {
    throw Error("Must provide a value for adminCreatedCallback");
  }

  if (
    props.mode === "Administrator" &&
    props.user &&
    !props.adminUpdatedCallback
  ) {
    throw Error("Must provide a value for adminUpdatedCallback");
  }

  const [userNameState, setUserNameState] = React.useState<UserNameState>(
    props.user ? "Locked" : "Unknown"
  );

  // Formik stores its own value. This one is used for checking
  // availability (debounced)
  const [userName, setUserName] = React.useState("");
  const debouncedUserName = useDebounce(userName, 500);

  React.useEffect(() => {
    if (debouncedUserName) {
      const checkUserNameAvailability = async () => {
        if (!debouncedUserName || debouncedUserName.length < 5) {
          setUserNameState("NotValid");
        } else {
          setUserNameState("Checking");

          try {
            await api.shared.users.getUserByUserName(debouncedUserName);
            // No error (404) so the user must exist
            setUserNameState("Taken");
          } catch (e) {
            if ((e as any).status === 404) {
              setUserNameState("Available");
            }
          }
        }
      };

      checkUserNameAvailability();
    }
  }, [debouncedUserName]);

  const isCompanyUser = (
    user: ICompanyUser | IAdminUser
  ): user is ICompanyUser => {
    return (user as ICompanyUser).permissions !== undefined;
  };

  const isAdminUser = (user: ICompanyUser | IAdminUser): user is IAdminUser => {
    return (user as IAdminUser).hasCatalogOrderManagerRole !== undefined;
  };

  const schema = yup.object().shape({
    firstName: yup.string().required("First name is required"),
    lastName: yup.string(),
    email: yup.string().email().required("Email address is required"),
    phoneNumber: yup.string(),
    userName: yup.string().email().required("User name is required"),
    permissions: yup
      .array()
      .of(
        yup.object().shape<ICompanyPermission>({
          role: yup
            .object()
            .shape<RoleOption>({
              label: yup.string(),
              value: yup.number(),
            })
            .nullable()
            .required("Role is required"),
          company: yup
            .object()
            .shape<CompanyOption>({
              computedBalance: yup.number(),
              label: yup.string(),
              value: yup.string().required("Company is required"),
              companyType: yup.mixed(),
            })
            .nullable()
            .required("Company is required"),
        })
      )
      .notRequired(),
  });

  type FormData = {
    firstName: string;
    lastName: string;
    email: string;
    userName: string;
    phoneNumber: string;
    jobTitle: string;
    permissions: ICompanyPermission[];
  } & AdminPermissionsType;

  const handleSubmit = async (
    values: FormData,
    actions: FormikActions<FormData>
  ) => {
    const commandPermissions = values.permissions.map((p) => {
      return { companyId: p.company!.value, roleId: p.role!.value };
    });

    if (!props.user && props.mode === "CompanyUser") {
      createCompanyUser(values, commandPermissions);
    } else if (!props.user && props.mode === "Administrator") {
      createAdministrator(values);
    } else if (props.user && isCompanyUser(props.user)) {
      if (props.mode !== "CompanyUser") throw Error("Invalid mode");
      updateCompanyUser(props.user.id, values, commandPermissions);
    } else if (props.user && isAdminUser(props.user)) {
      if (props.mode !== "Administrator") throw Error("Invalid mode");
      updateAdministrator(props.user.id, values);
    }
  };

  const createAdministrator = async (values: FormData) => {
    const user: IAdminUser = Object.assign({}, values, {
      isEnabled: true,
      id: "ignored",
    });

    const resposne = await api.admin.users.createAdminUserAccount(user);
    if (resposne.data && props.adminCreatedCallback) {
      props.adminCreatedCallback(resposne.data);
      props.handleClose();
    }
  };

  const updateAdministrator = async (userId: string, values: FormData) => {
    const user: IAdminUser = Object.assign({}, values, {
      isEnabled: true,
      id: props.user!.id,
    });

    const response = await api.admin.users.updateAdminUserAccount(user);
    if (response.data && props.adminUpdatedCallback) {
      props.adminUpdatedCallback(response.data);
      props.handleClose();
    }
  };

  const createCompanyUser = async (
    values: FormData,
    permissions: { companyId: string; roleId: number }[]
  ) => {
    const { email, lastName, firstName, userName } = values;

    const command: ICreateUserCommand = {
      firstName,
      lastName,
      userName,
      emailAddress: email,
      permissions,
    };

    const newUserResult = await usersStore.createUser(command);
    if (newUserResult) {
      if (props.companyUserCreatedCallback) {
        props.companyUserCreatedCallback(newUserResult.data);
      }
      props.handleClose();
    }
  };

  const updateCompanyUser = async (
    userId: string,
    values: FormData,
    permissions: { companyId: string; roleId: number }[]
  ) => {
    const { firstName, lastName, email } = values;
    const command: IUpdateUserCommand = {
      userId: userId,
      firstName,
      lastName,
      email,
      permissions,
    };

    const updatedUser = await usersStore.updateUser(command);

    if (updatedUser && props.companyUserUpdatedCallback) {
      props.companyUserUpdatedCallback(updatedUser);
    }

    props.handleClose();
  };

  const getDefaultCompanyOption = (): CompanyOption | null => {
    if (!props.user && props.currentCompany) {
      return {
        computedBalance: props.currentCompany.computedBalance,
        label: `${props.currentCompany.name} (${props.currentCompany.identifier})`,
        value: props.currentCompany.id,
        companyType: props.currentCompany.companyType,
      };
    }

    return null;
  };

  const getExistingUserCompanyPermissions = (): ICompanyPermission[] => {
    if (!props.user) throw Error("No user!");

    if (!isCompanyUser(props.user)) throw Error("User is not a company user");

    const mapped = props.user.permissions.map((p) => {
      const permission: ICompanyPermission = {
        company: {
          computedBalance: p.pointBalance,
          label: `${p.companyName} (${p.companyIdentifier})`,
          value: p.companyId,
          companyType: p.companyType,
        },
        role: companyRoleOptions.find((r) => r.value === p.roleId)!,
      };

      return permission;
    });

    return mapped;
  };

  const getInitialPermissionsValue = (): ICompanyPermission[] => {
    if (props.user && isCompanyUser(props.user)) {
      return getExistingUserCompanyPermissions();
    }

    if (!props.user && props.mode === "CompanyUser") {
      return [{ company: getDefaultCompanyOption(), role: null }];
    }

    return [];
  };

  // We might be creating a user from the contact sAdminPermissionsInfocreen, in which case
  // we can provide some reasonable defaults.
  const initialValues: FormData = {
    phoneNumber:
      props.user && isAdminUser(props.user) ? props.user.phoneNumber : "",
    email: props.user
      ? props.user.email
      : props.currentContact
      ? props.currentContact.email
      : "",
    jobTitle: props.user && isAdminUser(props.user) ? props.user.jobTitle : "",
    firstName: props.user
      ? props.user.firstName
      : props.currentContact
      ? props.currentContact.firstName
      : "",
    lastName: props.user
      ? props.user.lastName
      : props.currentContact
      ? props.currentContact.lastName
      : "",
    userName: props.user
      ? props.user.userName
      : props.currentContact
      ? props.currentContact.email
      : "",
    hasCatalogOrderManagerRole:
      props.user && isAdminUser(props.user)
        ? props.user.hasCatalogOrderManagerRole
        : false,
    hasGeneralAdminRole:
      props.user && isAdminUser(props.user)
        ? props.user.hasGeneralAdminRole
        : false,
    hasCompanyUserAccountManagerRole:
      props.user && isAdminUser(props.user)
        ? props.user.hasCompanyUserAccountManagerRole
        : false,
    hasImpersonatorRole:
      props.user && isAdminUser(props.user)
        ? props.user.hasImpersonatorRole
        : false,
    hasMonthlyReportEditorRole:
      props.user && isAdminUser(props.user)
        ? props.user.hasMonthlyReportEditorRole
        : false,
    hasSettingsManagerRole:
      props.user && isAdminUser(props.user)
        ? props.user.hasSettingsManagerRole
        : false,
    hasTransactionCreatorRole:
      props.user && isAdminUser(props.user)
        ? props.user.hasTransactionCreatorRole
        : false,
    hasEventAdminRole:
      props.user && isAdminUser(props.user)
        ? props.user.hasEventAdminRole
        : false,
    hasSysAdminRole:
      props.user && isAdminUser(props.user)
        ? props.user.hasSysAdminRole
        : false,
    permissions: getInitialPermissionsValue(),
  };

  console.log({ initialValues });

  const renderAdminPermissions = (formikProps: FormikProps<FormData>) => {
    if (props.mode !== "Administrator") return;
    return (
      <AdminPermissions
        mode={props.user ? "Update" : "Add"}
        getFieldValue={(field) => formikProps.values[field]}
        setFieldValue={formikProps.setFieldValue}
      />
    );
  };

  const renderCompanyPermissions = (formikProps: FormikProps<FormData>) => {
    if (props.mode !== "CompanyUser") return;

    const addButtonEnabled =
      formikProps.values.permissions.length <
        props.currentUserCompanies.length || props.userIsBcnAdmin;

    const simplifiedGetCompanyError = (index: number) => {
      return getCompanyError(formikProps, index);
    };

    const simplifiedGetRoleError = (index: number) => {
      return getRoleError(formikProps, index);
    };

    return (
      <CompanyPermissions
        canAdd={addButtonEnabled}
        setFieldValue={formikProps.setFieldValue}
        setFieldTouched={formikProps.setFieldTouched}
        getRoleError={simplifiedGetRoleError}
        getCompanyError={simplifiedGetCompanyError}
        userIsBcnAdmin={props.userIsBcnAdmin}
        permissions={formikProps.values.permissions}
        currentUserCompanies={props.currentUserCompanies}
      />
    );
  };

  const getUserNameError = (formikProps: FormikProps<FormData>) => {
    const error = formikProps.errors.userName;
    const userNameTouched = formikProps.touched.userName === true;
    const formikErrorExists = error !== undefined;
    const currentErrorExists =
      userNameState === "NotValid" || userNameState === "Taken";

    const showError =
      userNameTouched && (formikErrorExists || currentErrorExists);
    return { error, showError };
  };

  const renderBasicInfo = (formikProps: FormikProps<FormData>) => {
    const userNameErrorInfo = getUserNameError(formikProps);
    console.log({ values: formikProps.values });
    return (
      <BasicInfo
        mode={props.mode}
        disabled={Boolean(props.user)}
        userName={formikProps.values.userName}
        phoneNumber={formikProps.values.phoneNumber}
        userNameState={userNameState}
        onUserNameChanged={(newValue) => {
          formikProps.setFieldValue("userName", newValue);
          setUserNameState("Unknown");
          setUserName(newValue);
        }}
        onPhoneNumberChanged={(newValue) => {
          formikProps.setFieldValue("phoneNumber", newValue);
        }}
        userNameErrorInfo={userNameErrorInfo}
      />
    );
  };

  const getCompanyError = (
    formikProps: FormikProps<FormData>,
    index: number
  ) => {
    if (
      formikProps.touched.permissions &&
      formikProps.touched.permissions[index]?.company &&
      formikProps.errors.permissions &&
      formikProps.errors.permissions[index]
    ) {
      return formikProps.errors.permissions[index]!.company || "";
    }

    return "";
  };

  const getRoleError = (formikProps: FormikProps<FormData>, index: number) => {
    if (
      formikProps.touched.permissions &&
      formikProps.touched.permissions[index]?.role &&
      formikProps.errors.permissions &&
      formikProps.errors.permissions[index]
    ) {
      return formikProps.errors.permissions[index]!.role || "";
    }

    return "";
  };

  const getDialogTitle = () => {
    if (props.user) return "Edit Permissions";

    if (props.mode === "Administrator") return "Add Administrator";
    return "Add Company User";
  };

  return (
    <Observer
      render={() => {
        return (
          <div>
            <Formik
              validationSchema={schema}
              onSubmit={handleSubmit}
              initialValues={initialValues}
              render={(formikProps) => {
                return (
                  <ResponsiveModalShell
                    handleClose={props.handleClose}
                    title={getDialogTitle()}
                    disableSaveButton={formikProps.isSubmitting}
                    handleSave={() => formikProps.submitForm()}
                  >
                    <div>
                      {renderBasicInfo(formikProps)}
                      {props.mode === "CompanyUser" &&
                        renderCompanyPermissions(formikProps)}
                      {props.mode === "Administrator" &&
                        renderAdminPermissions(formikProps)}
                    </div>
                  </ResponsiveModalShell>
                );
              }}
            />
          </div>
        );
      }}
    />
  );
};

export default AddEditUserModal;
