import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Observer } from "mobx-react";
import Alert from "@material-ui/lab/Alert";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import { useHistory } from "react-router";
import eventStore from "stores/admin/events";
import {
  IListedCompanyInEvent,
  EventVisibility,
  CompanyType,
} from "services/api/types";
import { paths } from "routes/Paths";
import CompanyDropdown from "components/Dropdowns/CompanyDropdown";
import { CompanyOption } from "types/options";

type Props = {
  handleClose: () => void;
  eventId: string;
  navigateToEventGroup: (companyId: string) => void;
  existingEventGroups: IListedCompanyInEvent[];
  eventVisibility: EventVisibility;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    autoComplete: {
      minWidth: "30rem",
    },
    checkboxes: {
      marginTop: theme.spacing(2),
    },
    content: {
      display: "grid",
      gridTemplateColumns: "auto 1fr",
      gridGap: theme.spacing(2),
    },
    label: {
      textAlign: "right",
      fontWeight: "bold",
    },
    note: {
      fontSize: "0.9rem",
      color: theme.palette.grey[500],
    },
    errorMessage: {
      marginBottom: theme.spacing(2),
    },
  })
);

const CreateEventGroupModal = (props: Props) => {
  const [errorMessage, setErrorMessage] = React.useState("");
  const [duplicateEventGroup, setDuplicateEventGroup] = React.useState<
    IListedCompanyInEvent | undefined
  >();
  const [selectedOption, setSelectedOption] = React.useState<
    CompanyOption | undefined
  >(undefined);

  const classes = useStyles();

  React.useEffect(() => {
    setErrorMessage("");
    setDuplicateEventGroup(undefined);
  }, [selectedOption]);

  const sponsorCompanyTypeRef = React.useRef<CompanyType[]>(["Sponsor"]);
  const memberCompanyTypeRef = React.useRef<CompanyType[]>(["Member"]);
  const memberSponsorTypeRef = React.useRef<CompanyType[]>([
    "Member",
    "Sponsor",
  ]);

  const getCompanyTypes = (): React.MutableRefObject<CompanyType[]> => {
    switch (props.eventVisibility) {
      case "Members":
        return memberCompanyTypeRef;
      case "Sponsors":
        return sponsorCompanyTypeRef;
      default:
        return memberSponsorTypeRef;
    }
  };

  const handleCreateEventGroup = async () => {
    if (!selectedOption) alert("Please select a company");
    const result = await eventStore.signUpCompany(
      props.eventId,
      selectedOption!.value
    );

    if (typeof result === "string") {
      setErrorMessage(result);
      // Probably a duplicate company Id. Let's consult our list...
      const existingEventGroup = props.existingEventGroups.find(
        (e) =>
          e.companyId === selectedOption!.value &&
          e.eventGroupStatus !== "Cancelled"
      );
      setDuplicateEventGroup(existingEventGroup);
    } else {
      props.navigateToEventGroup(result.companyId);
      props.handleClose();
    }
  };

  const history = useHistory();

  const navigateToDuplicateEventGroup = () => {
    const { eventId, companyId } = duplicateEventGroup!;
    // https://www.buildersclubnorth.com/app/admin/events/31cf0a4e-572a-4202-88b3-08d865f87128/eventGroups/4c72f17c-57c5-4a73-68f4-08d8c9434e5b
    const url = `${paths.app.admin.root}/events/${eventId}/companies/${companyId}`;
    history.push(url);
    props.handleClose();
  };

  return (
    <Observer
      render={() => {
        return (
          <ResponsiveModalShell
            title="Create Event Group"
            saveButtonCaption={
              duplicateEventGroup ? "Go To Existing Event Group" : "Save"
            }
            handleSave={() => {
              if (duplicateEventGroup) {
                navigateToDuplicateEventGroup();
              } else {
                handleCreateEventGroup();
              }
            }}
            handleClose={props.handleClose}
          >
            {errorMessage ? (
              <Alert className={classes.errorMessage} severity="error">
                {errorMessage}
              </Alert>
            ) : undefined}
            <CompanyDropdown
              userType="Admin"
              label="Search companies"
              companyTypes={getCompanyTypes()}
              selectedOption={selectedOption}
              onSelectedOptionChanged={setSelectedOption}
            />
          </ResponsiveModalShell>
        );
      }}
    />
  );
};

export default CreateEventGroupModal;
