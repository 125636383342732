import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { Observer } from "mobx-react";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import List from "@material-ui/core/List";
import Chip from "@material-ui/core/Chip";
import red from "@material-ui/core/colors/red";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import searchStore from "stores/search";
import myStore from "stores/my";
import SearchBox from "components/Dropdowns/SearchBox";
import NoDataMessage from "components/Shell/NoDataMessage";
import { withRouter, RouteComponentProps } from "react-router-dom";
import CompaniesGrid from "components/Shell/CompaniesGrid";
import { companyColors } from "areas/Shared/companyColors";
import clsx from "clsx";

import {
  IGlobalSearchResponseCompany,
  IGlobalSearchResponseContact,
} from "services/api/types";
import { paths } from "routes/Paths";

type Props = RouteComponentProps;

const SearchBoxContainer = (props: Props) => {
  const [popperAnchor, setPopperAnchor] = React.useState<HTMLElement | null>(
    null
  );
  const [isPopperOpen, setIsPopperOpen] = React.useState(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      searchBoxContainerRoot: {
        // paddingLeft: theme.spacing(2),
      },
      popover: {
        marginTop: theme.spacing(1),
        transform: "translateX(-100px)",
        [theme.breakpoints.down("sm")]: {
          transform: "unset",
        },
      },
      sponsor: {
        color: companyColors.sponsor,
      },
      competitor: {
        color: companyColors.competitor,
      },
      prospect: {
        color: companyColors.prospect,
      },
      member: {
        color: companyColors.member,
      },
      searchResults: {
        display: "grid",
        gridGap: theme.spacing(2),
        gridTemplateColumns: "1fr 1fr 1fr auto",
        alignItems: "start",
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "1fr",
          gridGap: 0,
        },
      },
      paper: {
        padding: theme.spacing(2),
        maxHeight: "80vh",
      },
      inactiveLabel: {
        backgroundColor: red[800],
        color: theme.palette.common.white,
      },
      listItem: {
        padding: 0,
        minWidth: "250px",
        [theme.breakpoints.down("sm")]: {
          minWidth: "unset",
        },
      },
      noDataHost: {
        padding: 0,
        minWidth: "250px",
        [theme.breakpoints.down("sm")]: {
          minWidth: "unset",
        },
      },
      spinnerHost: {
        minWidth: "500px",
        textAlign: "center",
        padding: theme.spacing(8),
        [theme.breakpoints.down("sm")]: {
          minWidth: "unset",
        },
      },
      activeToggle: {
        marginLeft: "1em",
      },
      companiesGrid: {
        minWidth: 500,
        [theme.breakpoints.only("xs")]: {
          minWidth: 300,
        },
      },
    })
  );
  const classes = useStyles();

  const clearQuery = () => {
    searchStore.setQuery("");
    myStore.getRecentCompanies();
  };

  const renderSearchBox = () => {
    return (
      <SearchBox
        showBorder={false}
        onEnterPressed={handleOnEnterPressed}
        onChange={(query) => {
          searchStore.setQuery(query);
          setIsPopperOpen(true);
        }}
        onQueryCleared={clearQuery}
        onFocus={handleSearchBoxFocus}
        value={searchStore.nextQuery}
      />
    );
  };

  const navigateToCompany = (companyId: string) => {
    props.history.push(
      `${paths.app.admin.companyDetails}/${companyId}/overview`
    );
  };

  const navigateToCompanyContact = (companyId: string, contactId: string) => {
    props.history.push(
      `${paths.app.admin.companyDetails}/${companyId}/contacts/${contactId}`
    );
  };

  const handleOnEnterPressed = () => {
    if (searchStore.activeQuery.length > 0) {
      const memberCount = searchStore.results.members.length;
      const suppliersCount = searchStore.results.suppliers.length;
      const contactCount = searchStore.results.contacts.length;

      if (memberCount === 1 && suppliersCount === 0 && contactCount === 0) {
        navigateToCompany(searchStore.results.members[0].id);
      } else if (
        memberCount === 0 &&
        suppliersCount === 1 &&
        contactCount === 0
      ) {
        navigateToCompany(searchStore.results.suppliers[0].id);
      }
      window.setTimeout(() => {
        setIsPopperOpen(false);
      }, 1000);
    }
  };

  const handleSearchBoxFocus = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setPopperAnchor(evt.currentTarget as any);
    setIsPopperOpen(true);
  };

  const renderRecent = () => {
    if (!myStore.recentCompanies) return <div></div>;

    const { members, suppliers } = myStore.recentCompanies;

    return (
      <div className={classes.companiesGrid}>
        <CompaniesGrid
          header="My Recent Companies"
          suppliers={suppliers}
          members={members}
          onLinkClicked={() => {
            setIsPopperOpen(false);
          }}
        />
      </div>
    );
  };

  const renderCompany = (s: IGlobalSearchResponseCompany) => {
    return (
      <ListItem
        onClick={() => {
          navigateToCompany(s.id);
          setIsPopperOpen(false);
        }}
        classes={{
          root: clsx(classes.listItem, {
            [classes.member]: s.companyType === "Member",
            [classes.sponsor]: s.companyType === "Sponsor",
            [classes.prospect]: s.companyType === "Prospect",
            [classes.competitor]: s.companyType === "Competitor",
          }),
        }}
        button={true}
        key={s.id}
      >
        <ListItemText
          primary={
            <div>
              <strong>{`${s.name} (${s.identifier})`}</strong>
            </div>
          }
          secondary={s.primaryLocation ? s.primaryLocation.city : ""}
        />
        {s.isActive ? undefined : (
          <Chip
            className={classes.inactiveLabel}
            size="small"
            label="Inactive"
          />
        )}
      </ListItem>
    );
  };

  const getContactText = (contact: IGlobalSearchResponseContact) => {
    if (contact.email) {
      return `${contact.name} (${contact.email})`;
    }

    return contact.name;
  };

  const renderCompanyGroup = (
    title: string,
    companies: IGlobalSearchResponseCompany[]
  ) => {
    if (!companies) {
      console.error("No companies for " + title);
    }

    return (
      <div>
        <Typography variant="caption" color="secondary">
          {title}
        </Typography>
        {!companies || companies.length === 0 ? (
          <NoDataMessage alignLeft={true} dim={true} message="None found" />
        ) : (
          <List dense={true}>
            {companies.map((company) => renderCompany(company))}
          </List>
        )}
      </div>
    );
  };

  const renderContactResults = () => {
    return (
      <div>
        <Typography variant="caption" color="secondary">
          Contacts
        </Typography>
        {searchStore.results.contacts.length === 0 ? (
          <div className={classes.noDataHost}>
            <NoDataMessage alignLeft={true} dim={true} message="None Found" />
          </div>
        ) : (
          <List dense={true}>
            {searchStore.results.contacts.map((contact) => (
              <ListItem
                key={contact.id}
                classes={{ root: classes.listItem }}
                onClick={() => {
                  if (contact.company) {
                    navigateToCompanyContact(contact.company.id, contact.id);
                  }
                  setIsPopperOpen(false);
                }}
                button={true}
              >
                <ListItemText
                  primary={
                    <div>
                      <strong>{getContactText(contact)}</strong>
                    </div>
                  }
                  secondary={contact.company ? contact.company.name : ""}
                />
                {contact.isActive ? undefined : (
                  <Chip
                    className={classes.inactiveLabel}
                    size="small"
                    label="Inactive"
                  />
                )}
              </ListItem>
            ))}
          </List>
        )}
      </div>
    );
  };

  const renderInactiveToggle = () => {
    return (
      <FormControlLabel
        className={classes.activeToggle}
        control={
          <Switch
            checked={searchStore.activeOnly}
            onChange={(evt) =>
              searchStore.setActiveOnly(evt.currentTarget.checked)
            }
            value="active-only"
            color="secondary"
          />
        }
        label="Hide Inactive"
      />
    );
  };

  const renderSearchResults = () => {
    if (searchStore.loading) {
      return (
        <div className={classes.spinnerHost}>
          <CircularProgress />
        </div>
      );
    }

    const suppliers = searchStore.results.suppliers;
    // Adjust the suppliers so sponsors are first
    const sponsors = suppliers.filter((c) => c.companyType === "Sponsor");
    const prospect = suppliers.filter((c) => c.companyType === "Prospect");
    const competitors = suppliers.filter((c) => c.companyType === "Competitor");

    const suppliersWithSortOrderTweaked = [
      ...sponsors,
      ...prospect,
      ...competitors,
    ];

    return (
      <div className={classes.searchResults}>
        {renderCompanyGroup("Members", searchStore.results.members)}
        {renderCompanyGroup("Suppliers", suppliersWithSortOrderTweaked)}
        {renderContactResults()}
        {renderInactiveToggle()}
      </div>
    );
  };

  const renderPopper = () => {
    return (
      <Popover
        className={classes.popover}
        elevation={20}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableRestoreFocus={true}
        anchorEl={popperAnchor}
        onClose={() => {
          setIsPopperOpen(false);
        }}
        classes={{ paper: classes.paper }}
        open={isPopperOpen}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        {searchStore.activeQuery.length === 0 && myStore.recentCompanies
          ? renderRecent()
          : renderSearchResults()}
      </Popover>
    );
  };

  return (
    <Observer
      render={() => {
        return (
          <div className={classes.searchBoxContainerRoot}>
            {renderSearchBox()}
            {renderPopper()}
          </div>
        );
      }}
    />
  );
};

export default withRouter(SearchBoxContainer);
