import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import companyStore from "stores/shared/company";
import { CompanyOption } from "types/options";
import ResponsiveModalShell from "components/Shell/ResponsiveModalShell";
import CategorySelect from "components/Dropdowns/CategorySelect";
import CompanySelect from "components/Dropdowns/CompanySelect";
import Typography from "@material-ui/core/Typography";
import { Option, CompanyType } from "services/api/types";

type Props = {
  handleClose: () => void;
  supplierId: string;
  currentUserType: "Admin" | "Sponsor";
};

const AddSupplierPurchaser = (props: Props) => {
  const [saveError, setSaveError] = React.useState<string | undefined>();
  const [selectedCategory, setSelectedCategory] = React.useState<
    Option | undefined
  >();
  const [selectedCompany, setSelectedCompany] =
    React.useState<CompanyOption | null>(null);

  const [companyError, setCompanyError] = React.useState<string | undefined>();
  const [categoryError, setCategoryError] = React.useState<
    string | undefined
  >();

  const [isSaving, setIsSaving] = React.useState(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      error: {
        color: "red",
      },
    })
  );

  const classes = useStyles();

  const handleSave = async () => {
    setSaveError(undefined);
    const categoryErrorLocal =
      selectedCategory === undefined ? "Category is required" : undefined;
    const companyErrorLocal =
      selectedCompany === null ? "Member is required" : undefined;

    setCategoryError(categoryErrorLocal);
    setCompanyError(companyErrorLocal);

    if (categoryErrorLocal === undefined && companyErrorLocal === undefined) {
      setIsSaving(true);
      try {
        await companyStore.addPurchaserSupplier(
          selectedCompany!.value,
          selectedCategory!.value,
          props.supplierId
        );
        props.handleClose();
      } catch (e) {
        const error: any = e;
        if (error.status === 400) {
          setSaveError(error.data);
          setIsSaving(false);
        }
      }
    }
  };

  const handleCategoryChange = (value: Option) => {
    if (value) {
      setCategoryError(undefined);
    }

    setSelectedCategory(value);
  };

  const handleCompanyChange = (value: CompanyOption) => {
    if (value) {
      setCompanyError(undefined);
    }

    setSelectedCompany(value);
  };

  const purchaserCompanyTypeRef = React.useRef<CompanyType[]>([
    "Member",
    "Sponsor",
  ]);

  return (
    <ResponsiveModalShell
      handleClose={props.handleClose}
      title="Add Member"
      disableSaveButton={isSaving}
      handleSave={handleSave}
    >
      <div style={{ minWidth: 400 }}>
        {saveError ? (
          <Typography className={classes.error}>{saveError}</Typography>
        ) : undefined}
        <FormControl fullWidth={true}>
          <CategorySelect
            onChanged={handleCategoryChange}
            value={selectedCategory}
            hasError={Boolean(categoryError)}
          />
          <FormHelperText className={classes.error}>
            {categoryError}
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth={true}>
          <CompanySelect
            label="Member"
            currentUserType={props.currentUserType}
            searchCompanyTypes={purchaserCompanyTypeRef}
            errorClassName={classes.error}
            noRecordsText="No members found"
            value={selectedCompany}
            onChange={handleCompanyChange}
            errorMessage={companyError || ""}
          />
        </FormControl>
      </div>
    </ResponsiveModalShell>
  );
};

export default AddSupplierPurchaser;
