import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import { ICompanyPermissionViewModel, CompanyType } from "services/api/types";
import { CompanyOption } from "types/options";
import { companyRoleOptions } from "types/roles";
import FormHelperText from "@material-ui/core/FormHelperText";
import CompanySelect from "components/Dropdowns/CompanySelect";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MuiTextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import TrashIcon from "@material-ui/icons/Delete";
import FormControl from "@material-ui/core/FormControl";
import { FieldArray } from "formik";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

export interface ICompanyPermission {
  company: CompanyOption | null;
  role: RoleOption | null;
}

export type RoleOption = {
  value: number;
  label: string;
};

type Props = {
  currentUserCompanies: ICompanyPermissionViewModel[];
  userIsBcnAdmin: boolean;
  permissions: ICompanyPermission[];
  canAdd: boolean;
  getCompanyError: (index: number) => string;
  getRoleError: (index: number) => string;
  setFieldTouched: (fieldName: string) => void;
  setFieldValue: (fieldName: string, value: any) => void;
};

const CompanyPermissions = (props: Props) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {},
      formControl: {},
      error: {
        color: "red",
      },
      addButton: {
        marginTop: theme.spacing(2),
      },
      header: {
        margin: theme.spacing(2, 0),
      },
      permissionGroup: {
        [theme.breakpoints.down("xs")]: {
          borderBottom: "1px solid gray",
          padding: theme.spacing(1, 0),
        },
      },
    })
  );

  const classes = useStyles();
  const sponsorAndMemberCompanyTypeRef = React.useRef<CompanyType[]>([
    "Member",
    "Sponsor",
  ]);

  const getRoleOptions = (index: number): RoleOption[] => {
    const permission = props.permissions[index];
    if (permission && permission.company) {
      if (permission.company.companyType === "Member") {
        return companyRoleOptions.filter((r) => r.value < 200);
      } else if (permission.company.companyType === "Sponsor") {
        return companyRoleOptions.filter((r) => r.value > 200);
      }
    }

    return [];
  };

  const getHardCodedOptionsForCompanyAdmin = (
    valueOfSelect: CompanyOption | null,
    index: number
  ) => {
    if (props.userIsBcnAdmin) return undefined;

    const usedPermissions = props.permissions
      .filter((permission, i) => {
        return i !== index && permission.company;
      })
      .map((f) => f.company!.value);

    return props.currentUserCompanies
      .filter((p) => usedPermissions.indexOf(p.companyId) < 0)
      .map((p) => {
        const result: CompanyOption = {
          computedBalance: p.pointBalance,
          label: `${p.companyName} (${p.companyIdentifier})`,
          value: p.companyId,
          companyType: p.companyType,
        };

        return result;
      });
  };

  const filterCompanyOptions = (options: CompanyOption[]) => {
    const used = props.permissions
      .filter((p) => p.company !== null)
      .map((p) => p.company!.value);

    const unused = options.filter((o) => used.indexOf(o.value) < 0);
    return unused;
  };

  const renderCompanySelect = (
    company: CompanyOption | null,
    index: number
  ) => {
    const companyError = props.getCompanyError(index);

    const onlyAvailableOptions = getHardCodedOptionsForCompanyAdmin(
      company,
      index
    );

    return (
      <CompanySelect
        label="Company"
        searchCompanyTypes={sponsorAndMemberCompanyTypeRef}
        optionsFilter={(options) => filterCompanyOptions(options)}
        errorMessage={companyError}
        onBlur={(evt) => {
          props.setFieldTouched(`permissions.${index}.company`);
        }}
        errorClassName={classes.error}
        onlyAvailableOptions={onlyAvailableOptions}
        value={company}
        currentUserType={props.userIsBcnAdmin ? "Admin" : "Sponsor"}
        onChange={(option) => {
          props.setFieldValue(`permissions.${index}.company`, option);
          props.setFieldValue(`permissions.${index}.role`, null);
        }}
        className={classes.formControl}
      />
    );
  };

  const renderRole = (role: RoleOption | null, index: number) => {
    const error = props.getRoleError(index);

    const roleOptions = getRoleOptions(index);
    console.log("roleOptions", roleOptions);

    return (
      <FormControl fullWidth>
        <Autocomplete
          value={role}
          noOptionsText="Select a company first"
          loading={false}
          onBlur={(evt) => {
            props.setFieldTouched(`permissions.${index}.role`);
          }}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            if (typeof option.label === "string") {
              return option.label;
            }

            return (option as any).label.label;
          }}
          renderInput={(params) => (
            <MuiTextField
              {...params}
              variant="outlined"
              label="Role"
              error={Boolean(error)}
              placeholder="Select role"
              style={{ margin: 0 }}
              InputProps={{
                ...params.InputProps,
              }}
              margin="dense"
            />
          )}
          onChange={(evt: any, option: any) => {
            props.setFieldValue(`permissions.${index}.role`, option);
          }}
          options={roleOptions}
        />
        <FormHelperText variant="standard" className={classes.error}>
          {error}
        </FormHelperText>
      </FormControl>
    );
  };

  const renderPermission = (
    permission: ICompanyPermission,
    index: number,
    remove: (index: number) => void
  ) => {
    return (
      <Grid
        className={classes.permissionGroup}
        container
        spacing={1}
        alignItems="center"
        key={index}
      >
        <Grid item xs={12} sm={6}>
          {renderCompanySelect(permission.company, index)}
        </Grid>
        <Grid item xs={11} sm={5}>
          {renderRole(permission.role, index)}
        </Grid>
        <Grid item xs={1}>
          <IconButton size="small" onClick={() => remove(index)}>
            <TrashIcon />
          </IconButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Typography
        className={classes.header}
        component="p"
        color="primary"
        variant="caption"
      >
        Company Permissions
      </Typography>
      <FieldArray
        key="permissions"
        name="permissions"
        render={({ push, remove }) => {
          return (
            <div>
              <div>
                {props.permissions.map(
                  (permission: ICompanyPermission, index: number) => {
                    return renderPermission(permission, index, remove);
                  }
                )}
                {props.permissions.length === 0 && <div>None Found</div>}
              </div>
              <Button
                className={classes.addButton}
                disabled={!props.canAdd}
                variant="contained"
                size="small"
                onClick={() => {
                  const permission: ICompanyPermission = {
                    company: null,
                    role: null,
                  };
                  push(permission);
                }}
              >
                Add Company
              </Button>
            </div>
          );
        }}
      />
    </>
  );
};

export default CompanyPermissions;
